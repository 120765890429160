import "./MerchantMenuComponent.scss";
import { Box, Checkbox, Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { GrabbiMerchandise } from "types/GrabbiTypes";
import GrabbiTable, {
  GrabbiTableColumn,
} from "components/Shared/GrabbiTable/GrabbiTableComponent";
import GrabbiTabs from "components/Shared/GrabbiTabs/GrabbiTabsComponent";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";
import { useSelector } from "react-redux";
import { State } from "store";
import StoreDashboard from "components/Shared/StoreDashboard/StoreDashboardComponent";
import CreateDeleteMerchandiseDialog from "./EditMerchandiseDialog/CreateDeleteMerchandiseDialog";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import ToppingsTable from "./ToppingsDialog/ToppingsTableComponent";
import UpdatingRow from "components/Shared/UpdatingRow/UpdatingRowComponent";
import { Link } from "react-router-dom";
import { useAxiosContext } from "components/Shared/AxiosProvider/AxiosProvider";
import GrabbiDropdown from "components/Shared/GrabbiDropdown/GrabbiDropdown";
import useMerchandiseHooks from "hooks/MerchandiseHooks";

interface Props {
  className?: string;
}

interface EditMerchandiseState {
  open: boolean;
  mode?: "ADD" | "UPDATE" | "DELETE";
  merchandise?: GrabbiMerchandise;
}

const initialDialogState = {
  open: false,
};

const MerchantMenu: React.FC<Props> = ({ className }) => {
  const { useAxios } = useAxiosContext();
  const { useGetMerchandise } = useMerchandiseHooks();
  const [updatingRows, setUpdatingRows] = useState<number[]>([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  const handlePageChange = (pageSize: number, pageNumber: number) => {
    setPageSize(pageSize);
    setPageNumber(pageNumber);
  };

  const selectedStore = useSelector(
    (state: State) => state.storeList.selectedStore
  );

  const [
    {
      data: dataMerchandise,
      loading: loadingMerchandise,
      error: errorMerchandise,
    },
    getMerchandise,
  ] = useGetMerchandise();

  const [initCategories, setInitCategories] = useState(true);
  const [menuCategories, setMenuCategories] = useState<string[]>([
    "All",
    "Add-Ons",
  ]);
  const [selectedCategory, setSelectedCategory] = useState<number>(0);

  useEffect(() => {
    if (dataMerchandise && initCategories) {
      const newArr = menuCategories;
      for (const merch of dataMerchandise) {
        const cat = merch.menuCategory;
        if (cat && !newArr.includes(cat)) {
          newArr.push(cat);
        }
      }
      setInitCategories(false);
      setMenuCategories([...newArr, "Unassigned"]);
    }
  }, [dataMerchandise, initCategories, menuCategories, setMenuCategories]);

  const sortMerchandise = (merchandise: GrabbiMerchandise[]) => {
    return merchandise.sort((a: GrabbiMerchandise, b: GrabbiMerchandise) => {
      return (
        `${a?.createdDate ?? "0"}`.localeCompare(`${b?.createdDate ?? "0"}`) *
        -1
      );
    });
  };

  const sortedMerchandise = dataMerchandise
    ? sortMerchandise(dataMerchandise)
    : undefined;

  const [
    {
      data: dataUpdateMerch,
      loading: loadingUpdateMerch,
      error: errorUpdateMerch,
    },
    updateMerchandise,
  ] = useAxios({
    url: `restful-services/merchandise/update`,
    method: "POST",
  });

  const { enqueueSnackbar } = useSnackbar();
  const [tab, setTab] = useState<number>(0);
  const [toppingUpc, setToppingUpc] = useState<number | undefined>();
  const [dialogState, setDialogState] = useState<EditMerchandiseState>({
    open: false,
  });

  const storeUpc = selectedStore?.upcNumber;

  useEffect(() => {
    if (storeUpc) {
      getMerchandise({ id: parseInt(storeUpc) });
    }
    // eslint-disable-next-line
  }, [storeUpc]);

  useEffect(() => {
    if (errorMerchandise) {
      console.error(errorMerchandise);
      enqueueSnackbar("Failed to fetch merchandise", { variant: "error" });
    }
  }, [errorMerchandise, enqueueSnackbar]);

  useEffect(() => {
    if (dataUpdateMerch) {
      enqueueSnackbar("Update completed", { variant: "success" });
    } else if (errorUpdateMerch) {
      console.error(errorUpdateMerch);
      enqueueSnackbar("Update failed", { variant: "error" });
    }
  }, [dataUpdateMerch, errorUpdateMerch, enqueueSnackbar]);

  useEffect(() => {
    if (loadingUpdateMerch && toppingUpc !== undefined) {
      setToppingUpc(undefined);
    }
  }, [toppingUpc, loadingUpdateMerch]);

  const handleTabChange = (index: number) => {
    setTab(index);
    setPageNumber(0);
  };

  const handleStatusChange =
    (merchandise: GrabbiMerchandise, index: number) =>
    async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setUpdatingRows([...updatingRows, index]);
      if (selectedStore?.id) {
        const payload: GrabbiMerchandise = {
          ...merchandise,
          store: {
            id: selectedStore.id,
          },
          available: !merchandise.available,
        };
        await updateMerchandise({
          data: payload,
        });
        await getMerchandise({ id: selectedStore.id });
        setUpdatingRows(updatingRows.splice(-1, 1));
      }
    };

  const handleShowToppings = (merchandise: GrabbiMerchandise) => {
    setToppingUpc(merchandise.upc.upcNumber);
  };

  const handleAddMerchandise = () => {
    setDialogState({
      open: true,
      mode: "ADD",
    });
  };

  // const handleDeleteMerchandise = (merchandise: GrabbiMerchandise) => {
  //   setDialogState({
  //     merchandise,
  //     open: true,
  //     mode: "DELETE",
  //   });
  // };

  const handleCloseDialog = () => {
    setDialogState(initialDialogState);
  };

  const TABLE_CONFIG: GrabbiTableColumn[] = [
    new GrabbiTableColumn(<div className="checkmark_header" />, [], {
      mobilePlacement: "summary",
      render: (item: GrabbiMerchandise, index: number) => {
        return (
          <Checkbox
            checked={item.available}
            onChange={handleStatusChange(item, index)}
            classes={{
              root: "checkbox",
            }}
          />
        );
      },
    }),
    new GrabbiTableColumn("Name", ["upc", "label"], {
      mobilePlacement: "summary",
      mobile: {
        headerProps: {
          className: "name_column",
        },
        bodyProps: {
          className: "name_column",
        },
      },
    }),
    new GrabbiTableColumn("Price", [], {
      render: (item: GrabbiMerchandise) => {
        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        });
        return formatter.format(item.price);
      },
    }),
    new GrabbiTableColumn("Tax Amount", [], {
      render: (item: GrabbiMerchandise) => {
        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        });
        return formatter.format(item.taxAmount);
      },
    }),
    new GrabbiTableColumn("", [], {
      mobilePlacement: "summary",
      normal: {
        headerProps: {
          className: "button_column",
          align: "right",
        },
        bodyProps: {
          className: "button_column",
          align: "right",
        },
      },
      render: (item: GrabbiMerchandise) => {
        return (
          <div>
            <div className="button_wrapper">
              {item?.toppings?.length > 0 ? (
                <GrabbiButton onClick={() => handleShowToppings(item)}>
                  Add-Ons
                </GrabbiButton>
              ) : (
                <div style={{ width: 100 }}></div>
              )}
              <Link
                to={`${selectedStore?.upcNumber}/merch/edit/${item.upc.upcNumber}`}
                className="edit_button"
              >
                <GrabbiButton>EDIT</GrabbiButton>
              </Link>
              {/* <GrabbiButton onClick={() => handleDeleteMerchandise(item)}>
                Delete
              </GrabbiButton> */}
            </div>
          </div>
        );
      },
    }),
  ];

  const showAvailableMerchandise = tab === 0;

  const shownMerchandise = sortedMerchandise
    ?.filter((item) => item.available === showAvailableMerchandise)
    .filter((item) => {
      if (selectedCategory === 0) {
        return true;
      } else if (selectedCategory === 1) {
        return item.menuItemType === 1;
      } else if (selectedCategory === menuCategories.length - 1) {
        return item.menuCategory === undefined;
      } else {
        return item.menuCategory === menuCategories[selectedCategory];
      }
    });

  const handleClose = () => {
    setToppingUpc(undefined);
  };

  return (
    <Grid className={`merchant_menu_root ${className ?? ""}`} container>
      <StoreDashboard dashboardTitle="Merchant Menu">
        {dialogState.open && (
          <CreateDeleteMerchandiseDialog
            callback={() => {
              if (selectedStore?.id) getMerchandise({ id: selectedStore.id });
            }}
            onClose={handleCloseDialog}
            open={dialogState.open}
            merchandise={dialogState.merchandise}
            mode={dialogState.mode}
          />
        )}
        <div className="action_bar">
          <GrabbiButton onClick={handleAddMerchandise}>Add</GrabbiButton>
        </div>
        {menuCategories && (
          <GrabbiDropdown
            label="Menu Category"
            options={menuCategories}
            selectedIndex={selectedCategory}
            handleChange={(index) => setSelectedCategory(index)}
          />
        )}
        {toppingUpc && selectedStore?.id && (
          <ToppingsTable
            onClose={handleClose}
            merchandiseId={toppingUpc}
            storeId={selectedStore?.id}
            open={toppingUpc !== undefined}
          />
        )}
        <GrabbiTabs
          selectedTab={tab}
          tabs={DASHBOARD_TABS}
          callback={handleTabChange}
          classes={{
            tabs: {
              root: "tabs",
            },
            mobile: "tabs_mobile",
          }}
        />

        {sortedMerchandise && shownMerchandise && (
          <GrabbiTable
            className="merchant_menu_table"
            columns={TABLE_CONFIG}
            data={shownMerchandise}
            enablePagination={true}
            defaultOrderBy="updatedDate"
            defaultSortDirection="asc"
            overridePageNumber={pageNumber}
            overridePageSize={pageSize}
            onPageChange={handlePageChange}
            rowOverride={(key: string) => (
              <UpdatingRow text={"Updating Merchandise"} uniqueKey={key} />
            )}
            overrideRows={updatingRows}
          />
        )}
        {!sortedMerchandise && loadingMerchandise && <LoadingIcon />}
        {sortedMerchandise &&
          sortedMerchandise.length > 0 &&
          shownMerchandise &&
          shownMerchandise.length === 0 && (
            <Box className="no_results_root">
              <Box className="header">
                {`No Merchandise is currently marked as ${
                  showAvailableMerchandise ? "Available" : "Unavailable"
                }`}
              </Box>
            </Box>
          )}
        {sortedMerchandise && sortedMerchandise.length === 0 && (
          <Box className="no_results_root">
            <Box className="header">
              {`This store currently has no merchandise`}
            </Box>
          </Box>
        )}
        {!sortedMerchandise && !loadingMerchandise && (
          <Box className="no_results_root">
            <Box className="header">
              Failed to load Merchandise, please refresh and try again
            </Box>
          </Box>
        )}
      </StoreDashboard>
    </Grid>
  );
};

export default MerchantMenu;

const DASHBOARD_TABS = ["AVAILABLE", "UNAVAILABLE"];

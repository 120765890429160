import React from "react";
import ReactDOM from "react-dom";
import "index.scss";
import App from "App";
import * as serviceWorker from "serviceWorker";
import { StylesProvider } from "@material-ui/core/styles";

import createSagaMiddleware from "redux-saga";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { logger } from "redux-logger";
import rootReducers from "store";
import mySaga from "store/saga";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducers,
  composeWithDevTools(applyMiddleware(sagaMiddleware, thunk, logger))
);

sagaMiddleware.run(mySaga);

ReactDOM.render(
  <React.StrictMode> 
    <StylesProvider injectFirst>
      <Provider store={store}>
        <App />
      </Provider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

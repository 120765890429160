import React from 'react';
import './Home.scss';
import { NavLink } from "react-router-dom";
import { CONTEXT } from "shared/scripts/UrlContext";

const Home: React.FC = () => {
  return (
    <div className='homepage_container'>
      <div className='header_container'>
        <div className='main_header_text_and_logo_container'>
          <div className='main_header_text'>
            <span>WE MAKE RETAIL SMARTER</span>
          </div>
          <div className='main_header_logo_container'></div>
        </div>

        <div className='sub_header_text_container'>
          <div className='sub_header_text'>
            <span>Increase your profits, simplify operations and gain more customers</span>
          </div>
          <div className="signup_header_button_container">
            <NavLink
                className="signup_header_button"
                to={`${CONTEXT}getstarted`}
              >GET STARTED NOW</NavLink>
          </div>
        </div>
      </div>

      <div className='grabbi_offering_section'>
        <div className='grabbi_offering_section_header_text_container'>
          <span className='grabbi_offering_section_header_text'>
            With Grabbi, WE CAN PROVIDE
          </span>
        </div>
        <div className='grabbi_offering_section_grid'>
          <div className='grabbi_offering_section_left_column'>
            <div className='grabbi_feature_header_text_container'>
              <span className='grabbi_feature_header_text'>
                contactless shopping platform
              </span>
            </div>
            <div className='grabbi_feature_body_text_container'>
              <span className='grabbi_feature_body_text'>
                no friction, no need to install any apps.
              </span>
            </div>
          </div>
          <div className='grabbi_offering_section_middle_column'>
            <div className='grabbi_feature_header_text_container'>
              <span className='grabbi_feature_header_text'>
                fighting chance against amazon, uber eats, grubhub
              </span>
            </div>
            <div className='grabbi_feature_body_text_container'>
              <span className='grabbi_feature_body_text'>
                world class data analytics and insights
              </span>
            </div>
          </div>
          <div className='grabbi_offering_section_right_column'>
            <div className='grabbi_feature_header_text_container'>
              <span className='grabbi_feature_header_text'>
                increase revenue and build deeper customer relationships
              </span>
            </div>
            <div className='grabbi_feature_body_text_container'>
              <span className='grabbi_feature_body_text'>
                white label loyalty, coupons, promotions and rewards
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className='merchant_schedule_demo_section'>
        <div className='merchant_schedule_demo_section_grid'>
          <div className='merchant_schedule_demo_left_column'>
            <div className='merchant_schedule_demo_header_left_container'>
              <span className='merchant_schedule_demo_header_text'>
                Get Started
              </span>
            </div>
            <div className="schedule_demo_body_container">
              <span className='schedule_demo_body_container_text'>
                Want to see how Grabbi can help your store serve more customers?
              </span>
            </div>
            <div className="schedule_demo_button_container">
              <NavLink
                  className="schedule_demo_button"
                  to={`${CONTEXT}getstarted`}
                >Get Started</NavLink>
            </div>
          </div>
          <div className='merchant_schedule_demo_right_column'>
            <video className='grabbi_signup_video' muted autoPlay={true} loop={true} data-object-fit='cover'>
              <source src={`assets/grabbi-homepage-signup-video-v2.mp4`}
                type='video/mp4'/></video>
          </div>
        </div>
      </div>

      <div className='section_divider divider'>.</div>

      <div className='merchant_schedule_demo_section'>
        <div className='merchant_schedule_demo_section_grid'>
          <div className='merchant_schedule_demo_left_column'>
            <div className='merchant_schedule_demo_header_left_container'>
              <span className='merchant_schedule_demo_header_text'>
                Starter Kit
              </span>
            </div>
            <div className="schedule_demo_body_container">
              <span className='schedule_demo_body_container_text'>
                Order your Grabbi Starter Kit that includes our Smartmenu™ and merchant tablet.
              </span>
            </div>
            <div className="schedule_demo_button_container">
              <NavLink
                  className="schedule_demo_button"
                  to={`${CONTEXT}starterkit`}
                >Order Now</NavLink>
            </div>
          </div>
          <div className='merchant_schedule_demo_right_column'>
             <div className='grabbi_starter_kit_image' style={{
              backgroundImage: `url('assets/grabbi-starter-kit.png')`}}></div>
          </div>
        </div>
      </div>

      <div className='section_divider divider'>.</div>

      <div className='store_insight_section'>
        <div className='store_insight_section_grid'>
          <div className='store_insight_left_column'>
            {/* <div className='store_insight_vip_icon_container'>
              <div className='store_insight_vip_icon' style={{
              backgroundImage: `url('assets/store-insight-vip-icon.png')`}}></div>
            </div>
            <div className='in_store_shopping_header_container'>
              <span className='in_store_shopping_header_text'>
                In Store Shopping ...
              </span>
            </div>
            <div className='store_items_insight_icon_container'>
              <div className='store_items_insight_icon' style={{
              backgroundImage: `url('assets/store-insight-items.png')`}}></div>
            </div> */}

            <div className='store_items_insight_icon_container'>
              <div className='store_items_insight_icon' style={{
              backgroundImage: `url('assets/grabbi-partner-sales-graph.png')`}}></div>
            </div>
          </div>
          <div className='store_insight_right_column'>
            <div className='store_insight_header_right_container'>
              <span className='store_insight_header_text'>
                SMART STORE INSIGHTS
              </span>
            </div>
            <div className="store_insight_body_container">
              <span className='store_insight_body_container_text'>
                Receive actionable product and demographic insights in your neighborhood.
              </span>
            </div>
            <div className="store_insight_signup_button_container">
              <NavLink
                  className="store_insight_signup_button"
                  to={`${CONTEXT}getstarted`}
                >Get Started Today</NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className='section_divider divider'>.</div>

      <div className='install_app_section'>
        <div className='install_app_section_grid'>
          <div className='install_app_left_column'>
            <div className='install_app_header_right_container'>
              <span className='install_app_header_text'>
                SCAN TO ORDER
              </span>
            </div>
            <div className="install_app_buttons_container">
              <div className='app_stores_image_container'>
                <div className='grabbi_menu_qr_code' style={{
                  backgroundImage: `url('assets/grabbi-qr-code-for-menu.png')`}}></div>
              </div>
            </div>
          </div>
          <div className='install_app_right_column'>
            <div className='install_app_payment_and_proof_on_mobile_container'>
              <div className='install_app_payment_and_proof_on_mobile_icon' style={{
              backgroundImage: `url('assets/tilted-payment-and-proof-on-mobile-v2.png')`}}></div>
            </div>
          </div>
        </div>
      </div>

      <div className="ready_to_start_section_background_container"></div>
      <div className='ready_to_start_section'>
        <div className='ready_to_start_container'>
          <div className='ready_to_start_header_container'>
            <span className='ready_to_start_header_text'>
              ready to get started?
            </span>
          </div>
          <div className='ready_to_start_body_container'>
            <span className='ready_to_start_body_text'>
              Get an in-depth tour of the Grabbi Platform.
            </span>
          </div>
          <div className="ready_to_start_button_container">
            <NavLink className="ready_to_start_button"
                to={`${CONTEXT}getstarted`}>
                Get started
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

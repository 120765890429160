export const longWeekdayToShort = (weekdayName: string) => {
  const name = weekdayName.toLocaleLowerCase();

  switch (name) {
    case "monday":
      return "MON";
    case "tuesday":
      return "TUE";
    case "wednesday":
      return "WED";
    case "thursday":
      return "THUR";
    case "friday":
      return "FRI";
    case "saturday":
      return "SAT";
    case "sunday":
      return "SUN";
    default:
      console.error(`Error: Invalid day of week ${weekdayName}`);
      return weekdayName;
  }
};

export const numberToWeekday = (number: number) => {
  switch (number) {
    case 0:
      return "MON";
    case 1:
      return "TUE";
    case 2:
      return "WED";
    case 3:
      return "THUR";
    case 4:
      return "FRI";
    case 5:
      return "SAT";
    case 6:
      return "SUN";
    default:
      return "ERR";
  }
};

import React, { useEffect, useState } from "react";
import { Typography, Button, Box, CircularProgress } from "@material-ui/core";
import "components/AdminConsole/StoreManager/StoreManagerComponent.scss";
import { isMobile } from "shared/scripts/Constants";
import { useWindowSize } from "shared/scripts/ResizeListener";
import GrabbiTable, {
  GrabbiTableColumn,
} from "components/Shared/GrabbiTable/GrabbiTableComponent";
import GrabbiTabs from "components/Shared/GrabbiTabs/GrabbiTabsComponent";
import { GrabbiStore } from "types/GrabbiTypes";
import BottomScrollListener from "react-bottom-scroll-listener";

interface Props {
  storeList?: GrabbiStore[];
  storeListLoading: boolean;
  storePagesFetched: number[];
  lastStorePageFetched: boolean;
  storesPending: number[];
  dispatchGetStoreList: (
    page?: number,
    pageSize?: number,
    sortBy?: string
  ) => void;
  dispatchToggleStoreStatus: (id: number, index: number) => void;
}

const StoreManagerComponent: React.FC<Props> = ({
  storeList,
  storeListLoading,
  storePagesFetched,
  lastStorePageFetched,
  storesPending,
  dispatchGetStoreList,
  dispatchToggleStoreStatus,
}) => {
  useWindowSize();
  const [page, setPage] = useState(1);

  const handleScrollBottom = () => {
    if (!storeListLoading && !lastStorePageFetched) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    if (!storeListLoading) {
      if (!storePagesFetched.includes(page) && !lastStorePageFetched) {
        dispatchGetStoreList(page);
      }
    }
  }, [
    page,
    dispatchGetStoreList,
    lastStorePageFetched,
    storePagesFetched,
    storeListLoading,
  ]);

  const tabs = [isMobile() ? "ALL STORES" : "ALL", "ACTIVATED", "DEACTIVATED"];

  const [activeFilter, setActiveFilter] = React.useState(0);

  const handleTabChange = (value: any): void => {
    setActiveFilter(value);
  };

  const filterStores = (store: GrabbiStore) => {
    if (activeFilter === 1) {
      return store.active;
    } else if (activeFilter === 2) {
      return !store.active;
    } else {
      return true;
    }
  };

  const filteredStores = storeList ? storeList.filter(filterStores) : [];

  useEffect(() => {
    if (
      storeList &&
      !storeListLoading &&
      !lastStorePageFetched &&
      filteredStores.length < 10
    ) {
      setPage(page + 1);
    }
  }, [
    filteredStores.length,
    page,
      setPage,
    storeList,
    storeListLoading,
    lastStorePageFetched,
  ]);

  const handleStatusChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ): void => {
    if (storeList) {
      const id = filteredStores[index].id;
      if (id) {
        dispatchToggleStoreStatus(id, index);
      }
    }
    event.stopPropagation();
  };

  const tableConfig: GrabbiTableColumn[] = [
    new GrabbiTableColumn("Store Name", [], {
      mobilePlacement: "summary",
      render: (store: GrabbiStore) => {
        return (
          <React.Fragment>
            <Typography classes={{ root: `store_manager_cell_text_primary` }}>
              {store.name}
            </Typography>
            <Typography classes={{ root: `store_manager_cell_text_secondary` }}>
              {store.address ? store.address.addressLine1 : ""}
            </Typography>
          </React.Fragment>
        );
      },
    }),
    new GrabbiTableColumn("Owner", [], {
      mobile: {
        bodyProps: {
          align: "right",
        },
      },
      render: (store: GrabbiStore) => {
        return (
          <div>{`${store.merchant?.lastName}, ${store.merchant?.firstName}`}</div>
        );
      },
    }),
    new GrabbiTableColumn("Region", ["address", "country", "code"], {
      mobile: {
        bodyProps: {
          align: "right",
        },
      },
    }),
    new GrabbiTableColumn("Zip Code", ["address", "zip"], {
      mobile: {
        bodyProps: {
          align: "right",
        },
      },
    }),
    new GrabbiTableColumn("status", [], {
      mobile: {
        bodyProps: {
          align: "right",
        },
      },
      render: (store: GrabbiStore) => (
        <React.Fragment>
          <Typography
            classes={{ root: `store_manager_cell_text_primary StoreStatus` }}
          >
            {store.active ? "Activated" : "Deactivated"}
          </Typography>
        </React.Fragment>
      ),
    }),
    new GrabbiTableColumn("Action", [], {
      mobilePlacement: "summary",
      mobile: {
        headerProps: {
          align: "right",
        },
        bodyProps: {
          align: "right",
        },
      },

      render: (store: GrabbiStore, index: number) => {
        const pending = storesPending.includes(index);
        return (
          <Button
            classes={{
              contained: `store_manager_action_button ${
                pending
                  ? "store_manager_action_button_pending"
                  : store.active
                  ? "store_manager_action_button_deactivate"
                  : "store_manager_action_button_activate"
              }`,
            }}
            onClick={(event) => handleStatusChange(event, index)}
            variant="contained"
          >
            {pending ? "PENDING" : store.active ? "DEACTIVATE" : "ACTIVATE"}
          </Button>
        );
      },
    }),
  ];

  return (
    <Box className="store_manager_root">
      {!isMobile() && <Box className="store_manager_heading">STORES</Box>}
      <GrabbiTabs
        tabs={tabs}
        selectedTab={activeFilter}
        callback={handleTabChange}
      />
      {storeList && filteredStores.length !== 0 && (
        <BottomScrollListener
          onBottom={handleScrollBottom}
          triggerOnNoScroll={false}
        >
          <GrabbiTable data={filteredStores} columns={tableConfig} />
        </BottomScrollListener>
      )}
      {storeListLoading && !storeList && (
        <Box className="store_manager_loading_wrapper">
          <CircularProgress
            classes={{ root: "store_manager_loading_icon" }}
            size={60}
          />
        </Box>
      )}
      {storeList && (
        <Box className="store_manager_scroll_status_wrapper">
          <Typography classes={{ root: "store_manager_scroll_results" }}>
            {`Showing ${filteredStores.length} stores`}
          </Typography>
        </Box>
      )}
      {storeListLoading && storeList && (
        <Box className="store_manager_scroll_status_wrapper">
          <Box className="store_manager_scroll_loading_wrapper">
            <CircularProgress
              classes={{
                root: "store_manager_loading_icon",
              }}
              size={24}
            />
            <Typography classes={{ root: "store_manager_scroll_results" }}>
              Loading more stores...
            </Typography>
          </Box>
        </Box>
      )}

      {lastStorePageFetched && (
        <Box className="store_manager_scroll_status_wrapper">
          <Typography classes={{ root: "store_manager_scroll_results" }}>
            No more stores to show.
          </Typography>
        </Box>
      )}
      {storeList && filteredStores.length === 0 && (
        <Box className="store_manager_loading_wrapper">
          <Box className="store_manager_bold_text">No results</Box>
        </Box>
      )}
    </Box>
  );
};

export default StoreManagerComponent;

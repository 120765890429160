import {
  assign,
  cloneDeep,
  filter,
  flatten,
  groupBy,
  map,
  mapValues,
  sortBy,
  sum,
  sumBy,
  values,
} from "lodash";
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  GrabbiMerchandise,
  GrabbiStore,
  GrabbiTopping,
  Transaction,
} from "types/GrabbiTypes";
import Cart from "./Cart/CartComponent";
import MerchandiseListComponent from "./MerchandiseList/MerchandiseListComponent";
import "./MerchandiseSelectorComponent.scss";
import {
  decodeGroupingParams,
  getDependentMerchandisePrice,
} from "./ToppingSelector/utils";

interface Props {
  merchandise: GrabbiMerchandise[];
  store: GrabbiStore;
  transaction?: Transaction; //Initialize the cart from an existing transaction
  onAddToTab?: (singleSelection?: GrabbiMerchandiseSelection) => void;
}

const MerchandiseSelector: React.FC<Props> = ({
  merchandise,
  store,
  transaction,
  onAddToTab,
}) => {
  const { initialized, showCart, initStates, reset } = useMerchSelection();

  useEffect(() => {
    if (!initialized) {
      initStates(merchandise, store, transaction);
    }
  }, [merchandise, store, transaction, initialized, initStates]);

  //Component will unmount
  useEffect(() => {
    return () => {
      reset();
    };
    //! Disabling eslint is necessary here - specifying the dependencies will cause an infinite loop
    // eslint-disable-next-line
  }, []);

  return (
    <div className="merchandise_selector_root">
      {!showCart && <MerchandiseListComponent onAddToTab={onAddToTab} />}
      {showCart && <Cart />}
    </div>
  );
};

export default MerchandiseSelector;

export interface GrabbiMerchandiseSelection {
  price: number;
  taxAmount: number;
  merchandiseId: number;
  merchandise: GrabbiMerchandise;
  toppings: GrabbiTopping[];
  quantity: number;
}

interface ToppingState {
  topping: GrabbiTopping;
  checked: boolean;
  grouping?: string;
}

export interface ToppingGroupState {
  toppingStates: Record<string, ToppingState>;
  grouping: string;
  groupNumber?: number;
  groupCount?: number;
  groupRequired?: boolean;
  canAddMore?: boolean;
  isPosted: boolean;
  isValid?: boolean;
  itemsSelected: number;
}

export interface CartItemEntry {
  merchandise: GrabbiMerchandise;
  expanded?: boolean;
  groupingState: Record<string, ToppingGroupState>;
  quantity: number;
  isValid: boolean;
  isPosted: boolean;
}

export interface SelectionState extends Record<string, CartItemEntry> {}

interface SelectionContextResult {
  cartState: CartItemEntry[];
  showCart: boolean;
  selectionState: CartItemEntry[];
  merchandiseCartSelection: GrabbiMerchandiseSelection[];
  initialized: boolean;
  merchandise?: GrabbiMerchandise[];
  merchandiseByCategory?: Record<string, GrabbiMerchandise[]>;
  store?: GrabbiStore;
  showUnavailable: boolean;
  isPosted: boolean;
  setIsPosted: (posted: boolean) => void;
  getItemSelectedPrice: (itemIndex: number) => number;
  hasOneToppingChecked: (itemIndex: number) => boolean;
  getCartTotal: () => number;
  getCartQuantity: () => number;
  addItemToCart: (itemIndex: number) => void;
  getItemSelection?: (itemIndex: number) => GrabbiMerchandiseSelection;
  toggleExpandSelectionItem: (itemIndex: number) => void;
  toggleSelectionTopping: (index: number, topping: GrabbiTopping) => void;
  toggleShowCart: () => void;
  changeSelectionItemQuantity: (index: number, quantity: number) => void;
  canAddTopping: (
    merchandise: GrabbiMerchandise,
    topping: GrabbiTopping
  ) => boolean;
  isToppingChecked: (
    merch: GrabbiMerchandise,
    topping: GrabbiTopping
  ) => boolean;
  isCartToppingChecked: (index: number, topping: GrabbiTopping) => boolean;
  isCartValid: () => boolean;
  getCartChoicesNeeded: () => number;
  initStates: (
    merchandise: GrabbiMerchandise[],
    store: GrabbiStore,
    transaction?: Transaction
  ) => void;
  toggleShowUnavailable: () => boolean;
  reset: () => void;
}

const SelectionContext = React.createContext<SelectionContextResult>({
  cartState: [],
  showCart: false,
  selectionState: [],
  merchandiseCartSelection: [],
  initialized: false,
  showUnavailable: false,
  isPosted: false,
  setIsPosted: (posted: boolean) => {},
  getItemSelectedPrice: (index: number) => 0,
  hasOneToppingChecked: (index: number) => false,
  getCartTotal: () => 0,
  getCartQuantity: () => 0,
  addItemToCart: (itemIndex: number) => {},
  toggleExpandSelectionItem: (itemIndex: number) => {},
  toggleSelectionTopping: (index: number, topping: GrabbiTopping) => {},
  toggleShowCart: () => {},
  changeSelectionItemQuantity: (index: number, quantity: number) => {},
  canAddTopping: (merchandise: GrabbiMerchandise, topping: GrabbiTopping) =>
    true,
  isToppingChecked: (merch: GrabbiMerchandise, topping: GrabbiTopping) => false,
  isCartToppingChecked: (index: number, topping: GrabbiTopping) => false,
  isCartValid: () => false,
  getCartChoicesNeeded: () => 0,
  initStates: (
    merchandise: GrabbiMerchandise[],
    store: GrabbiStore,
    transaction?: Transaction
  ) => {},
  toggleShowUnavailable: () => false,
  reset: () => {},
});

const SelectionContextProvider = SelectionContext.Provider;

export const useMerchSelection = () => useContext(SelectionContext);

const merchandiseToSelection = (merchandise: GrabbiMerchandise[]) =>
  merchandise.map((merch) => {
    const groupingObject: Record<string, ToppingGroupState> =
      initGroupsState(merch);
    return {
      merchandise: merch,
      groupingState: groupingObject,
      expanded: false,
      quantity: 0,
      isValid:
        values(groupingObject).filter((obj) => !obj.isValid).length === 0,
      isPosted: false,
    };
  });

const transactionToCart = (
  merchandise: GrabbiMerchandise[],
  transaction: Transaction
): CartItemEntry[] => {
  const initCart: CartItemEntry[] = [];
  for (const item of transaction.transactionItems) {
    const merchItem = merchandise.find(
      (merch) => merch.id === item.merchandiseId
    );
    if (merchItem) {
      const selectedToppingUpcs = item.toppings.map(
        (topping) => topping.upc.upcNumber
      );
      const groupingState = initGroupsState(merchItem, selectedToppingUpcs);
      const cartItem: CartItemEntry = {
        merchandise: merchItem,
        groupingState,
        isValid: groupingStatesValid(groupingState),
        quantity: item.quantity,
        expanded: false,
        isPosted: true,
      };
      initCart.push(cartItem);
    }
  }
  return initCart;
};

const getInitialState = (
  merchandise: GrabbiMerchandise[],
  transaction?: Transaction
) => {
  const sortedMerchandise = merchandise;
  if (transaction) {
    return transactionToCart(sortedMerchandise, transaction);
  }
  const initState: CartItemEntry[] = merchandiseToSelection(sortedMerchandise);
  return initState;
};

const initToppingsState = (
  toppings: GrabbiTopping[],
  selectedToppingUpcs?: number[]
): Record<string, ToppingState> => {
  const toppingRecord: Record<string, ToppingState> = {};
  for (const topping of toppings) {
    const toppingId = topping.toppingUpc.upcNumber;
    toppingRecord[toppingId] = {
      checked: selectedToppingUpcs
        ? selectedToppingUpcs.includes(toppingId)
        : false,
      grouping: topping.grouping,
      topping,
    };
  }
  return toppingRecord;
};

const initGroupsState = (
  merch: GrabbiMerchandise,
  selectedToppingUpcs?: number[]
): Record<string, ToppingGroupState> => {
  const groupRecord: Record<string, ToppingGroupState> = mapValues(
    groupBy(merch.toppings, "grouping"),
    (toppings) => {
      const firstToppingGroup = toppings[0].grouping;
      if (firstToppingGroup) {
        const { groupCount, groupNumber, groupRequired } =
          decodeGroupingParams(firstToppingGroup);
        const groupState: ToppingGroupState = {
          grouping: firstToppingGroup ?? "normal",
          toppingStates: initToppingsState(toppings, selectedToppingUpcs),
          groupCount,
          groupNumber,
          groupRequired,
          isPosted: selectedToppingUpcs !== undefined,
          canAddMore: selectedToppingUpcs ? false : true,
          isValid: groupRequired ? (selectedToppingUpcs ? true : false) : true,
          itemsSelected: selectedToppingUpcs ? selectedToppingUpcs.length : 0,
        };
        return groupState;
      } else {
        const groupState: ToppingGroupState = {
          grouping: firstToppingGroup ?? "normal",
          toppingStates: initToppingsState(toppings, selectedToppingUpcs),
          itemsSelected: 0,
          isPosted: selectedToppingUpcs !== undefined,
        };
        return groupState;
      }
    }
  );

  return groupRecord;
};

export const MerchandiseSelectorProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [cartState, setCartState] = useState<CartItemEntry[]>([]);
  const [showCart, setShowCart] = useState<boolean>(false);
  const [initialized, setInitialized] = useState(false);
  const [selectionState, setSelectionState] = useState<CartItemEntry[]>([]);
  const [merchandise, setMerchandise] = useState<GrabbiMerchandise[]>();
  const [merchandiseByCategory, setMerchandiseByCategory] =
    useState<Record<string, GrabbiMerchandise[]>>();
  const [store, setStore] = useState<GrabbiStore>();
  const [showUnavailable, setShowUnavailable] = useState(false);
  const [isPosted, setIsPosted] = useState(false);

  const initStates = (
    merchandise: GrabbiMerchandise[],
    store?: GrabbiStore,
    transaction?: Transaction,
    skipInitialize?: boolean
  ) => {
    if (store) {
      setStore(store);
    }
    const dataMerchandiseFiltered = map(
      filter(merchandise, "available"),
      (m) => ({
        ...m,
        toppings: filter(m.toppings, (t) => t.available || showUnavailable),
      })
    );

    const dataMerchandiseExtended = map(
      dataMerchandiseFiltered,
      (item, index): GrabbiMerchandise => ({
        ...item,
        menuCategory: item.menuCategory ?? "Unassigned",
      })
    );

    const dataMerchandiseByCategory = {
      ...groupBy(
        sortBy(
          dataMerchandiseExtended?.filter((item) => item.menuCategory !== ""),
          "menuCategory"
        ),
        "menuCategory"
      ),
    };

    const unassignedItems = dataMerchandiseExtended?.filter(
      (item) =>
        item.menuCategory === "" ||
        item.menuCategory === "Unassigned" ||
        !item.menuCategory
    );
    //Add unassigned items to the end of the Dictionary
    if (unassignedItems) {
      dataMerchandiseByCategory["Unassigned"] = unassignedItems;
    }

    setMerchandiseByCategory(dataMerchandiseByCategory);

    const sortedMerchandise = flatten(values(dataMerchandiseByCategory));

    const initSelectionState = getInitialState(sortedMerchandise);
    if (transaction) {
      const initCartState = getInitialState(sortedMerchandise, transaction);
      setCartState(initCartState);
    } else {
      setCartState([]);
    }
    setShowCart(false);
    setSelectionState(initSelectionState);
    setMerchandise(sortedMerchandise);
    if (!skipInitialize) {
      setInitialized(true);
    }
  };

  const reset = () => {
    if (merchandise) {
      initStates(merchandise, store, undefined, true);
      setInitialized(false);
    }
  };

  const toggleShowCart = () => {
    setShowCart((showCart) => !showCart);
  };

  const addItemToCart = (itemIndex: number) => {
    setCartState((cart) => {
      const newCartItem = cloneDeep(selectionState[itemIndex] ?? {});
      const requiredGroups = values(newCartItem.groupingState).filter(
        (group) => group.groupRequired
      );
      const requiredGroupsCompleted = requiredGroups.filter(
        (group) => group.groupCount ?? 0 - group.itemsSelected <= 0
      );
      const hasRequiredToppings =
        requiredGroupsCompleted.length === requiredGroups.length;
      newCartItem.quantity = 1;
      const existingCartItemIndex = cart.findIndex(
        (itemEntry) =>
          itemEntry.merchandise.id === newCartItem.merchandise.id &&
          itemEntry.groupingState === newCartItem.groupingState
      );
      const existingCartItem = cart[existingCartItemIndex];

      if (hasRequiredToppings) {
        toggleExpandSelectionItem(itemIndex);
        if (existingCartItem) {
          const newItem = cart[existingCartItemIndex];
          newItem.quantity += 1;
          cart[existingCartItemIndex] = newItem;
          return cart;
        } else {
          const newCart = [...cart, newCartItem];
          return newCart;
        }
      } else {
        return cart;
      }
    });
  };

  const getItemSelectedPrice = (itemIndex: number) => {
    const currentState = selectionState[itemIndex];
    if (currentState) {
      const itemBasePrice = currentState.merchandise.price;
      const itemSelectedToppingTotal = sum(
        values(currentState.groupingState).map((group) =>
          sum(
            values(group.toppingStates)
              .filter((toppingState) => toppingState.checked)
              .map((toppingState) => toppingState.topping.price)
          )
        )
      );
      return itemBasePrice + itemSelectedToppingTotal;
    }
    return 0;
  };

  const hasOneToppingChecked = (itemIndex: number) => {
    const toppingsChecked = sum(
      selectionState.map((item) =>
        sum(
          values(item.groupingState).map(
            (groupingState) =>
              values(groupingState.toppingStates).filter(
                (toppingState) => toppingState.checked
              ).length
          )
        )
      )
    );
    return toppingsChecked > 0;
  };

  const getItemSelection = (itemIndex: number) => {
    const currentState = selectionState[itemIndex] ?? {};
    currentState.quantity = 1;
    return cartEntryToSelection(currentState);
  };

  const toggleExpandSelectionItem = (itemIndex: number) => {
    const merch = selectionState[itemIndex];
    const resetGroups: Record<string, ToppingGroupState> = mapValues(
      merch.groupingState,
      (group) => ({
        ...group,
        toppingStates: mapValues(group.toppingStates, (topping) => ({
          ...topping,
          checked: false,
        })),
      })
    );
    if (merch) {
      setSelectionState((selectionState) => {
        return getUpdateCartItemArray(itemIndex, selectionState, {
          ...merch,
          expanded: !merch.expanded,
          groupingState: resetGroups,
        });
      });
    }
  };

  const getUpdateCartItemArray = (
    index: number,
    merchArray: CartItemEntry[],
    newMerch: CartItemEntry
  ) => {
    const updatedArray = cloneDeep(merchArray);
    const currentMerch = updatedArray.splice(index, 1)[0];
    const left = updatedArray.slice(0, index);
    const right = updatedArray.slice(index);
    const updatedElement = assign(currentMerch, newMerch);
    const temp = [...left, updatedElement, ...right];
    return temp;
  };

  const toggleSelectionTopping = (index: number, topping: GrabbiTopping) =>
    toggleTopping(setSelectionState, index, topping);

  const toggleTopping = (
    setState: (value: React.SetStateAction<CartItemEntry[]>) => void,
    index: number,
    topping: GrabbiTopping
  ) => {
    setState((state: CartItemEntry[]) => {
      const toppingId = topping.toppingUpc.upcNumber;
      const grouping = topping.grouping ?? "undefined";
      const newState = cloneDeep<CartItemEntry[]>(state);
      const updatedElement = newState.splice(index, 1)[0];

      const groupingState = updatedElement.groupingState[grouping];
      const toppingState = groupingState.toppingStates[toppingId];
      const { checked } = toppingState;

      if (updatedElement) {
        if (
          groupingState.groupCount === 1 &&
          groupingState.itemsSelected === 1
        ) {
          updatedElement.groupingState[grouping].toppingStates = mapValues(
            updatedElement.groupingState[grouping].toppingStates,
            (t) => ({ ...t, checked: false })
          );
        }
        updatedElement.groupingState[grouping].toppingStates[
          toppingId
        ].checked = !checked;
        const { groupRequired, groupCount } = groupingState;
        if (topping.grouping && groupCount !== undefined) {
          const currentGroupState = updatedElement.groupingState[grouping];
          const newItemsSelected = values(
            updatedElement.groupingState[grouping].toppingStates
          ).filter((t) => t.checked).length;
          updatedElement.groupingState[grouping] = {
            ...currentGroupState,
            canAddMore: newItemsSelected < groupCount || groupCount === 1,
            itemsSelected: newItemsSelected,
            isValid: groupRequired ? groupCount === newItemsSelected : true,
          };
        }
        return getUpdateCartItemArray(index, state, updatedElement);
      } else {
        return state;
      }
    });
  };

  const changeSelectionItemQuantity = (index: number, quantity: number) => {
    setCartState((cart) => {
      const newCart = cloneDeep(cart);
      const updatedElement = newCart.splice(index, 1)[0];
      if (updatedElement) {
        updatedElement.quantity = quantity;
        if (quantity === 0) {
          return newCart;
        }
        return getUpdateCartItemArray(index, cart, updatedElement);
      } else {
        return cart;
      }
    });
  };

  const canAddTopping = (
    merchandise: GrabbiMerchandise,
    topping: GrabbiTopping
  ) => {
    const toppingGroup = topping.grouping;
    if (toppingGroup) {
      const { groupCount, groupNumber, groupRequired } =
        decodeGroupingParams(toppingGroup);
      const selectedMerchandise = selectionState[merchandise.id];
      if (selectedMerchandise) {
        const selectedToppingsInSameGroup = values(
          selectedMerchandise.groupingState
        ).filter((groupState) => {
          const selectedTopping =
            groupState.toppingStates[topping.toppingUpc.upcNumber];
          if (!selectedTopping || !selectedTopping.grouping) {
            return false;
          }
          const {
            groupCount: toppingGroupCount,
            groupNumber: toppingGroupNumber,
          } = decodeGroupingParams(selectedTopping.grouping);
          return (
            toppingGroupCount === groupCount &&
            toppingGroupNumber === groupNumber &&
            selectedTopping.checked
          );
        });
        return (
          (groupRequired
            ? selectedToppingsInSameGroup.length !== groupCount
            : selectedToppingsInSameGroup.length < groupCount) ||
          groupCount === 1
        );
      } else {
        return false;
      }
    }
    return true;
  };

  const isToppingChecked = (
    merch: GrabbiMerchandise,
    topping: GrabbiTopping
  ): boolean => {
    return (
      selectionState[merch.id]?.groupingState?.[topping.grouping ?? "undefined"]
        ?.toppingStates?.[topping.toppingUpc.upcNumber]?.checked ?? false
    );
  };

  const isCartToppingChecked = (
    index: number,
    topping: GrabbiTopping
  ): boolean => {
    return cartState[index].groupingState[topping.grouping ?? "undefined"]
      .toppingStates[topping.toppingUpc.upcNumber].checked;
  };

  const isCartValid = () =>
    cartState.length > 0 &&
    cartState.filter((cartItem) => !groupingStatesValid(cartItem.groupingState))
      .length === 0;

  const getCartChoicesNeeded = () =>
    cartState
      .map((cartItem) =>
        sum(
          values(cartItem.groupingState).map((group) =>
            groupChoicesNeeded(group)
          )
        )
      )
      .filter((needed) => needed > 0).length;

  const cartEntryToSelection = useCallback((selectedMerch: CartItemEntry) => {
    const merch = selectedMerch.merchandise;
    const toppingSelection = selectedMerch.groupingState;
    const toppingGroups = values(toppingSelection);
    const merchSelection: GrabbiMerchandiseSelection = {
      merchandiseId: merch.id,
      price: merch.price,
      taxAmount: merch.taxAmount,
      merchandise: merch,
      toppings: [],
      quantity: selectedMerch.quantity,
    };
    if (values(toppingSelection).length > 0) {
      const toppingStates: ToppingState[] = [];
      for (const group of values(toppingGroups)) {
        toppingStates.push(...values(group.toppingStates));
      }
      merchSelection.toppings = toppingStates
        .filter((toppingState) => toppingState.checked)
        .map((toppingState) => toppingState.topping);
    }

    return merchSelection;
  }, []);

  const merchandiseCartSelection = useMemo(() => {
    return cartState.filter((item) => !item.isPosted).map(cartEntryToSelection);
  }, [cartState, cartEntryToSelection]);

  const getCartTotal = useCallback(
    () =>
      sumBy(cartState, (value) => {
        return getDependentMerchandisePrice(value);
      }),
    [cartState]
  );

  const getCartQuantity = useCallback(
    () => sumBy(cartState, "quantity"),
    [cartState]
  );

  const toggleShowUnavailable = () => {
    setShowUnavailable((current) => !current);
    return !showUnavailable;
  };

  return (
    <SelectionContextProvider
      value={{
        cartState,
        showCart,
        initialized,
        selectionState,
        merchandiseCartSelection,
        merchandise,
        merchandiseByCategory,
        store,
        showUnavailable,
        isPosted,
        setIsPosted,
        getItemSelectedPrice,
        hasOneToppingChecked,
        toggleShowUnavailable,
        getCartTotal,
        getCartQuantity,
        toggleShowCart,
        addItemToCart,
        getItemSelection,
        toggleSelectionTopping,
        toggleExpandSelectionItem,
        canAddTopping,
        changeSelectionItemQuantity,
        isToppingChecked,
        isCartToppingChecked,
        isCartValid,
        getCartChoicesNeeded,
        initStates,
        reset,
      }}
    >
      {children}
    </SelectionContextProvider>
  );
};

export const groupChoicesNeeded = (toppingGroup: ToppingGroupState) => {
  return toppingGroup.groupCount !== undefined && toppingGroup.groupRequired
    ? toppingGroup.groupCount - toppingGroup.itemsSelected
    : 0;
};

export const groupingStatesValid = (
  groupingObject: Record<string, ToppingGroupState>
) => sum(values(groupingObject).map(groupChoicesNeeded)) === 0;

import React from "react";
import { VictoryData } from "types/GrabbiTypes";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel } from "victory";
import "./HorizontalBarChartComponent.scss";

interface Props {
  data: VictoryData[];
}

const barWidth = 20;

const HorizontalBarChartComponent: React.FC<Props> = ({ data }) => {
  return (
    <VictoryChart padding={{ left: 6, right: 60, top: 50, bottom: 50 }}>
      <VictoryAxis
        style={{
          axis: { stroke: "transparent" },
          ticks: { stroke: "transparent" },
        }}
        tickLabelComponent={
          <VictoryLabel
            dx={5}
            dy={-20}
            textAnchor="start"
            style={{ fontFamily: "LemonMilk", fontSize: 9 }}
          />
        }
      />
      <VictoryBar
        horizontal={true}
        data={data.slice(0, 2)}
        style={{
          data: { fill: "rgba(0,0,0,0.2)" },
          labels: { fontFamily: "LemonMilk", fontSize: 9 },
        }}
        barWidth={barWidth}
      />
      <VictoryBar
        horizontal={true}
        data={data.slice(2)}
        style={{
          data: { fill: "#D32479" },
          labels: { fontFamily: "LemonMilk", fontSize: 9 },
        }}
        barWidth={barWidth}
      />
    </VictoryChart>
  );
};

export default HorizontalBarChartComponent;

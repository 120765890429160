import React from "react";
import Drawer from "@material-ui/core/Drawer";
import "components/AdminConsole/Navigation/NavigationComponent.scss";
import { Box, List, ListItem, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { isMobile } from "shared/scripts/Constants";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { NavigationOption } from "types/navigationOption";
import { ADMIN_BASE_URL } from "../AdminConsole";
import { CONTEXT } from "shared/scripts/UrlContext";

interface Props {
  navigationOptions: NavigationOption[];
}

const NavigationComponent: React.FC<Props> = ({ navigationOptions }) => {
  const location = useLocation();
  let selected = navigationOptions.findIndex(
    (option) =>
      location.pathname.replace(`/${ADMIN_BASE_URL}/`, "") === option.route
  );

  if (selected === -1) {
    selected = 0;
  }

  const [open, setOpen] = React.useState<boolean>(false);

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setOpen(true);
  };

  const handleMenuClose = (
    event: {},
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    setOpen(false);
  };

  const isOpen = isMobile() ? open : true;
  const variant = isMobile() ? "temporary" : "permanent";

  const drawer = (
    <Drawer
      className="nav_drawer"
      classes={{ paper: "nav_drawer_paper" }}
      anchor="left"
      open={isOpen}
      onClose={handleMenuClose}
      variant={variant}
    >
      <Box className="nav_user_icon_box">
        <Box className="nav_user_icon">
          <span>CL</span>
        </Box>
      </Box>
      <Box className={"nav_drawer_area"}>
        <List classes={{ root: "nav_drawer", padding: "nav_drawer_padding" }}>
          {navigationOptions.map((item, index) => (
            <ListItem
              component={NavLink}
              to={`${CONTEXT}${ADMIN_BASE_URL}/${item.route}`}
              selected={index === selected}
              classes={{
                button: "nav_item",
                selected: "nav_item_selected",
              }}
              button
              key={item.title}
            >
              <span className="nav_item_text">
                {item.title.toLocaleUpperCase()}
              </span>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );

  const mobileMenu = (
    <Box>
      <Box className="navigation_mobile">
        <Box className="nav_user_icon nav_user_icon_mobile">
          <span>CL</span>
        </Box>
        <Box className="nav_menu_header_mobile">
          {navigationOptions[selected].title}
        </Box>
        <IconButton edge="end" onClick={handleMenuOpen}>
          <MenuIcon
            fontSize="large"
            classes={{ root: "nav_menu_icon_mobile" }}
          />
        </IconButton>
      </Box>
      {drawer}
    </Box>
  );

  return isMobile() ? mobileMenu : drawer;
};

export default NavigationComponent;

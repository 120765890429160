import React from 'react';
import './DividerComponent.scss';

type ColorVariant = "gray";

const colorVariantClasses: Record<ColorVariant, string> = {
  gray: "grabbi_divider_root_gray",
};

interface Props {
  className?: string;
  colorVariant?: ColorVariant;
  text: string;
}

const GrabbiDivider: React.FC<Props> = ({ className, colorVariant, text }) => {
  const colorVariantClass = colorVariantClasses[colorVariant ?? "gray"];
  const classNameProp = className ?? "";

  return (
    <div
      className={`grabbi_divider_root ${colorVariantClass} ${classNameProp}`}
    >
      <div className="line" />
      <div className="p-x-4">{text}</div>
      <div className="line" />
    </div>
  );
}

export default GrabbiDivider;
import { Box, Typography } from "@material-ui/core";
import React, { ReactNode } from "react";
import "./VennDiagram.scss";

interface CircleProps {
  className?: string;
  style?: React.CSSProperties;
}

export interface VennDiagramProps {
  className?: string;
  circle1Content?: ReactNode;
  circle2Content?: ReactNode;
  circleProps?: {
    circle1Props?: CircleProps;
    circle2Props?: CircleProps;
  };
  joinTextProps?: {
    className?: string;
    style?: React.CSSProperties;
  };
  joinText?: string;
}

const VennDiagram: React.FC<VennDiagramProps> = ({
  className,
  circleProps,
  circle1Content,
  circle2Content,
  joinText,
}) => {
  return (
    <Box className={`VennDiagramRoot ${className ?? ""}`}>
      <Box className="CircleWrapper">
        <Box className="Circle Circle1" {...circleProps?.circle1Props}>
          <Typography className="CircleContent">{circle1Content}</Typography>
        </Box>
      </Box>
      <Box className="JoinTextWrapper">
        <Box className="JoinText">
          <Typography>{joinText}</Typography>
        </Box>
      </Box>
      <Box className="CircleWrapper">
        <Box className="Circle Circle2" {...circleProps?.circle2Props}>
          <Typography className="CircleContent">{circle2Content}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default VennDiagram;

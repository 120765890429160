import { connect } from "react-redux";
import { State } from "store";
import AuthRouteComponent from "./AuthRouteComponent";
import { checkSessionStartAction, setLogoutStatusStartedAction } from "actions";

const mapDispatchToProps = (dispatch: any) => ({
  checkSessionDispatch: () => dispatch(checkSessionStartAction()),
  dispatchLogout: () => dispatch(setLogoutStatusStartedAction()),
});

const mapStateToProps = (state: State) => ({
  loginState: state.loginState,
  merchant: state.merchantStores.merchant
});

export const AuthRoute = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthRouteComponent);

import { get, post } from "./api";
import { Merchant, GrabbiLeadForMarketing } from "types/GrabbiTypes";

export const getMerchant = () => {
  const endpoint = "restful-services/merchant/";

  const thenHandler = (response: any) => {
    return response.data;
  };

  const errorHandler = (error: any) => {
    return {
      error: error.response.data.message,
      authenticationFailed: true,
    };
  };

  const finallyHandler = () => {
    return {};
  };

  return get(endpoint, {}, {}, thenHandler, errorHandler, finallyHandler);
};

export const signUpMerchant = (merchant: Merchant) => {
  const endpoint = "auth/merchant/create";

  const thenHandler = (response: any) => {
    return response;
  };

  const errorHandler = (error: any) => {
    return error.response;
  };

  const finallyHandler = () => {
    return {};
  };

  return post(
    endpoint,
    merchant,
    {},
    thenHandler,
    errorHandler,
    finallyHandler
  );
};

export const updateMerchant = (merchant: Merchant) => {
  const endpoint = "restful-services/merchant/update";

  const thenHandler = (response: any) => {
    return response.data;
  };

  const errorHandler = (error: any) => {
    return error.response.data;
  };

  const finallyHandler = () => {
    return {};
  };

  return post(
    endpoint,
    merchant,
    {},
    thenHandler,
    errorHandler,
    finallyHandler
  );
};

export const merchantResetPassword = (payload: {
  token?: string;
  email: string;
  password?: string;
  confirmedPassword?: string;
}) => {
  const endpoint = "auth/merchant/resetPassword";

  const thenHandler = (response: any) => {
    return response;
  };

  const errorHandler = (error: any) => {
    return error.response;
  };

  const finallyHandler = () => {
    return {};
  };

  if (payload.token && payload.password && payload.confirmedPassword) {
    return post(
      endpoint,
      {
        token: payload.token,
        email: payload.email,
        password: payload.password,
        confirmedPassword: payload.confirmedPassword,
      },
      {},
      thenHandler,
      errorHandler,
      finallyHandler
    );
  } else {
    const email = payload.email;
    return post(
      endpoint,
      { email },
      {},
      thenHandler,
      errorHandler,
      finallyHandler
    );
  }
};

export const createLead = (leadForMarketing: GrabbiLeadForMarketing) => {
  const endpoint = "api/lead";

  const thenHandler = (response: any) => {
    return response;
  };

  const errorHandler = (error: any) => {
    return error.response;
  };

  const finallyHandler = () => {
    return {};
  };

  return post(
    endpoint,
    leadForMarketing,
    {},
    thenHandler,
    errorHandler,
    finallyHandler
  );
};

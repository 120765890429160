import React, { ReactElement } from "react";
import "./TermsOfService.scss";
import { Box } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { CONTEXT } from "shared/scripts/UrlContext";

const link = (text: string, href: string, useATag = false) =>
  useATag ? (
    <a className="tos_text_link" href={href}>
      {text}
    </a>
  ) : (
    <NavLink className="tos_text_link" to={`/${href}`}>
      {text}
    </NavLink>
  );

const TermsOfService: React.FC = () => {
  const getAnchor = (text: string) =>
    text.replace(/[\s,.;]/g, "").toLowerCase();

  const tableItem = (text: string) => {
    const anchor = getAnchor(text);
    return (
      <li className="tos_table_of_contents_list_item">
        <a href={`${CONTEXT}/terms-of-service#${anchor}`} className="tos_table_of_contents_item">
          {text}
        </a>
      </li>
    );
  };

  return (
    <Box className="tos_root">
      <Box className="tos_header">
        Terms Of Service
      </Box>
      <Box component="p" className="tos_paragraph">
        Last Updated: November 1, 2019
      </Box>
      <Box component="p" className="tos_paragraph">
        Please read these Terms of Service carefully as they contain important
        information about your legal rights, remedies, and obligations. By
        accessing or using the Grabbi Platform, you agree to comply with and be
        bound by these Terms of Service.
      </Box>
      <Box component="p" className="tos_paragraph">
        Please note: if you reside in the United States, this provision applies
        to all disputes with Grabbi. If you reside outside of the United States,
        this provision applies to any action you bring against Grabbi in the
        United States. It affects how disputes with Grabbi are resolved. By
        accepting these Terms of Service, you agree to be bound by this
        arbitration clause and class action waiver. Please read it carefully.
      </Box>
      <Box component="p" className="tos_paragraph">
        These Terms of Service (these "Terms") constitute a legally binding
        agreement (this "Agreement") between you and Grabbi (as defined below)
        governing your access to and use of the Grabbi website, including any
        subdomains thereof and any other websites through which Grabbi makes the
        Grabbi Services available (collectively, the "Site"); our mobile,
        tablet, and other smart device applications and application program
        interfaces (collectively, the "Application"); and all associated
        services (collectively, the "Services"). The Site, Application, and
        Services together are hereinafter collectively referred to as the
        “Grabbi Platform.” Our other Policies applicable to your use of the
        Grabbi Platform are incorporated by reference into this Agreement. When
        these Terms mention “Grabbi,” “we,” “us,” or “our,” it refers to Grabbi
        LLC, a California Limited Liability Company with an office at 2526 Alida
        St, Oakland, CA 94602. These Terms may be modified at any time by Grabbi
        upon posting of the modified Terms. Any such modifications shall be
        effective immediately. Each use by you shall constitute and be deemed
        your unconditional acceptance of the Terms.
      </Box>
      <Box component="p" className="tos_paragraph">
        <Box component="span" className="tos_bold_text">
          IF YOU DO NOT AGREE TO THE CURRENT TERMS OF SERVICE, PLEASE DO NOT USE
          THE GRABBI PLATFORM, BECAUSE YOUR USE OF IT CONSTITUTES ACCEPTANCE OF
          ALL OF THE MOST CURRENT TERMS OF SERVICE FOR ITS USE.
        </Box>
      </Box>
      <Box component="p" className="tos_paragraph">
        Our collection and use of personal information in connection with your
        access to, and use of, the Grabbi Platform is described in our Privacy
        Policy. We encourage you to read our Privacy Policy carefully.
      </Box>
      <Box component="p" className="tos_paragraph">
        <Box
          component="span"
          className="tos_bold_text tos_table_of_contents"
        >
          Table of Contents
        </Box>
      </Box>
      <ol className="tos_table_of_contents_ordered_list">
        {TERMS_SECTIONS.map((item) => tableItem(item.title))}
      </ol>
      <ol className="tos_ordered_list">
        {TERMS_SECTIONS.map((item) => (
            <li className="tos_list_item">
              <Box
                id={getAnchor(item.title)}
                component="span"
                className="tos_subheader"
              >
                {item.title}
              </Box>
              {item.body}
            </li>
        ))}
      </ol>
      <Box
        component="p"
        className="tos_paragraph"
      ></Box>
    </Box>
  );
};

interface TermSection {
  title: string;
  body: ReactElement;
}

const TERMS_SECTIONS: TermSection[] = [
  {
    title: "Scope of Services",
    body: (
      <ol>
        <li className="tos_paragraph">
          The Grabbi Platform is a payments service and platform that enables
          registered users ("Shoppers") to scan and purchase products in
          registered stores (“Merchants”). Shoppers and Merchants are sometimes
          collectively referred to herein as the “Members” and individually as a
          “Member.”
        </li>
        <li className="tos_paragraph">
          As the provider of the Grabbi Platform, Grabbi does not own, create,
          sell, resell, provide, control, manage, offer, deliver, or supply any
          products to Shoppers. Merchants alone are responsible for their own
          products and services (collectively, “Store Merchandise”). Merchants
          are also solely responsible for their own listings of their Store
          Merchandise within the Grabbi Platform (“Product Listing”)When
          Shoppers buy a product in a registered store, they are entering into a
          contract directly with the Merchant and not with Grabbi. Grabbi is not
          and does not become a party to or become a participant in any
          contractual relationship between Shoppers, nor is Grabbi a store
          Merchant. Grabbi is not acting as an agent in any capacity for any
          Shopper.
        </li>
        <li className="tos_paragraph">
          While we may help facilitate the resolution of disputes, Grabbi has no
          control over and does not guarantee the following:
        </li>
        <ol type="i">
          <li className="tos_paragraph">
            the existence, quality, safety, suitability, or legality of any
            Store Merchandise.
          </li>
          <li className="tos_paragraph">
            the truth or accuracy of any Store Merchandise or any Merchant
            Content (as defined below); or
          </li>
          <li className="tos_paragraph">
            the performance or conduct of any Shopper or third party. Grabbi
            does not endorse any Shopper or Store Merchandise. Any references to
            a Shopper being "verified" (or similar language) only indicates that
            the Shopper has completed a relevant verification or identification
            process and nothing else.
          </li>
        </ol>
        <li className="tos_paragraph">
          {" "}
          To promote the Grabbi Platform and to increase the exposure of the
          platform to potential Shoppers, Store Merchandise, and other Merchant
          Content may be displayed on other websites, in applications, within
          emails, and in online and offline advertisements.
        </li>
        <li className="tos_paragraph">
          The Grabbi Platform may contain links to third-party websites or
          resources ("Third-Party Services"). Such Third-Party Services may be
          subject to different terms and conditions and privacy practices.
          Grabbi is not responsible or liable for the availability or accuracy
          of such Third-Party Services or liable for the content, products, or
          services available from such Third-Party Services. Links to such
          Third-Party Services are not an endorsement by Grabbi of such
          Third-Party Services.
        </li>
        <li className="tos_paragraph">
          Due to the nature of the Internet, Grabbi cannot guarantee the
          continuous and uninterrupted availability and accessibility of the
          Grabbi Platform. Grabbi may restrict the availability of the Grabbi
          Platform or certain areas or features thereof, if this is necessary in
          regards to capacity limits, the security or integrity of our servers,
          or to carry out maintenance measures that ensure the proper or
          improved functioning of the Grabbi Platform. Grabbi may improve,
          enhance, and modify the Grabbi Platform and introduce new Services
          from time to time.
        </li>
      </ol>
    ),
  },
  {
    title: "Eligibility, Verification, and Registration",
    body: (
      <ol>
        <li className="tos_paragraph">
          You must be at least 18 years old and able to enter into legally
          binding contracts to access and use the Grabbi Platform or register an
          Account. By accessing or using the Grabbi Platform, you represent and
          warrant that you are 18 years old or older and have the legal capacity
          and authority to enter into a contract.
        </li>
        <li className="tos_paragraph">
          User verification on the Internet is difficult and we do not assume
          any responsibility for the confirmation of any Member’s identity.
          Notwithstanding the above, for transparency and fraud prevention
          purposes and as permitted by applicable laws, we may, but have no
          obligation to, (i) ask Members to provide a form of government
          identification or other information or undertake additional checks
          designed to help verify the identities or backgrounds of Members; (ii)
          screen Members against third-party databases or other sources and
          request reports from service providers; and (iii) where we have
          sufficient information to identify a Member, obtain reports from
          public records of criminal convictions, 2or an equivalent version of
          background checks, or other checks in your local jurisdiction (if
          available).
        </li>
        <li className="tos_paragraph">
          As a Merchant, you are responsible for checking a valid form of
          identification that includes, but is not limited to, the following:
          (i) a valid motor vehicle operator’s license, (ii) a passport issued
          by the U.S. or by a foreign government, or (iii) a valid
          identification card issued to a member of the Armed Forces that
          includes a date of birth and a picture, showing the legal age of the
          Shopper.
        </li>
        <li className="tos_paragraph">
          You must register an account ("Account") to access and use certain
          features of the Grabbi Platform, such as locating nearby stores,
          scanning products, and paying through third party services, such as
          Apple Pay. If you are registering an Account for a company or other
          legal entity, you represent and warrant that you have the authority to
          legally bind that entity and grant us all permissions and licenses
          provided in these Terms.
        </li>
        <li className="tos_paragraph">
          You agree to provide true, accurate, current, and complete information
          about yourself as prompted by the membership Platform and process
          (such information being the "Membership Data"). If you knowingly
          provide any information that is untrue, inaccurate, not current, or
          incomplete or Grabbi has reasonable grounds to suspect that such
          information is untrue, inaccurate, not current, or incomplete, Grabbi
          has the right to suspend or terminate your Account and refuse any and
          all current or future use of the Services (or any portion thereof).
          For the avoidance of doubt and without limiting the foregoing, Grabbi
          may suspend or terminate your Account and refuse any and all current
          or future use of the Grabbi Platform (or any portion thereof) for
          convenience at any time in Grabbi’s sole discretion.
        </li>
        <li className="tos_paragraph">
          You may not register more than one (1) Account unless Grabbi
          authorizes you to do so. You may not assign or otherwise transfer your
          Account to another party.
        </li>
        <li className="tos_paragraph">
          You are responsible for maintaining the confidentiality and security
          of your Account credentials and may not disclose your credentials to
          any third party. You must immediately notify Grabbi if you know or
          have any reason to suspect that your credentials have been lost,
          stolen, misappropriated, or otherwise compromised or in case of any
          actual or suspected unauthorized use of your Account. You are liable
          for any and all activities conducted through your Account unless such
          activities are not authorized by you and you are not otherwise
          negligent (such as failing to report to Grabbi the unauthorized use or
          loss of your credentials).
        </li>
        <li className="tos_paragraph">
          Grabbi may enable features that allow you to authorize other Members
          or certain third parties to take certain actions that affect your
          Account. These features do not require that you share your credentials
          with any other person. No third party is authorized by Grabbi to ask
          for your credentials and you shall not request the credentials of
          another Member.
        </li>
      </ol>
    ),
  },
  {
    title: "Using the Grabbi Platform; Prohibited Activities",
    body: (
      <ol>
        <li className="tos_paragraph">
          You are solely responsible for complying with any and all laws, rules,
          regulations, and tax obligations that may apply to your use of the
          Grabbi Platform.
        </li>
        <li className="tos_paragraph">
          The Grabbi Platform may only be used for lawful purposes by all
          Members regardless of whether the Member is seeking to purchase Store
          Merchandise or the Member is providing Store Merchandise. In
          connection with your use of the Grabbi Platform, you will not and will
          not assist or enable others to do any of the following:
        </li>
        <ol>
          <li className="tos_paragraph">
            breach or circumvent any applicable laws or regulations; agreements
            with third-parties; third-party rights; or our Terms, policies, and
            standards;
          </li>
          <li className="tos_paragraph">
            use the Grabbi Platform or Collective Content (as defined below in
            Section 5) for any commercial or other purposes that are not
            expressly permitted by these Terms or in a manner that falsely
            implies Grabbi’s endorsement, partnership, or otherwise misleads
            others as to your affiliation with Grabbi;
          </li>
          <li className="tos_paragraph">
            copy, store, or otherwise access or use any information, including
            personally identifiable information about any other Member,
            contained on the Grabbi Platform in any way that is inconsistent
            with Grabbi’s Privacy Policy or these Terms or that otherwise
            violates the privacy rights of Members or third parties;
          </li>
          <li className="tos_paragraph">
            use the Grabbi Platform in connection with the distribution of
            unsolicited commercial messages ("Spam");
          </li>
          <li className="tos_paragraph">
            offer, as a Merchant, any Merchant Store Merchandise that you do not
            yourself own or have permission to make available through the Grabbi
            Platform;
          </li>
          <li className="tos_paragraph">
            discriminate against or harass anyone on the basis of race; national
            origin; religion; gender; gender identity; physical or mental
            disability; medical condition; marital status, age; sexual
            orientation; or otherwise engage in any abusive, offensive, or
            disruptive behavior;
          </li>
          <li className="tos_paragraph">
            without Grabbi’s express written consent, you are restricted from
            using, displaying, mirroring, or framing the Grabbi Platform,
            Collective Content, or any individual element within the Grabbi
            Platform, including, but not limited to, Grabbi’s name; Grabbi’s
            trademarks, logos, or other proprietary information; or the layout
            and design of any page or form contained on a page within the Grabbi
            Platform;
          </li>
          <li className="tos_paragraph">
            dilute, tarnish, or otherwise harm the Grabbi brand in any way,
            including but not limited to, unauthorized use of Collective
            Content; registering and/or using Grabbi or derivative terms in
            domain names, trade names, trademarks, or other source identifiers;
            or registering and/or using domain names, trade names, trademarks,
            or other source identifiers that closely imitate or are confusingly
            similar to Grabbi domains, trademarks, taglines, promotional
            campaigns, or Collective Conten
          </li>
          <li className="tos_paragraph">
            use any robot, spider, crawler, scraper, or other automated means or
            processes to access, collect data, or other content from or
            otherwise interact with the Grabbi Platform for any purpose;
          </li>
          <li className="tos_paragraph">
            avoid, bypass, remove, deactivate, impair, descramble, or otherwise
            attempt to circumvent any technological measure implemented by
            Grabbi or any of Grabbi’s providers or any other third party to
            protect the Grabbi Platform;
          </li>
          <li className="tos_paragraph">
            attempt to decipher, decompile, disassemble, or reverse engineer any
            of the software used to provide the Grabbi Platform;
          </li>
          <li className="tos_paragraph">
            take any action that damages or adversely affects or could damage or
            adversely affect the performance or proper functioning of the Grabbi
            Platform;
          </li>
          <li className="tos_paragraph">
            export, re-export, import, or transfer the Application except as
            authorized by United States law, the export control laws of your
            jurisdiction, and any other applicable laws; or
          </li>
          <li className="tos_paragraph">
            violate or infringe on any person’s rights or otherwise cause harm
            to anyone.
          </li>
        </ol>
        <li className="tos_paragraph">
          The access to, or use of, certain areas and features of the Grabbi
          Platform may be subject to separate policies, standards or guidelines,
          or may require that you accept additional terms and conditions. If
          there is a conflict between these Terms and any other terms and
          conditions applicable to a specific area or feature of the Grabbi
          Platform, the latter terms and conditions will take precedence with
          respect to your access to, or use of, that area or feature, unless
          specified otherwise.
        </li>
        <li className="tos_paragraph">
          You acknowledge that Grabbi has no obligation to monitor the access
          to, or use of, the Grabbi Platform by any Member or to review,
          investigate, disable access to, or edit any Member Content, but Grabbi
          has the right to do so in order to do any of the following: (i)
          operate, secure, and improve the Grabbi Platform (including, but not
          limited to, fraud prevention, risk assessment, enforcement of the
          Terms, and customer support purposes); (ii) ensure Members’ compliance
          with these Terms; (iii) comply with applicable law or the order or
          requirement of a court, law enforcement, or other administrative
          agency or governmental body; (iv) respond to Member Content that
          Grabbi, in its sole discretion, determines is harmful or
          objectionable; or (v) as otherwise set forth in these Terms. Members
          agree to cooperate with and assist Grabbi in good faith and to provide
          Grabbi with such information and take such actions as may be
          reasonably requested by Grabbi with respect to any investigation
          undertaken by Grabbi or a representative of Grabbi regarding the use
          or abuse of the Grabbi Platform.
        </li>
        <li className="tos_paragraph">
          If you feel that any Member you interact with, whether online or in
          person, is acting or has acted inappropriately, including but not
          limited to, anyone who (i) engages in offensive, violent, or sexually
          inappropriate behavior; (ii) you suspect of stealing from you; or
          (iii) engages in any other disturbing conduct, you should immediately
          report such person to Grabbi by contacting us, provided that your
          report will not obligate us to take any action beyond that what is
          required by law (if any) or cause us to incur any liability to you. At
          its sole discretion, Grabbi will determine whether any report provided
          to Grabbi warrants action to be taken against the Member(s) described
          in the report.
        </li>
        <li className="tos_paragraph">
          If Grabbi becomes aware of any possible or actual violations committed
          by you under these Terms, Grabbi reserves the right to investigate
          such violations and to take appropriate action to enforce Grabbi’s
          rights under these Terms and the applicable law. If, as a result of
          the investigation, Grabbi believes that criminal activity has
          occurred, Grabbi reserves the right to refer the matter to, and to
          cooperate with, any and all applicable legal authorities. Grabbi is
          entitled, except to the extent prohibited by applicable law, to
          disclose any information or materials related to the Store
          Merchandise, including Member Content and Membership Data in Grabbi’s
          possession in connection with your use of the Application or the
          Services to (i) comply with applicable laws, legal process, or
          governmental request; (ii) enforce the Terms; (iii) respond to any
          claims that Member Content violates the rights of third parties; (iv)
          respond to your requests for customer service; or (v) protect the
          rights, property, or personal safety of Grabbi, its Members or the
          public, and all enforcement or other government officials as Grabbi in
          its sole discretion believes to be necessary or appropriate.
        </li>
      </ol>
    ),
  },

  {
    title: "Modification of these Terms",
    body: (
      <ol>
        <li className="tos_paragraph">
          Grabbi reserves the right to modify these Terms at any time in
          accordance with this provision. If we make any changes to these Terms,
          we will post the revised Terms on the Grabbi Platform and update the
          “Last Updated” date at the top of these Terms. We will also provide
          you with notice of the modifications by email. If you disagree with
          the revised Terms, you may terminate this Agreement with immediate
          effect. We will inform you about your right to terminate the Agreement
          in the notification email. If you do not terminate the Agreement
          before the date the revised Terms becomes effective,{" "}
          <Box component="span" className="tos_bold_text">
            your continued access to or use of the Grabbi Platform will
            constitute acceptance of the revised Terms.
          </Box>
        </li>
      </ol>
    ),
  },
  {
    title: "Content",
    body: (
      <ol>
        <li className="tos_paragraph">
          The Services consist of interactive features that may allow Members,
          in Grabbi’s sole discretion to, (i) create, upload, post, transmit,
          and/or store content, including but not limited to text, items, or
          other materials or information on or through the Grabbi Platform
          (collectively, "Member Content"); and (ii) access and view Member
          Content and any content that Grabbi itself makes available on or
          through the Grabbi Platform, including proprietary Grabbi content and
          any content licensed or authorized for use by or through Grabbi from a
          third party ("Grabbi Content" and, together with Member Content,
          "Collective Content").
        </li>
        <li className="tos_paragraph">
          You acknowledge and agree that the Grabbi Platform and any necessary
          software used in connection with the Grabbi Platform ("Software")
          contain proprietary and confidential information that is the property
          of Grabbi and its licensors and is protected by applicable
          intellectual property and other laws. No rights or title of to any of
          the Software used in connection with the Grabbi Platform is provided,
          transferred, or assigned to you. You further acknowledge and agree
          that content contained in advertisements or information presented to
          you through the Grabbi Platform or advertisers is protected by
          copyright, trademarks, service marks, patent, privacy, and/or other
          proprietary rights and laws. Except as expressly authorized by Grabbi
          or advertisers, you agree not to modify, rent, lease, loan, sell,
          distribute, or create derivative works based on the Grabbi Platform or
          Software, in whole or in part, at any time. You also acknowledge
          Grabbi’s exclusive rights in the Grabbi trademark and service mark.
        </li>
        <Box className="tos_paragraph">
          Trademarks, service marks, logos, and copyrighted works appearing on
          the Grabbi Platform are the property of Grabbi or the party that
          provided such intellectual property to Grabbi. Grabbi and any party
          that provides intellectual property to the Grabbi Platform retain all
          rights with respect to any of their respective intellectual property
          appearing on the Grabbi Platform and no rights in such materials are
          transferred or assigned to you, in whole or in part, at any time under
          no circumstances.
        </Box>
        <li className="tos_paragraph">
          You will not use, copy, adapt, modify, prepare derivative works of,
          distribute, license, sell, transfer, publicly display, publicly
          perform, transmit, broadcast or otherwise exploit the Grabbi Platform
          or any Collective Content, except to the extent that you are the legal
          owner of certain Member Content or as expressly permitted in these
          Terms. No licenses or rights are granted to you by implication or
          otherwise under any intellectual property rights owned or controlled
          by Grabbi or its licensors or licensees, except for the licenses and
          rights expressly granted in these Terms.
        </li>
        <li className="tos_paragraph">
          Subject to your compliance with these Terms, Grabbi grants you, solely
          for your own personal use, a limited, non-exclusive,
          non-sublicensable, revocable, non-transferable license to (i) download
          and use the Application on your personal device(s) and (ii) access and
          view any Collective Content made available on or through the Grabbi
          Platform that is accessible to you.
        </li>
        <li className="tos_paragraph">
          By creating, uploading, posting, sending, receiving, storing, or
          otherwise making available any Member Content on or through, the
          Grabbi Platform, you grant to Grabbi a non-exclusive, worldwide,
          royalty-free, irrevocable, perpetual (or for the term of the
          protection), sub-licensable, and transferable license to such Member
          Content to access, use, store, copy, modify, prepare derivative works
          of, distribute, publish, transmit, stream, broadcast, and otherwise
          exploit in any manner such Member Content to provide and/or promote
          the Grabbi Platform in any media or platform. Without limiting the
          foregoing, when you submit Member Content, you agree that this
          submission accords Grabbi the right to sublicense such Member Content
          to other companies, organizations, or individuals in any and all media
          or distribution methods, now known or later developed. No such use of
          Member Content shall entitle you to any compensation from Grabbi or
          from any other companies, organizations, or individuals. Unless you
          provide specific consent, Grabbi does not claim any ownership rights
          in any Member Content and nothing in these Terms will be deemed to
          restrict any rights that you may have to use or exploit your own
          Member Content.
        </li>
        <li className="tos_paragraph">
          You are solely responsible for all Member Content that you make
          available on or through the Grabbi Platform. Accordingly, you
          represent and warrant that (i) you are either the sole and exclusive
          owner of all the Member Content that you made available on or through
          the Grabbi Platform or you have all rights, licenses, consents, and
          releases that are necessary to grant to Grabbi the rights in and to
          such Member Content as contemplated under these Terms and (ii) neither
          the Member Content nor your posting, uploading, publication,
          submission, or transmission of the Member Content or Grabbi’s use of
          the Member Content (or any portion thereof) will infringe;
          misappropriate; or violate a third party's patent, copyright,
          trademark, trade secret, moral rights, proprietary, other intellectual
          property, publicity, or privacy rights or result in the violation of
          any applicable law or regulation. While we are not obligated to do so,
          we reserve the right and have absolute discretion to review, screen,
          and delete Member Content at any time and for any reason with or
          without notice.
        </li>
        <li className="tos_paragraph">
          You will not post, upload, publish, submit, or transmit any Member
          Content that (i) is fraudulent, false, misleading (directly, by
          omission, or by failing to update profile information), or deceptive;
          (ii) is defamatory, libelous, obscene, pornographic, vulgar, or
          offensive; (iii) promotes discrimination, bigotry, racism, hatred,
          harassment, or harm against any individual or group; (iv) is violent,
          threatens violence, or promotes violence; (v) promotes illegal or
          harmful activities or substances; or (vi) violates any other Grabbi
          policy. Grabbi may, without prior notice, remove or disable access to
          any Member Content that Grabbi finds to be in violation of these Terms
          or Grabbi’s then-current policies or standards or otherwise may be
          harmful or objectionable to Grabbi, its Members, third parties, or
          property in Grabbi’s sole discretion
        </li>
        <li className="tos_paragraph">
          Grabbi respects copyright law and expects its Members respect the
          copyrights of others on the Grabbi Platform. If you believe that any
          content on the Grabbi Platform infringes on your or another’s
          copyright, please notify us in accordance with Section 19 below.
        </li>
      </ol>
    ),
  },
  {
    title: "Terms Specific to Shoppers",
    body: (
      <div>
        <Box className="tos_subheader">
          6.1 AUTHENTICATION SERVICES
        </Box>
        <Box className="tos_paragraph">
          Grabbi uses third-party solutions in order to securely sign in users
          into the Grabbi Platform.
        </Box>
        <ol>
          <li className="tos_paragraph">
            Google Sign-In Button — This button means for platforms, such as the
            Grabbi Platform, to request permission to access a user’s
            information on Google. Users may grant access to their identity as
            well as additional specified services in order to sign into the
            Grabbi Platform. For more information on signing in with Google, you
            can go to{" "}
            {link(
              "https://www.google.com/accounts/authsub/terms.html",
              "https://www.google.com/accounts/authsub/terms.html",
              true
            )}
          </li>
          <li className="tos_paragraph">
            Sign in with Apple Button — This button means for platforms, such as
            the Grabbi Platform, to request permission to access a user’s
            information on Apple. Users may grant access to their identity,
            Apple ID, or additional specified services in order to sign into the
            Grabbi Platform. For more information on signing in with Apple, you
            can go to{" "}
            {link(
              "https://support.apple.com/en-us/HT210318",
              "https://support.apple.com/en-us/HT210318",
              true
            )}
          </li>
        </ol>
        <Box className="tos_subheader">
          6.2 WALLET INFORMATION AND SET UP
        </Box>
        <ol>
          <li className="tos_paragraph">
            Shoppers who download the Application must add money to their wallet
            via Apple Pay in order to make purchases on the Grabbi Platform.
            Shoppers have the option of adding the following amounts to their
            wallet: $10.00 USD, $20.00 USD, and $50.00 USD.
          </li>
          <li className="tos_paragraph">
            In order to add money to your wallet on the Grabbi Platform, you
            will need to have your credit card information stored in Apple
            wallet on your mobile device. You acknowledge that you have one of
            the following stored in Apple Wallet in order to use Apple Pay:
            Credit Card, Debit Card, Prepaid Card, Loyalty Card. You authorize
            all purchases made from your device using the Grabbi Platform. When
            you set up a new card in Apple Wallet or initiate a transaction,
            authorization may be sought from your card issuer. Your use of a
            payment card may be subject to separate terms and conditions between
            you and the card issuer and we are not responsible for any failure
            to process a payment. If any unauthorized payments are made using
            the Grabbi Platform, you should contact your card issuer
            immediately. If you no longer wish to use a particular payment
            method, there is an option to delete your payment card information
            in Apple Wallet. Our use of your payment information, as well as any
            data shared with your card issuer or any third party, will be
            governed by our Privacy Notice. For more information on Apple Pay,
            you can go to{" "}
            {link(
              "https://support.apple.com/en-us/HT201469",
              "https://support.apple.com/en-us/HT201469",
              true
            )}
          </li>
        </ol>
        <Box className="tos_subheader">
          6.3 PAYMENT PROCESSING
        </Box>
        <ol>
          <li className="tos_paragraph">
            After a transaction is made using Apple Pay on the Grabbi Platform,
            the payment is processed by sending the Apple Pay transaction
            information to Stripe for payment processing. Stripe is a
            third-party service that the Grabbi Platform utilizes in order to
            successfully process credit card payment information for purchases
            made on the Grabbi Platform.
          </li>
          <li className="tos_paragraph">
            The Grabbi Platform will not see or have access to any personal
            credit card information associated with Apple Pay.
          </li>
        </ol>
        <Box className="tos_subheader">
          6.4 Transactions Involving Alcohol
        </Box>
        <ol>
          <li className="tos_paragraph">
            Some jurisdictions permit the ordering of alcoholic beverages. In
            such jurisdictions, if you place an order via the Grabbi Platform
            that includes any alcoholic beverage, you represent and warrant that
            you are at least 21 years of age to purchase the alcoholic beverage.
            You also agree to comply with the Merchant’s applicable policy of
            selling alcoholic beverages. If you do not comply with the above
            provisions, you agree that the alcoholic beverage(s) will not be
            released to you and you may forfeit the cost of such beverages.
          </li>
          <li className="tos_paragraph">
            Grabbi does not sell or facilitate the sale of alcohol to persons
            under the age of 21. As a Merchant, when selling any alcohol
            beverage via the Grabbi Platform, you are representing that the
            person receiving the alcoholic beverages from your Store Merchandise
            is at least 21 years old or older. You, as a Merchant, also agree
            that any alcohol purchased from your Store Merchandise is intended
            for personal consumption and not for resale and that you will not
            knowingly or negligently sell any alcohol beverage to a person under
            the age of 21.
          </li>
        </ol>
        <Box className="tos_subheader"></Box>
      </div>
    ),
  },
  {
    title: "Terms Specific to Merchants",
    body: (
      <div>
        <Box className="tos_subheader">
          7.1 ACH Payments
        </Box>
        <ol>
          <li className="tos_paragraph">
            Merchants using the Grabbi Platform will receive ACH payment
            deposits for confirmed transactions within 3-5 business days. The
            cost for each ACH payment deposit sent will be paid by the Merchant
            before the outstanding balance is sent from Grabbi to the Merchant.
          </li>
          <li className="tos_paragraph">
            In order to receive ACH payment deposits, the merchant agrees to
            provide Grabbi with the following: full name, routing number, and
            bank account number in order to properly set up ACH payments to the
            Merchant.
          </li>
          <li className="tos_paragraph">
            The information provided above by the Merchant will be encrypted and
            securely stored on Grabbi’s databases. Grabbi is not liable or
            responsible for the accuracy of the information provided by the
            Merchant.
          </li>
        </ol>
        <Box className="tos_subheader">
          7.2 Merchant Fee Structure
        </Box>
        <ol>
          <li className="tos_paragraph">
            The merchant fee structure will be addressed in a separate contract
            between Grabbi and the Merchant, titled “Merchant Fees.”
          </li>
        </ol>
      </div>
    ),
  },
  {
    title: "Disputes between Members",
    body: (
      <ol>
        <li className="tos_paragraph">
          Members agree to cooperate with and assist Grabbi in good faith and to
          provide Grabbi with such information and to take such actions as may
          be reasonably requested by Grabbi in connection with any complaints or
          claims made by Members relating to Product listings, transactions, or
          Store Merchandise. However, the Members are ultimately responsible for
          resolving their own disputes with other Members. If a dispute cannot
          be resolved, the Members should seek third-party dispute resolution
          solutions, such as mediation or arbitration. Grabbi is not responsible
          for resolving disputes between Members in regard to the terms and
          conditions set forth in the Listing.
        </li>
      </ol>
    ),
  },
  {
    title: "Modifications, Cancellations, and Refunds",
    body: (
      <ol>
        <li className="tos_paragraph">
          Merchants and Shoppers are responsible for any modifications to a
          transaction that they make via the Grabbi Platform ("Modifications")
          and agree to pay any increases in the product price, fees, and/or
          Taxes associated with such Modifications.
        </li>
        <li className="tos_paragraph">
          {" "}
          Merchants can cancel a confirmed purchase at any time on the Grabbi
          Platform or by emailing Grabbi at{" "}
          {link("support@grabbi.shop", "mailto:support@grabbi.shop", true)}. The
          Shopper must adhere to the Merchant’s cancellation and refund
          policies. Grabbi does not enforce its own refund or cancellation
          policy. The decision to cancel a confirmed purchase is made between
          the Merchant and the Shopper.
        </li>
        <li className="tos_paragraph">
          If a Merchant cancels a confirmed purchase, the Shopper will receive a
          full refund of the total fees for the purchase and Grabbi may publish
          an automated review on the Product cancelled by the Merchant
          indicating that a transaction was cancelled.
        </li>
        <li className="tos_paragraph">
          In certain circumstances, Grabbi may decide, in its sole discretion,
          that it is necessary to cancel a confirmed purchase and make
          appropriate refund and payout decisions, such as, but not limited to,
          for the following reasons: (i) where Grabbi, taking into account the
          legitimate interests of both parties, believes in good faith that it
          is necessary to avoid significant harm to Grabbi, other Members, third
          parties, or property or (ii) for any of the reasons set out in these
          Terms.
        </li>
      </ol>
    ),
  },
  {
    title: "Release",
    body: (
      <ol>
        <li className="tos_paragraph">
          If you have a dispute with one or more Members, you release us and our
          affiliates, and subsidiaries along with our and their respective
          officers, directors, employees, and agents from any claims, demands,
          and damages (actual and consequential) of every kind and nature, known
          and unknown, arising out of or in any way connected with such
          disputes. You understand that it is your own responsibility to resolve
          any disputes you have with any other Members and that Grabbi is not
          responsible or obligated to resolve or help resolve any disputes you
          may have with another Member.
        </li>
        <li className="tos_paragraph">
          In entering into this release, you expressly waive any protections
          (whether statutory or otherwise) that would otherwise limit the
          coverage of this release to include only those claims which you may
          know or suspect to exist in your favor at the time of agreeing to this
          release.
        </li>
      </ol>
    ),
  },
  {
    title: "Taxes",
    body: (
      <ol>
        <li className="tos_paragraph">
          Merchants are solely responsible for determining their own obligations
          to report, collect, remit, or include in the product price any
          applicable VAT or other indirect sales taxes, income taxes, or other
          taxes ("Taxes").
        </li>
        <li className="tos_paragraph">
          Tax regulations may require us to collect appropriate tax information
          from Merchants, to withhold Taxes from payouts to a Merchant, or both.
          If a Merchant fails to provide us with documentation that in our
          discretion is sufficient to alleviate our obligation (if any) to
          withhold Taxes from payouts to the Merchant, we reserve the right to
          freeze all payouts, withhold such amounts as required by law, or to do
          both until resolution of the issue.
        </li>
        <li className="tos_paragraph">
          You understand that any appropriate governmental agency, department,
          and/or authority ("Tax Authority") where you are located may require
          Taxes to be collected from either Shoppers or Merchants on product
          prices and to be remitted to the respective Tax Authority. The laws in
          jurisdictions may vary, but these Taxes may be required to be
          collected and remitted as a percentage of the product prices set by
          Merchants.
        </li>
      </ol>
    ),
  },
  {
    title: "Term and Termination, Suspension, and other Measures",
    body: (
      <ol>
        <li className="tos_paragraph">
          Subject to this Section, these Terms will remain in full force and
          effect while you use the Grabbi Platform.
        </li>
        <li className="tos_paragraph">
          You may terminate this Agreement at any time by sending us an email at
          support@grabbi.shop. If you cancel your Account as a Merchant, you are
          still responsible for fulfilling and completing the terms of any
          confirmed purchases for which products have been sold and the payment
          transactions are pending. If you cancel your Account as a Shopper, you
          are still responsible for fulfilling and completing the terms of any
          confirmed purchases for products you have purchased and have in your
          possession.
        </li>
        <li className="tos_paragraph">
          Without limiting our rights specified below, Grabbi may terminate this
          Agreement for convenience at any time by giving you notice via email
          to your registered email address.
        </li>
        <li className="tos_paragraph">
          Grabbi may immediately, without notice, terminate this Agreement if
          (i) you have materially breached your obligations under these Terms,
          our policies, or standards; (ii) you have violated applicable laws,
          regulations, or third party rights; or (iii) Grabbi believes in good
          faith that such action is reasonably necessary to protect the personal
          safety or property of Grabbi, its Members, or third parties (for
          example in the case of fraudulent behavior of a Member).
        </li>
        <li className="tos_paragraph">
          Grabbi may take any of the following measures:
        </li>
        <ol>
          <li className="tos_paragraph">
            refuse to surface, delete, or delay any product listings or other
            Member Content;
          </li>
          <li className="tos_paragraph">
            cancel any pending or confirmed transactions;
          </li>
          <li className="tos_paragraph">
            limit your access to, or use of, the Grabbi Platform;
          </li>
          <li className="tos_paragraph">
            temporarily or permanently revoke any special status associated with
            your Account; or
          </li>
          <li className="tos_paragraph">
            temporarily or permanently suspend your Account.
          </li>
        </ol>
        <li className="tos_paragraph">
          Grabbi may pursue any of the above measures for the following reasons:
          (i) in order to comply with applicable law, the order or request of a
          court, law enforcement, or other administrative agency or governmental
          body; (ii) if you have breached these Terms, our policies or
          standards, applicable laws, regulations, or third party rights; (iii)
          you provided inaccurate, fraudulent, outdated, or incomplete
          information during the Grabbi Account registration, product listing
          process, or thereafter; (iv) if you and/or your product listings or
          Store Merchandise at any time fail to meet any applicable quality or
          eligibility criteria; (v) if you have repeatedly received poor ratings
          or reviews or Grabbi otherwise becomes aware of or has received
          complaints about your performance or conduct; (vi) if you have
          repeatedly cancelled confirmed transactions or failed to respond to
          Shoppers’ inquiries without a valid reason; or (vii) if Grabbi
          believes in good faith that such action is reasonably necessary to
          protect the personal safety or property of Grabbi, its Members, or
          third parties or to prevent fraud or other illegal activity.
        </li>
        <li className="tos_paragraph">
          In case of non-material breaches and where appropriate, you will be
          given notice of any intended measure by Grabbi and an opportunity to
          resolve the issue where the resolution of the issue will be determined
          at Grabbi’s sole discretion.
        </li>
        <li className="tos_paragraph">
          When this Agreement has been terminated, you are not entitled to a
          restoration of your Account or any of your Member Content. If your
          access to, or use of, the Grabbi Platform has been limited or your
          Account has been suspended or this Agreement has been terminated by
          us, you may not register a new Account or access or use the Grabbi
          Platform through an Account of another Member.
        </li>
        <li className="tos_paragraph">
          If you or we terminate this Agreement, the clauses of these Terms that
          reasonably should survive termination of the Agreement will remain in
          effect.
        </li>
      </ol>
    ),
  },
  {
    title: "Disclaimers",
    body: (
      <div>
        <Box className="tos_paragraph">
          If you choose to use the Grabbi Platform or Collective Content, you do
          so voluntarily and at your sole risk. The Grabbi Platform and the
          Collective Content is provided “as is”, without warranty of any kind,
          either express or implied. To the extent permitted by law, GRABBI
          DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE
          IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, TITLE, AND NON-INFRINGEMENT. GRABBI MAKES NO WARRANTY THAT
          (1) THE GRABBI PLATFORM WILL MEET YOUR REQUIREMENTS; (2) THE GRABBI
          PLATFORM WILL BE UNINTERRUPTED, TIMELY SECURE, OR ERROR-FREE; (3) THE
          RESULTS OF USING THE GRABBI PLATFORM WILL BE ACCURATE OR RELIABLE; (4)
          THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL
          PURCHASED OR OBTAINED THROUGH YOUR USE OF THE GRABBI PLATFORM WILL
          MEET YOUR EXPECTATIONS; OR (5) THAT THE SOFTWARE WILL BE ERROR-FREE
          AND/OR ANY ERRORS IN THE SOFTWARE WILL BE CORRECTED.
        </Box>
        <Box className="tos_paragraph">
          No advice or information, whether oral or written, obtained by you
          from Grabbi or through or from the Grabbi Platform shall create a
          warranty not expressly stated in these Terms
        </Box>
        <Box className="tos_paragraph">
          You agree that you have had whatever opportunity you deem necessary to
          investigate the Services, laws, rules, or regulations that may be
          applicable to your product listings and/or Store Merchandise ;you are
          providing or receiving and that you are not relying upon any statement
          of law or fact made by Grabbi relating to a Product Listing.
        </Box>
        <Box className="tos_paragraph">
          If we choose to conduct identity verification or background checks on
          any Member, to the extent permitted by applicable law, we disclaim
          warranties of any kind, either express or implied, that such checks
          will identify prior misconduct by a Member or guarantee that a Member
          will not engage in misconduct in the future.
        </Box>
        <Box className="tos_paragraph">
          The foregoing disclaimers apply to the maximum extent permitted by
          law. You may have other statutory rights; however, the duration of
          statutorily required warranties, if any, shall be limited to the
          maximum extent permitted by law.
        </Box>
      </div>
    ),
  },
  {
    title: "Liability",
    body: (
      <ol>
        <li className="tos_paragraph">
          You acknowledge and agree that, to the maximum extent permitted by
          law, the entire risk arising out of your access to and use of the
          Grabbi Platform and Collective Content, your publishing of or
          purchasing through any Product Listing via the Grabbi Platform, your
          use of any of a Merchant’s Store Merchandise, or any other interaction
          you have with other Members whether in person or online remains with
          you. USE OF THE GRABBIT PLATFORM IS AT YOUR OWN RISK. YOU ASSUME FULL
          RESPONSIBILITY AND RISK OF LOSS FROM YOUR USE OF THE GRABBI PLATFORM.
        </li>
        <li className="tos_paragraph">
          Except for our obligations to pay amounts to applicable Merchants
          pursuant to these Terms, in no event will Grabbi’s aggregate liability
          arising out of, or in connection with these Terms and your use of the
          Grabbi Platform including, but not limited to, from your publishing of
          Member Content, purchasing through any Product lListings via the
          Grabbi Platform, from your use of or inability to use the Grabbi
          Platform or Collective Content in connection with any Store
          Merchandise, or your interactions with any other Members, exceed one
          hundred U.S. dollars (US$100). The limitations of damages set forth
          above are fundamental elements of the basis of the bargain between
          Grabbi and you. Some jurisdictions do not allow the exclusion or
          limitation of liability for consequential or incidental damages, so
          the above limitation may not apply to you. If you reside outside of
          the U.S., this does not affect Grabbi’s liability for death or
          personal injury arising from its negligence, nor for fraudulent
          misrepresentation as to a fundamental matter or any other liability
          which cannot be excluded or limited under applicable law.
        </li>
      </ol>
    ),
  },
  {
    title: "Indemnification",
    body: (
      <ol>
        <li className="tos_paragraph">
          You agree to release, defend (at Grabbi’s option), indemnify, and hold
          Grabbi and its affiliates and subsidiaries along with our and their
          officers, directors, employees and agents, harmless from any and all
          loss, cost, injury, liability, claims, damages, or demand of any kind,
          including, but not limited to, legal and accounting fees and related
          costs made by or arising out of or in connection with your use of the
          Grabbi Platform in violation of these Terms and/or your violation of
          any rights of another or any applicable law, rule, or regulation.
        </li>
      </ol>
    ),
  },
  {
    title: "Governing Law",
    body: (
      <ol>
        <li className="tos_paragraph">
          If there is any dispute about or involving Grabbi and/or the Grabbi
          Platform, you agree that any dispute shall be governed by the laws of
          the State of California, without regard to its conflict of law
          provisions.
        </li>
      </ol>
    ),
  },
  {
    title: "Dispute Resolution and Arbitration Agreement",
    body: (
      <ol>
        <li className="tos_paragraph">
          (a) Mediation <br /> If there is any dispute about or involving the
          Grabbi Platform or the Terms that cannot be resolved amicably, you
          agree that the dispute shall first be submitted to confidential
          mediation for a good faith resolution that shall take place in San
          Francisco, California. The parties shall mutually agree upon a
          meditation service. The mediation shall commence upon the parties’
          provision of a joint written request for mediation to the mediation
          service. Such request shall include a sufficient description of the
          dispute and relief requested. Each party shall cooperate with the
          mediation service in all reasonable respects and participate in good
          faith wherever required.
        </li>
        <Box className="tos_paragraph">
          Mediation fees and expenses shall be borne equally by the parties. All
          communications, whether oral or written, are confidential and will be
          treated by the parties as compromise & settlement negotiations for the
          purposes of Federal Rule of Evidence 408 as well as any applicable
          corresponding state rules. Notwithstanding the foregoing, evidence
          that is otherwise admissible or discoverable shall not be rendered
          inadmissible or non-discoverable as a result of its use in the
          mediation. Either party may seek equitable relief, such as an
          injunction, prior to or during the mediation in order to preserve the
          status quo and protect its interests during the process. If, after the
          earlier of: (i) 60 days following the commencement of a mediation
          hereunder or (ii) completion of the initial mediation session, the
          parties have still not come to a resolution for any reason (including
          a failure to actually meditate), they shall seek to resolve the
          dispute by binding arbitration as more fully set forth below. Until
          such time, neither binding arbitration nor litigation may be pursued
          by the parties.
        </Box>
        <Box className="tos_paragraph">
          (b) Arbitration <br /> If the dispute is not resolved in mediation as
          described above, then arbitration will be administered by the American
          Arbitration Association in accordance with its commercial arbitration
          rules. Judgment on the award rendered by the arbitrator(s) may be
          entered in any court having jurisdiction thereof. The arbitration
          shall take place before a panel of one or three arbitrators sitting in
          San Francisco, California. The language of the arbitration shall be
          English. The arbitrators will be bound to adjudicate all disputes in
          accordance with the laws of the State of California.
        </Box>
        <Box className="tos_paragraph">
          The decision of the arbitrators shall be in writing with written
          findings of fact and shall be final and binding on the Parties. The
          arbitrator shall be empowered to award money damages, but shall not be
          empowered to award consequential damages, indirect damages, incidental
          damages, special damages, exemplary damages, punitive damages, or
          specific performance. The prevailing party in any arbitration shall be
          entitled to recover its reasonable, outside attorneys’ fees, and
          related costs. This section provides the sole recourse for the
          settlement of any disputes arising out of, in connection with, or
          related to this Agreement, except that a Party may seek a preliminary
          injunction or other injunctive relief in any court of competent
          jurisdiction if in its reasonable judgment such action is necessary to
          avoid irreparable harm.
        </Box>
        <li className="tos_paragraph">
          Changes. Notwithstanding the provisions of Section 4 (“Modification of
          these Terms”), if Grabbi changes this Section 18 (“Dispute Resolution
          and Arbitration Agreement”) after the date you last accepted these
          Terms (or accepted any subsequent changes to these Terms), you may
          reject any such change by sending us written notice (including by
          email at{" "}
          {link("support@grabbi.shop", "mailto:support@grabbi.shop", true)})
          within thirty (30) days of the date such change became effective, as
          indicated in the “Last Updated” date above or in the date of Grabbi’s
          email to you notifying you of such change. By rejecting any change,
          you are agreeing that you will arbitrate any dispute between you and
          Grabbi in accordance with the provisions of the “Dispute Resolution
          and Arbitration Agreement” Section as of the date you last accepted
          these Terms (or accepted any subsequent changes to these Terms).
        </li>
        <li className="tos_paragraph">
          Survival. Except as provided in Section 18.10 and subject to Section
          13.9, this Section 18 will survive any termination of these Terms and
          will continue to apply even if you stop using the Grabbi Platform or
          terminate your Account.
        </li>
        <li className="tos_paragraph">
          YOU AGREE THAT REGARDLESS OF ANY STATUTE OR LAW TO THE CONTRARY, ANY
          CLAIM OR CAUSE OF ACTION ARISING OUT OF OR RELATED TO USE OF THE
          GRABBI PLATFORM OR THESE TERMS MUST BE FILED WITHIN ONE (1) YEAR AFTER
          SUCH CLAIM OR CAUSE OF ACTION AROSE OR BE FOREVER BARRED.
        </li>
      </ol>
    ),
  },
  {
    title: "Feedback",
    body: (
      <ol>
        <li className="tos_paragraph">
          We welcome and encourage you to provide feedback, comments, and
          suggestions for improvements to the Grabbi Platform ("Feedback"). You
          may submit Feedback by emailing us, through the “Footer” section of
          the Grabbi Platform or by other means of communication. Any Feedback
          you submit to us will be considered non-confidential and
          non-proprietary to you. By submitting Feedback to us, you grant us a
          non-exclusive, worldwide, royalty-free, irrevocable, sub-licensable,
          perpetual license to use and publish those ideas and materials for any
          purpose without compensation to you.
        </li>
      </ol>
    ),
  },
  {
    title: "Copyright Policy",
    body: (
      <ol>
        <li className="tos_paragraph">
          Grabbi is committed to complying with U.S. copyright law and
          responding to claims of copyright infringement. We have adopted and
          implemented a policy respecting copyright law that provides for the
          removal of any infringing materials and for the termination, in
          appropriate circumstances, of Members of the Grabbi Platform who are
          repeat infringers of intellectual property rights, including
          copyrights. Grabbi will promptly process and investigate notices of
          alleged infringement and will take appropriate actions under the
          Digital Millennium Copyright Act, Title 17, United States Code,
          Section 512(c) ("DMCA").
        </li>
        <li className="tos_paragraph">
          Pursuant to the DMCA, notifications of claimed copyright infringement
          must be submitted in writing to our Designated Copyright Agent in the
          manner described below:
        </li>
        <ol>
          <li className="tos_paragraph">
            By Mail: <br /> Grabbi LLC. 2526 Alida St, Oakland, CA 94602 By
            Email:{" "}
            {link("support@grabbi.shop", "mailto:support@grabbi.shop", true)}
          </li>
        </ol>
        <li className="tos_paragraph">
          For your complaint to be valid under the DMCA, you must provide all of
          the following information when providing notice of the claimed
          copyright infringement:
        </li>
        <ol>
          <li className="tos_paragraph">
            A physical or electronic signature of a person authorized to act on
            behalf of the copyright owner;
          </li>
          <li className="tos_paragraph">
            Identification of the copyrighted work claimed to have been
            infringed;
          </li>
          <li className="tos_paragraph">
            Identification of the material that is claimed to be infringing or
            to be the subject of the infringing activity and that is to be
            removed or access to which is to be disabled as well as information
            reasonably sufficient to permit Grabbi to locate the material;
          </li>
          <li className="tos_paragraph">
            Information reasonably sufficient to permit Grabbi to contact the
            copyright owner, such as an address, telephone number, and, if
            available, an electronic mail address;
          </li>
          <li className="tos_paragraph">
            A statement that you have a good faith belief that use of the
            material in the manner complained of is not authorized by the
            copyright owner, its agent, or law; and
          </li>
          <li className="tos_paragraph">
            A statement that the information in the notification is accurate
            and, under penalty of perjury, that you are authorized to act on
            behalf of the copyright owner.
          </li>
        </ol>
        <li className="tos_paragraph">
          For more details on the information required for valid notification,
          see 17 U.S.C. 512(c)(3).
        </li>
        <li className="tos_paragraph">
          You should be aware that, under the DMCA, claimants who make
          misrepresentations concerning copyright infringement may be liable for
          damages incurred as a result of the removal or blocking of the
          material, court costs, and attorneys’ fees.
        </li>
      </ol>
    ),
  },
  {
    title: "General Provisions",
    body: (
      <ol>
        <li className="tos_paragraph">
          Except as they may be supplemented by additional terms and conditions,
          policies, guidelines or standards, these Terms constitute the entire
          Agreement between Grabbi and you pertaining to the subject matter
          hereof and supersedes any and all prior oral or written understandings
          or agreements between Grabbi and you in relation to the access to and
          use of the Grabbi Platform.
        </li>
        <li className="tos_paragraph">
          No joint venture, partnership, employment, or agency relationship
          exists between you and Grabbi as a result of this Agreement or your
          use of the Grabbi Platform.
        </li>
        <li className="tos_paragraph">
          These Terms do not and are not intended to confer any rights or
          remedies upon any person other than the Members and Grabbi.
        </li>
        <li className="tos_paragraph">
          If any provision of these Terms is held to be invalid or
          unenforceable, such provision will be struck and will not affect the
          validity and enforceability of the remaining provisions.
        </li>
        <li className="tos_paragraph">
          Grabbi’s failure to enforce any right or provision in these Terms will
          not constitute a waiver of such right or provision unless acknowledged
          and agreed by us in writing. Except as expressly set forth in these
          Terms, the exercise by either party of any of its remedies under these
          Terms will be without prejudice to its other remedies under these
          Terms or otherwise permitted under law.
        </li>
        <li className="tos_paragraph">
          You may not assign, transfer, or delegate this Agreement and your
          rights and obligations hereunder without Grabbi's prior written
          consent. Grabbi may without restriction assign, transfer, or delegate
          this Agreement and any rights and obligations hereunder, at its sole
          discretion. Your right to terminate this Agreement at any time remains
          unaffected.
        </li>
        <li className="tos_paragraph">
          Unless specified otherwise, any notices or other communications to
          Members permitted or required under this Agreement, will be in writing
          and given by Grabbi via email, the Grabbi Platform notification, or
          messaging service (including SMS). The date of receipt will be deemed
          the date on which Grabbi transmits the notice.
        </li>
        <li className="tos_paragraph">
          If you have any questions about these Terms, please email us at{" "}
          {link("support@grabbi.shop", "mailto:support@grabbi.shop", true)} or
          contact us at: Grabbi, LLC. 2526 Alida St, Oakland, CA 94602, United
          States.
        </li>
      </ol>
    ),
  },
];

export default TermsOfService;

import { Grid, useMediaQuery } from "@material-ui/core";
import CheckoutTabDialog from "components/ResturantBoard/CheckoutTabDialog/CheckoutTabDialogComponent";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import GrabbiMoreActions, {
  MoreActionsItem,
} from "components/Shared/GrabbiMoreActions/GrabbiMoreActionsComponent";
import GrabbiPrint from "components/Shared/GrabbiPrint/GrabbiPrintComponent";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";
import OrderCode from "components/Shared/OrderCode/OrderCodeComponent";
import ToppingTag from "components/Shared/ToppingTag/ToppingTagComponent";
import useTransactionHooks from "hooks/TransactionHooks";
import { sortBy } from "lodash";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  formatCurrency,
  getTransactionTotalString,
  muism,
} from "shared/scripts/Constants";
import {
  FulfillTransactionPayload,
  GrabbiStore,
  ReadyTransactionPayload,
  Transaction,
  TransactionItem,
} from "types/GrabbiTypes";
import ConfirmCloseDialog from "../ConfirmCloseDialogComponent/ConfirmCloseDialogComponent";
import RefundDialog from "../RefundDialog/RefundDialogComponent";
import "./OrderCardComponent.scss";
import { useAxiosContext } from "components/Shared/AxiosProvider/AxiosProvider";

interface Props {
  className?: string;
  transaction: Transaction;
  store: GrabbiStore;
  refreshCallback: () => Promise<void>;
  showRefund?: boolean;
}

const OrderCard: FC<Props> = ({
  className,
  transaction: t,
  store,
  refreshCallback,
  showRefund,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [showLoading, setShowLoading] = useState(false);
  const [refundTransaction, setRefundTransaction] = useState<
    Transaction | undefined
  >();
  const [checkoutTransaction, setCheckoutTransaction] = useState<Transaction>();
  const [confirmCloseTransaction, setConfirmCloseTransaction] =
    useState<Transaction>();
  const { useSetFulfillTransaction, useSetTransactionReady } =
    useTransactionHooks();

  const handleRefresh = useCallback(async () => {
    setShowLoading(true);
    await refreshCallback();
    setShowLoading(false);
  }, [refreshCallback]);

  const [
    {
      data: dataTransactionFulfill,
      loading: loadingTransactionFulfill,
      error: errorTransactionFulfill,
    },
    setTransactionFulfilled,
  ] = useSetFulfillTransaction();

  useEffect(() => {
    if (errorTransactionFulfill) {
      enqueueSnackbar(errorTransactionFulfill, { variant: "error" });
    } else if (dataTransactionFulfill) {
      enqueueSnackbar("Transaction updated.", { variant: "success" });
      refreshCallback();
    }
  }, [
    errorTransactionFulfill,
    dataTransactionFulfill,
    refreshCallback,
    enqueueSnackbar,
  ]);

  const [
    {
      data: dataTransactionReady,
      loading: loadingTransactionReady,
      error: errorTransactionReady,
    },
    setTransactionReady,
  ] = useSetTransactionReady();

  useEffect(() => {
    if (dataTransactionReady) {
      enqueueSnackbar("Transaction updated", { variant: "success" });
    } else if (errorTransactionReady) {
      enqueueSnackbar(errorTransactionReady.response?.data?.message);
      refreshCallback();
    }
  }, [
    dataTransactionReady,
    errorTransactionReady,
    refreshCallback,
    enqueueSnackbar,
  ]);

  useMemo(() => {
    if (t) {
      setShowLoading(false);
    }
  }, [t, setShowLoading]);

  const handleOpenCloseTransaction = useCallback(() => {
    const payload: FulfillTransactionPayload = {
      id: t.id,
      fulfilled: !t.fulfilled,
      store: {
        id: store.id,
      },
    };
    setTransactionFulfilled({
      data: payload,
    });
  }, [t, store, setTransactionFulfilled]);

  const handleConfirmCloseTransaction = useCallback(() => {
    setConfirmCloseTransaction(t);
  }, [t, setConfirmCloseTransaction]);

  const handleReadyTransaction = useCallback(() => {
    const payload: ReadyTransactionPayload = {
      id: t.id,
      store: {
        id: store.id,
      },
    };
    setTransactionReady({
      data: payload,
    });
  }, [t, store, setTransactionReady]);

  const handleCheckoutTransaction = useCallback(
    () => setCheckoutTransaction(t),
    [t, setCheckoutTransaction]
  );

  const handleRefund = useCallback(() => {
    setRefundTransaction(t);
  }, [t, setRefundTransaction]);

  const actionLoading =
    loadingTransactionFulfill || loadingTransactionReady || showLoading;

  const refundEnabled =
    showRefund &&
    t.transactionStatus !== "NONE" &&
    t.transactionStatus !== "AWAITING_PAYMENT";

  const lessThanSm = useMediaQuery(`(max-width: ${muism}px)`);

  const collapseActionsBp = useMediaQuery(
    `(max-width: ${refundEnabled ? 760 : 600}px)`
  );
  const collapseActionsXsBp = useMediaQuery(`(max-width: ${475}px)`);
  const collapseActions =
    collapseActionsBp && (!lessThanSm || collapseActionsXsBp);

  return (
    <React.Fragment>
      <Grid
        item
        container
        xs={12}
        className={`order_card_root ${actionLoading ? "loading" : ""} ${
          className ?? ""
        }`}
      >
        {actionLoading && (
          <Grid className="loading_wrapper" item container xs={12}>
            <LoadingIcon className="loading_icon" />
          </Grid>
        )}
        <Grid xs={12} item container className="top_section">
          <Grid item container sm={5}>
            <div className="date_time">
              {DateTime.fromMillis(t.createdDate).toFormat("MMM dd | hh:mma")}
            </div>
            <OrderCode transaction={t} hideOrderedOn={true} />
          </Grid>
          {!lessThanSm && (
            <Grid item container sm={7} className="actions">
              <TransactionActions
                transaction={t}
                store={store}
                showRefund={showRefund}
                collapse={collapseActions}
                storeName={store.name}
                onConfirmClose={handleConfirmCloseTransaction}
                onOpenClose={handleOpenCloseTransaction}
                onReady={handleReadyTransaction}
                onCheckout={handleCheckoutTransaction}
                onRefund={handleRefund}
              />
            </Grid>
          )}
        </Grid>
        <Grid item container className="middle_section">
          <Grid
            xs={12}
            sm={4}
            lg={3}
            item
            container
            className="order_info_left"
          >
            {t.tableNumber != null && (
              <div className="table_number_container">
                <span>{`Table ${t.tableNumber}`}</span>
              </div>
            )}
            <div className="status_container">
              <TransactionStatus transaction={t} store={store} />
            </div>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={7}
            lg={8}
            item
            container
            className="order_info_middle"
          >
            {sortBy(t.transactionItems, "id").map((item: TransactionItem) => {
              return (
                <div className="item_row" key={`item-row-${item.id}`}>
                  <span className="quantity_container">{`${item.quantity}`}</span>
                  <span className="quantity_label_seperator">x</span>
                  <div className="label_and_topping_container">
                    <span>{item.upc.label}</span>
                    {sortBy(item.toppings, "id").map((topping) => {
                      return (
                        <ToppingTag
                          className="topping"
                          topping={topping}
                          key={`topping-tag-${topping.upc}`}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </Grid>
          <Grid
            xs={12}
            sm={2}
            md={1}
            item
            container
            className="order_info_right"
          >
            <span>{`${getTransactionTotalString(t)}`}</span>
            {t.tip ? (
              <span className="order_info_tip">
                TIP: {formatCurrency(t.tip, t.currency)}
              </span>
            ) : undefined}
          </Grid>
        </Grid>
        {lessThanSm && (
          <Grid item container className="bottom_section">
            <TransactionActions
              transaction={t}
              store={store}
              showRefund={showRefund}
              collapse={collapseActions}
              storeName={store.name}
              onConfirmClose={handleConfirmCloseTransaction}
              onOpenClose={handleOpenCloseTransaction}
              onReady={handleReadyTransaction}
              onCheckout={handleCheckoutTransaction}
              onRefund={handleRefund}
            />
          </Grid>
        )}
      </Grid>
      {checkoutTransaction && (
        <CheckoutTabDialog
          onClose={() => setCheckoutTransaction(undefined)}
          open={checkoutTransaction !== undefined}
          refreshCallback={handleRefresh}
          store={store}
          transaction={checkoutTransaction}
        />
      )}
      {confirmCloseTransaction && (
        <ConfirmCloseDialog
          onClose={() => setConfirmCloseTransaction(undefined)}
          open={confirmCloseTransaction !== undefined}
          refreshCallback={handleRefresh}
          transaction={confirmCloseTransaction}
          storeId={store.id}
        />
      )}
      {refundTransaction && store.id && (
        <RefundDialog
          transaction={refundTransaction}
          storeId={store.id}
          onClose={() => {
            setRefundTransaction(undefined);
          }}
          refreshCallback={handleRefresh}
        />
      )}
    </React.Fragment>
  );
};

export default OrderCard;

const nextStatus = (transaction: Transaction) => {
  return transaction.fulfilled ? "Open" : "Close";
};

interface TransactionStatusProps {
  className?: string;
  transaction: Transaction;
  store: GrabbiStore;
}

const TransactionStatus: React.FC<TransactionStatusProps> = ({
  className,
  transaction,
  store,
}) => {
  if (!store?.posEnabled && transaction.paymentType === "ATTENDANT_CHECKOUT") {
    //CASHIER CHECKOUT - NO POS
    if (transaction.transactionStatus === "AWAITING_PAYMENT") {
      return (
        <div className={`status_component red ${className ?? ""}`}>Unpaid</div>
      ); //CASHIER CHECKOUT UNPAID
    } else {
      return (
        <div className={`status_component ${className ?? ""}`}>
          Payment Complete
        </div>
      ); //CASHIER CHECKOUT PAID
    }
  } else if (
    store?.posEnabled &&
    transaction.paymentType === "ATTENDANT_CHECKOUT"
  ) {
    //CASHIER CHECKOUT - WITH POS
    if (transaction.fulfilled) {
      return (
        <div className={`status_component ${className ?? ""}`}>
          Closed by Merchant
        </div>
      ); //CASHIER CHECKOUT UNPAID
    } else {
      return (
        <div className={`status_component red ${className ?? ""}`}>Unpaid</div>
      ); //CASHIER CHECKOUT UNPAID
    }
  } else if (transaction.paymentType === "TERMINAL_CHECKOUT") {
    //TERMINAL CHECKOUT
    return (
      <div className={`status_component ${className ?? ""}`}>
        Terminal Checkout
      </div>
    );
  } else if (transaction.paymentType === "GOOGLE_PAY") {
    //GOOGLE PAY
    return (
      <div className={`status_component ${className ?? ""}`}>
        <svg className="google_icon" />
        <span>{`User Paid ${getTransactionTotalString(transaction)}`}</span>
      </div>
    );
  } else if (transaction.paymentType === "APPLE_PAY") {
    //APPLE PAY
    return (
      <div className={`status_component ${className ?? ""}`}>
        <svg className="apple_icon" />
        <span>{`User Paid ${getTransactionTotalString(transaction)}`}</span>
      </div>
    );
  } else {
    console.error(`[Error]: Unmapped Transaction Status`);
    console.error(`Transaction ID: ${transaction.id}`);
    console.error(`Payment Type: ${transaction.paymentType}`);
    console.error(`Transaction Status: ${transaction.transactionStatus}`);
    return <div />;
  }
};

interface TransactionActionsProps {
  transaction: Transaction;
  store: GrabbiStore;
  showRefund?: boolean;
  collapse?: boolean;
  storeName?: string;
  onReady: () => void;
  onConfirmClose: () => void;
  onOpenClose: () => void;
  onCheckout: () => void;
  onRefund: () => void;
}

const TransactionActions: React.FC<TransactionActionsProps> = ({
  transaction: t,
  store,
  showRefund,
  collapse,
  storeName,
  onReady,
  onConfirmClose,
  onOpenClose,
  onCheckout,
  onRefund,
}) => {
  const { token } = useAxiosContext();
  const collapsedActions: MoreActionsItem[] = [
    {
      // ? [REVISIT] Change this to a function that produces a link in the future
      content: (
        <GrabbiPrint
          className="print_button_collapsed"
          date={DateTime.fromMillis(t.createdDate).toFormat("MMM dd hh:mma")}
          transactionId={t.id}
          orderCode={t.orderCode}
          transactionItem={t.transactionItems}
          serviceFee={t.serviceFee}
          totalTax={t.totalTax}
          totalTip={t.tip}
          transactionTotal={t.transactionTotal}
          transactionCurrency={t.currency}
          paymentStatus={t.transactionStatus}
          tableNumber={t.tableNumber}
          storeId={Number(store.upcNumber)}
          storeName={storeName}
          storePrinters={store.printers ? store.printers : []}
          token={token}
          printerType={2}
        />
      ),
      callback: () => {},
    },
  ];

  const addRefundButton =
    showRefund &&
    t.transactionStatus !== "NONE" &&
    t.transactionStatus !== "AWAITING_PAYMENT";

  if (addRefundButton) {
    collapsedActions.push({
      content: "Refund",
      callback: onRefund,
    });
  }

  const newStatus = nextStatus(t);

  return (
    <React.Fragment>
      {store?.posEnabled && t.paymentType === "ATTENDANT_CHECKOUT" && (
        <GrabbiButton
          onClick={onCheckout}
          className="checkout_button action_button"
        >
          Checkout
        </GrabbiButton>
      )}
      <GrabbiButton className="status_button action_button" onClick={onReady}>
        Ready
      </GrabbiButton>
      {newStatus === "Close" &&
      (t.paymentType === "ATTENDANT_CHECKOUT" ||
        t.paymentType === "TERMINAL_CHECKOUT") &&
      t.transactionStatus !== "PROCESSING_ORDER" ? (
        <GrabbiButton
          className="status_button action_button"
          onClick={onConfirmClose}
        >
          {newStatus}
        </GrabbiButton>
      ) : (
        <GrabbiButton
          className="status_button action_button"
          onClick={onOpenClose}
        >
          {newStatus}
        </GrabbiButton>
      )}

      {!collapse && (
        <GrabbiPrint
          className="black print_button action_button"
          date={DateTime.fromMillis(t.createdDate).toFormat("MMM dd hh:mma")}
          transactionId={t.id}
          orderCode={t.orderCode}
          transactionItem={t.transactionItems}
          serviceFee={t.serviceFee}
          totalTax={t.totalTax}
          totalTip={t.tip}
          transactionTotal={t.transactionTotal}
          transactionCurrency={t.currency}
          paymentStatus={t.transactionStatus}
          tableNumber={t.tableNumber}
          storeId={Number(store.upcNumber)}
          storeName={storeName}
          storePrinters={store.printers ? store.printers : []}
          token={token}
          printerType={2}
        />
      )}
      {!collapse && addRefundButton && (
        <GrabbiButton
          className="refund_button action_button"
          onClick={onRefund}
        >
          REFUND
        </GrabbiButton>
      )}
      {collapse && (
        <GrabbiMoreActions
          className="collapsed_actions"
          actions={collapsedActions}
        />
      )}
    </React.Fragment>
  );
};

import { DateTime } from "luxon";
import { Transaction } from "types/GrabbiTypes";
import Cookies from "universal-cookie";
import { MEMBERSHIP_EMAIL_COOKIE_KEY } from "./Constants";

export const alertNotImplemented = () => {
  alert("This feature is not implemented.");
};

export const capitalizeFirst = (s: string) =>
  `${s.charAt(0).toUpperCase()}${s.toLowerCase().substring(1, s.length)}`;

export const formatCurrency = (amount: number, currency: string) => {
  return amount.toLocaleString(undefined, {
    style: "currency",
    currency: currency,
  });
};

export const calculateTipableAmount = (transaction: Transaction) => {
  return transaction.merchandiseTotal;
};

export const getMembershipEmailCookieKey = (storeId: number) =>
  `${MEMBERSHIP_EMAIL_COOKIE_KEY}-${storeId}`;

export const checkMembershipEmailCookieExists = (storeId: number) =>
  getCookie(getMembershipEmailCookieKey(storeId)) != null;

export const setCookie = (
  key: string,
  value: string | number,
  hoursToExpire: number = 24
) => {
  const now = DateTime.now();
  const expirationTime = now.plus({ hours: hoursToExpire }).toJSDate();
  const cookie = new Cookies();
  cookie.set(key, value, {
    expires: expirationTime,
    path: "/",
  });
};

export const getCookie = (key: string) => {
  const cookie = new Cookies();
  return cookie.get(key);
};

export const deleteCookie = (key: string) => {
  const cookie = new Cookies();
  cookie.remove(key, { path: "/" });
};

import {
  getMembershipCookieAction,
  getMembershipStartAction,
  setMembershipCheckedAction,
} from "actions/membership";
import GrabbiDivider from "components/Shared/Divider/DividerComponent";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import GrabbiTextField from "components/Shared/GrabbiTextField/GrabbiTextField";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCheckMembershipErrorSelector, getMembershipLoading } from "selectors/customer";
import { RFC_5322_EMAIL_REGEX } from "shared/scripts/Constants";
import { checkMembershipEmailCookieExists } from "shared/scripts/Utils";
import { GrabbiStore } from "types/GrabbiTypes";
import "./MembershipComponent.scss";

interface Props {
  store: GrabbiStore;
}

const Membership: React.FC<Props> = ({ store }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const checkMembershipError = useSelector(getCheckMembershipErrorSelector);
  const loading = useSelector(getMembershipLoading);
  const [email, setEmail] = useState("");
  

  const handleEmailChange = (event: any) => {
    const value = event.target.value;
    setEmail(value);
  };

  const emailValid = useMemo(() => {
    if (email.length === 0) {
      return false;
    } else if (!RFC_5322_EMAIL_REGEX.test(email)) {
      return false;
    }
    return true;
  }, [email]);

  useEffect(() => {
    const hasEmailCookie = checkMembershipEmailCookieExists(store.id);
    if (hasEmailCookie) {
      dispatch(getMembershipCookieAction(store.id));
    }
  }, [store, dispatch]);

  useEffect(() => {
    if (checkMembershipError) {
      enqueueSnackbar(checkMembershipError, { variant: "error" });
    }
  }, [checkMembershipError, enqueueSnackbar]);

  const handleCheckMembership = () => {
    dispatch(getMembershipStartAction(store.id, email));
  };

  const handleGoToMenu = () => {
    dispatch(setMembershipCheckedAction());
  };

  return (
    <div className="membership_root flex-column p-x-6">
      <h1 className="x-large-font m-t-7">Are you a wine club member?</h1>
      <p className="normal-font">Enter your email for exclusive discounts.</p>
      <GrabbiTextField
        className="m-t-7"
        disabled={loading}
        label="Email Address"
        placeholder="Email Address"
        colorVariant="black"
        value={email}
        onChange={handleEmailChange}
        type="email"
      />

      {loading ? (
        <LoadingIcon className="flex-align-self-center" colorVariant="yellow" />
      ) : (
        <GrabbiButton
          className="m-t-4 p-y-6"
          colorVariant="white"
          fullWidth={true}
          disabled={!emailValid || loading}
          onClick={handleCheckMembership}
        >
          SUBMIT
        </GrabbiButton>
      )}
      <GrabbiDivider className="m-t-7 font-weight-1" text="OR" />
      <p className="normal-font m-t-6 font-weight-1">
        Skip this step and go to our menu
      </p>
      <GrabbiButton
        className="m-t-3 p-y-6"
        colorVariant="yellow"
        fullWidth={true}
        onClick={handleGoToMenu}
        disabled={loading}
      >
        GO TO MENU
      </GrabbiButton>
    </div>
  );
};

export default Membership;

import { BaseAction, actionIds } from "actions";

export type ConfirmMerchantState = {
  merchantConfirmed: boolean;
  merchantConfirmedLoading: boolean;
  merchantConfirmedError?: string;
  merchantConfirmResendSuccess: boolean;
  merchantConfirmResendLoading: boolean;
  merchantConfirmResendError?: string;
};

const DEFAULT_STATE: ConfirmMerchantState = {
  merchantConfirmed: false,
  merchantConfirmedLoading: false,
  merchantConfirmResendLoading: false,
  merchantConfirmResendSuccess: false
};

export const confirmMerchantReducer = (
  state: ConfirmMerchantState = DEFAULT_STATE,
  action: BaseAction
): ConfirmMerchantState => {
  switch (action.type) {
    case actionIds.CONFIRM_MERCHANT_START:
      return {
        ...state,
        merchantConfirmed: false,
        merchantConfirmedLoading: true,
      };
    case actionIds.CONFIRM_MERCHANT_SUCCESS:
      return {
        ...state,
        merchantConfirmed: true,
        merchantConfirmedLoading: false,
      };
    case actionIds.CONFIRM_MERCHANT_FAILED:
      return {
        ...state,
        merchantConfirmed: false,
        merchantConfirmedLoading: false,
        merchantConfirmedError: action.payload.error,
      };
    case actionIds.RESEND_CONFIRM_MERCHANT_START:
      return {
        ...state,
        merchantConfirmResendLoading: true,
        merchantConfirmResendSuccess: false,
        merchantConfirmResendError: undefined,
      };
    case actionIds.RESEND_CONFIRM_MERCHANT_SUCCESS:
      return {
        ...state,
        merchantConfirmResendLoading: false,
        merchantConfirmResendSuccess: true,
        merchantConfirmResendError: undefined,
      };
    case actionIds.RESEND_CONFIRM_MERCHANT_FAILED:
      return {
        ...state,
        merchantConfirmResendLoading: false,
        merchantConfirmResendSuccess: false,
        merchantConfirmResendError: action.payload.error,
      };
  }
  return state;
};

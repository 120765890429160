import React from "react";
import "./FooterComponent.scss";
import { NavLink } from "react-router-dom";
import { CONTEXT } from "shared/scripts/UrlContext";
import { DateTime } from "luxon";

interface Props {
  className?: string;
}

interface Link {
  title: string;
  href: string;
}

const links: Link[] = [
  {
    title: "ABOUT THE TEAM",
    href: "about",
  },
  {
    title: "PRIVACY POLICY",
    href: "privacy-policy",
  },
  {
    title: "TERMS OF SERVICE",
    href: "terms-of-service",
  },
];

const Footer: React.FC<Props> = ({ className }) => {
  interface SocialIcon {
    href: string;
    path: string;
    name: string;
  }

  const socialMediaIcons: SocialIcon[] = [
    {
      href: "https://twitter.com/Grabbi_app",
      path: "twitter-icon.png",
      name: "TWITTER",
    },
    {
      href: "https://www.instagram.com/Grabbi_App",
      path: "instagram-icon.png",
      name: "INSTAGRAM",
    },
    {
      href: "https://www.facebook.com/Grabbi.LLC",
      path: "facebook-icon.png",
      name: "FACEBOOK",
    },
    {
      href: "https://www.linkedin.com/company/grabbi",
      path: "linkedin-icon.png",
      name: "LINKEDIN",
    },
    {
      href: "https://www.youtube.com/channel/UCMUtubrKiz6RxAgmVBbwaLA",
      path: "youtube-icon.png",
      name: "YOUTUBE",
    },
  ];

  return (
    <div className={`${className} footer_root`}>
      <div className='grabbi_icon_for_footer_container'>
        <div className='grabbi_icon_for_footer' style={{
        backgroundImage: `url('assets/grabbi-text-icon-for-footer.png')`}}></div>

        <div className='footer_links_section_grid'>
          <div className='footer_links_left_column'>
            <div className='footer_links_header_text'>GRABBI </div>
            <div className='footer_links_item_text_container'>
            {links.map((link: Link) => {
              return (
                <NavLink
                  key={`footer-link-${link.title}`}
                  className="footer_links_item_text_links"
                  to={`${CONTEXT}${link.href}`}
                >
                  {link.title}
                </NavLink>
              );
            })}
            </div>
          </div>

          <div className='footer_links_middle_column'>
            <div className='footer_links_header_text'>MERCHANTS</div>
            {/* <NavLink
              className="footer_links_item_text_links"
              to={`${CONTEXT}signup`}
            >
              SIGN UP
            </NavLink> */}
            <NavLink
              className="footer_links_item_text_links"
              to={`${CONTEXT}getstarted`}
            >
              GET STARTED
            </NavLink>
            <NavLink
              className="footer_links_item_text_links"
              to={`${CONTEXT}starterkit`}
            >
              STARTER KIT
            </NavLink>
          </div>
          <div className='footer_links_right_column'>
            <div className='footer_links_header_text'>FOLLOW US</div>

            {socialMediaIcons.map((icon: SocialIcon) => {
              return (
                <a
                  key={icon.href}
                  target="_blank"
                  href={icon.href}
                  rel="noopener noreferrer"
                  className="footer_links_item_text_links"
                >{icon.name}
                </a>
              );
            })}
          </div>
        </div>
      </div>
      <div className='footer_section_divider'>.</div>
      <div className='grabbi_spitfier_for_footer_container'>
        <div className='grabbi_spitfier_for_footer' style={{
        backgroundImage: `url('assets/grabbi-spitfier-icon.png')`}}></div>
        <div className='grabbi_culture_container'>
          <span className='grabbi_culture_container_text'>
            We call ourselves the spitfires, a tale of taking on the might of major retailers.
          </span>
        </div>
      </div>
      <div className='footer_section_divider'>.</div>
      <div className='grabbi_copy_right_container'>
        <div className='grabbi_culture_container'>
          <span className='grabbi_culture_container_text'>
            COPYRIGHT Ⓒ Grabbi LLC. 2019 - {DateTime.now().year}. ALL RIGHTS RESERVED.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import { Grid } from "@material-ui/core";
import { AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons";
import { values } from "lodash";
import React from "react";
import { formatCurrency } from "shared/scripts/Utils";
import {
  CartItemEntry,
  useMerchSelection,
} from "../../MerchandiseSelectorComponent";
import ToppingSelector from "../../ToppingSelector/ToppingSelectorComponent";
import { getDependentMerchandisePrice, getDependentMerchandiseTaxAmount } from "../../ToppingSelector/utils";
import "./CartItemComponent.scss";

interface Props {
  className?: string;
  cartEntry: CartItemEntry;
  currency: string;
  itemIndex: number; //? [REVISIT] Needed to delete correct element from cart array
}

const CartItem: React.FC<Props> = ({
  className,
  cartEntry,
  currency,
  itemIndex,
}) => {
  const { changeSelectionItemQuantity: changeItemQuantity } =
    useMerchSelection();

  const { merchandise, quantity, groupingState, isPosted } = cartEntry;
  const { upc } = merchandise;
  const { label, description } = upc;

  const dependantPrice = getDependentMerchandisePrice(cartEntry);

  const dependantTaxAmount = getDependentMerchandiseTaxAmount(cartEntry);

  return (
    <Grid container className={`cart_item_root ${className ?? ""}`}>
      <Grid item container xs={7} className="left">
        <span className="price">
          {formatCurrency(dependantPrice, currency)}
        </span>
        <span className="tax">
          <span>TAX</span>
          <span>{formatCurrency(dependantTaxAmount, currency)}</span>
        </span>
        <span className="label">{label}</span>
        <span className="description">{description}</span>
      </Grid>
      <Grid item container xs={5} className="right">
        <div className="quantity_selector">
          {!isPosted && (
            <RemoveCircleOutline
              className="quantity_button"
              onClick={() => changeItemQuantity(itemIndex, quantity - 1)}
            />
          )}
          <span className="quantity_amount">{quantity}</span>
          {!isPosted && (
            <AddCircleOutline
              className="quantity_button"
              onClick={() => changeItemQuantity(itemIndex, quantity + 1)}
            />
          )}
        </div>
      </Grid>
      <Grid item container xs={12} className="bottom">
        <ToppingSelector
          merch={merchandise}
          readOnly={true}
          toggleTopping={() => {}}
          toppingGroupStates={values(groupingState)}
        />
      </Grid>
    </Grid>
  );
};

export default CartItem;

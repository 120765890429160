import { useAxiosContext } from "components/Shared/AxiosProvider/AxiosProvider";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import GrabbiDialog from "components/Shared/GrabbiDialog/GrabbiDialogComponent";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";
import SelectionStateChip from "components/Shared/SelectionStateChip/SelectionStateChipComponent";
import useTransactionHooks from "hooks/TransactionHooks";
import { filter, map, sortBy } from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  AddTransactionPayload,
  GrabbiMerchandise,
  GrabbiStore,
  Transaction,
} from "types/GrabbiTypes";
import MerchandiseSelector, {
  useMerchSelection,
} from "../MerchandiseSelector/MerchandiseSelectorComponent";
import RestaurantBoardDialogHeader from "../RestaurantBoardDialogHeader/RestaurantBoardDialogHeader";
import UnavailableCheckbox from "../UnavailableCheckbox/UnavailableCheckbox";
import "./AddItemDialogComponent.scss";

interface Props {
  open: boolean;
  onClose: () => void;
  refreshCallback: () => void;
  store: GrabbiStore;
  transaction: Transaction;
}

const AddItemDialog: React.FC<Props> = ({
  open,
  transaction,
  store,
  refreshCallback,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { upc: storeIdParam } = useParams<{ upc: string }>();
  const { useAxios } = useAxiosContext();
  const { useAddTransaction } = useTransactionHooks();

  const {
    showUnavailable,
    merchandiseCartSelection,
    getCartTotal,
    getCartQuantity,
    toggleShowCart,
    toggleShowUnavailable,
  } = useMerchSelection();

  const handleOnClose = () => {
    onClose();
  };

  //Get Merchandise - Start
  const [
    { data: dataMerchandise, loading: loadingMerchandise },
    getMerchandise,
  ] = useAxios<GrabbiMerchandise[]>({
    url: `restful-services/merchandise/${storeIdParam}`,
    method: "GET",
  });

  const dataMerchandiseSorted = map(
    filter(
      sortBy(dataMerchandise, (m) => m.upc.upcNumber),
      "available"
    ),
    (m) => ({
      ...m,
      toppings: filter(m.toppings, (t) => t.available || showUnavailable),
    })
  );

  useEffect(() => {
    if (storeIdParam !== undefined) {
      getMerchandise();
    }
  }, [storeIdParam, getMerchandise]);
  //Get Merchandise - Finished

  //Add To Transactions - Start

  const storeId = parseInt(storeIdParam);

  const [{ loading: loadingAddToTransaction, error }, addToTransaction] =
    useAddTransaction();

  useEffect(() => {
    if (error) {
      enqueueSnackbar("Failed to add items to transaction", {
        variant: "error",
      });
    }
  }, [error, enqueueSnackbar]);

  const handleAddItems = () => {
    if (merchandiseCartSelection.length === 0) {
      enqueueSnackbar("Please select at least one item", { variant: "error" });
    } else {
      const data: AddTransactionPayload = {
        payload: {
          transactionId: transaction.id,
          currency: transaction.currency,
          payLater: true,
          storeId,
          tableNumber: transaction.tableNumber,
          totalAmount: transaction.transactionTotal,
          totalTax: transaction.totalTax,
          transactionTotal: transaction.transactionTotal,
          orderItems: merchandiseCartSelection.map((item) => ({
            itemTax: item.taxAmount,
            merchandiseId: item.merchandiseId,
            quantity: item.quantity,
            toppings: item.toppings.length > 0 ? item.toppings : undefined,
            itemTotal: item.price * item.quantity,
          })),
        },
      };

      if (data) {
        addToTransaction({ data }).then((data) => {
          enqueueSnackbar("Items added to Transaction", { variant: "success" });
          refreshCallback();
          handleOnClose();
        });
      }
    }
  };

  //Add To Transactions - Finish

  const transactionTotal = getCartTotal();
  const canOpenTab = merchandiseCartSelection.length > 0;

  const showLoadingHard = loadingMerchandise;
  const showLoadingSoft = loadingAddToTransaction;

  return (
    <GrabbiDialog
      dialogProps={{
        open,
        classes: {
          paper: "add_table_dialog_root resturant_dashboard_dialog",
          scrollPaper: "resturant_dashboard_dialog",
        },
      }}
      dialogContentProps={{ classes: { root: "add_table_dialog_content" } }}
      dialogHeader={
        <RestaurantBoardDialogHeader
          title="Add to Order"
          actionArea={
            <UnavailableCheckbox
              checked={showUnavailable}
              onChange={toggleShowUnavailable}
            />
          }
          onClose={handleOnClose}
        />
      }
      dialogFooter={
        <div className="footer_container">
          <SelectionStateChip
            quantity={getCartQuantity()}
            total={transactionTotal}
            currency={transaction?.currency ?? "USD"}
            onClick={toggleShowCart}
          />
          {canOpenTab && (
            <GrabbiButton
              disabled={showLoadingHard || showLoadingSoft}
              shapeVariant="rounded"
              colorVariant="black"
              onClick={handleAddItems}
              className="add_items_button"
            >
              Add Items
            </GrabbiButton>
          )}
        </div>
      }
    >
      {showLoadingHard && (
        <div className="loading_container">
          <LoadingIcon />
        </div>
      )}
      {!showLoadingHard && transaction && dataMerchandiseSorted && (
        <React.Fragment>
          {showLoadingSoft && (
            <div className="loading_soft_container">
              <LoadingIcon className="loading_soft_icon" />
            </div>
          )}
          <MerchandiseSelector merchandise={dataMerchandiseSorted} store={store} />
        </React.Fragment>
      )}
    </GrabbiDialog>
  );
};

export default AddItemDialog;

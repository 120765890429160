import { Box, Typography } from "@material-ui/core";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CONTEXT } from "shared/scripts/UrlContext";
import { useQueryParam } from "use-query-params";
import "./ConfirmMerchantComponent.scss";

interface Props {
  merchantConfirmed: boolean;
  merchantConfirmedLoading: boolean;
  merchantConfirmedError?: string;
  dispatchConfirmMerchant: (token: string) => void;
  merchantConfirmResendSuccess: boolean;
  merchantConfirmResendLoading: boolean;
  merchantConfirmResendError?: string;
  dispatchResendConfirmMerchant: (token: string) => void;
  resend?: boolean;
}

const ConfirmMerchantComponent: React.FC<Props> = ({
  merchantConfirmed,
  merchantConfirmedLoading,
  merchantConfirmedError,
  merchantConfirmResendSuccess,
  merchantConfirmResendLoading,
  merchantConfirmResendError,
  dispatchConfirmMerchant,
  dispatchResendConfirmMerchant,
  resend,
}) => {
  const history = useHistory();
  const [token] = useQueryParam<string | undefined>("token");

  if (!token) {
    history.push(`${CONTEXT}signup`);
  }

  useEffect(() => {
    if (token && !resend) {
      dispatchConfirmMerchant(token);
    } else if (token && resend) {
      dispatchResendConfirmMerchant(token);
    }
  }, [token, resend, dispatchResendConfirmMerchant, dispatchConfirmMerchant]);

  let showConfirm = false;
  let showLogin = false;
  let showResend = resend ?? false;
  let headerMessage = "Account Confirmation";
  let message = "Verifying account, please wait...";

  if (
    merchantConfirmedError
      ?.toLocaleLowerCase()
      .includes("no record found for token")
  ) {
    headerMessage = "Invalid link";
    message =
      "please contact customer service if you believe this is a mistake.";
  } else if (merchantConfirmedError?.toLocaleLowerCase().includes("expired")) {
    headerMessage = "Your link has expired";
    message = "We can resend the code to your email to verify your account.";
    showResend = true;
  } else if (merchantConfirmed) {
    headerMessage = "Account Activated";
    message = "Your account has been activated. Please try to log in.";
    showLogin = true;
    showConfirm = false;
  } else if (merchantConfirmedError) {
    message = merchantConfirmedError;
  }

  if (merchantConfirmResendLoading) {
    message = "Resending email, please wait...";
    showResend = false;
  } else if (merchantConfirmResendSuccess) {
    headerMessage = "Success!";
    message =
      "we have resent your activation link. Please check your email to login.";
    showLogin = true;
    showResend = false;
  } else if (
    merchantConfirmResendError
      ?.toLocaleLowerCase()
      .includes("no record found for token")
  ) {
    headerMessage = "Invald link";
    message =
      "please contact customer service if you believe this is a mistake.";
    showResend = false;
  } else if (
    merchantConfirmResendError
      ?.toLocaleLowerCase()
      .includes("old request token is still valid")
  ) {
    headerMessage = "Original link still valid";
    message = "The original link we sent you is still valid, please confirm your account.";
    showResend = false;
    showConfirm = true;
  } else if (merchantConfirmResendError) {
    message = merchantConfirmResendError;
    showResend = false;
  }

  const handleResendEmail = () => {
    if (token) {
      window.location.href = `${CONTEXT}resendToken?token=${token}`;
    }
  };

  const handleConfirm = () => {
    if (token) {
      window.location.href = `${CONTEXT}confirm?token=${token}`;
    }
  };

  const handleLogin = () => {
    history.push(`${CONTEXT}login`);
  };

  return (
    <Box className="confirm_merchant_root">
      <Typography className="header">{headerMessage}</Typography>
      <Typography className="subtext">{message}</Typography>
      {showResend && (
        <GrabbiButton className="button" onClick={handleResendEmail}>
          Resend Email
        </GrabbiButton>
      )}
      {showLogin && (
        <GrabbiButton className="button" onClick={handleLogin}>
          Back to Login
        </GrabbiButton>
      )}
      {showConfirm && (
        <GrabbiButton className="button" onClick={handleConfirm}>
          Confirm Account
        </GrabbiButton>
      )}
      {(merchantConfirmedLoading || merchantConfirmResendLoading) && (
        <LoadingIcon />
      )}
    </Box>
  );
};

export default ConfirmMerchantComponent;

import { Grid, RadioGroup, FormControlLabel, Box } from "@material-ui/core";
import BlackYellowRadioButton from "components/Shared/RadioButton/BlackYellowRadioButton";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { LS_SELECTOR_KEY } from "shared/scripts/Constants";
import "./EditSettingsComponent.scss";

interface Props {}

const EditSettings: React.FC<Props> = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [showSelectorState, setShowSelectorState] = useState<boolean>();

  useEffect(() => {
    if (showSelectorState === undefined) {
      const lsShowSelector = window.localStorage.getItem(LS_SELECTOR_KEY);
      if (lsShowSelector && lsShowSelector === "true") {
        setShowSelectorState(true);
      } else {
        setShowSelectorState(false);
      }
    }
  }, [showSelectorState, setShowSelectorState]);

  const changeSelectorState = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    value: string
  ) => {
    window.localStorage.setItem(LS_SELECTOR_KEY, value);
    setShowSelectorState(value === "true");
    enqueueSnackbar(
      `Store Selector set to ${value === "true" ? "Visible" : "Hidden"}`,
      { variant: "success", autoHideDuration: 1000 }
    );
  };

  return (
    <Grid item container className="show_store_selector_container">
      <Box className="subtitle">SHOW STORE SELECTION</Box>
      <RadioGroup
        className="edit_profile_store_details_radio_group"
        name="filter"
        value={showSelectorState ? "true" : "false"}
        onChange={changeSelectorState}
      >
        <FormControlLabel
          value={"true"}
          control={<BlackYellowRadioButton />}
          label={
            <Box className="edit_profile_store_details_radio_label edit_profile_store_details_header_2">
              Yes
            </Box>
          }
        />
        <FormControlLabel
          value={"false"}
          control={<BlackYellowRadioButton />}
          label={
            <Box className="edit_profile_store_details_radio_label edit_profile_store_details_header_2">
              No
            </Box>
          }
        />
      </RadioGroup>
    </Grid>
  );
};

export default EditSettings;

import { connect } from "react-redux";
import { State } from "store";
import EditProfileComponent from "./EditProfileComponent";

const mapStateToProps = (state: State) => ({
  loginState: state.loginState
});

const mapDispatchToProps = (dispatch: any) => ({});

export const EditProfile = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProfileComponent);

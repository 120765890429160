import { CreateGrabbiMerchandisePayload, GrabbiMerchandise, GrabbiTopping } from "types/GrabbiTypes";
import { get, post } from "./api";

export const getMerchandise = (storeId: number) => {
  const endpoint = `restful-services/merchandise/${storeId}`;

  const thenHandler = (response: any) => {
    return response;
  };

  const errorHandler = (error: any) => {
    return error.response;
  };

  const finallyHandler = () => {
    return {};
  };

  return get(endpoint, {}, {}, thenHandler, errorHandler, finallyHandler);
};

export const updateMerchandise = (merch: GrabbiMerchandise) => {
  const endpoint = `restful-services/merchandise/update`;

  const thenHandler = (response: any) => {
    return response;
  };

  const errorHandler = (error: any) => {
    return error.response;
  };

  const finallyHandler = () => {
    return {};
  };

  return post(endpoint, merch, {}, thenHandler, errorHandler, finallyHandler);
};

export const createMerchandise = (merch: CreateGrabbiMerchandisePayload) => {
  const endpoint = `restful-services/merchandise/create`;

  const thenHandler = (response: any) => {
    return response;
  };

  const errorHandler = (error: any) => {
    return error.response;
  };

  const finallyHandler = () => {
    return {};
  };

  return post(endpoint, merch, {}, thenHandler, errorHandler, finallyHandler);
};

// export interface DeleteMerchandisePayload {
//   storeId: number,
//   merchandiseId: number
// }

// export const deleteMerchandise = (payload: DeleteMerchandisePayload) => {
//   const endpoint = `restful-services/merchandise/${payload.storeId}/delete/${payload.merchandiseId}`;

//   const thenHandler = (response: any) => {
//     return response;
//   };

//   const errorHandler = (error: any) => {
//     return error.response;
//   };

//   const finallyHandler = () => {
//     return {};
//   };

//   return del(endpoint, {}, {}, thenHandler, errorHandler, finallyHandler);
// };

export const getMerchandiseCategories = () => {
  const endpoint = `restful-services/public/merchandiseCategories`;

  const thenHandler = (response: any) => {
    return response;
  };

  const errorHandler = (error: any) => {
    return error.response;
  };

  const finallyHandler = () => {
    return {};
  };

  return get(endpoint, {}, {}, thenHandler, errorHandler, finallyHandler);
}

export const updateTopping = (topping: GrabbiTopping) => {
  const endpoint = `restful-services/merchandise/topping`;

  const thenHandler = (response: any) => {
    return response;
  };

  const errorHandler = (error: any) => {
    return error.response;
  };

  const finallyHandler = () => {
    return {};
  };

  return post(endpoint, topping, {}, thenHandler, errorHandler, finallyHandler);
};
import { Box } from "@material-ui/core";
import {
  getStoreByUpcStartAction,
  getStoreListStartAction,
  setSelectedStoreAction,
} from "actions";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { LAST_VIEWED_KEY, LS_SELECTOR_KEY } from "shared/scripts/Constants";
import { CONTEXT } from "shared/scripts/UrlContext";
import { State } from "store";
import GrabbiDropdown from "../GrabbiDropdown/GrabbiDropdown";
import LoadingIcon from "../LoadingIcon/LoadingIconComponent";
import "./StoreDashboardComponent.scss";

interface Props {
  className?: string;
  dashboardTitle?: string;
}

const StoreDashboard: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  children,
  dashboardTitle,
}) => {
  const dispatch = useDispatch();
  const dispatchGetStoreDetails = useCallback(
    (upc: string) => dispatch(getStoreByUpcStartAction(upc)),
    [dispatch]
  );
  const dispatchGetAllStores = useCallback(
    (id: number) => dispatch(getStoreListStartAction(id)),
    [dispatch]
  );
  const dispatchSetSelectedStore = useCallback(
    (index: number) => {
      dispatch(setSelectedStoreAction(index));
    },
    [dispatch]
  );

  const showSelector =
    window.localStorage.getItem(LS_SELECTOR_KEY) &&
    window.localStorage.getItem(LS_SELECTOR_KEY) === "true";

  const loginState = useSelector((state: State) => state.loginState);

  const storeState = useSelector((state: State) => state.storeList);
  const {
    stores,
    selectedStoreIndex,
    selectedStore,
    loadingStore,
    loadingStores,
    loadingStoreError,
  } = storeState;

  const { upc } = useParams<{ upc: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  useEffect(() => {
    if (upc) {
      dispatchGetStoreDetails(upc);
    }
  }, [upc, dispatchGetStoreDetails]);

  useEffect(() => {
    if (loadingStoreError) {
      enqueueSnackbar(loadingStoreError, { variant: "error" });
    }
  }, [enqueueSnackbar, loadingStoreError]);

  const saveLastViewedStore = () => {
    if (loginState.merchantId) {
      window.localStorage.setItem(
        `${LAST_VIEWED_KEY}-${loginState.merchantId}`,
        `${upc}`
      );
    }
  };

  const loadLastViewedStore = useCallback(() => {
    return window.localStorage.getItem(
      `${LAST_VIEWED_KEY}-${loginState.merchantId}`
    );
  }, [loginState.merchantId]);

  const upcStoreIndex = stores
    ? stores.findIndex((store) => store.upcNumber === upc)
    : 0;

  if (stores && upcStoreIndex !== selectedStoreIndex && upcStoreIndex !== -1) {
    dispatchSetSelectedStore(upcStoreIndex);
  }

  const upcIsValid = stores?.find((store) => store.upcNumber === upc);

  if (upc && upcIsValid) {
    saveLastViewedStore();
  }

  const merchantID = loginState.merchantId;

  useEffect(() => {
    if (!stores && merchantID) {
      dispatchGetAllStores(Number(merchantID));
    } else if (stores && stores.length === 0) {
      history.push(`${CONTEXT}addstore`);
    } else if (stores && stores.length > 0 && upc === undefined) {
      const lastViewed = loadLastViewedStore();
      history.push(
        `${document.location.pathname}/${
          lastViewed ? lastViewed : stores[0].upcNumber
        }`
      );
    }
  }, [
    stores,
    upc,
    history,
    merchantID,
    loadLastViewedStore,
    dispatchGetAllStores,
  ]);

  const handleChangeStore = (index: number) => {
    if (stores && stores[index].upcNumber && stores[index].id) {
      dispatchGetStoreDetails(stores[index]?.upcNumber ?? "");
      const path = document.location.pathname.replace(upc, "");
      history.push(`${path}${stores[index].upcNumber}`);
    }
  };

  return (
    <Box className={`store_dashboard_root ${className ?? ""}`}>
      {stores &&
        selectedStore &&
        selectedStoreIndex !== undefined &&
        !loadingStore &&
        !loadingStores && (
          <React.Fragment>
            {showSelector ? (
              <React.Fragment>
                <Box className="title">
                  {selectedStore &&
                    `${
                      dashboardTitle ? `${dashboardTitle} - ` : ""
                    }${selectedStore?.name?.toUpperCase()}`}
                </Box>
                {stores.length > 1 && (
                  <GrabbiDropdown
                    handleChange={handleChangeStore}
                    options={stores.map((row) => row?.name ?? "")}
                    selectedIndex={selectedStoreIndex}
                    className="dropdown"
                  />
                )}
              </React.Fragment>
            ) : (
              <React.Fragment />
            )}
            {children}
          </React.Fragment>
        )}
      {(loadingStore || loadingStores) && <LoadingIcon />}
    </Box>
  );
};

export default StoreDashboard;

import { Box } from "@material-ui/core";
import React from "react";
import DemoCard, { DemoCardProps } from "../DemoCard/DemoCardComponent";
import "./VisitorsComponent.scss";

interface Props {
  demoCards: DemoCardProps[]
  className?: string;
}

const Visitors: React.FC<Props> = ({demoCards, className}) => {
  return <Box className={`visitors_root ${className ?? ''}`}>
    {demoCards.map(cardProps => {
      return <DemoCard {...cardProps}/>
    })}
  </Box>;
};

export default Visitors;

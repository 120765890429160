import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import GrabbiDropdown from "components/Shared/GrabbiDropdown/GrabbiDropdown";
import GrabbiTextField from "components/Shared/GrabbiTextField/GrabbiTextField";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { MERCHANT_STYLE_OPTIONS, PRICE_REGEX } from "shared/scripts/Constants";
import {
  CreateGrabbiMerchandisePayload,
  CreateGrabbiToppingPayload,
  GrabbiMerchandise,
  GrabbiMerchandiseCategory,
  GrabbiTopping,
} from "types/GrabbiTypes";
import "./MerchandiseFormComponent.scss";

export interface MerchandiseFormErrors {
  update?: string;
  add?: string;
}

interface Props {
  className?: string;
  merchandise?: GrabbiMerchandise;
  menuCategories?: string[];
  topping?: GrabbiTopping;
  isTopping?: boolean;
  dataCategories: GrabbiMerchandiseCategory[];
  addMode?: boolean;
  errors?: MerchandiseFormErrors;
  handlePayload: (
    payload: CreateGrabbiMerchandisePayload | CreateGrabbiToppingPayload
  ) => void;
  handleCancel?: () => void;
  actionLoading?: boolean;
  actionText?: string;
}

const allowedCategories = [
  "food",
  "alcohol",
  "beer",
  "soft drinks",
  "sweets and snacks",
  "unassigned",
  "water",
  "wine",
];

const MerchandiseForm: React.FC<Props> = ({
  className,
  merchandise,
  topping,
  menuCategories,
  isTopping: isToppingOverride,
  dataCategories: unfilteredCategories,
  addMode,
  handlePayload,
  handleCancel,
  actionLoading,
  errors: errorProps,
  actionText,
}) => {
  const { storeId: storeIdParam, upc: storeUpc } =
    useParams<{ upc: string; storeId: string; merchUpc: string }>();

  const storeId = parseInt(storeIdParam ?? storeUpc);

  const {
    register,
    handleSubmit,
    errors,
    setError,
    setValue,
    getValues,
    clearErrors,
  } = useForm({
    reValidateMode: "onChange",
    shouldFocusError: true,
  });

  const [fieldsInitialized, setFieldsInitialized] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [category, setCategory] = useState<number | undefined>(-1);
  const [menuCategory, setMenuCategory] = useState<number | undefined>(-1);
  const [menuStyle, setMenuStyle] = useState<number | undefined>(-1);

  const [addCat, setAddCat] = useState(false);

  useEffect(() => {
    if (menuCategories === undefined || menuCategories.length === 0) {
      setAddCat(true);
    }
  }, [menuCategories, setAddCat]);

  const dataCategories = unfilteredCategories.filter((c) =>
    allowedCategories.includes(c.name.toLowerCase())
  );

  useEffect(() => {
    register("category", {
      required: {
        value: true,
        message: "Please pick a category",
      },
    });
  }, [register]);

  useEffect(() => {
    if (menuCategories && merchandise?.menuCategory) {
      const index = menuCategories.findIndex(
        (cat) => cat.toLowerCase() === merchandise.menuCategory?.toLowerCase()
      );
      setMenuCategory(index);
    }
  }, [menuCategories, merchandise, setMenuCategory]);

  const initializeStoreFields = useCallback(() => {
    if (topping) {
      setValue("label", topping.toppingUpc.label);
      setValue("price", topping.price);
      setValue("upc", topping.toppingUpc.upcNumber);
      setValue("description", topping?.toppingUpc?.description);
      const categoryIndex = dataCategories.findIndex(
        (category) => category.id === topping.toppingUpc.merchandiseCategory.id
      );
      setCategory(categoryIndex === -1 ? 0 : categoryIndex);
      setValue("category", categoryIndex === -1 ? 0 : categoryIndex);
      setValue("grouping", topping.grouping);
    } else if (merchandise) {
      setValue("label", merchandise.upc.label);
      setValue("price", merchandise.price);
      setValue("sku", merchandise.externalSku);
      setValue("upc", merchandise.upc.upcNumber);
      setValue("description", merchandise?.upc?.description);
      setValue("menu_category", merchandise.menuCategory);
      const categoryIndex = dataCategories.findIndex(
        (category) => category.id === merchandise.upc.merchandiseCategory.id
      );
      setCategory(categoryIndex === -1 ? 0 : categoryIndex);
      setValue("category", categoryIndex === -1 ? 0 : categoryIndex);
      setValue("menuStyle", merchandise.menuStyle);
      setMenuStyle(
        merchandise.menuStyle && MERCHANT_STYLE_OPTIONS[merchandise.menuStyle] //? [REVISIT] Remove check once we're done hard coidng stuff
          ? merchandise.menuStyle
          : 0
      );
    }

    clearErrors();
    setFieldsInitialized(true);
  }, [
    merchandise,
    topping,
    dataCategories,
    setFieldsInitialized,
    setValue,
    clearErrors,
  ]);

  //For Testing purposes
  // useEffect(() => {
  //   if (addMode) {
  //     setValue("label", `Create Test ${new Date().getTime()}`);
  //     setValue("description", "Description test 123");
  //     setValue("price", "1.23");
  //     setValue("category", "5");
  //   }
  // });

  useEffect(() => {
    if (!fieldsInitialized) {
      initializeStoreFields();
    }
  }, [fieldsInitialized, initializeStoreFields]);

  useEffect(() => {
    // ? [REVISIT] Delete this if case after backend sends correct status code for error
    const addError = errorProps?.add;
    if (addError) {
      enqueueSnackbar(addError, {
        variant: "error",
      });
      if (addError.includes("Record already exists for upc")) {
        setError("upc", {
          shouldFocus: true,
          message: "UPC already exists, please use another",
          type: "validate",
        });
      }
    }
  }, [errorProps, enqueueSnackbar, setError]);

  const createMerchandisePayload = ():
    | CreateGrabbiMerchandisePayload
    | undefined => {
    if (dataCategories && storeId) {
      const label = getValues("label");
      const price = getValues("price");
      const externalSku = getValues("sku");
      const upcNumber = getValues("upc");
      const description = getValues("description");
      const menuCategoryIndex = menuCategory;
      const menuCategoryValue = addCat
        ? getValues("new_menu_category")
        : menuCategories && menuCategoryIndex !== undefined
        ? menuCategories[menuCategoryIndex]
        : merchandise?.menuCategory;
      const merchandiseCategory = dataCategories[getValues("category")];

      return {
        ...merchandise,
        price,
        externalSku,
        store: {
          id: storeId,
        },
        upc: {
          upcNumber,
          label,
          description,
          merchandiseCategory,
        },
        menuItemType: isToppingOverride ? 1 : 0,
        menuCategory: menuCategoryValue,
        available: merchandise?.available,
        menuStyle,
      };
    } else {
      return;
    }
  };

  const createToppingPayload = (): CreateGrabbiToppingPayload | undefined => {
    if (dataCategories && storeId) {
      const label = getValues("label");
      const price = getValues("price");
      const upcNumber = getValues("upc") ?? undefined;
      const description = getValues("description");
      const merchandiseCategory = dataCategories[getValues("category")];
      const grouping = getValues("grouping")

      return {
        ...topping,
        merchandiseId: merchandise?.id,
        price,
        storeId,
        toppingUpc: {
          upcNumber,
          label,
          description,
          merchandiseCategory,
        },
        menuItemType: 1,
        available: topping?.available ?? false,
        upc: undefined,
        grouping,
      };
    } else {
      return;
    }
  };

  const handleSubmitValid = () => {
    const payload =
      topping !== undefined
        ? createToppingPayload()
        : createMerchandisePayload();
    if (payload) {
      handlePayload(payload);
    }
  };

  const handleSubmitError = () => {
    enqueueSnackbar("Please resolve errors and try again.", {
      variant: "error",
    });
  };

  const isTopping = topping !== undefined || isToppingOverride;

  return (
    <div className={`merchandise_form_root ${className ?? ""}`}>
      <form
        autoComplete="off"
        className="form_area"
        onSubmit={handleSubmit(handleSubmitValid, handleSubmitError)}
      >
        <GrabbiTextField
          ref={register({
            minLength: {
              value: 2,
              message: "Must have 2 or more characters.",
            },
            maxLength: {
              value: 64,
              message: "Must have 64 or less characters.",
            },
            required: {
              value: true,
              message: "Please enter a label",
            },
          })}
          InputProps={{
            className: "field",
          }}
          name="label"
          placeholder="Label"
          label={`${isTopping ? "Add-On" : "Merchandise"} Label *`}
          error={errors.label}
          errorMessage={errors?.label?.message}
        />
        <GrabbiTextField
          ref={register({
            maxLength: {
              value: 255,
              message: "Must have 255 or less characters.",
            },
          })}
          InputProps={{
            className: "field",
          }}
          name="description"
          label="Description"
          multiline={true}
          error={errors.description}
          errorMessage={errors?.description?.message}
        />
        {!addMode && (
          <GrabbiTextField
            ref={register({})}
            InputProps={{
              className: "field",
            }}
            name="upc"
            placeholder="012345 012345"
            label="UPC *"
            disabled={true}
            error={errors.upc}
            errorMessage={errors?.upc?.message}
          />
        )}
        {!isTopping &&
          (!addCat && menuCategories && menuCategories.length > 0 ? (
            <div className="category_selection_container">
              <GrabbiDropdown
                name="menu_category"
                className="field dropdown"
                label="Menu Category"
                selectedIndex={menuCategory}
                handleChange={(index) => {
                  setMenuCategory(index);
                  setValue("menu_category", index);
                }}
                options={menuCategories}
                error={errors.menu_category}
                errorMessage={errors?.menu_category?.message}
              />
              {menuCategories && menuCategories.length > 0 && (
                <GrabbiButton
                  className="add_category_button"
                  onClick={() => setAddCat(true)}
                >
                  +
                </GrabbiButton>
              )}
            </div>
          ) : (
            <GrabbiTextField
              ref={register({
                maxLength: {
                  message: "Menu Category must be less than 32 characters",
                  value: 32,
                },
              })}
              InputProps={{
                className: "field",
              }}
              name="new_menu_category"
              placeholder="e.g. Entrees, Sides, Drinks, etc."
              label="New Menu Category"
              error={errors.new_menu_category}
              errorMessage={errors?.new_menu_category?.message}
            />
          ))}
        <GrabbiTextField
          ref={register({
            required: {
              value: true,
              message: "Please enter a price",
            },
            pattern: {
              value: PRICE_REGEX,
              message: "Please enter a valid dollar amount (e.g 10, 10.99)",
            },
          })}
          InputProps={{
            className: "field",
          }}
          name="price"
          placeholder="$0.00"
          label="Price *"
          error={errors.price}
          errorMessage={errors?.price?.message}
        />
        <GrabbiTextField
          ref={register()}
          InputProps={{
            className: "field",
          }}
          name="sku"
          placeholder="AAABBB1CC"
          label="External SKU *"
          error={errors.sku}
          errorMessage={errors?.sku?.message}
        />
        {category !== undefined && (
          <GrabbiDropdown
            name="category"
            className="field"
            label="Category *"
            selectedIndex={category}
            handleChange={(index) => {
              setCategory(index);
              setValue("category", index);
            }}
            options={dataCategories.map((item) => item.name)}
            error={errors.category}
            errorMessage={errors?.category?.message}
          />
        )}

        <GrabbiDropdown
          name="menuStyle"
          className="field"
          label="Served From"
          selectedIndex={menuStyle}
          handleChange={(index) => {
            setMenuStyle(index);
            setValue("menuStyle", index);
          }}
          options={MERCHANT_STYLE_OPTIONS} // ? [REVISIT] Change to data source when we decide we're not hard-coding this anymore
          error={errors.menuStyle}
          errorMessage={errors?.menuStyle?.message}
        />

        {isTopping && !addMode && (
          <GrabbiTextField
            ref={register({})}
            InputProps={{
              className: "field",
            }}
            name="grouping"
            placeholder="(Advanced Use) A1, A2, B1, B2, AZ1, BZ1, etc..."
            label="Grouping Code"
            error={errors.grouping}
            errorMessage={errors?.grouping?.message}
          />
        )}

        {!actionLoading && (
          <div className="action_area">
            <GrabbiButton type="submit">
              {actionText
                ? actionText
                : addMode
                ? "Create"
                : `Update ${isTopping ? "Add-On" : ""}`}{" "}
            </GrabbiButton>
            {handleCancel && (
              <GrabbiButton onClick={handleCancel}>Cancel</GrabbiButton>
            )}
          </div>
        )}
        {actionLoading && (
          <div className="action_area loading">
            <LoadingIcon />
          </div>
        )}
      </form>
    </div>
  );
};

export default MerchandiseForm;

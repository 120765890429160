import React from "react";
import { Radio, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    border: "thick solid",
    borderColor: "#000000",
    borderWidth: 2,
    width: 22,
    height: 22,
  },
  checked_icon: {
    "&:before": {
      display: "block",
      width: 18,
      height: 18,
      backgroundImage: "radial-gradient(#000,#000 42%,transparent 45%)",
      content: '""',
    },
  },
});

const BlackYellowRadioButton = (props: any) => {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={
        <span className={clsx(classes.icon, classes.checked_icon)} />
      }
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};

export default BlackYellowRadioButton;

import useAxios from "axios-hooks";
import MerchandiseSelector, {
  GrabbiMerchandiseSelection,
  useMerchSelection,
} from "components/ResturantBoard/MerchandiseSelector/MerchandiseSelectorComponent";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import MembershipChipComponent from "components/Shared/MembershipChip/MembershipChipComponent";
import SelectionStateChip from "components/Shared/SelectionStateChip/SelectionStateChipComponent";
import { useLoginContext } from "hooks/CustomerLogin";
import { filter, map, sortBy, sumBy } from "lodash";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  getExternalCustomerIdSelector,
  getMemberEmailSelector,
  getMembershipTypeSelector,
} from "selectors/customer";
import {
  CustomerAddTransactionPayload,
  GrabbiMerchandise,
  GrabbiStore,
  Transaction,
} from "types/GrabbiTypes";
import "./OpenTabComponent.scss";

interface Props {
  className?: string;
  store: GrabbiStore;
  merchandise: GrabbiMerchandise[];
  onCreated: (transaction: Transaction) => void;
  onSuccess: (transaction: Transaction) => void;
  onClose: () => void;
  addToTransaction?: Transaction;
}

const OpenTab: React.FC<Props> = ({
  className,
  store,
  merchandise,
  onCreated,
  onSuccess,
  onClose,
  addToTransaction,
}) => {
  const {
    getCartTotal,
    getCartQuantity,
    toggleShowCart,
    isCartValid,
    reset,
    isPosted,
    merchandiseCartSelection,
  } = useMerchSelection();
  const { enqueueSnackbar } = useSnackbar();
  const {
    customerLogin,
    isAuthenticated,
    error,
    loading: loginLoading,
    accessToken,
    tokenUuid,
  } = useLoginContext();

  const externalCustomerId = useSelector(getExternalCustomerIdSelector);
  const memberEmail = useSelector(getMemberEmailSelector);
  const membershipType = useSelector(getMembershipTypeSelector);

  const isFoodTruck = store.storeType === 0;

  useEffect(() => {
    if (!isAuthenticated && !loginLoading && !error) {
      customerLogin();
    }
  }, [isAuthenticated, loginLoading, error, customerLogin]);

  useMemo(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
  }, [error, enqueueSnackbar]);

  const storeId = store?.id;

  const merchandiseSorted = useMemo(() => {
    return map(
      filter(
        sortBy(merchandise, (m) => m.upc.upcNumber),
        (m) => m.available
      ),
      (m) => ({
        ...m,
        toppings: filter(m.toppings, (t) => t.available),
      })
    );
  }, [merchandise]);

  const transactionTotal = getCartTotal();
  const canOpenTab = merchandiseCartSelection.length > 0 || addToTransaction;

  const [{ loading: loadingAddTransaction }, postAddTransaction] =
    useAxios<Transaction>(
      {
        url: `restful-services/customer/payWithGooglePay`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      { manual: true }
    );

  const handleCreateTransaction = useCallback(
    (singleSelection?: GrabbiMerchandiseSelection) => {
      const merchandiseSelection = singleSelection
        ? [singleSelection]
        : merchandiseCartSelection;

      const data: CustomerAddTransactionPayload | undefined =
        storeId !== undefined && tokenUuid
          ? {
              tokenUuid,
              payload: {
                currency: store?.currency,
                payLater: true,
                storeId: storeId,
                totalAmount: sumBy(merchandiseSelection, "price"),
                totalTax: sumBy(merchandiseSelection, "taxAmount"),
                transactionTotal:
                  sumBy(merchandiseSelection, "price") +
                  sumBy(merchandiseSelection, "taxAmount"),
                externalCustomerId,
                memberEmail,
                orderItems: merchandiseSelection.map((item) => ({
                  itemTax: item.taxAmount,
                  merchandiseId: item.merchandiseId,
                  quantity: item.quantity,
                  toppings: item.toppings,
                  itemTotal: item.price * item.quantity,
                })),
              },
            }
          : undefined;

      if (addToTransaction && data?.payload) {
        data.payload.transactionId = addToTransaction.id;
      }

      if (data) {
        postAddTransaction({ data }).then((data) => {
          if (!isFoodTruck) {
            enqueueSnackbar(addToTransaction ? "Tab Updated" : "Tab Opened", {
              variant: "success",
            });
            onSuccess(data.data);
            reset();
          } else {
            onCreated(data.data);
          }
        });
      }
    },
    [
      addToTransaction,
      enqueueSnackbar,
      externalCustomerId,
      isFoodTruck,
      memberEmail,
      merchandiseCartSelection,
      onCreated,
      onSuccess,
      postAddTransaction,
      reset,
      store,
      storeId,
      tokenUuid,
    ]
  );

  const isValid = isCartValid();

  const submitButtonText = useMemo(
    () =>
      addToTransaction
        ? "Close Tab"
        : isValid
        ? "Open Tab"
        : `Make Required Choices`,
    [isValid, addToTransaction]
  );

  return (
    <div className={`open_tab_root ${className ?? ""}`}>
      {merchandiseSorted && merchandiseSorted.length > 0 && (
        <React.Fragment>
          <div className="header">
            <span className="store_title">{store?.name}</span>
          </div>
          {membershipType && (
            <div className="p-x-4 m-b-4">
              <MembershipChipComponent text={membershipType} />
            </div>
          )}
          <div className="selection_body">
            <MerchandiseSelector
              merchandise={merchandiseSorted}
              store={store}
              transaction={addToTransaction}
              onAddToTab={isPosted ? handleCreateTransaction : undefined}
            />
          </div>
          <div className="footer">
            <SelectionStateChip
              className="selection_state_chip"
              quantity={getCartQuantity()}
              total={transactionTotal}
              currency={store?.currency ?? "USD"}
              onClick={toggleShowCart}
            />
            {canOpenTab && !isFoodTruck && (
              <GrabbiButton
                disabled={loadingAddTransaction}
                className="add_items_button"
                shapeVariant="rounded"
                colorVariant="black"
                onClick={
                  addToTransaction
                    ? onClose
                    : isValid
                    ? () => handleCreateTransaction()
                    : () => {}
                }
              >
                {submitButtonText}
              </GrabbiButton>
            )}
            {canOpenTab && isFoodTruck && (
              <GrabbiButton
                className="add_items_button"
                disabled={loadingAddTransaction}
                shapeVariant="rounded"
                colorVariant="black"
                onClick={() => handleCreateTransaction()}
              >
                Place Order
              </GrabbiButton>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default OpenTab;

import { connect } from "react-redux";
import { State } from "store";
import UsernameButtonComponent from "./UsernameButtonComponent";

const mapDispatchToProps = (dispatch: any) => ({
});

const mapStateToProps = (state: State) => ({
  loginState: state.loginState
});

export const UsernameButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsernameButtonComponent);
import { BaseAction, actionIds } from "actions";

export type LoginState = {
  loggedIn: boolean;
  loginLoading: boolean;
  loginError?: string;
  loginResponse?: any;
  sessionChecked: boolean;
  checkingSession: boolean;
  //tokensExpire?: number;
  accessToken?: string;
  refreshToken?: string;
  isAdmin?: boolean;
  username?: string;
  merchantId?: number;
  tokenDecoded?: {
    exp: number;
    iat: number;
    iss: string;
    scopes: string[];
    sub: string;
  };
  status?: number;
  error?: string;
  noStores: boolean;
  changePasswordStatus?: number;
  changePasswordMessage?: string;
  changePasswordLoading: boolean;
  merchantResetPassword?: boolean;
  merchantResetPasswordReason?: string;
  merchantResetPasswordLoading: boolean;
  authProvider?: string;
};

const DEFAULT_STATE: LoginState = {
  loggedIn: false,
  loginLoading: false,
  sessionChecked: false,
  checkingSession: false,
  noStores: false,
  changePasswordLoading: false,
  authProvider: undefined,
  merchantResetPasswordLoading: false,
};

export const loginReducer = (
  state: LoginState = DEFAULT_STATE,
  action: BaseAction
) => {
  switch (action.type) {
    case actionIds.CHECK_SESSION_START:
      return {
        ...state,
        sessionChecked: false,
        checkingSession: true,
      };
    case actionIds.CHECK_SESSION_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        sessionChecked: true,
        checkingSession: false,
        username: action.payload.email,
        isAdmin: action.payload.admin,
        merchantId: action.payload.id,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        authProvider: action.payload.authProvider,
      };
    case actionIds.CHECK_SESSION_FAILED:
      return {
        ...state,
        loggedIn: false,
        sessionChecked: true,
        checkingSession: false,
      };
    case actionIds.CHECK_SESSION_SKIPPED:
      return {
        ...state,
        loggedIn: false,
        sessionChecked: true,
        checkingSession: false,
      };
    case actionIds.LOGIN_START:
      return {
        ...DEFAULT_STATE,
        loginLoading: true,
      };
    case actionIds.LOGIN_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        sessionChecked: false,
        merchantId: action.payload.merchantid,
        isAdmin: action.payload.admin,
        loginLoading: false,
      };
    case actionIds.LOGIN_FAILED:
      return {
        ...state,
        status: action.payload.status,
        sessionChecked: false,
        loginLoading: false,
        loginError: action.payload.message,
        loginResponse: action.payload.response,
      };
    case actionIds.GOOGLE_LOGIN_START:
      return {
        ...DEFAULT_STATE,
        loginLoading: true,
      };
    case actionIds.GOOGLE_LOGIN_COMPLETE:
      return {
        ...state,
        ...action.payload,
        sessionChecked: false,
        merchantId: action.payload.merchantid,
        isAdmin: action.payload.admin,
        loginLoading: false,
      };
    case actionIds.GOOGLE_LOGIN_FAILED:
      return {
        ...state,
        ...action.payload,
        sessionChecked: false,
        loginLoading: false,
        error: "Error: Google Sign-in failed.",
      };
    case actionIds.APPLE_LOGIN_START:
      return {
        ...DEFAULT_STATE,
        loginLoading: true,
      };
    case actionIds.APPLE_LOGIN_COMPLETE:
      return {
        ...state,
        ...action.payload,
        sessionChecked: false,
        merchantId: action.payload.merchantid,
        isAdmin: action.payload.admin,
        loginLoading: false,
      };
    case actionIds.APPLE_LOGIN_FAILED:
      return {
        ...state,
        ...action.payload,
        sessionChecked: false,
        loginLoading: false,
        error: "Error: Apple Sign-in failed.",
      };
    case actionIds.GET_STORE_LIST_COMPLETED:
      return {
        ...state,
        noStores: action.payload.length === 0,
      };
    case actionIds.LOGOUT_START:
      return DEFAULT_STATE;
    case actionIds.CHANGE_PASSWORD_START:
      return {
        ...state,
        changePasswordStatus: undefined,
        changePasswordLoading: true,
      };
    case actionIds.CHANGE_PASSWORD_COMPLETED:
      return {
        ...state,
        changePasswordMessage: "Password Changed",
        changePasswordStatus: 200,
        changePasswordLoading: false,
      };
    case actionIds.CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        changePasswordMessage: action.payload.error,
        changePasswordStatus: 400,
        changePasswordLoading: false,
      };
    case actionIds.RESET_PASSWORD_START:
      return {
        ...state,
        merchantResetPassword: undefined,
        merchantResetPasswordLoading: true,
      };
    case actionIds.RESET_PASSWORD_COMPLETED:
      return {
        ...state,
        merchantResetPassword: 200,
        merchantResetPasswordReason: action.payload.reason,
        merchantResetPasswordLoading: false,
      };
    case actionIds.RESET_PASSWORD_FAILED:
      return {
        ...state,
        merchantResetPassword: 401,
        merchantResetPasswordReason: action.payload.reason,
        merchantResetPasswordLoading: false,
      };
  }
  return state;
};

import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import Cookies from "universal-cookie";
//import { TOKEN_EXPIRATION } from "shared/scripts/Constants";
import { clearTokens } from "./login";

const cookies = new Cookies();

const request = <T = any>(
  method: "GET" | "POST" | "PUT" | "DELETE",
  endpoint: string,
  data: any,
  axiosConfig: AxiosRequestConfig,
  successHandler?: (response: any) => any,
  errorHandler?: (response: any) => any,
  finallyHandler?: () => any
): Promise<AxiosResponse<T>> => {
  const { headers, ...otherConfig } = axiosConfig;
  const accessToken = cookies.get("accessToken") ?? cookies.get('customerAccessToken');
  const config: AxiosRequestConfig = {
    method: method,
    url: `${process.env?.API_CONTEXT ?? ""}${endpoint}`,
    data: data,
    headers: {
      ...headers,
    },
    ...otherConfig,
  };

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return axios
    .request<T>(config)
    .then((response) => {
      if (response.data && (response.data as any).accessToken) {
        cookies.remove("accessToken", {
          path: "/",
        });
        cookies.remove("refreshToken", {
          path: "/",
        });

        // cookies.remove("tokensExpire", {
        //   path: "/",
        // });
        cookies.set("accessToken", (response.data as any).accessToken, {
          //maxAge: TOKEN_EXPIRATION,
          path: "/",
        });
        cookies.set("refreshToken", (response?.data as any)?.refreshToken ?? "", {
          //maxAge: TOKEN_EXPIRATION,
          path: "/",
        });
        // const now = new Date();
        // const expirationTime = Math.floor(now.getTime() / 1000) + TOKEN_EXPIRATION;
        // cookies.set("tokensExpire", expirationTime, {
        //   maxAge: TOKEN_EXPIRATION,
        //   path: "/"
        // });
      }

      if (response.data && (response.data as any).authProvider) {
        cookies.remove("authProvider", {
          path: "/",
        });
        cookies.set("authProvider", (response?.data as any)?.authProvider ?? "", {
          path: "/",
        });
      }

      if (successHandler) {
        return successHandler(response);
      } else {
        return response;
      }
    })
    .catch((error) => {
      const errorMessage = error.response.data.message;
      if (errorMessage?.toLocaleLowerCase()?.includes("expired")) {
        clearTokens();
      }
      console.error(error);
      if (errorHandler) {
        return errorHandler(error);
      } else {
        return error;
      }
    })
    .finally(() => {
      if (finallyHandler) {
        return finallyHandler;
      } else {
        return;
      }
    });
};

export const get = <T>(
  endpoint: string,
  data: any = {},
  axiosConfig: AxiosRequestConfig = {},
  successHandler?: (response: any) => any,
  errorHandler?: (response: any) => any,
  finallyHandler?: () => any
) =>
  request<T>(
    "GET",
    endpoint,
    data,
    axiosConfig,
    successHandler,
    errorHandler,
    finallyHandler
  );

export const post = (
  endpoint: string,
  data: any,
  axiosConfig: AxiosRequestConfig,
  successHandler?: (response: any) => any,
  errorHandler?: (response: any) => any,
  finallyHandler?: () => any
) =>
  request(
    "POST",
    endpoint,
    data,
    axiosConfig,
    successHandler,
    errorHandler,
    finallyHandler
  );

export const put = (
  endpoint: string,
  data: any,
  axiosConfig: AxiosRequestConfig,
  successHandler?: (response: any) => any,
  errorHandler?: (response: any) => any,
  finallyHandler?: () => any
) =>
  request(
    "PUT",
    endpoint,
    data,
    axiosConfig,
    successHandler,
    errorHandler,
    finallyHandler
  );

export const del = (
  endpoint: string,
  data: any,
  axiosConfig: AxiosRequestConfig,
  successHandler?: (response: any) => any,
  errorHandler?: (response: any) => any,
  finallyHandler?: () => any
) =>
  request(
    "DELETE",
    endpoint,
    data,
    axiosConfig,
    successHandler,
    errorHandler,
    finallyHandler
  );

export const createQueryString = (params: any) => {
  const keys = Object.keys(params);
  return keys
    .map((key, index) => {
      return `${index === 0 ? "?" : "&"}${key}=${params[key]}`;
    })
    .join("");
};

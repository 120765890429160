import { BaseAction, actionIds } from "actions";

export type CreatedLeadForMarketingState = {
  createdLeadForMarketing: boolean;
  createdLeadForMarketingError?: string;
};

export const createdLeadForMarketingReducer = (
  state: CreatedLeadForMarketingState = {
    createdLeadForMarketing: false,
  },
  action: BaseAction
) => {
  switch (action.type) {
    case actionIds.LEAD_FOR_MARKETING_START:
      return {
        ...state,
        createdLeadForMarketing: false,
        createdLeadForMarketingError: undefined
      };
    case actionIds.LEAD_FOR_MARKETING_SUCCESS:
      return {
        ...state,
        createdLeadForMarketing: true,
      };
    case actionIds.LEAD_FOR_MARKETING_FAILED:
      return {
        ...state,
        createdLeadForMarketing: false,
        createdLeadForMarketingError: action.payload.error,
      };
  }
  return state;
};

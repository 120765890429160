import { Box, TableCell, TableRow } from "@material-ui/core";
import React from "react";
import LoadingIcon from "../LoadingIcon/LoadingIconComponent";
import "./UpdatingRowComponent.scss";

interface Props {
  className?: string;
  text: string;
  uniqueKey: string;
}

const UpdatingRow: React.FC<Props> = ({ className, text, uniqueKey }) => {
  return (
    <TableRow
      key={`updating-row-key-${uniqueKey}`}
      className={`updating_row_root ${className ?? ""}`}
    >
      <TableCell
        className="loading_wrapper"
        align="center"
        colSpan={1000}
      >
        <Box className="flex_wrapper">
          {text}
          <LoadingIcon className="loading" />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default UpdatingRow;

import { Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import "./CardSelectorComponent.scss";

interface Props {
  className?: string;
  data: Record<string, CardData[]>;
}

export interface CardData {
  text: string;
}

const CardSelector: React.FC<Props> = ({ className, data }) => {
  const [selected, setSelected] = useState(0);

  const tabs = Object.keys(data);
  const selectedCards = data[tabs[selected]];

  const handleTabSelect = (index: number) => () => {
    setSelected(index);
  };

  return (
    <Box className={`card_selector_root ${className}`}>
      <Box className="tabs">
        {tabs.map((item, index) => {
          return (
            <Box
              className={`tab ${index === selected ? "tab_selected" : ""}`}
              onClick={handleTabSelect(index)}
            >
              {item}
            </Box>
          );
        })}
      </Box>
      <Box className="card_area">
        {selectedCards.map((card) => {
          return (
            <Box className="card">
              <Typography className="card_content">{card.text}</Typography>
              <Typography className="card_seehow">
                <Typography component="span">SEE HOW</Typography>
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default CardSelector;

import { BaseAction, actionIds } from "actions";

export type CreatedLeadFromStarterKitState = {
  createdLeadFromStarterKit: boolean;
  createdLeadFromStarterKitError?: string;
};

export const createdLeadFromStarterKitReducer = (
  state: CreatedLeadFromStarterKitState = {
    createdLeadFromStarterKit: false,
  },
  action: BaseAction
) => {
  switch (action.type) {
    case actionIds.LEAD_FROM_STARTER_KIT_START:
      return {
        ...state,
        createdLeadFromStarterKit: false,
        createdLeadFromStarterKitError: undefined
      };
    case actionIds.LEAD_FROM_STARTER_KIT_SUCCESS:
      return {
        ...state,
        createdLeadFromStarterKit: true,
      };
    case actionIds.LEAD_FROM_STARTER_KIT_FAILED:
      return {
        ...state,
        createdLeadFromStarterKit: false,
        createdLeadFromStarterKitError: action.payload.error,
      };
  }
  return state;
};

import {
  GrabbiPrinter,
  GrabbiPrinterUrlPayload,
  TransactionItem,
  TransactionTopping,
} from "types/GrabbiTypes";

export const printerFinder = (
  printersList: GrabbiPrinter[],
) => {
  for (let printer of printersList) {
    return printer.model.toLowerCase().indexOf("tsp") !== -1 || printer.model.toLowerCase().indexOf("str") !== -1 ? "star" : "epson";
  }
}

export const findPrinterPort = (
  printersList: GrabbiPrinter[],
  printerType: number
) => {
  for (let printer of printersList) {
    if (printer.location === printerType) {
      return printer.ipAddress
    }
  }
};

export const generatePrintReceipt = (params: GrabbiPrinterUrlPayload) => {
  let tableNum = "";
  let adjustedStoreName = "";
  let toppingLabelStr = "";
  let tableNumLength = 0;
  let storeNameLength = 0;
  let dateLength = Math.floor((26 - params.date.length) / 2);
  let orderCodeLength = Math.floor((26 - params.orderCode.length) / 2);
  let receiptText = "";
  const spaceChar = " ";
  const currencyCodeChar = "";//"$";
  const hashCodeChar = "Num: ";//"#";
  const newLineChar = "\n";
  let transactionItems: any[] = [];
  // let printerDetails = "";
  // let printQueryParams = "";

  if (params.tableNumber !== null) {
    const tableNumStr = `Table ${hashCodeChar}${params.tableNumber}`;
    tableNumLength = Math.floor((28 - tableNumStr.length) / 2);
    tableNum = `${spaceChar.repeat(
      tableNumLength
    )}${tableNumStr}${spaceChar.repeat(tableNumLength)}`;
  }

  if (params.storeName !== undefined && params.storeName.length > 26) {
    storeNameLength = Math.abs(28 - Math.abs(28 - params.storeName.length));
    adjustedStoreName = `${spaceChar.repeat(storeNameLength)}${
      params.storeName
    }${spaceChar.repeat(storeNameLength)}`;
  } else if (params.storeName !== undefined) {
    storeNameLength = Math.floor((28 - params.storeName.length) / 2);
    adjustedStoreName = `${spaceChar.repeat(storeNameLength)}${
      params.storeName
    }${spaceChar.repeat(storeNameLength)}`;
  }

  if (params.paymentStatus === "FULFILLED" || params.orderStep === "DELIVERED") {
    const itemToppingsList = (toppings: TransactionTopping[]) =>
      toppings
        .sort((a, b) => a.id.toString().localeCompare(b.id.toString()))
        .map((topping: TransactionTopping) => {
          let toppingLabelLength = 0;

          if (
            topping?.upc?.label !== undefined &&
            topping?.upc?.label.length > 28
          ) {
            toppingLabelLength = Math.abs(
              13 - Math.abs(13 - topping?.upc?.label.length)
            );
            toppingLabelStr = `${topping?.upc?.label}${spaceChar.repeat(
              toppingLabelLength
            )}`;
          } else {
            toppingLabelLength = Math.abs(13 - topping?.upc?.label.length);
            toppingLabelStr = `${topping?.upc?.label}${spaceChar.repeat(
              toppingLabelLength
            )}`;
          }

          return `* ${toppingLabelStr}${spaceChar.repeat(
            topping.price > 10 ? 6 : 4
          )}${currencyCodeChar}${topping.price}${newLineChar}`;
        })
        .join("");

    const itemsList = (items: TransactionItem[]) =>
      items
        .sort((a, b) => a.id.toString().localeCompare(b.id.toString()))
        .map((item: TransactionItem) => {
          let labelLength = 0;
          let labelStr = "";

          if (item?.upc?.label !== undefined && item?.upc?.label.length > 26) {
            labelLength = Math.abs(15 - Math.abs(15 - item?.upc?.label.length));
            labelStr = `${item?.upc?.label}${spaceChar.repeat(labelLength)}`;
          } else {
            labelLength = Math.abs(15 - item?.upc?.label.length);
            labelStr = `${item?.upc?.label}${spaceChar.repeat(labelLength)}`;
          }
          return `${labelStr}${spaceChar.repeat(
            item.price > 10 ? 6 : 4
          )}${currencyCodeChar}${item.price}${newLineChar}${
            item.toppings.length !== 0 ? itemToppingsList(item.toppings) : ""
          }${newLineChar}`;
        })
        .join("");

    const transactionFees = `Service Fee: ${currencyCodeChar}${params.serviceFee}${newLineChar}
    ${
      params.totalTax !== undefined && params.totalTax > 0
        ? `Tax: ${currencyCodeChar}${params.totalTax}${newLineChar}`
        : `${newLineChar}`
    }
    ${
      params.totalTip !== undefined && params.totalTip > 0
        ? `Tip: ${currencyCodeChar}${params.totalTip}${newLineChar}`
        : `${newLineChar}`
    }
    Total: ${currencyCodeChar}${params.transactionTotal}${newLineChar}`;

    receiptText = `${adjustedStoreName}${newLineChar}${itemsList(
      params.transactionItem
    )}${newLineChar}${spaceChar.repeat(dateLength)}${params.date}${spaceChar.repeat(
      dateLength
    )}${newLineChar}${spaceChar.repeat(orderCodeLength)}${
      params.orderCode
    }${spaceChar.repeat(orderCodeLength)}${newLineChar}
    ${transactionFees}${newLineChar}`;
  } else {
    const orderItemToppingsList = (toppings: TransactionTopping[]) =>
      toppings
        .sort((a, b) => a.id.toString().localeCompare(b.id.toString()))
        .map((topping: TransactionTopping) => {
          let toppingLabelLength = 0;
          if (
            topping?.upc?.label !== undefined &&
            topping?.upc?.label.length > 26
          ) {
            toppingLabelLength = Math.abs(
              21 - Math.abs(21 - topping?.upc?.label.length)
            );
            toppingLabelStr = `${topping?.upc?.label}${spaceChar.repeat(
              toppingLabelLength
            )}`;
          } else {
            toppingLabelLength = 21 - topping?.upc?.label.length;
            toppingLabelStr = `${topping?.upc?.label}${toppingLabelLength > 0 ? spaceChar.repeat(
              toppingLabelLength
            ) : spaceChar}`;
          }

          return `* ${toppingLabelStr}${spaceChar.repeat(
            topping.quantity > 10 ? 2 : 1
          )}${newLineChar}`;
        })
        .join("");

    const orderItemsList = (items: TransactionItem[]) =>
      items
        .sort((a, b) => a.id.toString().localeCompare(b.id.toString()))
        .map((item: TransactionItem) => {
          let labelLength = 0;
          let labelStr = "";
          transactionItems.push({"id": item.id});
          if (item?.upc?.label !== undefined && item?.upc?.label.length > 26) {
            labelLength = Math.abs(26 - Math.abs(26 - item?.upc?.label.length));
            labelStr = `${item?.upc?.label}${spaceChar.repeat(labelLength)}`;
          } else {
            labelLength = Math.abs(26 - item?.upc?.label.length);
            labelStr = `${item?.upc?.label}${spaceChar.repeat(labelLength)}`;
          }

          return `${labelStr}${spaceChar.repeat(item.quantity > 10 ? 2 : 1)}${
            item.quantity
          }${newLineChar}${
            item.toppings.length !== 0
              ? orderItemToppingsList(item.toppings)
              : ""
          }${newLineChar}`;
        })
        .join("");

    receiptText = `${adjustedStoreName}${newLineChar}${orderItemsList(
        params.transactionItem
      )}${newLineChar}${spaceChar.repeat(dateLength)}${params.date}${spaceChar.repeat(
          dateLength
        )}${newLineChar}${tableNum}${newLineChar}${spaceChar.repeat(orderCodeLength)}${
        params.orderCode
      }${spaceChar.repeat(orderCodeLength)}${newLineChar}`;
  }

  const printObject = {
    "port": params.storePrinters ? findPrinterPort(params.storePrinters, params.printerType) : null,
    "fontSize": 18,
    "receipt": receiptText,//'HELLOHELLOHELLOHELLOHELLOHELLOHELLOHELLOHELLOHELLOHELLOHELLO',
    "transactionId": params.transactionId,
    "transactionItems": transactionItems ? transactionItems : []
  }
  return printObject;
};

export const generatePrintUrl = (receiptString: any, storePrinters: GrabbiPrinter[], token: string, storeID: number) => {
  //PassPrnt via POS Terminal
  // let base_passprnt_uri = "posterminal://pos.paygex.com/pay?command=print&printerType=star&port=TCP:192.168.7.218&receipt=";

  const EncodedRecipt = encodeURI(JSON.stringify(receiptString).replace(/\\n/g, "\n"));
  const getPrinterDetail = printerFinder(storePrinters);
  const printerDetails = `&printerType=${getPrinterDetail}&storeId=${storeID}`;

  let base_passprnt_uri = "posterminal://pos.paygex.com/pay?command=print";

  return `${base_passprnt_uri}${printerDetails}&callbackURL=dev.paygex.com&payload=${EncodedRecipt}&token=${token}`;
};

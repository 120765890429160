import { BaseAction, actionIds } from "actions";
import {
  GrabbiMerchandise,
  GrabbiMerchandiseCategory,
  GrabbiTopping,
} from "types/GrabbiTypes";

export type MerchandiseState = {
  merchandise?: GrabbiMerchandise[];
  merchandiseLoading: boolean;
  getMerchandiseStatus?: string;
  getMerchandiseError?: string;
  updatingMerchandiseIds: number[];
  updateLoading: boolean;
  updateStatus?: string;
  availableStatus?: string;
  updateError?: string;
  categories?: GrabbiMerchandiseCategory[];
  getCategoriesLoading: boolean;
  getCategoriesError?: string;
  createLoading: boolean;
  createStatus?: string;
  createError?: string;
  deleteLoading: boolean;
  deleteStatus?: string;
  deleteError?: string;
  updatingToppingUpcs: number[];
  updateToppingStatus?: string;
  updateToppingError?: string;
};

export const initialMerchandiseState: MerchandiseState = {
  updatingToppingUpcs: [],
  merchandiseLoading: false,
  updatingMerchandiseIds: [],
  getCategoriesLoading: false,
  createLoading: false,
  deleteLoading: false,
  updateLoading: false,
};

const removeId = (array: number[], id: number) => {
  const index = array.findIndex((arrayId) => arrayId === id);
  if (index !== -1) array.splice(index, 1);
};

const removeUpcTopping = (array: number[], toppingUpcNumber: number) => {
  const index = array.findIndex((upc) => upc === toppingUpcNumber);
  if (index !== -1) array.splice(index, 1);
};

export const merchandiseReducer = (
  state: MerchandiseState = initialMerchandiseState,
  action: BaseAction
): MerchandiseState => {
  switch (action.type) {
    case actionIds.GET_MERCH_START:
      return {
        ...state,
        merchandise: undefined,
        merchandiseLoading: true,
        getMerchandiseStatus: undefined,
        getMerchandiseError: undefined,
      };
    case actionIds.GET_MERCH_COMPLETE:
      const merchandise = action.payload.map(
        (merch: GrabbiMerchandise): GrabbiMerchandise => {
          return {
            ...merch,
            toppings: merch?.toppings?.sort(
              (a: GrabbiTopping, b: GrabbiTopping) =>
                a.toppingUpc.label.localeCompare(b.toppingUpc.label)
            ),
          };
        }
      );
      return {
        ...state,
        merchandise: merchandise.sort(
          (a: GrabbiMerchandise, b: GrabbiMerchandise) => {
            return (
              `${a?.createdDate ?? "0"}`.localeCompare(
                `${b?.createdDate ?? "0"}`
              ) * -1
            );
          }
        ),
        merchandiseLoading: false,
        getMerchandiseStatus: "200",
        getMerchandiseError: undefined,
      };
    case actionIds.GET_MERCH_FAILED:
      return {
        ...state,
        merchandiseLoading: false,
        getMerchandiseStatus: undefined,
        getMerchandiseError: action.payload.error,
      };
    case actionIds.UPDATE_MERCH_START:
      return {
        ...state,
        updateLoading: true,
        updatingMerchandiseIds: state.updatingMerchandiseIds
          ? [...state.updatingMerchandiseIds, action.payload.id]
          : [action.payload.id],
      };
    case actionIds.UPDATE_MERCH_COMPLETE:
      const completedId = action.payload.id;
      const currentIdsSuccess = state.updatingMerchandiseIds;
      removeId(currentIdsSuccess, completedId);
      return {
        ...state,
        updateLoading: false,
        updatingMerchandiseIds: currentIdsSuccess,
        updateStatus: `Item updated successfully`,
        availableStatus: `Item is ${
          action.payload.available ? "now available" : "no longer available"
        }`,
      };
    case actionIds.UPDATE_MERCH_FAILED:
      const failedId = action.payload.id;
      const currentIdsFailed = state.updatingMerchandiseIds;
      removeId(currentIdsFailed, failedId);
      return {
        ...state,
        updateLoading: false,
        updatingMerchandiseIds: currentIdsFailed,
        updateError: `Failed to update merchandise`,
      };
    case actionIds.GET_MERCH_CATEGORIES_START:
      return {
        ...state,
        getCategoriesLoading: true,
      };
    case actionIds.GET_MERCH_CATEGORIES_COMPLETE:
      return {
        ...state,
        getCategoriesLoading: false,
        categories: action.payload.categories,
      };
    case actionIds.GET_MERCH_CATEGORIES_FAILED:
      return {
        ...state,
        getCategoriesLoading: false,
        getMerchandiseError: action.payload.error,
      };
    case actionIds.ADD_MERCH_START:
      return {
        ...state,
        createLoading: true,
      };
    case actionIds.ADD_MERCH_COMPLETE:
      return {
        ...state,
        createLoading: false,
        createStatus: "Merchandise Created",
      };
    case actionIds.ADD_MERCH_FAILED:
      return {
        ...state,
        createLoading: false,
        createError: action.payload.error,
      };
    // case actionIds.DELETE_MERCH_START:
    //   return {
    //     ...state,
    //     deleteLoading: true,
    //   };
    // case actionIds.DELETE_MERCH_COMPLETE:
    //   return {
    //     ...state,
    //     deleteLoading: false,
    //     deleteStatus: "Merchandise Deleted",
    //   };
    // case actionIds.DELETE_MERCH_FAILED:
    //   return {
    //     ...state,
    //     deleteLoading: false,
    //     deleteError: action.payload.error,
    //   };
    case actionIds.UPDATE_TOPPING_START:
      return {
        ...state,
        updatingToppingUpcs: [
          ...state.updatingToppingUpcs,
          action.payload.topping.toppingUpc.upcNumber,
        ],
      };
    case actionIds.UPDATE_TOPPING_COMPLETE:
      const completedToppingUpc = action.payload.upc;
      const currentToppingIdsSuccess = state.updatingToppingUpcs;
      removeUpcTopping(currentToppingIdsSuccess, completedToppingUpc);
      return {
        ...state,
        updatingToppingUpcs: currentToppingIdsSuccess,
        updateStatus: `Item updated successfully`,
        availableStatus: `Item is ${
          action.payload.available ? "now available" : "no longer available"
        }`,
      };
    case actionIds.UPDATE_TOPPING_FAILED:
      const failedMerchId = action.payload.upc;
      const currentToppingIdsFailed = state.updatingToppingUpcs;
      removeUpcTopping(currentToppingIdsFailed, failedMerchId);
      return {
        ...state,
        updatingToppingUpcs: currentToppingIdsFailed,
        updateError: `Failed to update merchandise`,
      };
    case actionIds.RESET_CATEGORIES:
      return {
        ...state,
        categories: undefined,
      };
    case actionIds.CLEAR_STATUS:
      return {
        ...state,
        createStatus: undefined,
        deleteStatus: undefined,
        createError: undefined,
        deleteError: undefined,
        availableStatus: undefined,
        updateError: undefined,
        updateStatus: undefined,
        updateToppingStatus: undefined,
        updateToppingError: undefined,
      };
    case actionIds.LOGOUT_START:
      return initialMerchandiseState;
  }
  return state;
};

import { get, post } from "./api";
import { GrabbiStore } from "types/GrabbiTypes";

export const getAllMerchantStores = (id: number) => {
  const endpoint = `restful-services/store/${id}`;

  const thenHandler = (response: any) => {
    return response.data;
  };

  const errorHandler = (error: any) => {
    console.error(error);
    return {};
  };

  const finallyHandler = () => {
    return {};
  };

  return get(endpoint, {}, {}, thenHandler, errorHandler, finallyHandler);
};

export const addStore = (store: GrabbiStore) => {
  const endpoint = "restful-services/store/create";

  const thenHandler = (response: any) => {
    return response;
  };

  const errorHandler = (error: any) => {
    console.error(error);
    return error.response;
  };

  const finallyHandler = () => {
    return {};
  };

  return post(endpoint, store, {}, thenHandler, errorHandler, finallyHandler);
};

export const getStore = (upc: string) => {
  const endpoint = `restful-services/store/byUpc/${upc}`;

  const thenHandler = (response: any) => {
    return response.data;
  };

  const errorHandler = (error: any) => {
    console.error(error);
    return {
      error,
    };
  };

  const finallyHandler = () => {
    return {};
  };

  return get(endpoint, {}, {}, thenHandler, errorHandler, finallyHandler);
};

export const toggleStoreStatus = (id: number, active: boolean) => {
  const newStatus = active ? "deactivate" : "activate";
  const endpoint = `restful-services/store/${id}/${newStatus}`;

  const thenHandler = (response: any) => {
    return response.data;
  };

  const errorHandler = (error: any) => {
    return {};
  };

  const finallyHandler = () => {
    return {};
  };

  return post(endpoint, {}, {}, thenHandler, errorHandler, finallyHandler);
};

export const updateStore = (store: GrabbiStore) => {
  const endpoint = "restful-services/store/update";

  const thenHandler = (response: any) => {
    return response;
  };

  const errorHandler = (error: any) => {
    console.error(error.response);
    return error.response;
  };

  const finallyHandler = () => {
    return {};
  };

  return post(endpoint, store, {}, thenHandler, errorHandler, finallyHandler);
};

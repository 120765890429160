import React, { ReactNode } from "react";
import "./HeaderComponent.scss";
import {
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  Grid,
  Typography,
} from "@material-ui/core";
import { isMobile } from "shared/scripts/Constants";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { useWindowSize } from "shared/scripts/ResizeListener";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { LoginState } from "store/signin/loginReducer";
import { UsernameButton } from "./UsernameButton/UsernameButtonContainer";
import { CONTEXT } from "shared/scripts/UrlContext";
import { Merchant } from "types/GrabbiTypes";

interface Props {
  loginState: LoginState;
  getMerchantDispatch: () => void;
  getStoreListDispatch: (id: number) => void;
  loadingStores: boolean;
  loadingMerchant: boolean;
  merchant?: Merchant;
  storesChecked: boolean;
}

interface HeaderLink {
  text: string;
  href: string;
  mobileOnly?: boolean;
  disableSelect?: boolean;
  matchRegex?: string;
  render?: () => ReactNode;
}

const HeaderComponent: React.FC<Props> = ({
  loginState,
  loadingStores,
  loadingMerchant,
  storesChecked,
  merchant,
  getStoreListDispatch,
  getMerchantDispatch,
}) => {
  useWindowSize();

  const loggedIn: boolean = loginState.loggedIn;

  const noStores = loginState.noStores;

  const history = useHistory();

  const [open, setOpen] = React.useState<boolean>(false);

  const handleLogin = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    history.push(`${CONTEXT}login`);
    event.stopPropagation();
  };

  const navigationLinksLoggedOut: HeaderLink[] = [
    {
      text: "Home",
      href: `${CONTEXT}`,
      mobileOnly: true,
    },
    // {
    //   text: "Sign UP",
    //   href: `${CONTEXT}signup`,
    // },
    {
      text: "Get Started",
      href: `${CONTEXT}getstarted`,
    },
    {
      text: "Log in",
      href: `${CONTEXT}login`,
      disableSelect: true,
      render: loginState.loggedIn
        ? () => (
            <UsernameButton
              key="nav-button-username"
              className="header_username"
            />
          )
        : () => (
            <Typography
              className={`header_link`}
              onClick={handleLogin}
              key={`nav-link-login`}
            >
              LOG IN
            </Typography>
          ),
    },
  ];

  const navigationLinksLoggedIn: HeaderLink[] = [
    {
      text: "Home",
      href: `${CONTEXT}`,
      mobileOnly: true,
    },
    noStores
      ? {
          text: "Add Store",
          href: `${CONTEXT}addstore`,
        }
      : {
          text: "Dashboard",
          href: `${CONTEXT}dashboard/activity`,
          matchRegex: "/dashboard/activity(.*)",
        },
  ];

  if (!noStores) {
    navigationLinksLoggedIn.push({
      text: "Menu",
      href: `${CONTEXT}menu`,
      matchRegex: "/menu(.*)",
    });
    navigationLinksLoggedIn.push({
      text: "Reports",
      href: `${CONTEXT}report`,
      matchRegex: "/report(.*)",
    });
  }

  navigationLinksLoggedIn.push(
    ...[
      {
        text: "Edit Profile",
        href: `${CONTEXT}merchant/edit-profile`,
        mobileOnly: true,
      },
      {
        text: "Log out",
        href: `${CONTEXT}logout`,
        render: () => (
          <UsernameButton
            key="nav-button-username"
            className="header_username"
          />
        ),
      },
    ]
  );

  const handleHome = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    history.push(`${CONTEXT}`);
  };

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setOpen(true);
  };

  const handleMenuClose = (
    event?: {},
    reason?: "backdropClick" | "escapeKeyDown"
  ) => {
    setOpen(false);
  };

  const location = useLocation();

  const isDashboard = location.pathname.includes("dashboard");

  React.useEffect(() => {
    handleMenuClose();
  }, [location]);

  React.useEffect(() => {
    if (loginState.loggedIn && !merchant && !loadingMerchant) {
      getMerchantDispatch();
    }
    if (merchant && merchant?.id && !storesChecked && !loadingStores) {
      getStoreListDispatch(merchant.id);
    }
  }, [
    merchant,
    loginState.loggedIn,
    loadingStores,
    loadingMerchant,
    storesChecked,
    getMerchantDispatch,
    getStoreListDispatch,
  ]);

  const links: HeaderLink[] = loggedIn
    ? navigationLinksLoggedIn
    : navigationLinksLoggedOut;

  const header = (
    <Grid
      classes={{
        root: `header_root ${isDashboard ? "" : "home_padded home_header"}`,
      }}
      container
      justify="space-between"
    >
      <Grid className="header_icon_container" item xs={1}>
        <Box
          className="header_icon"
          style={{
            backgroundImage: `url('assets/grabbi.svg')`,
          }}
          onClick={handleHome}
        />
      </Grid>
      <Grid classes={{ root: "header_right_section" }} item>
        {links
          .filter((link) => !link.mobileOnly)
          .map((link: HeaderLink) => {
            const selected = link.matchRegex
              ? document.location.pathname.match(new RegExp(link.matchRegex))
              : document.location.pathname === link.href;
            const selectedStyle =
              selected && !link.disableSelect ? "header_link_selected" : "";
            return link.render ? (
              link.render()
            ) : (
              <NavLink
                className={`header_link ${selectedStyle}`}
                to={link.href}
                key={`nav-link-${link.text}`}
              >
                {link.text}
              </NavLink>
            );
          })}
      </Grid>
    </Grid>
  );

  const mobileHeader = (
    <Box className={isDashboard ? "" : `header_root`}>
      {isDashboard ? (
        <Box className="header_dashboard">
          <div className="content_container">
            <Box
              className="header_menu_icon_button_mobile"
              onClick={handleMenuOpen}
            >
              <MenuIcon
                fontSize="large"
                classes={{ root: "header_menu_icon_mobile" }}
              />
            </Box>
          </div>
        </Box>
      ) : (
        <Box className="header_center header_grabbi_mobile">
          <Box className="header_icon" onClick={handleHome} />
          <Box
            className="header_menu_icon_button_mobile"
            onClick={handleMenuOpen}
          >
            <MenuIcon
              fontSize="large"
              classes={{ root: "header_menu_icon_mobile" }}
            />
          </Box>
        </Box>
      )}
      <Drawer
        classes={{ paper: "header_menu_paper" }}
        open={open}
        variant="temporary"
        anchor="top"
        onClose={handleMenuClose}
      >
        <Box className="header_menu_top">
          <IconButton edge="end" onClick={handleMenuClose}>
            <CloseIcon
              fontSize="large"
              classes={{ root: "header_close_icon_mobile" }}
            />
          </IconButton>
        </Box>
        <List>
          {links.map((link: HeaderLink) => {
            const selected = link.matchRegex
              ? document.location.pathname.match(new RegExp(link.matchRegex))
              : document.location.pathname === link.href;
            const selectedStyle = selected
              ? "header_menu_list_item_selected"
              : "";
            return (
              <ListItem
                className={`header_menu_list_item ${selectedStyle}`}
                component={NavLink}
                to={link.href}
                button
                key={`list-item-${link.text}`}
              >
                {link.text}
              </ListItem>
            );
          })}
        </List>
      </Drawer>
    </Box>
  );

  return isMobile() || isDashboard ? mobileHeader : header;
};

export default HeaderComponent;

import { connect } from 'react-redux';
import { State } from 'store';
import GetStartedComponent from './GetStartedComponent';
import { creatingLeadForMarketingStartAction } from 'actions';
import { GrabbiLeadForMarketing } from "types/GrabbiTypes";

const mapStateToProps = (state: State) => ({
  loginState: state.loginState,
  createdLeadForMarketingState: state.createdLeadForMarketingState,
  createdLeadForMarketingError: state.createdLeadForMarketingState.createdLeadForMarketingError
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatchGetStarted: (leadForMarketing: GrabbiLeadForMarketing) =>
   dispatch(creatingLeadForMarketingStartAction(leadForMarketing))
});

export const GetStarted = connect(
  mapStateToProps,
  mapDispatchToProps
)(GetStartedComponent);

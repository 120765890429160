import React, { ReactNode, useState } from "react";
import {
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  ExpansionPanelSummary,
  ExpansionPanel,
  ExpansionPanelDetails,
  TableRowProps,
  TableBodyProps,
  TableSortLabel,
} from "@material-ui/core";
import "components/Shared/GrabbiTable/GrabbiTableComponent.scss";
import { isMobile } from "shared/scripts/Constants";
import { useWindowSize } from "shared/scripts/ResizeListener";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GrabbiPagination from "../GrabbiPagination/GrabbiPaginationComponent";

interface Props {
  data: any[];
  columns: GrabbiTableColumn[];
  expansionRowProps?: TableBodyProps;
  expansionElement?: (data: any, index: number) => ReactNode;
  showExpansionElements?: number[];
  rowProps?: TableRowProps;
  onRowClick?: (data: any, index?: number) => () => void;
  className?: string;
  mobileBreakpoint?: number;
  disableMobile?: boolean;
  defaultOrderBy?: string;
  defaultSortDirection?: "asc" | "desc";
  enablePagination?: boolean;
  defaultPageSize?: number;
  overridePageSize?: number;
  overridePageNumber?: number;
  totalCount?: number;
  onPageChange?: (pageSize: number, pageNumber: number) => void;
  onChangeRowsPerPage?: (pageSize: number, pageNumber: number) => void;
  rowOverride?: (key: string) => ReactNode;
  overrideRows?: number[];
}

const GrabbiTable: React.FC<Props> = ({
  data,
  columns,
  rowProps,
  onRowClick,
  className,
  mobileBreakpoint,
  disableMobile,
  expansionRowProps,
  expansionElement,
  showExpansionElements,
  defaultOrderBy,
  defaultSortDirection,
  enablePagination,
  defaultPageSize,
  overridePageSize,
  overridePageNumber,
  totalCount,
  onPageChange,
  onChangeRowsPerPage,
  rowOverride,
  overrideRows,
}) => {
  const [width] = useWindowSize();
  const [orderBy, setOrderBy] = useState<string | undefined>(defaultOrderBy);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">(
    defaultSortDirection ? defaultSortDirection : "desc"
  );

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(defaultPageSize ?? 10);

  const handlePageChange = (event: any, page: number) => {
    setPage(page);
    if (onPageChange) {
      onPageChange(pageSize, page);
    }
  };

  const handlePageSizeChange = (event: any) => {
    const newPageSize = parseInt(event.target.value);
    const currentItemStart = pageSize * page;
    const newPage = Math.floor(currentItemStart / newPageSize);
    setPageSize(newPageSize);
    setPage(newPage);
    if (onPageChange) {
      onPageChange(newPageSize, newPage);
    }
    if (onChangeRowsPerPage) {
      onChangeRowsPerPage(newPageSize, newPage);
    }
  };

  const useMobile =
    (mobileBreakpoint ? width < mobileBreakpoint : isMobile()) &&
    !disableMobile;

  const showMobileSummary = (column: GrabbiTableColumn): boolean => {
    return useMobile
      ? column.options.mobilePlacement === "summary" &&
          (column.options.placementBreakpoint === undefined ||
            column.options.placementBreakpoint < width)
      : true;
  };

  const showMobileDetails = (column: GrabbiTableColumn): boolean => {
    return useMobile
      ? column.options.mobilePlacement === "details" ||
          (column.options.placementBreakpoint !== undefined &&
            column.options.placementBreakpoint >= width)
      : false;
  };

  const expansionRow = (
    data: any,
    summaryColumns: GrabbiTableColumn[],
    detailsColumns: GrabbiTableColumn[],
    rowIndex: number,
    endElement?: ReactNode
  ) => {
    const noDetails = detailsColumns.length === 0;

    return (
      <TableRow
        key={`key-mobile-row-${rowIndex}`}
        classes={{ root: `TableBodyRow` }}
      >
        <TableCell
          colSpan={summaryColumns.length}
          variant="body"
          classes={{ body: `grabbi_table_body_cell_mobile` }}
          padding="none"
        >
          <ExpansionPanel
            classes={{ root: "grabbi_table_row_expansion_panel" }}
            defaultExpanded={!noDetails}
          >
            <ExpansionPanelSummary
              disabled={noDetails}
              expandIcon={!noDetails ? <ExpandMoreIcon /> : undefined}
              classes={{
                root: `grabbi_table_row_expansion_panel_summary ${
                  noDetails ? "disabled_panel" : ""
                }`,
                expandIcon: "grabbi_table_row_expansion_panel_icon",
              }}
            >
              <Table>
                <TableBody>
                  <TableRow>
                    {summaryColumns.map(
                      (column: GrabbiTableColumn, columnIndex: number) => {
                        const cellData = traversePath(data, column.dataPath);
                        const { className, ...otherPorps } =
                          column.options.mobile.bodyProps;
                        return (
                          <TableCell
                            key={`key-${column.title}-${columnIndex}`}
                            classes={{
                              root: `grabbi_table_mobile_summary_table_body_cell grabbi_table_cell_text_primary ${className}`,
                            }}
                            {...otherPorps}
                          >
                            {column.options.render
                              ? column.options.render(cellData, rowIndex)
                              : cellData}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </ExpansionPanelSummary>
            {!noDetails && (
              <ExpansionPanelDetails className="grabbi_table_row_expansion_details">
                <Table
                  classes={{ root: "grabbi-table-expansion-table_mobile" }}
                >
                  <TableBody
                    style={onRowClick ? { cursor: "pointer" } : {}}
                    onClick={onRowClick ? onRowClick(data, rowIndex) : () => {}}
                    {...expansionRowProps}
                  >
                    {detailsColumns
                      .filter((column) => !column.options.hidden)
                      .map(
                        (
                          column: GrabbiTableColumn,
                          columnIndex: number,
                          array: GrabbiTableColumn[]
                        ) => {
                          const cellData = traversePath(data, column.dataPath);
                          const borderStyle =
                            columnIndex < array.length - 1
                              ? "grabbi_table_mobile_details_table_body_cell_border"
                              : "grabbi_table_mobile_details_table_body_cell_no_border";
                          const {
                            className: classNameHeader,
                            ...otherPropsHeader
                          } = column.options.mobile.headerProps;
                          const {
                            className: classNameBody,
                            ...otherPropsBody
                          } = column.options.mobile.bodyProps;
                          const colSpan = column.options.hideDetailsHeader
                            ? 2
                            : 1;
                          return (
                            <TableRow key={`key-${column.title}-body-row`}>
                              {!column.options.hideDetailsHeader && (
                                <TableCell
                                  key={`key-${column.title}-body-cell-mobile-1`}
                                  classes={{
                                    root: `${borderStyle} grabbi_table_mobile_details_cell_header grabbi_table_cell_text_primary ${classNameHeader}`,
                                  }}
                                  {...otherPropsHeader}
                                >
                                  {column.title}
                                </TableCell>
                              )}
                              <TableCell
                                key={`key-${column.title}-body-cell-mobile-2`}
                                classes={{
                                  root: `${borderStyle} grabbi_table_mobile_details_cell_body grabbi_table_cell_text_primary ${classNameBody}`,
                                }}
                                colSpan={colSpan}
                                {...otherPropsBody}
                              >
                                {column.options.render
                                  ? column.options.render(cellData, rowIndex)
                                  : cellData}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                  </TableBody>
                </Table>
                {endElement}
              </ExpansionPanelDetails>
            )}
          </ExpansionPanel>
        </TableCell>
      </TableRow>
    );
  };

  const traversePath = (data: any, path: string[]): any => {
    if (path.length === 0) {
      return data;
    }
    if (path.length === 1) {
      return data[path[0]];
    }
    return traversePath(data[path[0]], path.slice(1));
  };

  const fullRow = (
    data: any,
    index: number,
    expansionElement?: (row: any, index: number) => ReactNode
  ) => {
    return (
      <React.Fragment key={`key-full-row-${index}`}>
        <TableRow
          classes={{ root: `grabbi_table_row` }}
          style={onRowClick ? { cursor: "pointer" } : {}}
          onClick={onRowClick ? onRowClick(data, index) : () => {}}
          {...rowProps}
        >
          {columns
            .filter((column) => !column.options.hidden)
            .map((column: GrabbiTableColumn, columnIndex: number) => {
              const cellData = traversePath(data, column.dataPath);
              const { className, ...otherProps } =
                column.options.normal.bodyProps;
              return (
                <TableCell
                  key={`key-${column.title}-${columnIndex}-body-cell-full`}
                  variant="body"
                  classes={{
                    body: `grabbi_table_body_cell ${className}`,
                  }}
                  {...otherProps}
                >
                  {column.options.render
                    ? column.options.render(cellData, index)
                    : cellData}
                </TableCell>
              );
            })}
        </TableRow>
        {expansionElement && showExpansionElements?.includes(index) ? (
          <TableRow>
            <TableCell
              colSpan={columns.length}
              className="grabbi_table_cell_no_border"
            >
              {expansionElement(data, index)}
            </TableCell>
          </TableRow>
        ) : undefined}
      </React.Fragment>
    );
  };

  columns.forEach((column: GrabbiTableColumn, index: number) => {
    if (column.options.normal.headerProps === undefined) {
      columns[index].options.normal.headerProps = {};
    }
    if (column.options.normal.headerProps === undefined) {
      columns[index].options.normal.bodyProps = {};
    }
    if (column.options.mobile.headerProps === undefined) {
      columns[index].options.mobile.headerProps = {};
    }
    if (column.options.mobile.headerProps === undefined) {
      columns[index].options.mobile.bodyProps = {};
    }
    if (
      column.options !== undefined &&
      column.options.sort !== undefined &&
      column.options.sort?.accessor !== undefined &&
      column.options.sort?.sortFunction === undefined
    ) {
      column.options.sort.sortFunction = (a: any, b: any) => {
        return a < b ? -1 : a > b ? 1 : 0;
      };
    }
  });

  let sortedData = data.slice(0);

  columns.forEach((column: GrabbiTableColumn) => {
    if (
      column.options.sort &&
      column.options.sort.accessor === orderBy &&
      column.options.sort?.sortFunction
    ) {
      const reverse = sortDirection === "asc" ? true : false;

      const sortFunction = column.options.sort?.sortFunction;
      sortedData = sortedData.sort((a: any, b: any) => {
        a = traversePath(a, column.options.sort?.path ?? []);
        b = traversePath(b, column.options.sort?.path ?? []);
        return reverse ? sortFunction(a, b) * -1 : sortFunction(a, b);
      });
    }
  });

  const startIndex =
    (overridePageNumber ?? page) * (overridePageSize ?? pageSize);
  const endPageIndex =
    ((overridePageNumber ?? page) + 1) * (overridePageSize ?? pageSize);
  const endIndex =
    endPageIndex < sortedData.length ? endPageIndex : sortedData.length;

  const paginatedData =
    (overridePageNumber === undefined || overridePageSize === undefined) &&
    enablePagination
      ? sortedData.slice(startIndex, endIndex)
      : sortedData;

  return (
    <Table classes={{ root: `grabbi_table_table ${className}` }}>
      <TableHead>
        <TableRow classes={{ root: "grabbi_table_header_row" }}>
          {columns
            .filter(showMobileSummary)
            .filter((column) => !column.options.hidden)
            .map((column: GrabbiTableColumn, index: number) => {
              const { className, ...otherProps } =
                column.options[useMobile ? "mobile" : "normal"].headerProps;
              const headerClasses = useMobile
                ? `grabbi_table_header grabbi_table_header_mobile ${className}`
                : `grabbi_table_header ${className}`;
              const sortActive = column.options.sort
                ? column.options.sort.accessor === orderBy
                : false;
              return (
                <TableCell
                  key={`key-${column.title}-${index}-header-cell`}
                  variant="head"
                  classes={{ root: headerClasses }}
                  {...otherProps}
                >
                  {column.options.sort ? (
                    column.options.sort.locked ? (
                      <TableSortLabel
                        classes={{
                          root: "grabbi_table_sort_label",
                          active: "grabbi_table_sort_label",
                          icon: "grabbi_table_sort_label",
                        }}
                        style={{ cursor: "unset" }}
                        active={sortActive}
                        direction={sortDirection}
                      >
                        {column.title}
                      </TableSortLabel>
                    ) : (
                      <TableSortLabel
                        classes={{
                          root: "grabbi_table_sort_label",
                          active: "grabbi_table_sort_label",
                          icon: "grabbi_table_sort_label",
                        }}
                        active={sortActive}
                        direction={sortDirection}
                        onClick={() => {
                          if (sortActive && sortDirection === "asc") {
                            setSortDirection("desc");
                          } else if (sortActive && sortDirection === "desc") {
                            setSortDirection("asc");
                            setOrderBy(
                              defaultOrderBy ? defaultOrderBy : undefined
                            );
                          } else {
                            setOrderBy(column.options.sort?.accessor);
                          }
                        }}
                      >
                        {column.title}
                      </TableSortLabel>
                    )
                  ) : (
                    column.title
                  )}
                </TableCell>
              );
            })}
        </TableRow>
      </TableHead>
      <TableBody>
        {paginatedData.map((row: any, index: number) => {
          return rowOverride && overrideRows && overrideRows?.includes(index)
            ? rowOverride(`row-override-key-${index}`)
            : useMobile
            ? expansionRow(
                row,
                columns.filter(showMobileSummary),
                columns.filter(showMobileDetails),
                index,
                expansionElement ? expansionElement(row, index) : undefined
              )
            : fullRow(row, index, expansionElement);
        })}
        <tr>
          {enablePagination && totalCount !== undefined && (
            <GrabbiPagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              count={totalCount}
              page={overridePageNumber ?? page}
              rowsPerPage={overridePageSize ?? pageSize}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePageSizeChange}
            />
          )}
        </tr>
      </TableBody>
    </Table>
  );
};

interface GrabbiTableColumnOptions {
  headerProps?: any;
  bodyProps?: any;
}

interface SortOption {
  accessor: string;
  path: string[];
  locked?: boolean;
  sortFunction?: (a: any, b: any) => number;
}

export class GrabbiTableColumn {
  constructor(
    title: string | ReactNode,
    dataPath: string[],
    options: {
      hidden?: boolean;
      sort?: SortOption;
      normal?: GrabbiTableColumnOptions;
      mobile?: GrabbiTableColumnOptions;
      hideDetailsHeader?: boolean;
      mobilePlacement?: "summary" | "details";
      placementBreakpoint?: number;
      render?: (data: any, index: number) => ReactNode;
    } = {}
  ) {
    this.title = title;
    this.dataPath = dataPath;
    this.options = { ...this.options, ...options };
  }

  title!: string | ReactNode;
  dataPath!: string[];
  options: {
    hidden?: boolean;
    sort?: SortOption;
    normal: GrabbiTableColumnOptions;
    mobile: GrabbiTableColumnOptions;
    hideDetailsHeader?: boolean;
    mobilePlacement?: "summary" | "details";
    placementBreakpoint?: number;
    render?: (data: any, index: number) => ReactNode;
  } = {
    normal: {
      bodyProps: {
        align: "left",
      },
      headerProps: {
        align: "left",
      },
    },
    mobile: {
      bodyProps: {
        align: "left",
      },
      headerProps: {
        align: "left",
      },
    },
    mobilePlacement: "details",
  };
}

export default GrabbiTable;

import { AxiosRequestConfig } from "axios";
import useAxios, { RefetchOptions } from "axios-hooks";

export const CreateAxiosHookDefault = <
  T1 = any,
  T2 = any,
  T3 = any
> (): FakeUseAxiosResultWithParams<T1, T2, T3> => {
  const fakeResult = useAxios<T1, T2>({});

  const fakeFunction : FunctionWithParams<T1, T3> = async (params: T3, config?: AxiosRequestConfig,
    options?: RefetchOptions) => {
    return (await fakeResult[1](config, options)).data;
  }

  return [fakeResult[0], fakeFunction];
};

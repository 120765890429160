import React, { useEffect, useState } from "react";
import "./LoginComponent.scss";
import { Box, Typography } from "@material-ui/core";
import GrabbiTextField from "components/Shared/GrabbiTextField/GrabbiTextField";
import { NavLink, useHistory } from "react-router-dom";
import { LoginState } from "store/signin/loginReducer";
import { CONTEXT } from "shared/scripts/UrlContext";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";
import { useForm } from "react-hook-form";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import GoogleLogin from "react-google-login";
import { APPLE_CLIENT_ID, GOOGLE_CLIENT_ID } from "shared/scripts/Constants";
import { useSnackbar } from "notistack";
import { AppleLoginPayload } from "types/GrabbiTypes";
import AppleSignin from "react-apple-signin-auth";
import { useAxiosContext } from "components/Shared/AxiosProvider/AxiosProvider";

interface Props {
  loginState: LoginState;
  dispatchLogin: (
    username: string,
    passwordHash: string,
    admin: boolean
  ) => void;
  dispatchGoogleLogin: (id_token: string) => void;
  dispatchAppleLogin: (payload: AppleLoginPayload) => void;
  admin?: boolean;
}

const LoginComponent: React.FC<Props> = ({
  loginState,
  dispatchLogin,
  dispatchGoogleLogin,
  dispatchAppleLogin,
  admin,
}) => {
  const { setToken } = useAxiosContext();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { register, handleSubmit, errors, getValues, control } = useForm({
    reValidateMode: "onChange",
    shouldFocusError: true,
  });
  const [loginError, setLoginError] = useState<string | undefined>(undefined);

  useEffect(() => {
    let loginError = "";
    if (loginState.loginError) {
      if (loginState.status === 401 || loginState.status === 403) {
        const serverResponse = loginState.loginError;
        if (serverResponse === "User has no roles assigned!") {
          loginError =
            "Account not activated. We have sent another activation email to your inbox.";
        } else {
          loginError = serverResponse;
        }
      } else if (loginState.status === 404) {
        loginError = "Could not connect to login server.".toUpperCase();
      } else if (loginState.status !== 200) {
        loginError = "An unknown error occured.";
      }
      setLoginError(loginError);
    } else if (
      loginState?.loginResponse &&
      loginState?.loginResponse?.status === 500
    ) {
      setLoginError(
        "Account not activated. We have sent another activation email to your inbox."
      );
    }
  }, [loginState]);

  useEffect(() => {
    if (loginState.error !== undefined) {
      let errorMessage = loginState.error;
      if (errorMessage === "Request failed with status code 401") {
        errorMessage = "Wrong username or password";
      }

      enqueueSnackbar(errorMessage, { variant: "error" });
    }
  }, [loginState.error, enqueueSnackbar]);

  useEffect(() => {
    if (loginState.loggedIn) {
      setToken(`${loginState.accessToken}`);
      if (loginState.isAdmin) {
        history.push(`${CONTEXT}admin/store-manager`);
      } else {
        history.push(`${CONTEXT}dashboard/activity`);
      }
    }
  }, [history, loginState, setToken]);

  const handleLogin = (): void => {
    dispatchLogin(
      getValues("username"),
      getValues("password"),
      admin ? admin : false
    );
  };

  const handleSubmitValid = () => {
    handleLogin();
  };

  const handleGoogleResponse = (response: any) => {
    if (response.tokenObj && response.tokenObj.id_token) {
      dispatchGoogleLogin(response.tokenObj.id_token);
    }
  };

  const handleAppleResponse = (response: any) => {
    dispatchAppleLogin({
      ...response.authorization,
      ...response.user,
    });
  };

  const handleAppleError = (error: any) => {
    if (
      (error.error as string).toLocaleLowerCase() !== "popup_closed_by_user"
    ) {
      enqueueSnackbar("Error: Apple sign in failed.", { variant: "error" });
    }
  };

  const handleSignUp = () => {
    history.push(`${CONTEXT}getstarted`);
  };

  const appleRedirect = `${window.location.protocol}//${
    window.location.hostname
  }${
    window.location.port === "3000" ? window.location.port : ""
  }/auth/apple/merchant/redirect`;

  return (
    <div className="login_wrapper">
      <Box className="login_root">
        <Typography classes={{ root: "login_title" }}>
          {" "}
          {admin ? "ADMIN" : ""} LOGIN
        </Typography>
        <Typography className="bold to_continue">
          To continue, log in to Grabbi.
        </Typography>
        <Box className="social_sign_in">
          <AppleSignin
            authOptions={{
              clientId: APPLE_CLIENT_ID,
              scope: "email name",
              redirectURI: appleRedirect,
              state: (Math.random() * 1000000).toString(),
              nonce: (Math.random() * 1000000).toString(),
              responseType: "form_post",
              usePopup: true,
            }}
            onSuccess={handleAppleResponse}
            onError={handleAppleError}
            render={(props: any) => (
              <Box
                className="apple_sign_in social_button"
                onClick={props.onClick}
              >
                <div
                  className="apple_icon"
                  style={{
                    backgroundImage: `url(assets/apple-icon.svg)`,
                  }}
                />
                <Typography className="text">Continue with Apple</Typography>
              </Box>
            )}
          />
          <GoogleLogin
            className="google_sign_in social_button"
            clientId={GOOGLE_CLIENT_ID}
            onSuccess={handleGoogleResponse}
            onFailure={handleGoogleResponse}
            cookiePolicy={"single_host_origin"}
            buttonText={"Continue with Google"}
          />
        </Box>
        <Box className="seperator">
          <div />
          <Typography className="bold">OR</Typography>
          <div />
        </Box>
        <form className="login_form" onSubmit={handleSubmit(handleSubmitValid)}>
          <Box>
            <GrabbiTextField
              ref={register({
                required: {
                  value: true,
                  message: "Please enter username",
                },
              })}
              placeholder="Username"
              name="username"
              autoComplete="username"
              label="Username"
              control={control}
              error={errors.username}
              errorMessage={errors?.username?.message}
            ></GrabbiTextField>
          </Box>

          <Box>
            <GrabbiTextField
              ref={register({
                required: {
                  value: true,
                  message: "Please enter password",
                },
              })}
              placeholder="Password"
              name="password"
              type="password"
              autoComplete="current-password"
              error={errors.password}
              errorMessage={errors?.password?.message}
              control={control}
              label="Password"
            ></GrabbiTextField>
          </Box>

          {loginError && (
            <React.Fragment>
              <Typography className="login_form_helper_text_root">
                {loginError}
              </Typography>
            </React.Fragment>
          )}

          <NavLink
            className="login_forgot_password_link"
            to={`${CONTEXT}forgot`}
          >
            {"Forgot Your Password".toUpperCase()}
          </NavLink>
          <Typography classes={{ root: "login_subtext" }}>
            By continuing, I confirm that I am 18+ and agree to Grabbi's{" "}
            <NavLink
              to={`${CONTEXT}terms-of-service`}
              className="login_subtext_link"
            >
              Terms of Service
            </NavLink>{" "}
            and{" "}
            <NavLink
              to={`${CONTEXT}privacy-policy`}
              className="login_subtext_link"
            >
              Privacy Policy
            </NavLink>
            .
          </Typography>
          {!loginState.loginLoading ? (
            <GrabbiButton type="submit" className="login_submit_button">
              LOGIN
            </GrabbiButton>
          ) : (
            <LoadingIcon />
          )}
        </form>
        <Typography className="bold no_account">
          Don't have an account?
        </Typography>
        <GrabbiButton
          onClick={handleSignUp}
          variant="outlined"
          className="signup_button"
        >
          Sign up for Grabbi
        </GrabbiButton>
      </Box>
    </div>
  );
};

export default LoginComponent;

import { LoginState } from "store/signin/loginReducer";
import { jwtTokens } from "./login";
import {
  Merchant,
  CountryCode,
  StateCode,
  GrabbiStore,
  GrabbiMerchant,
  GrabbiLeadForMarketing,
  AppleLoginPayload,
  GrabbiMerchandiseCategory,
  GrabbiMerchandise,
  CreateGrabbiMerchandisePayload,
  Transaction,
  GrabbiTopping,
  GetStoreTransactionsPayload,
} from "types/GrabbiTypes";
import {
  TransactionRefundPayload,
} from "./transactions";
// import { DeleteMerchandisePayload } from "./merchandise";
export const actionIds = {
  ADD_STORE_START: "[0] Start requesting add store",
  ADD_STORE_SUCCESS: "[1] Successfully add new store",
  ADD_STORE_FAILED: "[2] add store failed",
  GET_STORE_LIST_START: "[0] Start getting store list",
  GET_STORE_LIST_COMPLETED: "[1] Completed getting store list",
  GET_MERCHANT_START: "[0] Start get merchant",
  GET_MERCHANT_COMPLETE: "[1] Completed get merchant",
  AUTHENTICATION_FAILED: "[401] Authentication Failed - session expired.",
  GET_STORE_BY_UPC_START: "[0] Start getting store by upc",
  GET_STORE_BY_UPC_COMPLETED: "[1] Completed getting store by upc",
  GET_STORE_BY_UPC_FAILED: "[2] Failed getting store by upc",
  GET_STORE_TRANSACTIONS_START: "[0] Start getting store transactions",
  GET_STORE_TRANSACTIONS_COMPLETED: "[1] Completed getting store transactions",
  GET_STORE_TRANSACTIONS_FAILED: "[2] Failed getting store transactions",
  TOGGLE_STORE_STATUS_START: "[0] Start setting store status",
  TOGGLE_STORE_STATUS_COMPLETED: "[1] Completed setting store status",
  TOGGLE_STORE_STATUS_FAILED: "[2] Failed setting store status",
  TOGGLE_STORE_PENDING_START: "[0] Toggle store pending",
  TOGGLE_STORE_PENDING_COMPLETED: "[1] Completed toggling store pending",
  CHECK_SESSION_START: "[0] Start - checking if user's session is valid",
  CHECK_SESSION_SKIPPED: "[3] Skipped - no access token to check",
  CHECK_SESSION_SUCCESS: "[1] Complete - Success - User's session is valid",
  CHECK_SESSION_FAILED: "[2] Complete - Failed - User's session is invalid",
  CHANGE_PASSWORD_START: "[0] Start - Changing user's password",
  CHANGE_PASSWORD_COMPLETED:
    "[1] Complete - Success - User's password has been changed",
  CHANGE_PASSWORD_FAILED:
    "[2] Complete - Failed - User's password has not been changed",
  RESET_PASSWORD_START: "[0] Start - Resetting user's password",
  RESET_PASSWORD_COMPLETED:
    "[1] Complete - Success - User's password has been reset",
  RESET_PASSWORD_FAILED:
    "[2] Complete - Failed - User's password has not been reset",
  RESET_PASSWORD_SUCCESS: "[1] Reset password completed successfully ",
  GET_COUNTRIES_START: "[0] Start - Getting list of countries",
  GET_COUNTRIES_COMPLETE: "[1] Complete - Country list retrieved",
  GET_COUNTRIES_FAILED: "[2] Failed - Could not fetch country list",
  GET_STATES_START: "[0] Start - Getting list of states",
  GET_STATES_COMPLETE: "[1] Complete - State list retrieved",
  GET_STATES_FAILED: "[2] Failed - Could not fetch state list",
  GET_CITIES_START: "[0] Start - Getting list of cities",
  GET_CITIES_COMPLETE: "[1] Complete - City list retrieved",
  GET_CITIES_FAILED: "[2] Failed - Could not fetch cities list",
  UPDATE_PERSONAL_DETAILS_START: "[0] Start - Updating personal details",
  UPDATE_PERSONAL_DETAILS_COMPLETED: "[1] Complete - Personal details updated",
  UPDATE_PERSONAL_DETAILS_FAILED: "[2] Failed - Personal details not updated",
  UPDATE_STORE_DETAILS_START: "[0] Start - Updating store details",
  UPDATE_STORE_DETAILS_COMPLETED: "[1] Complete - Store details updated",
  UPDATE_STORE_DETAILS_FAILED: "[2] Failed - Store details not updated",
  SIGNUP_START: "[0] Start requesting Signup",
  SIGNUP_SUCCESS: "[1] Successfully Signup",
  SIGNUP_FAILED: "[2] Signup failed",
  LOGIN_START: "[0] Start requesting login",
  LOGIN_SUCCESS: "[1] Successfully logged in",
  LOGIN_FAILED: "[2] Login failed",
  LOGIN_RESET: "[3] Reset login errors",
  GOOGLE_LOGIN_START: "[0] Start Google Login",
  GOOGLE_LOGIN_FAILED: "[1] Google Login Failed",
  GOOGLE_LOGIN_COMPLETE: "[2] Google Login Complete",
  APPLE_LOGIN_START: "[0] Start Apple Login",
  APPLE_LOGIN_FAILED: "[1] Apple Login Failed",
  APPLE_LOGIN_COMPLETE: "[2] Apple Login Complete",
  LOGOUT_START: "[0] Start logout",
  LOGOUT_SUCCESS: "[1] Succesfully logged out",
  LOGOUT_FAILED: "[2] Failed to log out",
  ADMIN_GET_ALL_MERCHANTS_START: "[0] Admin - Get all merchants",
  ADMIN_GET_ALL_MERCHANTS_COMPLETED:
    "[1] Admin - Get all merchants completed - success",
  ADMIN_GET_ALL_MERCHANTS_REFRESH_COMPLETED:
    "[2] Admin - Get all merchants complete - list refreshed",
  ADMIN_GET_ALL_MERCHANTS_FAILED:
    "[3] Admin - Get all merchants incomplete - failed",
  ADMIN_GET_ALL_STORES_START: "[0] Admin - Get all stores",
  ADMIN_GET_ALL_STORES_COMPLETED: "[1] Admin - Get all stores",
  ADMIN_GET_ALL_STORES_REFRESH_COMPLETED:
    "[2] Admin - Get all stores complete - list refreshed",
  ADMIN_GET_ALL_STORES_FAILED: "[3] Admin - Get all stores incomplete - failed",
  LEAD_FOR_MARKETING_START: "[0] Start requesting for creating a lead",
  LEAD_FOR_MARKETING_SUCCESS: "[1] Lead for marketing has been created",
  LEAD_FOR_MARKETING_FAILED: "[2] Lead for marketing has not been created",
  CONFIRM_MERCHANT_START: "[0] Confirm merchant start",
  CONFIRM_MERCHANT_SUCCESS: "[1] Confirm merchant success",
  CONFIRM_MERCHANT_FAILED: "[2] Confirm merchant failed",
  RESEND_CONFIRM_MERCHANT_START: "[0] Resend Confirm merchant start",
  RESEND_CONFIRM_MERCHANT_SUCCESS: "[1] Resend Confirm merchant success",
  RESEND_CONFIRM_MERCHANT_FAILED: "[2] Resend Confirm merchant failed",
  UPDATE_TRANSACTION_STATUS_START: "[0] Send update to transaction status",
  UPDATE_TRANSACTION_STATUS_SUCCESS:
    "[1] Successfully updated transaction status",
  UPDATE_TRANSACTION_STATUS_FAILED: "[2] Failed to update transaction status",
  CLEAR_STATUS: "[0] Global action for clearing status messages in redux store",
  GET_MERCH_START: "[0] Get merchandise start",
  GET_MERCH_COMPLETE: "[1] Get merchandise complete",
  GET_MERCH_FAILED: "[2] Get merchandise failed",
  UPDATE_MERCH_START: "[0] Update merch start",
  UPDATE_MERCH_COMPLETE: "[1] Update merch complete",
  UPDATE_MERCH_FAILED: "[2] Update merch failed",
  SET_SELECTED_STORE: "[0] Set Selected Store",
  GET_MERCH_CATEGORIES_START: "[0] Get Merchandise Categories Start",
  GET_MERCH_CATEGORIES_COMPLETE: "[1] Get Merchandise Categories Success",
  GET_MERCH_CATEGORIES_FAILED: "[2] Get Merchandise Categories Failed",
  ADD_MERCH_START: "[0] Add Merchandise Start",
  ADD_MERCH_COMPLETE: "[1] Add Merchandise Success",
  ADD_MERCH_FAILED: "[2] Add Merchandise Failed",
  // DELETE_MERCH_START: "[0] Delete Merchandise Start",
  // DELETE_MERCH_COMPLETE: "[1] Delete Merchandise Success",
  // DELETE_MERCH_FAILED: "[2] Delete Merchandise Failed",
  RESET_CATEGORIES: "[0] Reset categories test",
  REFUND_TRANSACTION_START: "[0] Start Refund Transaction",
  REFUND_TRANSACTION_COMPLETED: "[1] Completed Refund Transaction",
  REFUND_TRANSACTION_FAILED: "[2] Failed Refund Transaction",
  REFRESH_TRANSACTION_START: "[0] Refresh transactions",
  GET_ALL_TRANSACTIONS_START: "[0] Get all store transactions start",
  GET_ALL_TRANSACTIONS_COMPLETE: "[1] Get all store transactions complete",
  GET_ALL_TRANSACTIONS_FAILED: "[0] Get all store transactions failed",
  UPDATE_TOPPING_START: "[0] Update topping start",
  UPDATE_TOPPING_COMPLETE: "[1] Update topping completed",
  UPDATE_TOPPING_FAILED: "[2] Update topping failed",
  LEAD_FROM_STARTER_KIT_START: "[0] Start requesting for creating a lead from starter kit",
  LEAD_FROM_STARTER_KIT_SUCCESS: "[1] Lead from starter kit has been created",
  LEAD_FROM_STARTER_KIT_FAILED: "[2] Lead from starter kit has not been created",
  GET_MEMBERSHIP_START: "[0] Get Membership start",
  GET_MEMBERSHIP_SUCCESS: "[1] Get Membership Success",
  GET_MEMBERSHIP_FAILED: "[2] Get Membership Failed",
  SET_MEMBERSHIP_CHECKED: "[0] Membership Checked",
  GET_MEMBERSHIP_COOKIE: "[0] Get Membership Cookie"
};

export interface BaseAction<T = any> {
  type: string;
  payload?: T;
}

export const authenticationFailedAction = (): BaseAction => {
  return {
    type: actionIds.AUTHENTICATION_FAILED,
    payload: null,
  };
};

export const addStoreStartAction = (store: Omit<GrabbiStore, "id">): BaseAction => {
  return {
    type: actionIds.ADD_STORE_START,
    payload: {
      store,
    },
  };
};

export const addStoreCompleteAction = (store: GrabbiStore): BaseAction => {
  return {
    type: actionIds.ADD_STORE_SUCCESS,
    payload: {
      store,
    },
  };
};

export const addStoreFailedAction = (error: string): BaseAction => {
  return {
    type: actionIds.ADD_STORE_FAILED,
    payload: {
      error,
    },
  };
};

export const getStoreListStartAction = (id: number): BaseAction => {
  return {
    type: actionIds.GET_STORE_LIST_START,
    payload: {
      id: id,
    },
  };
};

export const getStoreListCompletedAction = (
  storeList: GrabbiStore[]
): BaseAction => ({
  type: actionIds.GET_STORE_LIST_COMPLETED,
  payload: storeList,
});

export const setStoreStatusStartAction = (
  id: number,
  index: number
): BaseAction => {
  return {
    type: actionIds.TOGGLE_STORE_STATUS_START,
    payload: {
      id,
      index,
    },
  };
};

export const setStoreStatusCompletedAction = (
  actionStatus: boolean,
  storeList: GrabbiStore[]
): BaseAction => {
  return {
    type: actionIds.TOGGLE_STORE_STATUS_COMPLETED,
    payload: {
      actionStatus,
      storeList,
    },
  };
};

export const setStoreStatusFailedAction = (): BaseAction => {
  return {
    type: actionIds.TOGGLE_STORE_STATUS_FAILED,
    payload: {},
  };
};

export const setStorePendingAction = (
  index: number,
  pending: boolean
): BaseAction => {
  return {
    type: actionIds.TOGGLE_STORE_PENDING_START,
    payload: {
      id: index,
      pending,
    },
  };
};

export const checkSessionStartAction = (): BaseAction => {
  return {
    type: actionIds.CHECK_SESSION_START,
    payload: {},
  };
};

export const checkSessionSkippedAction = (): BaseAction => {
  return {
    type: actionIds.CHECK_SESSION_SKIPPED,
    payload: {},
  };
};

export const checkSessionSuccessAction = (cookies: jwtTokens): BaseAction => {
  return {
    type: actionIds.CHECK_SESSION_SUCCESS,
    payload: cookies,
  };
};

export const checkSessionFailedAction = (error?: string): BaseAction => {
  return {
    type: actionIds.CHECK_SESSION_FAILED,
    payload: {
      error,
    },
  };
};

export const setLoginStatusStartAction = (
  username: string,
  password: string,
  admin: boolean = false
): BaseAction => {
  return {
    type: actionIds.LOGIN_START,
    payload: {
      username,
      password,
      admin,
    },
  };
};

export const resetLoginErrors = (): BaseAction => ({
  type: actionIds.LOGIN_RESET,
});

export const setLoginStatusFailedAction = (payload: any): BaseAction => {
  return {
    type: actionIds.LOGIN_FAILED,
    payload,
  };
};

export const setLoginStatusCompletedAction = (
  state: LoginState
): BaseAction => {
  return {
    type: actionIds.LOGIN_SUCCESS,
    payload: state,
  };
};

export const googleLoginStartAction = (payload: {
  id_token: string;
}): BaseAction => {
  return {
    type: actionIds.GOOGLE_LOGIN_START,
    payload: {
      id_token: payload.id_token,
    },
  };
};

export const googleLoginCompleteAction = (): BaseAction => {
  return {
    type: actionIds.GOOGLE_LOGIN_COMPLETE,
    payload: {},
  };
};

export const googleLoginFailedAction = (): BaseAction => {
  return {
    type: actionIds.GOOGLE_LOGIN_FAILED,
    payload: {},
  };
};

export const appleLoginStartAction = (
  payload: AppleLoginPayload
): BaseAction => {
  return {
    type: actionIds.APPLE_LOGIN_START,
    payload,
  };
};

export const resetPasswordStartAction = (payload: {
  email: string;
  password?: string;
  confirmedPassword?: string;
}): BaseAction => {
  return {
    type: actionIds.RESET_PASSWORD_START,
    payload,
  };
};

export const appleLoginCompleteAction = (): BaseAction => {
  return {
    type: actionIds.APPLE_LOGIN_COMPLETE,
    payload: {},
  };
};

export const appleLoginFailedAction = (): BaseAction => {
  return {
    type: actionIds.APPLE_LOGIN_FAILED,
    payload: {},
  };
};

export const sendRequestToResetPasswordStartAction = (payload: {
  token?: string;
  email: string;
  password?: string;
  confirmedPassword?: string;
}): BaseAction => {
  return {
    type: actionIds.RESET_PASSWORD_START,
    payload,
  };
};

export const sendRequestToResetPasswordFailedAction = (
  reason: string
): BaseAction => {
  return {
    type: actionIds.RESET_PASSWORD_FAILED,
    payload: {
      reason,
    },
  };
};

export const sendRequestToResetPasswordCompleteAction = (
  reason: string
): BaseAction => {
  return {
    type: actionIds.RESET_PASSWORD_COMPLETED,
    payload: {
      reason,
    },
  };
};

export const setLogoutStatusStartedAction = (): BaseAction => {
  return {
    type: actionIds.LOGOUT_START,
    payload: {},
  };
};

export const setLogoutStatusCompletedAction = (
  state: LoginState
): BaseAction => {
  return {
    type: actionIds.LOGOUT_SUCCESS,
    payload: state,
  };
};

export const setLogoutStatusFailedAction = (): BaseAction => {
  return {
    type: actionIds.LOGOUT_FAILED,
    payload: {},
  };
};

export const getStoreByUpcStartAction = (upc: string): BaseAction => {
  return {
    type: actionIds.GET_STORE_BY_UPC_START,
    payload: {
      upc: upc,
    },
  };
};

export const getStoreByUpcCompletedAction = (
  store: GrabbiStore
): BaseAction => {
  return {
    type: actionIds.GET_STORE_BY_UPC_COMPLETED,
    payload: {
      store: store,
    },
  };
};

export const getStoreByUpcFailedAction = (payload: any): BaseAction => {
  return {
    type: actionIds.GET_STORE_BY_UPC_FAILED,
    payload,
  };
};

export const getStoreTransactionsStartAction = (
  payload: GetStoreTransactionsPayload
): BaseAction => {
  return {
    type: actionIds.GET_STORE_TRANSACTIONS_START,
    payload,
  };
};

export const refreshTransactionsStartAction = (
  payload: GetStoreTransactionsPayload
): BaseAction => {
  return {
    type: actionIds.REFRESH_TRANSACTION_START,
    payload,
  };
};

export const getStoreTransactionsCompletedAction = (
  transactions: any[]
): BaseAction => {
  return {
    type: actionIds.GET_STORE_TRANSACTIONS_COMPLETED,
    payload: {
      transactions: transactions,
    },
  };
};

export const getStoreTransactionsFailedAction = (error: string): BaseAction => {
  return {
    type: actionIds.GET_STORE_TRANSACTIONS_FAILED,
    payload: {
      error,
    },
  };
};

export const getMerchantStartAction = (): BaseAction => {
  return {
    type: actionIds.GET_MERCHANT_START,
    payload: {},
  };
};

export const getMerchantCompletedAction = (merchant: Merchant): BaseAction => {
  return {
    type: actionIds.GET_MERCHANT_COMPLETE,
    payload: {
      merchant: merchant,
    },
  };
};

export const getCountriesStartAction = (): BaseAction => {
  return {
    type: actionIds.GET_COUNTRIES_START,
    payload: {},
  };
};

export const getCountriesCompletedAction = (
  countries: CountryCode[]
): BaseAction => {
  return {
    type: actionIds.GET_COUNTRIES_COMPLETE,
    payload: {
      countries,
    },
  };
};

export const getCountriesFailedAction = (): BaseAction => {
  return {
    type: actionIds.GET_COUNTRIES_FAILED,
    payload: {},
  };
};

export const getStatesStartAction = (countryCode: string): BaseAction => {
  return {
    type: actionIds.GET_STATES_START,
    payload: {
      countryCode,
    },
  };
};

export const getStatesCompletedAction = (states: StateCode[]): BaseAction => {
  return {
    type: actionIds.GET_STATES_COMPLETE,
    payload: {
      states,
    },
  };
};

export const getStatesFailedAction = (): BaseAction => {
  return {
    type: actionIds.GET_STATES_FAILED,
    payload: {},
  };
};

export const changePasswordStartAction = (payload: {
  merchant: Merchant;
  newPassword: string;
  confirmPassword: string;
  currentPassword?: string;
  oneTimeToken?: string;
}): BaseAction => {
  return {
    type: actionIds.CHANGE_PASSWORD_START,
    payload,
  };
};

export const changePasswordCompleteAction = (): BaseAction => {
  return {
    type: actionIds.CHANGE_PASSWORD_COMPLETED,
    payload: {},
  };
};

export const changePasswordFailedAction = (payload: {
  error: string;
}): BaseAction => {
  return {
    type: actionIds.CHANGE_PASSWORD_FAILED,
    payload,
  };
};

export const signUpStartAction = (merchant: GrabbiMerchant): BaseAction => {
  return {
    type: actionIds.SIGNUP_START,
    payload: {
      merchant,
    },
  };
};

export const signUpComplete = (merchant: GrabbiMerchant): BaseAction => {
  return {
    type: actionIds.SIGNUP_SUCCESS,
    payload: {
      merchant,
    },
  };
};

export const signUpFailed = (error: string): BaseAction => {
  return {
    type: actionIds.SIGNUP_FAILED,
    payload: {
      error,
    },
  };
};

export const updateStoreDetailsStart = (store: GrabbiStore): BaseAction => {
  return {
    type: actionIds.UPDATE_STORE_DETAILS_START,
    payload: {
      store,
    },
  };
};

export const updateStoreDetailsComplete = (store: GrabbiStore): BaseAction => {
  return {
    type: actionIds.UPDATE_STORE_DETAILS_COMPLETED,
    payload: {
      store,
    },
  };
};

export const updateStoreDetailsFailed = (errors: string[]): BaseAction => {
  return {
    type: actionIds.UPDATE_STORE_DETAILS_FAILED,
    payload: {
      errors,
    },
  };
};

export const updatePersonalDetailsStart = (merchant: Merchant): BaseAction => {
  return {
    type: actionIds.UPDATE_PERSONAL_DETAILS_START,
    payload: {
      merchant,
    },
  };
};

export const updatePersonalDetailsComplete = (
  merchant: Merchant
): BaseAction => {
  return {
    type: actionIds.UPDATE_PERSONAL_DETAILS_COMPLETED,
    payload: {
      merchant,
    },
  };
};

export const updatePersonalDetailsFailed = (error: string): BaseAction => {
  return {
    type: actionIds.UPDATE_PERSONAL_DETAILS_FAILED,
    payload: {
      error,
    },
  };
};

export const adminGetAllMerchantsStart = (
  page: number,
  pageSize: number,
  sortBy: string
): BaseAction => {
  return {
    type: actionIds.ADMIN_GET_ALL_MERCHANTS_START,
    payload: {
      page,
      pageSize,
      sortBy,
    },
  };
};

export const adminGetAllMerchantsComplete = (
  merchants: Merchant[],
  page: number,
  refresh: boolean = false
): BaseAction => {
  if (refresh) {
    return {
      type: actionIds.ADMIN_GET_ALL_MERCHANTS_REFRESH_COMPLETED,
      payload: { merchants, page },
    };
  } else {
    return {
      type: actionIds.ADMIN_GET_ALL_MERCHANTS_COMPLETED,
      payload: { merchants, page },
    };
  }
};

export const adminGetAllMerchantsFailed = (): BaseAction => {
  return {
    type: actionIds.ADMIN_GET_ALL_MERCHANTS_FAILED,
    payload: {},
  };
};

export const adminGetAllStoresStart = (
  page: number,
  pageSize: number,
  sortBy: string
): BaseAction => {
  return {
    type: actionIds.ADMIN_GET_ALL_STORES_START,
    payload: {
      page,
      pageSize,
      sortBy,
    },
  };
};

export const adminGetAllStoresComplete = (
  stores: GrabbiStore[],
  page: number
): BaseAction => {
  return {
    type: actionIds.ADMIN_GET_ALL_STORES_COMPLETED,
    payload: { stores, page },
  };
};

export const adminGetAllStoresFailed = (): BaseAction => {
  return {
    type: actionIds.ADMIN_GET_ALL_STORES_FAILED,
    payload: {},
  };
};

export const creatingLeadForMarketingStartAction = (
  leadForMarketing: GrabbiLeadForMarketing
): BaseAction => {
  return {
    type: actionIds.LEAD_FOR_MARKETING_START,
    payload: {
      leadForMarketing,
    },
  };
};

export const creatingLeadForMarketingComplete = (
  leadForMarketing: GrabbiLeadForMarketing
): BaseAction => {
  return {
    type: actionIds.LEAD_FOR_MARKETING_SUCCESS,
    payload: {
      leadForMarketing,
    },
  };
};

export const creatingLeadForMarketingFailed = (error: string): BaseAction => {
  return {
    type: actionIds.LEAD_FOR_MARKETING_FAILED,
    payload: {
      error,
    },
  };
};

export const confirmMerchantStartAction = (token: string): BaseAction => {
  return {
    type: actionIds.CONFIRM_MERCHANT_START,
    payload: {
      token,
    },
  };
};
export const confirmMerchantSuccess = (): BaseAction => {
  return {
    type: actionIds.CONFIRM_MERCHANT_SUCCESS,
    payload: {},
  };
};
export const confirmMerchantFailed = (error: string): BaseAction => {
  return {
    type: actionIds.CONFIRM_MERCHANT_FAILED,
    payload: {
      error,
    },
  };
};

export const resendConfirmMerchantStartAction = (token: string): BaseAction => {
  return {
    type: actionIds.RESEND_CONFIRM_MERCHANT_START,
    payload: {
      token,
    },
  };
};
export const resendConfirmMerchantSuccess = (): BaseAction => {
  return {
    type: actionIds.RESEND_CONFIRM_MERCHANT_SUCCESS,
    payload: {},
  };
};
export const resendConfirmMerchantFailed = (error: string): BaseAction => {
  return {
    type: actionIds.RESEND_CONFIRM_MERCHANT_FAILED,
    payload: {
      error,
    },
  };
};

export interface UpdateTransactionStatusPayload {
  id: number;
  fulfilled: boolean;
  transactionStatus?: string;
  refreshParams: GetStoreTransactionsPayload;
}

export const updateTransactionStatusStartAction = (
  payload: UpdateTransactionStatusPayload
): BaseAction => {
  return {
    type: actionIds.UPDATE_TRANSACTION_STATUS_START,
    payload,
  };
};

export const updateTransactionStatusSuccessAction = (
  id: number,
  fulfilled: boolean
): BaseAction => {
  return {
    type: actionIds.UPDATE_TRANSACTION_STATUS_SUCCESS,
    payload: {
      id,
      fulfilled,
    },
  };
};

export const updateTransactionStatusFailedAction = (
  id: number,
  error?: string
): BaseAction => {
  return {
    type: actionIds.UPDATE_TRANSACTION_STATUS_FAILED,
    payload: { id, error },
  };
};

export const clearStatusAction = (): BaseAction => {
  return {
    type: actionIds.CLEAR_STATUS,
    payload: {},
  };
};

export const getMerchandiseStartAction = (storeId: number) => {
  return {
    type: actionIds.GET_MERCH_START,
    payload: { storeId },
  };
};

export const getMerchandiseCompleteAction = (payload: any) => {
  return {
    type: actionIds.GET_MERCH_COMPLETE,
    payload,
  };
};

export const getMerchandiseFailedAction = (error: string) => {
  return {
    type: actionIds.GET_MERCH_FAILED,
    payload: { error },
  };
};

export const updateMerchandiseStartAction = (payload: any) => {
  return {
    type: actionIds.UPDATE_MERCH_START,
    payload,
  };
};

export const updateMerchandiseCompleteAction = (payload: any) => {
  return {
    type: actionIds.UPDATE_MERCH_COMPLETE,
    payload,
  };
};

export const updateMerchandiseFailedAction = (error: string, id: number) => {
  return {
    type: actionIds.UPDATE_MERCH_FAILED,
    payload: { error, id },
  };
};

export const setSelectedStoreAction = (index: number) => {
  return {
    type: actionIds.SET_SELECTED_STORE,
    payload: { index },
  };
};

export const getMerchandiseCategoriesStartAction = () => {
  return {
    type: actionIds.GET_MERCH_CATEGORIES_START,
    payload: {},
  };
};

export const getMerchandiseCategoriesCompleteAction = (
  categories: GrabbiMerchandiseCategory[]
) => {
  return {
    type: actionIds.GET_MERCH_CATEGORIES_COMPLETE,
    payload: {
      categories,
    },
  };
};

export const getMerchandiseCategoriesFailedAction = (error: string) => {
  return {
    type: actionIds.GET_MERCH_CATEGORIES_FAILED,
    payload: {
      error,
    },
  };
};

export const createMerchandiseStartAction = (
  payload: CreateGrabbiMerchandisePayload
) => {
  return {
    type: actionIds.ADD_MERCH_START,
    payload,
  };
};
export const refundTransactionStartAction = (
  payload: TransactionRefundPayload
) => {
  return {
    type: actionIds.REFUND_TRANSACTION_START,
    payload,
  };
};

export const createMerchandiseCompleteAction = (
  merchandise: GrabbiMerchandise
) => {
  return {
    type: actionIds.ADD_MERCH_COMPLETE,
    payload: {
      merchandise,
    },
  };
};

export const createMerchandiseFailedAction = (error: string) => {
  return {
    type: actionIds.ADD_MERCH_FAILED,
  };
};
export const refundTransactionCompletedAction = (payload: any) => {
  return {
    type: actionIds.REFUND_TRANSACTION_COMPLETED,
    payload,
  };
};

export const refundTransactionFailedAction = (error: string) => {
  return {
    type: actionIds.REFUND_TRANSACTION_FAILED,
    payload: {
      error,
    },
  };
};

// export const deleteMerchandiseStartAction = (
//   payload: DeleteMerchandisePayload
// ) => {
//   return {
//     type: actionIds.DELETE_MERCH_START,
//     payload,
//   };
// };

// export const deleteMerchandiseCompleteAction = () => {
//   return {
//     type: actionIds.DELETE_MERCH_COMPLETE,
//     payload: {},
//   };
// };

// export const deleteMerchandiseFailedAction = (error: string) => {
//   return {
//     type: actionIds.DELETE_MERCH_FAILED,
//     payload: { error },
//   };
// };

export const resetCategoriesTestAction = () => {
  return {
    type: actionIds.RESET_CATEGORIES,
    payload: {},
  };
};

export const getAllTransactionsStartAction = (id: number) => {
  return {
    type: actionIds.GET_ALL_TRANSACTIONS_START,
    payload: {
      apiPayload: {
        id,
        pageNumber: 1,
        pageSize: 10000,
      },
      isAll: true,
    },
  };
};

export const getAllTransactionsCompleteAction = (
  transactions: Transaction[]
) => {
  return {
    type: actionIds.GET_ALL_TRANSACTIONS_COMPLETE,
    payload: {
      transactions,
    },
  };
};

export const getAllTransactionsFailedAction = (error: string) => {
  return {
    type: actionIds.GET_ALL_TRANSACTIONS_FAILED,
    payload: {
      error,
    },
  };
};

export interface UpdateToppingPayload {
  storeId: number;
  merchandiseId: number;
  toppingUpc: GrabbiTopping;
}

export const updateToppingStartAction = (payload: UpdateToppingPayload) => {
  return {
    type: actionIds.UPDATE_TOPPING_START,
    payload,
  };
};

export const updateToppingCompleteAction = (upc: number) => {
  return {
    type: actionIds.UPDATE_TOPPING_COMPLETE,
    payload: {
      upc
    },
  };
};

export const updateToppingFailedAction = (error: string) => {
  return {
    type: actionIds.UPDATE_TOPPING_FAILED,
    payload: { error },
  };
};

export const creatingLeadFromStarterKitStartAction = (
  leadForMarketing: GrabbiLeadForMarketing
): BaseAction => {
  return {
    type: actionIds.LEAD_FROM_STARTER_KIT_START,
    payload: {
      leadForMarketing,
    },
  };
};

export const creatingLeadFromStarterKitComplete = (
  leadForMarketing: GrabbiLeadForMarketing
): BaseAction => {
  return {
    type: actionIds.LEAD_FROM_STARTER_KIT_SUCCESS,
    payload: {
      leadForMarketing,
    },
  };
};

export const creatingLeadFromStarterKitFailed = (error: string): BaseAction => {
  return {
    type: actionIds.LEAD_FROM_STARTER_KIT_FAILED,
    payload: {
      error,
    },
  };
};

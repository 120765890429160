import { Grid, useMediaQuery } from "@material-ui/core";
import { createQueryString } from "actions/api";
import { useAxiosContext } from "components/Shared/AxiosProvider/AxiosProvider";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import GrabbiDialog from "components/Shared/GrabbiDialog/GrabbiDialogComponent";
import GrabbiTextField from "components/Shared/GrabbiTextField/GrabbiTextField";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";
import OrderCode from "components/Shared/OrderCode/OrderCodeComponent";
import useTransactionHooks from "hooks/TransactionHooks";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { formatCurrency } from "shared/scripts/Utils";
import {
  AddTransactionPayload,
  GrabbiStore,
  PosCheckoutPayload,
  Transaction,
} from "types/GrabbiTypes";
import RestaurantBoardDialogHeader from "../RestaurantBoardDialogHeader/RestaurantBoardDialogHeader";
import "./CheckoutTabDialogComponent.scss";

interface Props {
  open: boolean;
  onClose: () => void;
  refreshCallback: () => void;
  store: GrabbiStore;
  transaction: Transaction;
}

const CheckoutTabDialog: React.FC<Props> = ({
  open,
  onClose,
  store,
  transaction,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { useAddTransaction } = useTransactionHooks();
  const [tipAmount, setTipAmount] = useState<string>();
  const [tipConfirmed, setTipConfirmed] = useState(false);
  const [error, setError] = useState<string>();
  const [checkoutUrl, setCheckoutUrl] = useState<string>();

  const setTipAmountString = (
    amountString: string,
    manual: boolean = false
  ) => {
    let value = amountString;
    const number = parseFloat(amountString);
    if (!manual) {
      value = number.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    }
    value = value.replace(/\$/g, "");
    const sigFigs = value.split(".")[1];
    value =
      sigFigs && sigFigs.length > 2
        ? value.substring(0, value.length - 1)
        : value;
    value = `$${value}`;
    setTipAmount(value);
  };

  const calculateTip = (percentage: number) => {
    return Math.round(transaction.transactionTotal * percentage * 100) / 100;
  };

  const handleSelectTipAmount = (percentage: number) => {
    setTipAmountString(calculateTip(percentage).toString());
  };

  const [
    {
      error: updateTransactionError,
      data: updatedTransaction,
      loading: loadingUpdateTransaction,
    },
    updateTransaction,
  ] = useAddTransaction();

  useEffect(() => {
    if (updateTransactionError) {
      enqueueSnackbar("Error: Update Transaction Failed", { variant: "error" });
    }
  }, [updateTransactionError, enqueueSnackbar]);

  const handleConfirmTip = async () => {
    if (tipAmount) {
      const tip = parseFloat(tipAmount.replace("$", ""));
      if (!isNaN(tip) && store && store.id) {
        const payload: AddTransactionPayload = {
          payload: {
            payLater: true,
            storeId: store.id,
            transactionId: transaction.id,
            tip,
          },
        };
        const response = await updateTransaction({
          data: payload,
        });
        if (response.data.id) {
          enqueueSnackbar("Transaction Updated.", { variant: "success" });
          setTipConfirmed(true);
        }
      } else {
        setError("Tip must be valid number");
      }
    } else {
      setError("Tip Amount Required");
    }
  };

  const handleOnClose = () => {
    onClose();
  };

  const { token } = useAxiosContext();

  useEffect(() => {
    if (updatedTransaction) {
      const payload: PosCheckoutPayload = {
        storeId: store.id ?? 0,
        clientKey: store.stripeConnectedAccountId ?? "",
        currency: updatedTransaction.currency,
        fee: updatedTransaction.serviceFee,
        locationId: store.stripeTerminalLocationId ?? "",
        pay: updatedTransaction.transactionTotal,
        transactionId: updatedTransaction.id,
        token
      };
      const queryString = createQueryString(payload);
      setCheckoutUrl(`posterminal://pos.paygex.com/pay${queryString}`);
    } 
  }, [token, store, updatedTransaction, setCheckoutUrl]);

  const smallHeight = useMediaQuery("(max-height: 560px)");

  return (
    <GrabbiDialog
      dialogProps={{
        open,
        classes: {
          paper: "checkout_tab_dialog_root resturant_dashboard_dialog",
        },
      }}
      dialogContentProps={{ classes: { root: "checkout_tab_dialog_content" } }}
      dialogHeader={
        <RestaurantBoardDialogHeader
          title="Checkout Transaction"
          onClose={handleOnClose}
        />
      }
      dialogFooter={
        <div className="dialog_footer_container">
          {!tipConfirmed ? (
            !loadingUpdateTransaction ? (
              <GrabbiButton
                className="action_button"
                onClick={handleConfirmTip}
              >
                Confirm
              </GrabbiButton>
            ) : (
              <LoadingIcon />
            )
          ) : (
            <React.Fragment>
              <GrabbiButton className="action_button" onClick={handleOnClose}>
                No
              </GrabbiButton>
              <a
                href={checkoutUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="action_button"
                aria-disabled={!checkoutUrl}
              >
                <GrabbiButton className="action_button" onClick={handleOnClose} disabled={!checkoutUrl}>
                  Yes
                </GrabbiButton>
              </a>
            </React.Fragment>
          )}
        </div>
      }
    >
      {!tipConfirmed ? (
        <Grid container>
          <Grid
            xs={12}
            item
            container
            className="confirmation_text_header_container"
            justify="center"
          >
            Enter Tip Amount
          </Grid>
          <Grid
            xs={12}
            item
            container
            className="tip_selection_container"
            justify="center"
            spacing={2}
          >
            <Grid xs={4} sm={3} item>
              <GrabbiButton onClick={() => handleSelectTipAmount(0.15)}>
                15%
              </GrabbiButton>
            </Grid>
            <Grid xs={4} sm={3} item>
              <GrabbiButton onClick={() => handleSelectTipAmount(0.18)}>
                18%
              </GrabbiButton>
            </Grid>
            <Grid xs={4} sm={3} item>
              <GrabbiButton onClick={() => handleSelectTipAmount(0.2)}>
                20%
              </GrabbiButton>
            </Grid>
          </Grid>
          <Grid xs={12} item container justify="center">
            <GrabbiTextField
              classes={{
                root: "tip_amount_field",
              }}
              name="tip_amount"
              value={tipAmount}
              label="Tip Amount"
              onChange={(e) =>
                setTipAmountString(
                  e.currentTarget.value.replace(/^(?!.*(\d|\.)).*/g, ""),
                  true
                )
              }
              error={error !== undefined}
              errorMessage={error}
            />
          </Grid>
        </Grid>
      ) : updatedTransaction ? (
        <Grid xs={12} item container justify="center" alignContent="center">
          <Grid
            xs={12}
            item
            container
            className="confirmation_text_header_container"
          >
            Confirm Checkout
          </Grid>
          <Grid item container xs={smallHeight ? 6 : 12}>
            <Grid xs={12} item container>
              <OrderCode
                className="order_code_container"
                transaction={transaction}
              />
            </Grid>
            {smallHeight && (
              <Grid
                xs={12}
                item
                container
                className="confirmation_text_info_container"
              >
                All transaction items will be closed.
              </Grid>
            )}
          </Grid>
          <Grid item container xs={smallHeight ? 6 : 12}>
            <Grid
              xs={12}
              item
              container
              className="confirmation_text_info_container"
            >
              <span>Merchandise Total:</span>
              <strong>
                {formatCurrency(
                  updatedTransaction.merchandiseTotal,
                  updatedTransaction.currency
                )}
              </strong>
            </Grid>
            <Grid
              xs={12}
              item
              container
              className="confirmation_text_info_container"
            >
              <span>Service Fee:</span>
              <strong>
                {formatCurrency(
                  updatedTransaction.serviceFee,
                  updatedTransaction.currency
                )}
              </strong>
            </Grid>
            <Grid
              xs={12}
              item
              container
              className="confirmation_text_info_container"
            >
              <span>Tax Amount:</span>
              <strong>
                {formatCurrency(
                  updatedTransaction.totalTax,
                  updatedTransaction.currency
                )}
              </strong>
            </Grid>
            <Grid
              xs={12}
              item
              container
              className="confirmation_text_info_container"
            >
              <span>Tip Amount:</span>
              <strong>
                {formatCurrency(
                  updatedTransaction?.tip ?? 0,
                  updatedTransaction.currency
                )}
              </strong>
            </Grid>
            <Grid
              xs={12}
              item
              container
              className="confirmation_text_info_container"
            >
              <span>Transaction Total:</span>
              <strong>
                {formatCurrency(
                  updatedTransaction.transactionTotal,
                  updatedTransaction.currency
                )}
              </strong>
            </Grid>
          </Grid>
          {!smallHeight && (
            <Grid
              xs={12}
              item
              container
              className="confirmation_text_info_container"
            >
              All transaction items will be closed.
            </Grid>
          )}
        </Grid>
      ) : (
        <React.Fragment>
          <div className="confirmation_text_header_container">
            Checkout Failed
          </div>
          <OrderCode
            className="order_code_container"
            transaction={transaction}
          />
          <div className="confirmation_text_info_container">
            Transaction checkout couldn't be completed due to an error. Please
            close this message and try again.
          </div>
          <div className="confirmation_text_info_container">
            If this problem persists, please contact support.
          </div>
        </React.Fragment>
      )}
    </GrabbiDialog>
  );
};

export default CheckoutTabDialog;

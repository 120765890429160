import React, { useCallback, useEffect, useState } from "react";
import "./MerchantDashboardComponent.scss";
import { Box, Grid } from "@material-ui/core";
import { GetStoreTransactionsPayload, Transaction } from "types/GrabbiTypes";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";
import { useInterval } from "shared/scripts/useInterval";
import { Howl } from "howler";
import { generateExcel } from "components/Shared/ExcelExport/ExcelExportComponent";
import { useSnackbar } from "notistack";
import GrabbiTabs from "components/Shared/GrabbiTabs/GrabbiTabsComponent";
import { useDispatch, useSelector } from "react-redux";
import { State } from "store";
import { checkSessionStartAction } from "actions";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import { createQueryString } from "actions/api";
import { useTransactionHooks } from "hooks/TransactionHooks";
import OpenTabDialog from "components/ResturantBoard/OpenTabDialog/OpenTabDialogComponent";
import GrabbiMoreActions, {
  MoreActionsItem,
} from "components/Shared/GrabbiMoreActions/GrabbiMoreActionsComponent";
import { createExcelConfig } from "./MerchantDashboardReportConfig";
import OrderCard from "./OrderCard/OrderCardComponent";
import GrabbiPagination from "components/Shared/GrabbiPagination/GrabbiPaginationComponent";
import { omit } from "lodash";

interface Props {
  className?: string;
}

const SESSION_REFRESH_THRESHOLD = 120;
const TRANSACTION_REFRESH_THRESHOLD = 7;

const MerchantDashboard: React.FC<Props> = ({ className }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [tab, setTab] = useState<number>(0);
  const [showRefund, setShowRefund] = useState(false);
  const [paramChange, setParamChange] = useState(false);
  const [init, setInit] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(0);

  const [dataTransactions, setDataTransactions] = useState<Transaction[]>();
  const [showAddTransaction, setShowAddTransaction] = useState(false);

  // const [closeTheNewOrderAlert, setCloseTheNewOrderAlert] = useState(false);
  // const [countOfTransactions, setCountOfTransactions] = useState(0);

  const [params, setParams] = useState<GetStoreTransactionsPayload>();

  // const [closeTheNewOrderAlert, setCloseTheNewOrderAlert] = useState(false);
  // const [countOfTransactions, setCountOfTransactions] = useState(0);

  const dispatch = useDispatch();

  const dispatchCheckSession = useCallback(
    () => dispatch(checkSessionStartAction()),
    [dispatch]
  );

  const { useGetTransactions, useGetReportTransactions } =
    useTransactionHooks();

  const selectedStore = useSelector(
    (state: State) => state.storeList.selectedStore
  );

  const { "1": getAllTransactions } = useGetReportTransactions(
    selectedStore?.id ?? 0
  );

  const showCompletedTransactions = tab === 1;
  const showRefundedTransactions = tab === 2;

  useEffect(() => {
    if (selectedStore?.id) {
      setParamChange(true);
      const params = {
        storeId: selectedStore.id,
        pageSize: pageSize,
        pageNumber: pageNumber + 1,
        isRefunded: showRefundedTransactions,
        isFulfilled: showCompletedTransactions,
      };
      setParams(tab === 2 ? omit(params, "isFulfilled") : params);
    }
  }, [
    tab,
    pageSize,
    pageNumber,
    setParams,
    selectedStore,
    showRefundedTransactions,
    showCompletedTransactions,
  ]);

  const [countdowns, setCountdowns] = useState({
    session: SESSION_REFRESH_THRESHOLD,
    transactions: 0,
  });

  const [lastTransaction, setLastTransaction] = useState<number | undefined>(
    undefined
  );

  const playBellsound = () => {
    const soundUrl = `assets/bell_sound.mp3`;
    const sound = new Howl({ src: [soundUrl] });
    sound.play();
  };

  const transactionQueryString = params ? createQueryString(params) : "";

  const [
    {
      data: dataTransactionsResp,
      loading: loadingTransactions,
      error: errorTransactions,
    },
    getTransactions,
  ] = useGetTransactions(transactionQueryString);

  const totalTransactions = dataTransactionsResp?.totalObjects;

  const fetchTransactions = useCallback(async () => {
    if (params) {
      await getTransactions().then((response) => {
        setDataTransactions(response.data.contents);
        setParamChange(false);
      });
    }
  }, [params, getTransactions, setParamChange]);

  useEffect(() => {
    if (params) {
      fetchTransactions();
    }
  }, [params, fetchTransactions]);

  useEffect(() => {
    if (errorTransactions) {
      enqueueSnackbar(errorTransactions, { variant: "error" });
    }
  }, [errorTransactions, enqueueSnackbar]);

  useEffect(() => {
    if (
      dataTransactions !== undefined &&
      dataTransactions[0] !== undefined &&
      !paramChange &&
      tab === 0
    ) {
      // if(countOfTransactions !== dataTransactions.length ) {
      //   setCountOfTransactions(dataTransactions.length);
      //   setCloseTheNewOrderAlert(false);
      // }

      const latestTransactionTime = dataTransactions[0].createdDate;
      if (lastTransaction === undefined && !init) {
        setLastTransaction(latestTransactionTime);
        setInit(true);
      } else if (
        lastTransaction &&
        lastTransaction < latestTransactionTime &&
        init
      ) {
        playBellsound();
        setLastTransaction(latestTransactionTime);
      }
    }
  }, [init, setInit, paramChange, tab, dataTransactions, lastTransaction, errorTransactions]);

  useInterval(async () => {
    if (
      params &&
      countdowns.transactions === 0 &&
      tab === 0 &&
      !paramChange &&
      !loadingTransactions &&
      selectedStore?.id
    ) {
      await fetchTransactions();
    }

    if (countdowns.session === 0) {
      dispatchCheckSession();
    }

    setCountdowns({
      session:
        countdowns.session > 0
          ? countdowns.session - 1
          : SESSION_REFRESH_THRESHOLD,
      transactions:
        countdowns.transactions > 0
          ? countdowns.transactions - 1
          : TRANSACTION_REFRESH_THRESHOLD,
    });
  }, 1000);

  const handlePageChange = (event: any, page: number) => {
    setPageNumber(page);
  };

  const handlePageSizeChange = (event: any) => {
    const newPageSize = parseInt(event.target.value);
    const currentItemStart = pageSize * pageNumber;
    const newPage = Math.floor(currentItemStart / newPageSize);
    setPageSize(newPageSize);
    setPageNumber(newPage);
  };

  const handleTabChange = useCallback(
    async (index: number) => {
      setPageNumber(0);
      setTab(index);
      setParamChange(true);
      if (params) {
        setParams({
          ...params,
          isFulfilled: index === 1,
          isRefunded: index === 2,
        });
      }
    },
    [setPageNumber, setTab, setParamChange, setParams, params]
  );

  const handleGenerateReport = useCallback(async () => {
    const transactions = await getAllTransactions();
    if (transactions && selectedStore) {
      const excelProps = {
        title: "Transaction Report",
        sheets: createExcelConfig(transactions.data, selectedStore),
      };
      generateExcel(excelProps);
      enqueueSnackbar("Download complete", { variant: "success" });
    }
  }, [enqueueSnackbar, getAllTransactions, selectedStore]);

  const handleShowRefund = useCallback(() => {
    setTab(0);
    setShowRefund(!showRefund);
  }, [showRefund, setTab, setShowRefund]);

  const moreActions: MoreActionsItem[] = [
    {
      content: "Download Report",
      callback: handleGenerateReport,
    },
    {
      content: `${showRefund ? "Hide" : ""} Refund Order`,
      callback: handleShowRefund,
    },
  ];

  return (
    <Grid container item xs={12}>
      {showAddTransaction && selectedStore && (
        <OpenTabDialog
          open={showAddTransaction}
          store={selectedStore}
          refreshCallback={fetchTransactions}
          onClose={() => setShowAddTransaction(false)}
        />
      )}
      {/* {dataTransactions &&
        selectedStore &&
        selectedStore.id &&
        totalTransactions != null &&
        dataTransactions.length > 0 &&
        !closeTheNewOrderAlert && (
        <div className="blink"
        onClick={() => setCloseTheNewOrderAlert(true)}><span className="new_order_title">YOU HAVE A NEW ORDER</span></div>
      )} */}
      <Grid item container xs={12} className="action_bar">
        <GrabbiButton
          className="action_button open_order_button"
          onClick={() => setShowAddTransaction(true)}
        >
          Open Order
        </GrabbiButton>
        <GrabbiMoreActions
          className="action_button more_action_button"
          actions={moreActions}
        />
      </Grid>
      <Grid item xs={12}>
        <GrabbiTabs
          selectedTab={tab}
          tabs={showRefund ? REFUND_TABS : DASHBOARD_TABS}
          disabled={paramChange}
          callback={handleTabChange}
          classes={{
            tabs: {
              root: "tabs",
            },
            mobile: "tabs_mobile",
          }}
        />
      </Grid>
      {dataTransactions &&
        selectedStore &&
        selectedStore.id &&
        totalTransactions != null &&
        dataTransactions.length > 0 && (
          <Grid
            item
            container
            xs={12}
            className={paramChange ? "refresh_state" : ""}
            justify="center"
          >
            {dataTransactions.map((transaction, index) => (
              <OrderCard
                key={`order-card-${transaction.id}`}
                className="order_card"
                transaction={transaction}
                store={selectedStore}
                refreshCallback={fetchTransactions}
                showRefund={showRefund && tab !== 2}
              />
            ))}
            <table>
              <tbody>
                <tr>
                  <GrabbiPagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    count={totalTransactions}
                    page={pageNumber}
                    rowsPerPage={pageSize}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePageSizeChange}
                  />
                </tr>
              </tbody>
            </table>

            {}
          </Grid>
        )}
      {dataTransactions &&
        dataTransactions &&
        dataTransactions.length === 0 &&
        !paramChange && (
          <Grid
            item
            container
            xs={12}
            className={paramChange ? "refresh_state" : ""}
            justify="center"
          >
            <Box className="no_results_root">
              <Box className="header">
                {tab === 1
                  ? "No Completed Orders"
                  : tab === 2
                  ? "No Refunded Orders"
                  : "No Results"}
              </Box>
              <Box className="message">
                {tab === 1
                  ? "ORDERS WILL APPEAR HERE WHEN THEY ARE MARKED AS COMPLETED"
                  : tab === 2
                  ? "ORDERS WILL APPEAR HERE WHEN THEY ARE REFUNDED"
                  : "REAL TIME TRANSACTIONS WILL BEGIN TO APPEAR HERE"}
              </Box>
            </Box>
          </Grid>
        )}
      {paramChange && (
        <div className="refresh_loading_wrapper">
          <LoadingIcon className="refresh_loading_icon" />
        </div>
      )}
      {errorTransactions && (
        <Box className="no_results_root">
          <Box className="header">A server error occurred</Box>
          <Box className="message">
            An error has occurred and we could not fetch your store's
            transactions. Please try again later.
          </Box>
        </Box>
      )}
    </Grid>
  );
};

export default MerchantDashboard;

const DASHBOARD_TABS = ["OPEN ORDERS", "COMPLETED"];

const REFUND_TABS = ["OPEN ORDERS", "COMPLETED", "REFUNDED"];

import React, { useState } from "react";
import "./ChangeTipButtonComponent.scss";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import {
  CustomerAddTransactionPayload,
  GrabbiStore,
  Transaction,
} from "types/GrabbiTypes";
import GrabbiDialog from "components/Shared/GrabbiDialog/GrabbiDialogComponent";
import ChangeTip from "components/Shared/ChangeTip/ChangeTipComponent";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";
import { useSnackbar } from "notistack";
import { useLoginContext } from "hooks/CustomerLogin";
import useAxios from "axios-hooks";

interface Props {
  className?: string;
  transaction: Transaction;
  store: GrabbiStore;
  onChange?: (transaction: Transaction) => void;
}

const ChangeTipButton: React.FC<Props> = ({
  className,
  transaction,
  store,
  onChange,
}) => {
  const [open, setOpen] = useState(false);
  const [tip, setTip] = useState<number>();
  const [error, setError] = useState<string>();
  const { enqueueSnackbar } = useSnackbar();
  const { accessToken, tokenUuid } = useLoginContext();
  const [
    { data: dataUpdateTransaction, loading: loadingUpdateTransaction },
    updateTransaction,
  ] = useAxios<Transaction>(
    {
      url: `restful-services/customer/payWithGooglePay`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
    { manual: true }
  );

  const showChangeTipDialog = () => {
    setOpen(true);
  };

  const handleChangeTip = (amount: number) => {
    setTip(amount);
  };

  const handleConfirmTip = async () => {
    if (tip !== undefined && tokenUuid) {
      const payload: CustomerAddTransactionPayload = {
        payload: {
          payLater: true,
          storeId: store.id,
          transactionId: transaction.id,
          tip,
          currency: transaction.currency,
        },
        tokenUuid,
      };
      try {
        const response = await updateTransaction({
          data: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (response.data.id) {
          enqueueSnackbar("Transaction Updated.", { variant: "success" });
          if (onChange) {
            onChange(response.data);
          }

          setOpen(false);
        }
      } catch (err) {
        enqueueSnackbar("Update Transaction Error", { variant: "error" });
        console.error(err);
      }
    }
  };

  const handleError = (error?: string) => {
    setError(error);
  };

  const dialogFooter = (
    <div className="change_tip_dialog_footer_root">
      {!loadingUpdateTransaction ? (
        <GrabbiButton
          className="action_button"
          onClick={handleConfirmTip}
          disabled={tip === undefined || error !== undefined}
        >
          Confirm
        </GrabbiButton>
      ) : (
        <LoadingIcon />
      )}
    </div>
  );

  return (
    <React.Fragment>
      <GrabbiButton
        className={`change_tip_button_root ${className ?? ""}`}
        onClick={showChangeTipDialog}
      >
        Change Tip
      </GrabbiButton>

      <GrabbiDialog
        dialogProps={{
          open,
          classes: {
            paper: "resturant_dashboard_dialog",
          },
        }}
        dialogFooter={dialogFooter}
      >
        <ChangeTip
          transaction={dataUpdateTransaction || transaction}
          onChange={handleChangeTip}
          onError={handleError}
        />
      </GrabbiDialog>
    </React.Fragment>
  );
};

export default ChangeTipButton;

import React from "react";
import { Button, Popover } from "@material-ui/core";
import "./UsernameButtonComponent.scss";
import { NavLink } from "react-router-dom";
import { LoginState } from "store/signin/loginReducer";
import { CONTEXT } from "shared/scripts/UrlContext";

interface Props {
  loginState: LoginState;
  className?: string;
}

const UsernameButtonComponent: React.FC<Props> = ({
  loginState,
  className,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const admin = loginState.isAdmin;

  return (
    <React.Fragment>
      <Button
        disableRipple={true}
        disableFocusRipple={true}
        variant="text"
        onClick={handleClick}
        classes={{ root: `username_button_root ${className}` }}
      >
        {loginState.username ? loginState.username : "NO USERNAME"}
      </Button>
      <Popover
        classes={{
          paper: "username_button_popover",
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {admin && (
          <NavLink
            to={`${CONTEXT}admin/store-manager`}
            className="username_button_popover_button"
          >
            ADMIN
          </NavLink>
        )}
        <NavLink
          to={`${CONTEXT}addstore`}
          className="username_button_popover_button"
        >
          ADD STORE
        </NavLink>
        <NavLink
          to={`${CONTEXT}merchant/edit-profile`}
          className="username_button_popover_button"
        >
          EDIT PROFILE
        </NavLink>
        <NavLink
          to={`${CONTEXT}logout`}
          className="username_button_popover_button"
        >
          LOGOUT
        </NavLink>
      </Popover>
    </React.Fragment>
  );
};

export default UsernameButtonComponent;

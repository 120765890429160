import { connect } from "react-redux";
import { State } from "store";
import StoreManagerComponent from "components/AdminConsole/StoreManager/StoreManagerComponent";
import { adminGetAllStoresStart, setStoreStatusStartAction } from "actions";
import {
  ADMIN_STORE_FETCH_DEFAULT_SORT_BY,
  ADMIN_STORE_FETCH_PAGE_SIZE,
} from "shared/scripts/Constants";

const mapStateToProps = (state: State) => {
  return {
    //merchantList: state.adminState.merchants,
    //merchantListLoading: state.adminState.loadingMerchants,
    //merchantPagesFetched: state.adminState.merchantPagesFetched,
    //lastMerchantPageFetched: state.adminState.lastMerchantPageFetched,
    storeList: state.adminState.stores,
    storeListLoading: state.adminState.loadingStores,
    storePagesFetched: state.adminState.storePagesFetched,
    lastStorePageFetched: state.adminState.lastStorePageFetched,
    storesPending: state.adminState.storesPending
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  dispatchGetStoreList: (
    page: number = 1,
    pageSize: number = ADMIN_STORE_FETCH_PAGE_SIZE,
    sortBy: string = ADMIN_STORE_FETCH_DEFAULT_SORT_BY
  ) => dispatch(adminGetAllStoresStart(page, pageSize, sortBy)),
  dispatchToggleStoreStatus: (id: number, index: number) =>
    dispatch(setStoreStatusStartAction(id, index)),
});

export const StoreManagerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreManagerComponent);

import { connect } from 'react-redux';
import { State } from 'store';
import SignUpComponent from './SignUpComponent';
import { signUpStartAction, getStoreListStartAction, appleLoginStartAction, googleLoginStartAction } from 'actions';
import { GrabbiMerchant } from 'types/GrabbiTypes';
import { AppleLoginPayload } from "types/GrabbiTypes";


const mapStateToProps = (state: State) => ({
  loginState: state.loginState,
  createdMerchantState: state.createdMerchantState,
  createdMerchantError: state.createdMerchantState.createdMerchantError
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatchGetStores: (id: number) => dispatch(getStoreListStartAction(id)),
  dispatchSignUp: (merchant: GrabbiMerchant) =>
    dispatch(signUpStartAction(merchant)),
  dispatchAppleLogin: (payload: AppleLoginPayload) =>
    dispatch(appleLoginStartAction(payload)),
  dispatchGoogleLogin: (id_token: string) =>
    dispatch(googleLoginStartAction({ id_token })),
});

export const SignUp = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpComponent);

import { useAxiosContext } from "components/Shared/AxiosProvider/AxiosProvider";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import GrabbiTextField from "components/Shared/GrabbiTextField/GrabbiTextField";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";
import { useLoginContext } from "hooks/CustomerLogin";
import { useSnackbar } from "notistack";
import React from "react";
import { useForm } from "react-hook-form";
import { RFC_5322_EMAIL_REGEX } from "shared/scripts/Constants";
import { GrabbiStore, Transaction } from "types/GrabbiTypes";
import "./EmailReceiptComponent.scss";

interface Props {
  className?: string;
  transaction: Transaction;
  store: GrabbiStore;
  onSuccess: () => void;
}

const EmailReceipt: React.FC<Props> = ({
  className,
  transaction,
  store,
  onSuccess,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { accessToken } = useLoginContext();
  const { useAxios } = useAxiosContext();
  const { register, watch, handleSubmit } = useForm<{
    email: string;
  }>({
    defaultValues: {
      email: "",
    },
    reValidateMode: "onChange",
  });
  const receiptEmail = watch("email");

  const isValid =
    receiptEmail.length !== 0 && RFC_5322_EMAIL_REGEX.test(receiptEmail);

  const [{ data: dataEmail, loading: loadingEmail }, sendReceiptEmail] =
    useAxios({
      url: `/restful-services/transaction/emailReceipt`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

  const handleFormSubmit = handleSubmit(() => {
    try {
      sendReceiptEmail({
        data: {
          id: transaction.id,
          storeId: store.id,
          receiptEmail,
        },
      }).then((data) => {
        enqueueSnackbar("Success - Email sent", { variant: "success" });
        onSuccess();
      }).catch(err => {
        enqueueSnackbar("Error - Email not sent", { variant: "error" });
      });
    } catch (e) {
      enqueueSnackbar("Error - Email not sent", { variant: "error" });
      console.error(e);
    }
  });

  return (
    <div className={`email_receipt_root ${className ?? ""}`}>
      {!dataEmail && !loadingEmail && (
        <form onSubmit={handleFormSubmit}>
          <GrabbiTextField
            name="email"
            ref={register({
              pattern: RFC_5322_EMAIL_REGEX,
              required: true,
            })}
            placeholder="address@email.com"
            label="Your Email"
          />
          <p className="description_text">
            We will send you ane mail with a receipt of your purchase.
          </p>
          <GrabbiButton type="submit" disabled={!isValid} colorVariant="black">
            SEND RECEIPT
          </GrabbiButton>
        </form>
      )}
      {loadingEmail && <LoadingIcon className="loading" />}
    </div>
  );
};

export default EmailReceipt;

import { Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import VennDiagram, { VennDiagramProps } from "../VennDiagram/VennDiagram";
import "./ProductPairingDisplay.scss";

interface Props {
  className?: string;
  title?: string;
  dataSet?: PairingData[];
  data?: PairingData;
  tip?: TipData;
  showTip?: boolean;
  vennDiagramProps?: VennDiagramProps;
}

export interface TipData {
  title: string;
  problem: string;
  solution: string;
}

export interface PairingData {
  antecedents: string;
  consequents: string;
  ant_categ?: string;
  con_categ?: string;
}

const ProductPairingDisplay: React.FC<Props> = ({
  className,
  title,
  dataSet,
  data,
  tip,
  showTip,
  vennDiagramProps
}) => {
  const [selected, setSelected] = useState(0);

  const selectedRow = dataSet ? dataSet[selected] : data ? data : undefined;

  const handleClick = (index: number) => () => {
    setSelected(index);
  };

  return (
    <Box className={`product_pairing_display_root ${className}`}>
      {title && <Typography className="title">{title}</Typography>}
      <Box className={`selection_area`}>
        {!showTip && dataSet && (
          <Box className={`selections`}>
            {dataSet.map((row: PairingData, index: number) => {
              return (
                <Box
                  onClick={handleClick(index)}
                  className={`selection ${
                    index === selected ? "selected" : ""
                  }`}
                >
                  {`${row.ant_categ} AND ${row.con_categ}`}
                </Box>
              );
            })}
          </Box>
        )}

        {showTip && selectedRow && tip && (
          <Box className="tip_area">
            <Typography className="tip_title">{tip.title}</Typography>
            <Box className="tip_content">
              <Box className="icon_wrapper">
                <Box className="grabbi_icon"></Box>
              </Box>
              <Box className="text_wrapper">
                <Typography>{tip.problem}</Typography>
                <Typography>{tip.solution}</Typography>
              </Box>
            </Box>
          </Box>
        )}
        <Box className="venn_diagram_area">
          {selectedRow && (
            <VennDiagram
              circle1Content={selectedRow.antecedents}
              circle2Content={selectedRow.consequents}
              joinText="AND"
              {...vennDiagramProps}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ProductPairingDisplay;

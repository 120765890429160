import React from "react";
import { TransactionTopping } from "types/GrabbiTypes";
import "./ToppingTagComponent.scss";

interface Props {
  topping: TransactionTopping;
  className?: string;
}

const ToppingTag: React.FC<Props> = ({ topping, className }) => {
  return (
    <div className={`topping_wrapper ${className ?? ""}`}>
      {topping.upc.label}
    </div>
  );
};

export default ToppingTag;

import { DateTime } from "luxon";
import React from "react";
import { Transaction } from "types/GrabbiTypes";
import "./OrderCodeComponent.scss";

interface Props {
  transaction: Transaction;
  hideOrderedOn?: boolean;
  className?: string;
}

const OrderCode: React.FC<Props> = ({
  transaction,
  hideOrderedOn,
  className,
}) => {
  return (
    <div className={`order_code_root ${className ?? ""}`}>
      <div className="order_number_container">
        <div
          className="order_color_circle"
          style={{
            backgroundColor: transaction.orderCodeColor,
          }}
        />
        <div className="order_number">{transaction.orderCode}</div>
      </div>
      {!hideOrderedOn && (
        <div className="ordered_on_container">
          {`Ordered on ${DateTime.fromMillis(transaction.createdDate).toFormat(
            "MM/dd h:mma"
          )}`}
        </div>
      )}
    </div>
  );
};

export default OrderCode;

import React from "react";
import "./About.scss";
import { Box } from "@material-ui/core";

const About: React.FC = () => {
  return (
    <Box className="about_root">
      <Box className="about_text">
        <Box className="about_header">
          About The Team
        </Box>
        <Box className="about_body">
          We are a team of dedicated and passionate business, designer and
          developers who are on a mission to build a platform for all small
          businesses and customers to enjoy.
        </Box>
        <Box className="about_body">
          Drop us an email if you are interested in joining the team at
          <a href="mailto:Careers@grabbi.shop">Careers@grabbi.shop</a>
        </Box>
      </Box>
      <Box className="about_image_wrapper">
        <img alt="Grabbi Team Members" src={`assets/grabbi-about-team.svg`} />
      </Box>
    </Box>
  );
};

export default About;

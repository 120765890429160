import axios from "axios";
import { makeUseAxios } from "axios-hooks";
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { Cookies } from "react-cookie";

const initialContext = {
  token: "",
  setToken: (token: string) => {},
  useAxios: makeUseAxios({
    axios: axios.create({
      baseURL: process.env?.API_CONTEXT ?? "",
    }),
    defaultOptions: {
      manual: true,
    },
  }),
};

export const AxiosContext = React.createContext(initialContext);

const AxiosProviderContext = AxiosContext.Provider;

interface Props {}

export const AxiosProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
}) => {
  const [token, setToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    const cookies = new Cookies();
    setToken(cookies.get("accessToken"));
  }, [setToken]);

  return (
    <AxiosProviderContext
      value={{
        token: `${token}`,
        setToken,
        useAxios: makeUseAxios({
          axios: axios.create({
            baseURL: process.env?.API_CONTEXT ?? "",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          defaultOptions: {
            manual: true,
          },
        }),
      }}
    >
      {children}
    </AxiosProviderContext>
  );
};

export const useAxiosContext = () => useContext(AxiosContext);

export default AxiosProvider;

import { ItemCard } from "components/ResturantBoard/ItemCard/ItemCardComponent";
import React from "react";
import { Transaction } from "types/GrabbiTypes";
import "./MyTabComponent.scss";

interface Props {
  className?: string;
  transaction: Transaction;
}

const MyTab: React.FC<Props> = ({ className, transaction }) => {
  return (
    <div className={`my_tab_root ${className ?? ""}`}>
      <div className="tab_header_container">
        <span>My Tab</span>
      </div>
      <div className="tab_items_container">
        {transaction.transactionItems.map((item, index) => {
          return (
            <ItemCard
              key={`my_tab_item_card_${index}`}
              item={item}
              currency={transaction.currency}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MyTab;

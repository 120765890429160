import { connect } from "react-redux";
import { State } from "store";
import RoutesComponent from "./RoutesComponent";
import { checkSessionStartAction } from "actions";

const mapDispatchToProps = (dispatch: any) => ({
  checkSessionDispatch: () => dispatch(checkSessionStartAction()),
});

const mapStateToProps = (state: State) => ({
  loginState: state.loginState
});

export const Routes = connect(mapStateToProps, mapDispatchToProps)(RoutesComponent);

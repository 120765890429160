import {
  Dialog,
  DialogContent,
  DialogContentProps,
  DialogProps,
} from "@material-ui/core";
import React, { ReactNode } from "react";
import { useWindowSize } from "shared/scripts/ResizeListener";
import "./GrabbiDialogComponent.scss";

interface Props {
  dialogProps?: DialogProps;
  dialogContentProps?: DialogContentProps;
  dialogHeader?: ReactNode;
  dialogFooter?: ReactNode;
}

const GrabbiDialog: React.FC<React.PropsWithChildren<Props>> = ({
  dialogProps,
  dialogContentProps,
  dialogHeader,
  dialogFooter,
  children,
}) => {
  useWindowSize();

  const { open, classes, ...otherDialogProps } = dialogProps!;
  const { classes: dialogContentClasses, ...otherDialogContentProps } =
    dialogContentProps ?? {};
  const { root: dialogContentRootClass, ...otherDialogContentClasses } =
    dialogContentClasses ?? {};
  const { paper, ...otherClasses } = classes ?? {};

  return (
    <Dialog
      classes={{ paper: `grabbi_dialog_root ${paper}`, ...otherClasses }}
      open={open}
      {...otherDialogProps}
    >
      {dialogHeader}
      <DialogContent
        classes={{
          root: `grabbi_dialog_content ${dialogContentRootClass}`,
          ...otherDialogContentClasses,
        }}
        {...otherDialogContentProps}
      >
        {children}
      </DialogContent>
      {dialogFooter}
    </Dialog>
  );
};

export default GrabbiDialog;

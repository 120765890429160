import React from "react";
import {
  Flyout,
  FlyoutProps,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import "./BarChart.scss";

interface Props {
  data: {
    x: any;
    y: number;
  }[];
  className?: string;
  barWidth?: number;
}

const BarChart: React.FC<Props> = ({ data, className, barWidth }) => {
  const finalBarWidth = barWidth ?? 20;

  const CustomFlyout: React.FC<FlyoutProps> = (props: any) => {
    const { x, y, datum } = props;
    const label = datum ? datum["x"] : "";
    return (
      <React.Fragment>
        <Flyout {...props} />
        <rect
          x={x - finalBarWidth / 2}
          y={y}
          height={248 - y}
          stroke="#000000"
          width={finalBarWidth}
        />
        <VictoryLabel
          text={label}
          textAnchor="middle"
          verticalAnchor="middle"
          x={x}
          y={261}
          style={{
            fontSize: 8,
            fontWeight: 600,
            fontFamily: "LemonMilkBold",
            fill: "white",
          }}
          backgroundStyle={{
            fill: "black",
          }}
          backgroundComponent={<rect ry="8" rx="8" />}
          backgroundPadding={{ top: 4, left: 5, right: 6, bottom: 5 }}
        />
      </React.Fragment>
    );
  };

  const maxY = Math.max(...data.map((item) => item.y));

  const maxYDomain = maxY + Math.floor(maxY / 4);

  return (
    <VictoryChart
      domainPadding={{ x: 20 }}
      containerComponent={
        <VictoryVoronoiContainer
          className={`bar_chart_root ${className ?? ""}`}
          voronoiDimension="x"
        />
      }
    >
      <VictoryAxis
        dependentAxis
        crossAxis={false}
        style={{
          axis: {
            strokeWidth: 5,
          },
          tickLabels: {
            fontSize: 8,
            fontWeight: 600,
            fontFamily: "LemonMilkBold",
          },
        }}
        domain={{ y: [0, maxYDomain] }}
        tickFormat={(tick: any) => {
          return `$${tick.toLocaleString()}`;
        }}
      />
      <VictoryAxis
        style={{
          axis: {
            strokeWidth: 5,
          },
        }}
        tickLabelComponent={
          <VictoryLabel
            style={{
              fontSize: 8,
              fontWeight: 600,
              fontFamily: "LemonMilkBold",
              fill: "black",
            }}
          />
        }
      />

      <VictoryBar
        style={{
          data: { fill: "#000000", opacity: 0.3 },
          labels: { color: "#ffffff" },
        }}
        barWidth={finalBarWidth}
        labelComponent={
          <VictoryTooltip
            cornerRadius={2}
            pointerLength={1}
            pointerWidth={4}
            centerOffset={{ x: 0, y: -2 }}
            flyoutComponent={<CustomFlyout />}
            dy={-7}
            style={{
              paddingLeft: 12,
              paddingRight: 12,
              fontSize: 6,
              fontFamily: "LemonMilkBold",
              fill: "#ffffff",
            }}
            flyoutStyle={{
              fill: "#2F89FC",
              stroke: "none",
            }}
          />
        }
        data={data}
      />
    </VictoryChart>
  );
};

export default BarChart;

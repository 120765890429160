import { AxiosRequestConfig } from "axios";
import qs from "qs";
import { AppleLoginPayload, GrabbiMerchant } from "types/GrabbiTypes";
import Cookies from "universal-cookie";
import { get, post } from "./api";

const cookies = new Cookies();

export interface jwtTokens {
  accessToken?: string;
  refreshToken?: string;
  authProvider?: string;
  //tokensExpire?: number;
  noToken: boolean;
}

export const getCookies = (): jwtTokens => {
  return cookies.get("accessToken") !== undefined
    ? {
        accessToken: cookies.get("accessToken"),
        refreshToken: cookies.get("refreshToken"),
        authProvider: cookies.get("authProvider"),
        //tokensExpire: cookies.get("tokensExpire"),
        noToken: false,
      }
    : {
        noToken: true,
      };
};

export const login = (
  options: { username: string; password: string },
  admin: boolean = false
) => {
  const merchantEndpoint = `auth/merchant/login`;
  const adminEndpoint = `auth/admin/login`;
  const data = {
    userName: options.username,
    password: options.password,
  };
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
      accepts: "application/json",
    },
  };
  const thenHandler = (response: any) => {
    return {
      loggedIn:
        cookies.get("accessToken") !== undefined,
      status: response.status,
      admin: response.data.isAdmin,
      username: response.data.userName,
      merchantid: response.data.merchantId,
    };
  };
  const errorHandler = (error: any) => {
    let status =
      error.response && error.response.status ? error.response.status : 404;
    const errorResponse: String = error.response.data;
    if (
      Array.isArray(errorResponse) &&
      errorResponse.indexOf("Proxy error") !== -1 &&
      process.env.NODE_ENV === "development"
    ) {
      status = 404;
    }
    return {
      loggedIn: false,
      status: status,
      error,
    };
  };
  const finallyHandler = () => {
    return {
      loggedIn: false,
      status: 404,
      error: "Unknown error",
    };
  };
  return post(
    admin ? adminEndpoint : merchantEndpoint,
    data,
    config,
    thenHandler,
    errorHandler,
    finallyHandler
  );
};

export const refresh = (options: {
  accessToken: string;
  refreshToken: string;
}) => {
  const data = {
    accessToken: options.accessToken,
    refreshToken: options.refreshToken,
  };
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
      accepts: "application/json",
    },
  };
  const thenHandler = (response: any) => {
    return {
      loggedIn:
        cookies.get("accessToken") !== undefined,
      status: response.status,
      admin: response.data.isAdmin,
      username: response.data.userName,
      merchantid: response.data.merchantId,
    };
  };
  const errorHandler = (error: any) => {
    let status =
      error.response && error.response.status ? error.response.status : 404;
    return {
      loggedIn: false,
      status: status,
      error: error,
    };
  };
  const finallyHandler = () => {
    return {
      loggedIn: false,
      status: 404,
      error: "Unknown error",
    };
  };
  return post(
    `auth/token`,
    data,
    config,
    thenHandler,
    errorHandler,
    finallyHandler
  );
};

export const changePassword = (payload: {
  merchant: GrabbiMerchant;
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  oneTimeToken?: string;
}) => {
  const token = cookies.get("accessToken") ?? payload.oneTimeToken ?? undefined;

  if (!token) {
    return {
      status: 401,
    };
  }

  const data = {
    id: payload.merchant.id,
    identity: {
      id: payload.merchant.identity?.id,
      oldPassword: payload.currentPassword,
      password: payload.newPassword,
      confirmedPassword: payload.confirmPassword,
    },
  };
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
      accepts: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const thenHandler = (response: any) => {
    return response;
  };
  const errorHandler = (error: any) => {
    return error;
  };
  const finallyHandler = () => {
    return {
      status: 500,
    };
  };
  return post(
    `restful-services/merchant/changePassword`,
    data,
    config,
    thenHandler,
    errorHandler,
    finallyHandler
  );
};

export const sendRequestToResetPassword = (payload: { email: string }) => {
  const data = {};
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
      accepts: "application/json",
    },
  };
  const thenHandler = (response: any) => {
    return {
      status: 200,
    };
  };
  const errorHandler = (error: any) => {
    let status =
      error.response && error.response.status ? error.response.status : 404;
    return { status };
  };
  const finallyHandler = () => {
    return {
      status: 500,
    };
  };
  return post(
    `reset/endpoint`, //? [PLACEHOLDER]
    data,
    config,
    thenHandler,
    errorHandler,
    finallyHandler
  );
};

export const clearTokens = () => {
  cookies.remove("accessToken", {
    path: "/",
  });
  cookies.remove("refreshToken", {
    path: "/",
  });
  cookies.remove("authProvider", {
    path: "/",
  });
  // cookies.remove("tokensExpire", {
  //   path: "/",
  // });
};

export const googleLogin = (id_token: string) => {
  const data = qs.stringify({
    id_token,
  });
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accepts: "application/json",
    },
  };
  const thenHandler = (response: any) => {
    return {
      loggedIn: cookies.get("accessToken") !== undefined,
    };
  };
  const errorHandler = (error: any) => {
    let status =
      error.response && error.response.status ? error.response.status : 404;
    return {
      loggedIn: false,
      status,
    };
  };
  const finallyHandler = () => {
    return {
      status: 500,
    };
  };
  return post(
    `auth/merchant/GOOGLE`,
    data,
    config,
    thenHandler,
    errorHandler,
    finallyHandler
  );
};

export const appleLogin = (payload: AppleLoginPayload) => {
  const data = qs.stringify(payload);
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accepts: "application/json",
    },
  };
  const thenHandler = (response: any) => {
    return {
      loggedIn: cookies.get("accessToken") !== undefined,
    };
  };
  const errorHandler = (error: any) => {
    let status =
      error.response && error.response.status ? error.response.status : 404;
    return {
      loggedIn: false,
      status,
    };
  };
  const finallyHandler = () => {
    return {
      status: 500,
    };
  };
  return post(
    `auth/merchant/APPLE`,
    data,
    config,
    thenHandler,
    errorHandler,
    finallyHandler
  );
}

export const confirmMerchant = (token: string) => {
  const data = {};
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
      accepts: "application/json",
    },
  };
  const thenHandler = (response: any) => {
    return {
      status: 200,
    };
  };
  const errorHandler = (error: any) => {
    return error;
  };
  const finallyHandler = () => {
    return {
      status: 500,
    };
  };
  return get(
    `auth/merchant/confirm?token=${token}`,
    data,
    config,
    thenHandler,
    errorHandler,
    finallyHandler
  );
};

export const resendConfirmMerchant = (token: string) => {
  const data = {};
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
      accepts: "application/json",
    },
  };
  const thenHandler = (response: any) => {
    return {
      status: 200,
    };
  };
  const errorHandler = (error: any) => {
    return error;
  };
  const finallyHandler = () => {
    return {
      status: 500,
    };
  };
  return get(
    `auth/merchant/resendToken?token=${token}`,
    data,
    config,
    thenHandler,
    errorHandler,
    finallyHandler
  );
};

import { BaseAction, actionIds } from "actions";
import {
  ADMIN_MERCHANT_FETCH_PAGE_SIZE,
  ADMIN_STORE_FETCH_PAGE_SIZE,
} from "shared/scripts/Constants";
import { GrabbiStore, Merchant } from "types/GrabbiTypes";

export type AdminState = {
  stores?: GrabbiStore[];
  storePagesFetched: number[];
  lastStorePageFetched: boolean;
  loadingStores: boolean;
  merchants?: Merchant[];
  merchantPagesFetched: number[];
  lastMerchantPageFetched: boolean;
  loadingMerchants: boolean;
  storesPending: number[];
};

const DEFAULT_STATE: AdminState = {
  stores: undefined,
  storePagesFetched: [],
  lastStorePageFetched: false,
  loadingStores: false,
  merchants: undefined,
  merchantPagesFetched: [],
  lastMerchantPageFetched: false,
  loadingMerchants: false,
  storesPending: [],
};

export const adminReducer = (
  state: AdminState = DEFAULT_STATE,
  action: BaseAction
): AdminState => {
  switch (action.type) {
    case actionIds.ADMIN_GET_ALL_MERCHANTS_START:
      return { ...state, loadingMerchants: true };
    case actionIds.ADMIN_GET_ALL_MERCHANTS_COMPLETED:
      const merchants = action.payload.merchants;
      const lastMerchantPageFetched =
        action.payload.merchants &&
        action.payload.merchants.length < ADMIN_MERCHANT_FETCH_PAGE_SIZE;

      return {
        ...state,
        merchantPagesFetched: state.merchantPagesFetched.includes(
          action.payload.page
        )
          ? state.merchantPagesFetched
          : [...state.merchantPagesFetched, action.payload.page],
        lastMerchantPageFetched,
        loadingMerchants: false,
        merchants,
      };
    case actionIds.ADMIN_GET_ALL_MERCHANTS_FAILED:
      return {
        ...state,
        loadingStores: false,
      };
    case actionIds.ADMIN_GET_ALL_STORES_START:
      return { ...state, loadingStores: true };
    case actionIds.ADMIN_GET_ALL_STORES_COMPLETED:
      const stores = action.payload.stores.stores;
      const lastStorePageFetched =
        stores && stores.length < ADMIN_STORE_FETCH_PAGE_SIZE;
      return {
        ...state,
        stores: state.stores ? [...state.stores, ...stores] : stores,
        storePagesFetched: state.storePagesFetched.includes(action.payload.page)
          ? state.storePagesFetched
          : [...state.storePagesFetched, action.payload.page],
        lastStorePageFetched,
        loadingStores: false,
      };
    case actionIds.ADMIN_GET_ALL_STORES_FAILED:
      return {
        ...state,
        loadingStores: false,
      };
    case actionIds.TOGGLE_STORE_STATUS_START:
      return {
        ...state,
        storesPending: [action.payload.index, ...state.storesPending],
      };
    case actionIds.TOGGLE_STORE_STATUS_COMPLETED:
      return {
        ...state,
        storesPending: state.storesPending.slice(
          0,
          state.storesPending.length - 2
        ),
        stores: action.payload.storeList,
      };
    case actionIds.LOGOUT_START:
      return DEFAULT_STATE;
  }
  return state;
};

import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import { values } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { formatCurrency } from "shared/scripts/Utils";
import { State } from "store";
import {
  GrabbiMerchandiseSelection,
  groupChoicesNeeded,
  useMerchSelection,
} from "../MerchandiseSelectorComponent";
import ToppingSelector from "../ToppingSelector/ToppingSelectorComponent";
import "./MerchandiseListComponent.scss";

interface Props {
  className?: string;
  onAddToTab?: (singleSelection?: GrabbiMerchandiseSelection) => void;
}

const MerchandiseListComponent: React.FC<Props> = ({
  className,
  onAddToTab,
}) => {
  const {
    merchandiseByCategory,
    selectionState,
    toggleExpandSelectionItem: toggleExpandItem,
    addItemToCart,
    toggleSelectionTopping: toggleTopping,
    getItemSelection,
    isPosted,
    getItemSelectedPrice,
    hasOneToppingChecked
  } = useMerchSelection();

  const selectedStore = useSelector(
    (state: State) => state.storeList.selectedStore
  );

  let flatItemIndex = 0;

  return merchandiseByCategory ? (
    <div className={`merchandise_list_root ${className ?? ""}`}>
      {Object.keys(merchandiseByCategory).map(
        (key: string, categoryIndex: number) => {
          const merchandise = merchandiseByCategory[key];
          return (
            <React.Fragment key={`category-section-${key}`}>
              <div className="menu_category_container">{key}</div>
              {merchandise.map((merch) => {
                const index = flatItemIndex++;
                const expanded =
                  selectionState[index] && selectionState[index].expanded;
                const toppingGroups = values(
                  selectionState[index]?.groupingState
                );
                const choicesNeeded = toppingGroups.map(groupChoicesNeeded);
                const choicesRequired = choicesNeeded.filter(
                  (needed) => needed > 0
                ).length;
                const toppingsChecked = hasOneToppingChecked(index);
                const itemSelectedPrice = getItemSelectedPrice(index);
                const itemBasePrice = merch.price;

                return (
                  <div
                    className="merchandise_row"
                    key={`merchandise-row-${index}`}
                  >
                    <div
                      key={`merch-key-${index}`}
                      className={`merchandise_container ${
                        expanded ? "expanded" : ""
                      }`}
                      style={{
                        backgroundColor: expanded ? "#2F89FC" : undefined,
                      }}
                      onClick={
                        expanded ? () => {} : () => toggleExpandItem(index)
                      }
                    >
                      <div
                        className="top_row"
                        onClick={
                          expanded ? () => toggleExpandItem(index) : () => {}
                        }
                      >
                        <div className="left_side">
                          <span className="merchandise_text">
                            {merch.upc.label}{" "}
                            {!merch.available && (
                              <span className="merchandise_text_status_warning">
                                - Unavailable
                              </span>
                            )}
                          </span>
                        </div>

                        <div className="right_side">
                          <span className="price_container">
                            {merch.dependant
                              ? "See Options"
                              : formatCurrency(
                                  toppingsChecked ? itemSelectedPrice : itemBasePrice,
                                  selectedStore?.currency ?? "USD"
                                )}
                          </span>
                        </div>
                      </div>

                      {expanded && (
                        <div className="bottom_row">
                          <ToppingSelector
                            merch={merch}
                            toggleTopping={(topping) =>
                              toggleTopping(index, topping)
                            }
                            toppingGroupStates={toppingGroups}
                          />
                          <GrabbiButton
                            className="select_button"
                            shapeVariant="rounded"
                            colorVariant="black"
                            onClick={
                              choicesRequired > 0
                                ? undefined
                                : () => {
                                    if (
                                      getItemSelection &&
                                      onAddToTab &&
                                      isPosted
                                    ) {
                                      const selection = getItemSelection(index);
                                      onAddToTab(selection);
                                    } else {
                                      addItemToCart(index);
                                    }
                                  }
                            }
                          >
                            {choicesRequired > 0
                              ? `Make Required Choices (${choicesRequired})`
                              : "Add to Tab"}
                          </GrabbiButton>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </React.Fragment>
          );
        }
      )}
    </div>
  ) : null;
};

export default MerchandiseListComponent;

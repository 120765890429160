import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@material-ui/core";
import React from "react";
import "./GrabbiDropdown.scss";

interface Props extends SelectProps {
  className?: string;
  options: string[];
  selectedIndex?: number | undefined | null;
  handleChange?: (index: number) => void;
  label?: string;
  error?: boolean;
  errorMessage?: string;
}

const GrabbiDropdown: React.FC<Props> = React.forwardRef(
  (props: Props, ref) => {
    const {
      className,
      options,
      selectedIndex,
      handleChange,
      label,
      error,
      errorMessage,
      ...otherProps
    } = props;

    const handleClick = (index: number) => () => {
      if (handleChange) {
        handleChange(index);
      }
    };

    return (
      <React.Fragment>
        {errorMessage && (
          <Box className="grabbi_dropdown_error">{errorMessage}</Box>
        )}
        <FormControl
          className={`grabbi_dropdown_wrapper ${
            props.disabled ? "disabled" : ""
          }`}
        >
          {label && <InputLabel className="label">{label}</InputLabel>}
          <Select
            inputRef={ref}
            inputProps={{
              className: "grabbi_dropdown_input"
            }}
            displayEmpty
            value={selectedIndex != null ? `${selectedIndex}` : ""}
            variant="outlined"
            renderValue={(value) => {
              return value !== ""
                ? options[parseInt(`${value}`)]
                : props?.placeholder ?? "";
            }}
            className={`grabbi_dropdown_root ${
              label ? "labeled" : ""
            } ${className}`}
            MenuProps={{
              PaperProps: {
                className: "grabbi_dropdown_menu",
              },
            }}
            {...otherProps}
          >
            {options.map((option: string, index: number) => {
              return (
                <MenuItem
                  key={`option-${option}-${index}`}
                  value={`${index}`}
                  onClick={handleClick(index)}
                >
                  {option}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </React.Fragment>
    );
  }
);

export default GrabbiDropdown;

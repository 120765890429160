import { Checkbox, Grid, Typography } from "@material-ui/core";
import { useAxiosContext } from "components/Shared/AxiosProvider/AxiosProvider";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import GrabbiDropdown from "components/Shared/GrabbiDropdown/GrabbiDropdown";
import GrabbiTable, {
  GrabbiTableColumn,
} from "components/Shared/GrabbiTable/GrabbiTableComponent";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";
import UpdatingRow from "components/Shared/UpdatingRow/UpdatingRowComponent";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CONTEXT } from "shared/scripts/UrlContext";
import {
  CreateGrabbiMerchandisePayload,
  CreateGrabbiToppingPayload,
  GrabbiMerchandise,
  GrabbiTopping,
  MerchandiseCategory,
} from "types/GrabbiTypes";
import CreateDeleteMerchandiseDialog from "../EditMerchandiseDialog/CreateDeleteMerchandiseDialog";
import MerchandiseForm from "../MerchandiseForm/MerchandiseFormComponent";
import "./EditMerchandiseComponent.scss";

interface Props {
  className?: string;
}

const EditMerchandise: React.FC<Props> = ({ className }) => {
  const { useAxios } = useAxiosContext();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { storeId: storeIdParam, merchUpc: merchUpcParam } =
    useParams<{ storeId: string; merchUpc: string }>();

  const storeId = parseInt(storeIdParam);
  const merchUpc = parseInt(merchUpcParam);

  const [selectedToppingIndex, setSelectedToppingIndex] = useState<
    number | undefined
  >(undefined);
  const [updatingRows, setUpdatingRows] = useState<number[]>([]);

  const [addTopping, setAddTopping] = useState<GrabbiTopping | undefined>(
    undefined
  );
  const [editTopping, setEditTopping] = useState<GrabbiTopping | undefined>(
    undefined
  );
  const [deleteTopping, setDeleteTopping] = useState<GrabbiTopping | undefined>(
    undefined
  );
  const [createNew, setCreateNew] = useState(false);

  const [
    {
      data: dataMerchandise,
      loading: loadingMerchandise,
      error: errorMerchandise,
    },
    getMerchandise,
  ] = useAxios<GrabbiMerchandise>(
    {
      url: `restful-services/merchandise/${storeId}/${merchUpc}`,
      method: "GET",
    },
    {
      manual: false,
    }
  );

  dataMerchandise?.toppings?.sort((a: GrabbiTopping, b: GrabbiTopping) =>
    a.toppingUpc.label.localeCompare(b.toppingUpc.label)
  );

  const [
    {
      data: dataAllMerchandise,
      loading: loadingAllMerchandise,
      error: errorAllMerchandise,
    },
    getAllMerchandise,
  ] = useAxios<GrabbiMerchandise[]>(
    {
      url: `restful-services/merchandise/${storeId}`,
      method: "GET",
    },
    { manual: false }
  );

  const [initCategories, setInitCategories] = useState(true);
  const [menuCategories, setMenuCategories] = useState<string[]>();

  useEffect(() => {
    if (dataAllMerchandise && initCategories) {
      const newArr = menuCategories ?? [];
      for (const merch of dataAllMerchandise) {
        const cat = merch.menuCategory;
        if (cat && !newArr.includes(cat)) {
          newArr.push(cat);
        }
      }
      setInitCategories(false);
      setMenuCategories([...newArr]);
    }
  }, [
    dataMerchandise,
    dataAllMerchandise,
    initCategories,
    menuCategories,
    setMenuCategories,
  ]);

  const sortMerchandise = (merchandise: GrabbiMerchandise[]) => {
    return merchandise.sort((a: GrabbiMerchandise, b: GrabbiMerchandise) => {
      return (
        `${a?.createdDate ?? "0"}`.localeCompare(`${b?.createdDate ?? "0"}`) *
        -1
      );
    });
  };

  const dataAllMerchandiseSorted = dataAllMerchandise
    ? sortMerchandise(dataAllMerchandise)
    : undefined;

  const hasToppings =
    dataMerchandise?.toppings && dataMerchandise.toppings.length > 0;

  const availableToppings = hasToppings
    ? dataAllMerchandiseSorted?.filter(
        (row) =>
          dataMerchandise?.toppings.findIndex(
            (topping) => topping.toppingUpc.upcNumber === row.upc.upcNumber
          ) === -1
      )
    : dataAllMerchandiseSorted;

  const [
    {
      data: dataUpdateMerch,
      loading: loadingUpdateMerch,
      error: errorUpdateMerch,
    },
    updateMerchandise,
  ] = useAxios<GrabbiMerchandise>({
    url: `restful-services/merchandise/update`,
    method: "POST",
  });

  const [
    {
      data: dataCategories,
      loading: loadingCategories,
      error: errorCategories,
    },
  ] = useAxios<MerchandiseCategory[]>(
    {
      url: `restful-services/public/merchandiseCategories`,
      method: "GET",
    },
    {
      manual: false,
    }
  );

  const [
    {
      data: dataUpdateTopping,
      loading: loadingUpdateTopping,
      error: errorUpdatingTopping,
    },
    updateTopping,
  ] = useAxios<GrabbiTopping>({
    url: `restful-services/merchandise/topping`,
    method: "POST",
  });

  const handleUpdateMerchandiseOrTopping = async (
    payload: CreateGrabbiMerchandisePayload | CreateGrabbiToppingPayload
  ) => {
    if (payload.upc) {
      await updateMerchandise({
        data: payload,
      });
      handleCloseMerchForm();
    } else {
      await updateTopping({
        data: payload,
      });
    }
    await getMerchandise();
    setSelectedToppingIndex(undefined);
    setAddTopping(undefined);
  };

  const handleOpenToppingForm = (merch: GrabbiMerchandise) => {
    const payload: GrabbiTopping = {
      toppingUpc: {
        description: merch.upc.description,
        label: merch.upc.label,
        merchandiseCategory: merch.upc.merchandiseCategory,
        upcNumber: merch.upc.upcNumber,
      },
      available: merch.available,
      merchandiseId: merch.id,
      price: merch.price,
      taxAmount: merch.taxAmount,
      createdDate: merch.createdDate ?? 0,
      updatedDate: merch.updatedDate ?? 0,
      menuItemType: 1,
      upc: undefined,
      ranking: 0,
    };
    setAddTopping(payload);
  };

  const handleCloseToppingForm = () => {
    setAddTopping(undefined);
    setEditTopping(undefined);
  };

  const handleCloseMerchForm = () => {
    history.push(`${CONTEXT}menu/${storeIdParam}`);
  };

  useEffect(() => {
    if (dataUpdateTopping) {
      setEditTopping(undefined);
      enqueueSnackbar("Topping updated", { variant: "success" });
    } else if (errorUpdatingTopping) {
      enqueueSnackbar(errorUpdatingTopping.message, { variant: "error" });
    }
  }, [dataUpdateTopping, errorUpdatingTopping, enqueueSnackbar]);

  useEffect(() => {
    if (dataUpdateMerch) {
      enqueueSnackbar("Merchandise updated", { variant: "success" });
    } else if (errorUpdateMerch) {
      enqueueSnackbar(errorUpdateMerch.message, { variant: "error" });
    }
  }, [dataUpdateMerch, errorUpdateMerch, enqueueSnackbar]);

  useEffect(() => {
    if (errorCategories) {
      enqueueSnackbar(errorCategories, { variant: "error" });
    }
    if (errorMerchandise) {
      enqueueSnackbar(errorMerchandise, { variant: "error" });
    }
    if (errorAllMerchandise) {
      enqueueSnackbar(errorAllMerchandise, { variant: "error" });
    }
  }, [errorCategories, errorMerchandise, errorAllMerchandise, enqueueSnackbar]);

  const handleStatusChange =
    (topping: GrabbiTopping, index: number) =>
    async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setUpdatingRows([...updatingRows, index]);
      const payload: GrabbiTopping = {
        ...topping,
        available: !topping.available,
      };
      await updateTopping({
        data: payload,
      });
      await getMerchandise();
      setUpdatingRows(updatingRows.splice(-1, 1));
    };

  const columns: GrabbiTableColumn[] = [
    new GrabbiTableColumn(<div className="checkmark_header" />, [], {
      mobilePlacement: "summary",
      render: (item: GrabbiTopping, index: number) => {
        return (
          <Checkbox
            checked={item.available}
            onChange={handleStatusChange(item, index)}
            classes={{
              root: "checkbox",
            }}
          />
        );
      },
    }),
    new GrabbiTableColumn("Add-On Name", [], {
      mobilePlacement: "summary",
      render: (item: GrabbiTopping) => item.toppingUpc.label,
    }),
    new GrabbiTableColumn("", [], {
      mobilePlacement: "summary",
      render: (item: GrabbiTopping) => (
        <GrabbiButton
          onClick={() => {
            setEditTopping(item);
          }}
        >
          Edit
        </GrabbiButton>
      ),
    }),
    new GrabbiTableColumn("", [], {
      mobilePlacement: "summary",
      render: (item: GrabbiTopping) => (
        <GrabbiButton
          onClick={() => {
            setDeleteTopping(item);
          }}
        >
          Delete
        </GrabbiButton>
      ),
    }),
  ];

  const handleCreateNewAddon = async (payload?: GrabbiMerchandise) => {
    if (payload?.upc) {
      //const {id, menuCategory, ...otherPayloadProps } = payload;
      // const toppingPayload: CreateGrabbiToppingPayload = {
      //   ...otherPayloadProps,
      //   toppingUpc: {
      //     upcNumber: `${payload.upc.upcNumber}`,
      //     label: payload.upc.label,
      //     description: payload.upc.description,
      //     merchandiseCategory: payload.upc.merchandiseCategory,
      //   },
      //   available: payload.available,
      //   merchandiseId: payload.id,
      //   price: `${payload.price}`,
      //   menuItemType: payload.menuItemType,
      //   storeId,
      //   ranking: 0,
      //   upc: undefined,
      // };
      // await updateTopping({
      //   data: toppingPayload,
      // });
      //await getMerchandise();
      await getAllMerchandise();
      setSelectedToppingIndex(undefined);
      setAddTopping(undefined);
      // if (payload) handleOpenToppingForm(payload);
    }
  };

  const loading =
    (loadingMerchandise || loadingCategories || loadingAllMerchandise) &&
    updatingRows.length === 0;
  const actionLoading = loadingUpdateMerch;
  const toppingActionLoading = loadingUpdateTopping;

  return (
    <div className={`edit_merchandise_root ${className ?? ""}`}>
      <Grid container xs={12}>
        <Grid item xs={12}>
          <Typography className="title">{`Update Merchandise`}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {dataMerchandise && dataCategories && menuCategories && (
            <MerchandiseForm
              merchandise={dataMerchandise}
              menuCategories={menuCategories}
              dataCategories={dataCategories}
              actionLoading={actionLoading}
              handlePayload={handleUpdateMerchandiseOrTopping}
              handleCancel={handleCloseMerchForm}
            />
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          {availableToppings && availableToppings.length !== 0 && (
            <div className="toppings_menu">
              <div className="subtitle">Add-On</div>
              {!loadingUpdateTopping && (
                <div className="add_menu">
                  <GrabbiDropdown
                    disabled={addTopping !== undefined}
                    className="dropdown"
                    placeholder="Select existing Add-On"
                    selectedIndex={selectedToppingIndex}
                    handleChange={(index) => {
                      if (availableToppings) {
                        handleOpenToppingForm(availableToppings[index]);
                      }
                    }}
                    options={availableToppings.map((row) => row.upc.label)}
                  />
                  <GrabbiButton
                    style={{ width: 250 }}
                    disabled={addTopping !== undefined}
                    onClick={() => setCreateNew(true)}
                  >
                    Create Add-On
                  </GrabbiButton>
                </div>
              )}

              {addTopping && dataCategories && (
                <MerchandiseForm
                  merchandise={dataMerchandise}
                  topping={addTopping}
                  dataCategories={dataCategories}
                  handlePayload={handleUpdateMerchandiseOrTopping}
                  handleCancel={handleCloseToppingForm}
                  actionLoading={toppingActionLoading}
                  actionText="Assign Add-On"
                />
              )}

              {editTopping && dataMerchandise && dataCategories && (
                <MerchandiseForm
                  merchandise={dataMerchandise}
                  topping={editTopping}
                  dataCategories={dataCategories}
                  handlePayload={handleUpdateMerchandiseOrTopping}
                  handleCancel={handleCloseToppingForm}
                  actionLoading={toppingActionLoading}
                />
              )}
            </div>
          )}

          {hasToppings && dataMerchandise?.toppings && !editTopping && (
            <React.Fragment>
              <GrabbiTable
                className={`${loading || actionLoading ? "refresh_state" : ""}`}
                columns={columns}
                data={dataMerchandise.toppings}
                rowOverride={(key: string) => (
                  <UpdatingRow uniqueKey={key} text="Updating Topping" />
                )}
                overrideRows={updatingRows}
              />
            </React.Fragment>
          )}
        </Grid>
      </Grid>
      <Grid container justify="center" className="loading_icon_area">
        <Grid item>{loading && <LoadingIcon />}</Grid>
      </Grid>
      {createNew && dataMerchandise && (
        <CreateDeleteMerchandiseDialog
          callback={handleCreateNewAddon}
          onClose={() => setCreateNew(false)}
          open={createNew}
          merchandise={undefined}
          isTopping={true}
          mode={"ADD"}
        />
      )}
      {deleteTopping && dataMerchandise && (
        <CreateDeleteMerchandiseDialog
          callback={() => {}}
          onClose={() => {
            setDeleteTopping(undefined);
            getMerchandise();
          }}
          open={deleteTopping !== undefined}
          merchandise={dataMerchandise}
          topping={deleteTopping}
          isTopping={true}
          mode={"DELETE"}
        />
      )}
    </div>
  );
};

export default EditMerchandise;

import React from "react";
import "./SelectionStateChipComponent.scss";
import { formatCurrency } from "shared/scripts/Utils";

interface Props {
  className?: string;
  quantity: number;
  total: number;
  currency: string;
  onClick?: () => void;
}

const SelectionStateChip: React.FC<Props> = ({
  className,
  quantity,
  total,
  currency,
  onClick,
}) => {
  return (
    <div
      className={`selection_state_chip_root ${className ?? ""}`}
      onClick={onClick}
      style={{ cursor: onClick ? "pointer" : "default" }}
    >
      <div className="quantity_container">{quantity}</div>
      <div className="total">{formatCurrency(total, currency)}</div>
    </div>
  );
};

export default SelectionStateChip;

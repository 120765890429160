import { actionIds, BaseAction } from "actions";
import { fetchMembership } from "api/membership";
import { Dispatch } from "redux";
import { deleteCookie, getCookie, getMembershipEmailCookieKey, setCookie } from "shared/scripts/Utils";
import { MembershipAction } from "store/customer/customerReducer";

export const getMembershipCookieAction = (storeId: number) => (dispatch: Dispatch) => {
  dispatch({
    type: actionIds.GET_MEMBERSHIP_COOKIE,
  });
  const emailCookieKey = getMembershipEmailCookieKey(storeId);
  const email = getCookie(emailCookieKey);
  return fetchMembership(storeId, email)
    .then((res) => { 
      if (res.externalCustomerId != null) {
        setCookie(emailCookieKey, email);
        dispatch(
          getMembershipSuccessAction({
            hasMembership: true,
            externalCustomerId: res.externalCustomerId,
            membershipMenuId: res.menu?.id,
            membershipType: res.description,
            memberEmail: email,
          })
        );
      } else {
        deleteCookie(emailCookieKey);
        dispatch(
          getMembershipFailedAction({
            hasMembership: false,
            error: "Email not found.",
          })
        );
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        getMembershipFailedAction({
          hasMembership: false,
          error: "Failed to check membership.",
        })
      );
    });
};

export const setMembershipCheckedAction = (): BaseAction => {
  return {
    type: actionIds.SET_MEMBERSHIP_CHECKED,
  };
};

export const getMembershipStartAction =
  (storeId: number, email: string) => (dispatch: Dispatch) => {
    dispatch({
      type: actionIds.GET_MEMBERSHIP_START,
    });
    return fetchMembership(storeId, email)
      .then((res) => {
        if (res.externalCustomerId != null) {
          setCookie(getMembershipEmailCookieKey(storeId), email);
          dispatch(
            getMembershipSuccessAction({
              hasMembership: true,
              externalCustomerId: res.externalCustomerId,
              membershipMenuId: res.menu?.id,
              membershipType: res.description,
              memberEmail: email,
            })
          );
        } else {
          dispatch(
            getMembershipFailedAction({
              hasMembership: false,
              error: "Email not found.",
            })
          );
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch(
          getMembershipFailedAction({
            hasMembership: false,
            error: "Failed to check membership.",
          })
        );
      });
  };

export const getMembershipSuccessAction = (
  payload: MembershipAction
): BaseAction => {
  return {
    type: actionIds.GET_MEMBERSHIP_SUCCESS,
    payload,
  };
};

export const getMembershipFailedAction = (
  payload: MembershipAction
): BaseAction => {
  return {
    type: actionIds.GET_MEMBERSHIP_FAILED,
    payload,
  };
};

import { flatten, sumBy, values } from "lodash";
import { CartItemEntry } from "../MerchandiseSelectorComponent";

export interface GroupingParams {
  groupCount: number;
  groupRequired: boolean;
  groupNumber: number;
}

export const decodeGroupingParams = (grouping: string): GroupingParams => {
  const groupingRegex = /(A|B|C)(Z?)([0-9]+)/g;
  const match = groupingRegex.exec(grouping.toUpperCase());
  if (!match) {
    throw new Error(
      `[ERROR] Invalid grouping string - Regex: $${groupingRegex}`
    );
  } else {
    const groupCount = match[1].charCodeAt(0) - 64;
    const groupRequired = match[2] === "Z";
    const groupNumber = parseInt(match[match.length - 1]);
    return {
      groupCount,
      groupRequired,
      groupNumber,
    };
  }
};

export const encodeGroupParams = (params: GroupingParams) => {
  const { groupCount, groupNumber, groupRequired } = params;
  return `${String.fromCharCode(groupCount + 64)}${
    groupRequired ? "Z" : ""
  }${groupNumber}`;
};

export const getDependentMerchandisePrice = (item: CartItemEntry) => {
  const { merchandise, groupingState, quantity } = item;
  const { dependant, price } = merchandise;
  const checkedToppingTotal = sumBy(
    flatten(
      values(groupingState).map((group) =>
        values(group.toppingStates)
          .filter((topping) => topping.checked)
          .map((topping) => topping.topping)
      )
    ),
    "price"
  );
  return (
    (dependant ? checkedToppingTotal : price + checkedToppingTotal) * quantity
  );
};

export const getDependentMerchandiseTaxAmount = (item: CartItemEntry) => {
  const { merchandise, groupingState, quantity } = item;
  const { dependant, taxAmount } = merchandise;
  const checkedToppingTotal = sumBy(
    flatten(
      values(groupingState).map((group) =>
        values(group.toppingStates)
          .filter((topping) => topping.checked)
          .map((topping) => topping.topping)
      )
    ),
    "taxAmount"
  );
  return (
    (dependant ? checkedToppingTotal : taxAmount + checkedToppingTotal) *
    quantity
  );
};

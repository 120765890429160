import { Box, Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import "./PrinterSetupComponent.scss";
import { useSnackbar } from "notistack";
import {
  GrabbiPrinter,
  GrabbiPrinterSetupPayload,
  GrabbiPrinterWithLocationArray,
  GrabbiStore,
  SearchPrinterPayload,
} from "types/GrabbiTypes";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";
import { useAxiosContext } from "components/Shared/AxiosProvider/AxiosProvider";
import { LoginState } from "store/signin/loginReducer";
import { createQueryString } from "actions/api";
import GrabbiMultiSelectDropdown from "components/Shared/GrabbiMultiSelectDropdown/GrabbiMultiSelectDropdown";
import { printersLocationBasedOnId, printersLocationIdBasedOnNameStr } from "shared/scripts/Constants";

interface Props {
  stores?: GrabbiStore[];
  loadingStores: boolean;
  loginState: LoginState;
  dispatchGetStores: (id: number) => void;
}

const PrinterSetupComponent: React.FC<Props> = ({
  stores,
  loadingStores,
  loginState,
  dispatchGetStores,
}) => {
  const [searchForPrintersClicked, setSearchForPrintersClicked] =
    useState(false);
 const [savePrintersLocationsClicked, setSavePrintersLocationsClicked] =
  useState(false);
  const [savePrintersLocations, setSavePrintersLocations] = useState(false);
  const [selectedStoreId, setSelectedStoreId] = useState(0);
  const [fieldsInitialized, setFieldsInitialized] = useState(false);
  const [flagForGettingTheListOfPrinters, setFlagForGettingTheListOfPrinters] =
    useState(false);
  const [checkoutUrl, setCheckoutUrl] = useState<string>();
  const [store, setSore] = useState<GrabbiStore>();
  const [printerLocationsMap, setPrinterLocationsMap] = useState(new Map());
  const [
    printersLocationsInitializedFalg,
    setPrintersLocationsInitializedFalg,
  ] = useState<boolean>(false);
  const { token } = useAxiosContext();
  const { useAxios } = useAxiosContext();
  const { enqueueSnackbar } = useSnackbar();

  if (!stores && !loadingStores && loginState) {
    dispatchGetStores(Number(loginState.merchantId));
  }

  const selectedStore =
    selectedStoreId !== undefined ? stores?.[selectedStoreId] : undefined;
  const initializeStoreFields = useCallback(() => {
    if (selectedStore !== undefined) {
      setSelectedStoreId(selectedStore.id);
      setSore(selectedStore);
    }
    setPrintersLocationsInitializedFalg(false);
    setFieldsInitialized(true);
  }, [selectedStore, setFieldsInitialized]);

  const [{ data: storePrinters }, getStorePrinters] = useAxios<GrabbiPrinter[]>(
    {
      url: `/restful-services/store/${selectedStoreId}/printers`,
      method: "GET",
    }
  );

  const [
    { data: updateListOfPrinters, error: errorListOfPrinters },
    updatePrintersLocations,
  ] = useAxios({
    url: `restful-services/store/printerSettings`,
    method: "POST",
  });

  useEffect(() => {
    if (updateListOfPrinters) {
      enqueueSnackbar("Update completed", { variant: "success" });
    } else if (errorListOfPrinters) {
      enqueueSnackbar("Update failed", { variant: "error" });
    }
  }, [updateListOfPrinters, errorListOfPrinters, enqueueSnackbar]);

  useEffect(() => {
    if (!fieldsInitialized) {
      initializeStoreFields();
    }

    if (!storePrinters && selectedStoreId !== 0) {
      getStorePrinters();
    }

    const paramsString: SearchPrinterPayload = {
      command: "searchPrinters",
      storeId: selectedStoreId ?? 0,
      callbackURL: "https://dev.paygex.com",
      token,
    };
    const queryString = createQueryString(paramsString);
    setCheckoutUrl(`posterminal://pos.paygex.com/pay${queryString}`);
  }, [
    stores,
    fieldsInitialized,
    selectedStoreId,
    initializeStoreFields,
    token,
    store,
    storePrinters,
    getStorePrinters,
  ]);

  useEffect(() => {
    if (
      storePrinters &&
      storePrinters.length > 0 &&
      !printersLocationsInitializedFalg
    ) {
      printerLocationsMap.clear();
      for (const printerItem of storePrinters) {
        let locationArray: any = [];
        const printerObject: GrabbiPrinterWithLocationArray = {
          id: printerItem.id ? printerItem.id : 0,
          macAddress: printerItem.macAddress,
          model: printerItem.model,
          brand: printerItem.brand ? printerItem.brand : "",
          interfaceType: printerItem.interfaceType,
          ipAddress: printerItem.ipAddress,
          serialNumber: printerItem.serialNumber
            ? printerItem.serialNumber
            : "",
          active: printerItem.active ? printerItem.active : 0,
          location: locationArray,
        };
        if (printerLocationsMap.get(printerItem.serialNumber)) {
          let copyFromExistingPrinterObject: GrabbiPrinterWithLocationArray = {
            ...printerLocationsMap.get(printerItem.serialNumber),
          };
          let newLocation = printersLocationBasedOnId.get(
            printerItem.location ? Number(printerItem.location) : 0
          );
          copyFromExistingPrinterObject.location?.push(newLocation);
          setPrinterLocationsMap(
            new Map(
              printerLocationsMap.set(
                printerItem.serialNumber,
                copyFromExistingPrinterObject
              )
            )
          );
        } else {
          let copyFromPrinterObject = { ...printerObject };
          copyFromPrinterObject.location?.push(
            printersLocationBasedOnId.get(Number(printerItem.location))
          );
          setPrinterLocationsMap(
            new Map(
              printerLocationsMap.set(
                printerItem.serialNumber,
                copyFromPrinterObject
              )
            )
          );
        }
        locationArray = [];
      }
      setPrintersLocationsInitializedFalg(true);
    }
  }, [printerLocationsMap, printersLocationsInitializedFalg, storePrinters]);

  const searchForPrinters = () => {
    setSearchForPrintersClicked(true);
    setTimeout(async () => {
      await getStorePrinters();
      setFlagForGettingTheListOfPrinters(!flagForGettingTheListOfPrinters);
    }, 8000);
  };

  const requestToSavePrintersLocations = async () => {
    setSavePrintersLocationsClicked(true);
    let printerPayLoad: Array<GrabbiPrinterSetupPayload> = [];

    printerLocationsMap.forEach((printerObj: any) => {
      if (printerObj.location.length === 0) {
        if (storePrinters) {
          for (let printerFromDB of storePrinters) {
            const payload: GrabbiPrinterSetupPayload = {
              store: {
                id: selectedStoreId,
              },
              macAddress: printerObj.macAddress,
              model: printerObj.model,
              brand: printerObj.brand ? printerObj.brand : "",
              interfaceType: printerObj.interfaceType,
              ipAddress: printerObj.ipAddress,
              serialNumber: printerObj.serialNumber ? printerObj.serialNumber : "",
              active: printerObj.active > 0 ? printerObj.active : 0,
              location: null,
            };

            if (storePrinters.some(printerItem => printerItem.serialNumber === payload.serialNumber
              && printerFromDB.location !== null)) {
              payload.id = printerFromDB.id;
              printerPayLoad.push(payload);
            }
          }
        }
      } else {
        for (let location of printerObj.location) {
          let locationId = printersLocationIdBasedOnNameStr.get(location);

          if(storePrinters) {
            const searchIndex = storePrinters.findIndex((printer) => printer.location === locationId && printer.serialNumber === printerObj.serialNumber);
            if(searchIndex === -1) {
              const payload: GrabbiPrinterSetupPayload = {
                id: null,
                store: {
                  id: selectedStoreId,
                },
                macAddress: printerObj.macAddress,
                model: printerObj.model,
                brand: printerObj.brand ? printerObj.brand : "",
                interfaceType: printerObj.interfaceType,
                ipAddress: printerObj.ipAddress,
                serialNumber: printerObj.serialNumber ? printerObj.serialNumber : "",
                location: locationId,
                active: 1,
              };
              printerPayLoad.push(payload);
            }
          }
        }

        if (storePrinters) {
          for (let printerFromDB of storePrinters) {
            if (printerFromDB.serialNumber === printerObj.serialNumber) {
              let dbLocationStr;
              if( printerFromDB.location !== undefined ) {
                dbLocationStr = printersLocationBasedOnId.get(
                  printerFromDB.location || printerFromDB.location === 0 ? printerFromDB.location : 3)
              }
              if (printerObj.location.indexOf(dbLocationStr) !== -1) {
                const payload: GrabbiPrinterSetupPayload = {
                  id: printerFromDB.id,
                  store: {
                    id: selectedStoreId,
                  },
                  macAddress: printerFromDB.macAddress,
                  model: printerFromDB.model,
                  brand: printerFromDB.brand ? printerObj.brand : "",
                  interfaceType: printerFromDB.interfaceType,
                  ipAddress: printerFromDB.ipAddress,
                  serialNumber: printerFromDB.serialNumber ? printerObj.serialNumber : "",
                  location: printerFromDB.location,
                  active: 1,
                };
                printerPayLoad.push(payload);
              }else {
                const inActivePayload: GrabbiPrinterSetupPayload = {
                  id: printerFromDB.id,
                  store: {
                    id: selectedStoreId,
                  },
                  macAddress: printerFromDB.macAddress,
                  model: printerFromDB.model,
                  brand: printerFromDB.brand ? printerObj.brand : "",
                  interfaceType: printerFromDB.interfaceType,
                  ipAddress: printerFromDB.ipAddress,
                  serialNumber: printerFromDB.serialNumber ? printerObj.serialNumber : "",
                  location: printerFromDB.location,
                  active: 0,
                };
                printerPayLoad.push(inActivePayload);
              }
            }
          }
        }
      }
    });
    await updatePrintersLocations({
      data: printerPayLoad,
    });
    setSavePrintersLocations(true);
  };

  return (
    <React.Fragment>
      <Grid
        item
        container
        className="edit_profile_printer_setup_content"
        direction="column"
        alignItems="center"
      >
        {printerLocationsMap.size > 0 && (
          <div>
            <Box className="setup_printer_header_text">
              Printers Found &#40;{printerLocationsMap.size}&#41;
            </Box>
          </div>
        )}
        <div className="setup_printer_header_section_divider">.</div>

        {Array.from(printerLocationsMap.entries()).map(([key, item]) => (
          <div>
            <div className="columns">
              <div className="left_column">
                <p
                  data-id={item.id}
                  data-macAddres={item.macAddress}
                  data-model={item.model}
                  data-brand={item.brand}
                  data-interfaceType={item.interfaceType}
                  data-ipAddress={item.ipAddress}
                  data-serialNumber={item.serialNumber}
                  data-active={item.active}
                  data-location={item.location}
                  data-createdDate={item.createdDate}
                  data-updatedDate={item.updatedDate}
                  data-errorResponse={item.errorResponse}
                >
                  {item.ipAddress}
                </p>
              </div>
              <div className="right_column">
                <GrabbiMultiSelectDropdown
                  id={`printerLocation-${key}`}
                  handleChange={(dropDownValue: any) => {
                    const updatedValue = dropDownValue.filter((value: string) => {
                      return value;
                    });
                    item.location = [];
                    item.location = [...updatedValue];
                    item.active = (updatedValue.length !== 0 ? 1 : 0);
                    setPrinterLocationsMap(
                      new Map(printerLocationsMap.set(item.serialNumber, item))
                    );
                  }}
                  label="Select the location"
                  options={["Kitchen", "Bar", "Customer Reciept"]}
                  selectedValues={item.location.filter((value: string) => value !== '')}
                />
              </div>
            </div>
            <div className="setup_printer_row_section_divider">.</div>
          </div>
        ))}
        {/* {listOfPrinters && printerLocationsMap.size === 0 ? ( */}
          <a
            href={checkoutUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="action_button"
            aria-disabled={!checkoutUrl}
          >
            <GrabbiButton
              type="submit"
              classes={{
                root: "edit_profile_button",
              }}
              onClick={searchForPrinters}
            >
              {searchForPrintersClicked && !flagForGettingTheListOfPrinters ? (
                <LoadingIcon />
              ) : (
                "SEARCH FOR PRINTERS"
              )}
              {/* SEARCH FOR PRINTERS */}
            </GrabbiButton>
          </a>
        {/* ) : ( */}
          <div>
            <GrabbiButton
              className="save_action_button"
              onClick={requestToSavePrintersLocations}
            >
              {savePrintersLocationsClicked && !savePrintersLocations ? <LoadingIcon /> : "SAVE"}
              {/* SAVE PRINTERS LOCATIONS*/}
            </GrabbiButton>
          </div>
        {/* )} */}
      </Grid>
      {/* </form> */}
    </React.Fragment>
  );
};

export default PrinterSetupComponent;

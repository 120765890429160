import React, { useState } from "react";
import "./PaymentSuccessComponent.scss";
import { GrabbiStore, Transaction } from "types/GrabbiTypes";
import MyTab from "components/CustomerOrder/MyTab/MyTabComponent";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import EmailReceipt from "components/CustomerOrder/EmailReceipt/EmailReceiptComponent";

interface Props {
  className?: string;
  transaction: Transaction;
  store: GrabbiStore;
  onDone: () => void;
}

const PaymentSuccessComponent: React.FC<Props> = ({
  className,
  transaction,
  store,
  onDone,
}) => {
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [showTab, setShowTab] = useState(false);

  const toggleShowTab = () => {
    setShowTab((showTab) => !showTab);
  };

  const toggleEmailForm = () => {
    setShowEmailForm((showEmail) => !showEmail);
  };

  //TODO: Clear the localStorage transaction ID here? Unless we want user to always click "Done" first

  return (
    <div className={`payment_success_root ${className ?? ""}`}>
      <div className="header">
        <span className="store_title">{store?.name}</span>
        {!showTab && <GrabbiButton onClick={onDone}>Done</GrabbiButton>}
        {showTab && <GrabbiButton onClick={toggleShowTab}>Back</GrabbiButton>}
      </div>
      {showTab && (
        <div className="content">
          <MyTab transaction={transaction} />
        </div>
      )}
      {showEmailForm && (
        <div className="content">
          <EmailReceipt
            transaction={transaction}
            store={store}
            onSuccess={toggleEmailForm}
          />
        </div>
      )}
      {!showTab && !showEmailForm && (
        <div className="content">
          <div className="paid_card">
            <div className="left">
              <p className="paid">PAID</p>
              <p className="thank_you">Thank you for your order.</p>
            </div>
            <div className="right">
              <div
                className="view_order"
                style={{ backgroundColor: transaction.orderCodeColor }}
                onClick={toggleShowTab}
              >
                <span className="order_code">{transaction.orderCode}</span>
                <span className="view_order_text">View Order</span>
              </div>
            </div>
          </div>
          <div className="email_card">
            <p className="email_text">Email Receipt</p>
            <p className="description_text">
              We will send you an email with a receipt of your purchase
            </p>
            <GrabbiButton
              type="submit"
              colorVariant="black"
              onClick={toggleEmailForm}
            >
              GET RECEIPT
            </GrabbiButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentSuccessComponent;

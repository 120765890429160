import {
  call,
  put,
  all,
  takeEvery,
  fork,
  takeLeading,
  select,
  cancel,
} from "redux-saga/effects";
import {
  actionIds,
  addStoreStartAction,
  addStoreCompleteAction,
  addStoreFailedAction,
  getStoreListCompletedAction,
  setStoreStatusCompletedAction,
  getStoreListStartAction,
  setLoginStatusCompletedAction,
  setLoginStatusFailedAction,
  setLogoutStatusCompletedAction,
  getStoreByUpcCompletedAction,
  getStoreTransactionsCompletedAction,
  getMerchantCompletedAction,
  checkSessionFailedAction,
  checkSessionSuccessAction,
  getMerchantStartAction,
  getCountriesCompletedAction,
  getCountriesFailedAction,
  getStatesCompletedAction,
  getStatesFailedAction,
  updatePersonalDetailsComplete,
  updatePersonalDetailsFailed,
  updateStoreDetailsComplete,
  updateStoreDetailsFailed,
  adminGetAllMerchantsComplete,
  adminGetAllMerchantsFailed,
  adminGetAllStoresComplete,
  adminGetAllStoresFailed,
  signUpStartAction,
  signUpComplete,
  signUpFailed,
  setStoreStatusFailedAction,
  creatingLeadForMarketingStartAction,
  creatingLeadForMarketingComplete,
  creatingLeadForMarketingFailed,
  changePasswordCompleteAction,
  changePasswordFailedAction,
  sendRequestToResetPasswordFailedAction,
  sendRequestToResetPasswordCompleteAction,
  checkSessionSkippedAction,
  googleLoginCompleteAction,
  googleLoginFailedAction,
  appleLoginCompleteAction,
  appleLoginFailedAction,
  getStoreByUpcFailedAction,
  confirmMerchantSuccess,
  confirmMerchantFailed,
  resendConfirmMerchantSuccess,
  resendConfirmMerchantFailed,
  updateTransactionStatusSuccessAction,
  updateTransactionStatusFailedAction,
  clearStatusAction,
  getMerchandiseCompleteAction,
  getMerchandiseFailedAction,
  updateMerchandiseCompleteAction,
  updateMerchandiseFailedAction,
  createMerchandiseCompleteAction,
  createMerchandiseFailedAction,
  // deleteMerchandiseCompleteAction,
  // deleteMerchandiseFailedAction,
  getMerchandiseCategoriesCompleteAction,
  getMerchandiseCategoriesFailedAction,
  refundTransactionCompletedAction,
  refundTransactionFailedAction,
  UpdateTransactionStatusPayload,
  getAllTransactionsCompleteAction,
  getStoreTransactionsFailedAction,
  UpdateToppingPayload,
  updateToppingCompleteAction,
  updateToppingFailedAction,
  creatingLeadFromStarterKitStartAction,
  creatingLeadFromStarterKitComplete,
  creatingLeadFromStarterKitFailed,
} from "actions";
import {
  login,
  clearTokens,
  getCookies,
  jwtTokens,
  changePassword,
  googleLogin,
  appleLogin,
  confirmMerchant,
  resendConfirmMerchant,
} from "actions/login";
import {
  getAllMerchantStores,
  toggleStoreStatus,
  getStore,
  updateStore,
} from "actions/store";
import {
  getMerchant,
  updateMerchant,
  signUpMerchant,
  merchantResetPassword,
  createLead,
} from "actions/merchant";
import { getCountries, getStateCodes } from "actions/country-state";
import {
  CreateGrabbiMerchandisePayload,
  GrabbiStore,
  Transaction,
} from "types/GrabbiTypes";
import { getAllMerchants, getAllStores } from "actions/admin";
import { addStore } from "actions/store";
import {
  createMerchandise,
  // deleteMerchandise,
  // DeleteMerchandisePayload,
  getMerchandiseCategories,
  updateTopping,
} from "actions/merchandise";
import { getMerchandise, updateMerchandise } from "actions/merchandise";
import {
  getStoreTransactions,
  updateTransactionStatus,
  TransactionRefundPayload,
  refundTransaction,
} from "actions/transactions";

//? [REVISIT] I've assigned the any type to all the generator functions and untyped variables in saga.ts since it's going to eventually get replaced with GraphQL anyways

export function* requestStoreList(action: any): Generator<any> {
  const storeList: any = yield call(getAllMerchantStores, action.payload.id);
  yield put(getStoreListCompletedAction(storeList));
}

export function* toggleStoreStatusStart(action: any): Generator<any> {
  const state: any = yield select();
  const storeList: GrabbiStore[] = state.adminState.stores;
  const storeIndex = storeList.findIndex(
    (store: GrabbiStore) => action.payload.id === store.id
  );

  const store = storeList[storeIndex];
  const completed: any = yield call(
    toggleStoreStatus,
    action.payload.id,
    store.active ? store.active : false
  );
  if (completed) {
    store.active = !store.active;
    yield put(setStoreStatusCompletedAction(completed, storeList));
  } else {
    yield put(setStoreStatusFailedAction());
  }
}

export function* addStoreStart(action: any): Generator<any> {
  const response: any = yield call(addStore, action.payload.store);

  if (response && response.status === 200) {
    const state: any = yield select();
    const merchantId = state.merchantStores.merchant.id ?? undefined;
    if (merchantId) yield put(getStoreListStartAction(merchantId));
    yield put(addStoreCompleteAction(response));
    yield put(addStoreStartAction(action.payload));
  } else {
    yield put(addStoreFailedAction(response.data.message));
  }
}

export function* watchAddStoreStart(): Generator<any> {
  yield takeLeading(actionIds.ADD_STORE_START, addStoreStart);
}

export function* requestLogin(action: any): Generator<any> {
  const response: any = yield call(
    login,
    {
      username: action.payload.username,
      password: action.payload.password,
    },
    action.payload.admin
  );

  if (response.status && response.loggedIn) {
    yield put(setLoginStatusCompletedAction(response));
  } else if (response.status && response.error) {
    yield put(
      setLoginStatusFailedAction({
        ...response.error.response.data,
        response,
        status: response.status,
      })
    );
  }
}

export function* requestLogout(action: any): Generator<any> {
  const completed: any = yield call(clearTokens);
  yield put(setLogoutStatusCompletedAction(completed));
}

export function* sendRequestToResetPasswordStart(action: any): Generator<any> {
  const response: any = yield call(merchantResetPassword, action.payload);
  if (response && response.data && response.status === 200) {
    yield put(sendRequestToResetPasswordCompleteAction(response.data.reason));
  } else {
    yield put(sendRequestToResetPasswordFailedAction(response.data.message));
  }
}

export function* getStoreByUpc(action: any): Generator<any> {
  const response: any = yield call(getStore, action.payload.upc);
  if (response.error) {
    yield put(getStoreByUpcFailedAction(response));
  } else {
    yield put(getStoreByUpcCompletedAction(response));
  }
}

export function* getStoreTransactionsStart(action: any): Generator<any> {
  const isAll = action.payload?.isAll && action.payload?.apiPayload;
  if (!isAll) {
    const priorTransactionFetch: any = yield fork(
      watchGetStoreTransactionsStart
    );
    yield cancel(priorTransactionFetch);
    const response: any = yield call(getStoreTransactions, action.payload);
    if (response?.status && response?.status !== 200) {
      yield put(getStoreTransactionsFailedAction(response.data.message));
    } else {
      yield put(getStoreTransactionsCompletedAction(response));
    }
  } else {
    //? [REVISIT] Make backend filter inclusive rather than exclusive
    const completedResponse: any = yield call(getStoreTransactions, {
      ...action.payload.apiPayload,
      isFulfilled: true,
    });
    const refundedResponse: any = yield call(getStoreTransactions, {
      ...action.payload.apiPayload,
      isRefunded: true,
    });
    //? [REVISIT] Refunded totals should be set as negative on the backend
    const refundedResponseFixed = refundedResponse.map(
      (item: Transaction): Transaction => {
        return {
          ...item,
          transactionTotal: item.transactionTotal * -1,
        };
      }
    );

    if (
      (refundedResponse.status && refundedResponse.status !== 200) ||
      (completedResponse.status && completedResponse.status !== 200)
    ) {
      const errorMessage =
        refundedResponse?.data && refundedResponse?.data?.message
          ? refundedResponse.data.message
          : completedResponse.data.message;
      yield put(getStoreTransactionsFailedAction(errorMessage));
    } else {
      yield put(
        getAllTransactionsCompleteAction([
          ...completedResponse,
          ...refundedResponseFixed,
        ])
      );
    }
  }
}

export function* getMerchantStart(): Generator<any> {
  const response: any = yield call(getMerchant);
  if (response.authenticationFailed) {
    yield put(checkSessionFailedAction());
  } else {
    yield put(getMerchantCompletedAction(response));
  }
}

export function* checkSessionStart(): Generator<any> {
  const cookies: jwtTokens = getCookies();
  if (cookies.accessToken !== undefined) {
    const merchant: any = yield call(getMerchant);
    if (merchant) {
      const payload = { ...cookies, ...merchant };
      yield put(checkSessionSuccessAction(payload));
    } else {
      yield call(clearTokens);
      yield put(checkSessionFailedAction());
    }
  } else {
    yield put(checkSessionSkippedAction());
  }
}

export function* getCountriesStart(): Generator<any> {
  const response: any = yield call(getCountries);
  if (response) {
    for (var i = 0; i < response.length; i++) {
      const country = response[i];
      const stateResponse = yield call(getStateCodes, country.code);
      response[i]["states"] = stateResponse;
    }
    yield put(getCountriesCompletedAction(response));
  } else {
    yield put(getCountriesFailedAction());
  }
}

export function* getStatesStart(action: any): Generator<any> {
  const response: any = yield call(getStateCodes, action.payload.countryCode);
  if (response) {
    yield put(getStatesCompletedAction(response));
  } else {
    yield put(getStatesFailedAction());
  }
}

export function* signUpMerchantStart(action: any): Generator<any> {
  const response: any = yield call(signUpMerchant, action.payload.merchant);
  if (response && response.status === 200) {
    yield put(signUpComplete(response));
    yield put(signUpStartAction(action.payload));
  } else {
    yield put(signUpFailed(response.data.message));
  }
}

export function* updateMerchantStart(action: any): Generator<any> {
  const response: any = yield call(updateMerchant, action.payload.merchant);
  if (response.id) {
    yield put(updatePersonalDetailsComplete(response));
    yield put(getMerchantStartAction());
  } else if (response.message) {
    yield put(updatePersonalDetailsFailed(response.message));
  }
}

export function* updateStoreStart(action: any): Generator<any> {
  const store = action.payload.store;

  const response: any = yield call(updateStore, store);
  if (response && response.status === 200 && store.merchant) {
    yield put(updateStoreDetailsComplete(response));
    const merchantId = store.merchant;
    yield put(getStoreListStartAction(merchantId));
  } else {
    yield put(updateStoreDetailsFailed([response.data.message]));
  }
}

export function* startAdminGetAllMerchants(action: any): Generator<any> {
  const response: any = yield call(getAllMerchants, {
    pageNumber: action.payload.page,
    pageSize: action.payload.pageSize,
    sortBy: action.payload.sortBy,
  });
  if (response) {
    yield put(adminGetAllMerchantsComplete(response, action.payload.page));
  } else {
    yield put(adminGetAllMerchantsFailed());
  }
}

export function* startAdminGetAllStores(action: any): Generator<any> {
  const response: any = yield call(getAllStores, {
    pageNumber: action.payload.page,
    pageSize: action.payload.pageSize,
    sortBy: action.payload.sortBy,
  });
  if (response) {
    yield put(adminGetAllStoresComplete(response, action.payload.page));
  } else {
    yield put(adminGetAllStoresFailed());
  }
}

export function* creatingLeadForMarketing(action: any): Generator<any> {
  const response: any = yield call(createLead, action.payload.leadForMarketing);
  if (response && response.status === 200) {
    yield put(creatingLeadForMarketingComplete(response));
    yield put(creatingLeadForMarketingStartAction(action.payload));
  } else {
    yield put(creatingLeadForMarketingFailed(response.data.message));
  }
}

export function* startChangePassword(action: any): Generator<any> {
  const response: any = yield call(changePassword, action.payload);
  if (response && response.data && response.data.id) {
    yield put(changePasswordCompleteAction());
  } else {
    let errorMessage = response.response.data.message ?? "Unknown server error";
    if (errorMessage.includes("The old password does not match!")) {
      errorMessage = "Current password is incorrect.";
    }
    yield put(changePasswordFailedAction({ error: errorMessage }));
  }
}

export function* startGoogleLogin(action: any): Generator<any> {
  const response: any = yield call(googleLogin, action.payload.id_token);
  if (response && response.loggedIn) {
    yield put(googleLoginCompleteAction());
  } else {
    yield put(googleLoginFailedAction());
  }
}

export function* startAppleLogin(action: any): Generator<any> {
  const response: any = yield call(appleLogin, action.payload);
  if (response && response.loggedIn) {
    yield put(appleLoginCompleteAction());
  } else {
    yield put(appleLoginFailedAction());
  }
}

export function* confirmMerchantStart(action: any): Generator<any> {
  const response: any = yield call(confirmMerchant, action.payload.token);
  if (response && response.status === 200) {
    yield put(confirmMerchantSuccess());
  } else {
    yield put(confirmMerchantFailed(response.response.data.message));
  }
}

export function* resendConfirmMerchantStart(action: any): Generator<any> {
  const response: any = yield call(resendConfirmMerchant, action.payload.token);
  if (response && response.status === 200) {
    yield put(resendConfirmMerchantSuccess());
  } else {
    yield put(resendConfirmMerchantFailed(response.response.data.message));
  }
}

export function* updateTransactionStatusStart(action: {
  type: string;
  payload: UpdateTransactionStatusPayload;
}): Generator<any> {
  const payload = {
    id: action.payload.id,
    fulfilled: action.payload.fulfilled,
    store: {
      id: action.payload.refreshParams.storeId,
    },
    transactionStatus: action.payload.transactionStatus,
  };
  const response: any = yield call(updateTransactionStatus, payload);
  if (response && response.status === 200) {
    yield getStoreTransactionsStart({
      payload: action.payload.refreshParams,
    });
    yield put(
      updateTransactionStatusSuccessAction(
        action.payload.id,
        action.payload.fulfilled
      )
    );
    yield put(clearStatusAction());
  } else {
    yield put(
      updateTransactionStatusFailedAction(
        action.payload.id,
        response?.data?.message
      )
    );
    yield put(clearStatusAction());
  }
}

export function* getMerchandiseStart(action: any): Generator<any> {
  const response: any = yield call(getMerchandise, action.payload.storeId);
  if (response && response.status === 200) {
    yield put(getMerchandiseCompleteAction(response.data));
  } else {
    yield put(getMerchandiseFailedAction(response?.data?.message));
  }
}

export function* updateMerchandiseStart(action: any): Generator<any> {
  const response: any = yield call(updateMerchandise, action.payload);
  if (response && response.status === 200) {
    const getResponse: any = yield call(
      getMerchandise,
      action.payload.store.id
    );
    yield put(getMerchandiseCompleteAction(getResponse.data));
    yield put(
      updateMerchandiseCompleteAction({
        id: action.payload.id,
        available: action.payload.available,
      })
    );
    yield put(clearStatusAction());
  } else {
    yield put(
      updateMerchandiseFailedAction(response?.data?.message, action.payload.id)
    );
    yield put(clearStatusAction());
  }
}

export function* getMerchandiseCategoriesStart(action: any): Generator<any> {
  const response: any = yield call(getMerchandiseCategories);
  if (response && response.status === 200) {
    yield put(getMerchandiseCategoriesCompleteAction(response.data));
  } else {
    yield put(getMerchandiseCategoriesFailedAction(response?.data?.message));
    yield put(clearStatusAction());
  }
}

export function* createMerchandiseStart(action: {
  type: string;
  payload: CreateGrabbiMerchandisePayload;
}): Generator<any> {
  const response: any = yield call(createMerchandise, action.payload);
  if (response && response.data.status === undefined) {
    const getResponse: any = yield call(
      getMerchandise,
      action.payload.store.id
    );
    yield put(getMerchandiseCompleteAction(getResponse.data));
    yield put(createMerchandiseCompleteAction(response));
    yield put(clearStatusAction());
  } else {
    yield put(createMerchandiseFailedAction(response?.data?.reason));
    yield put(clearStatusAction());
  }
}

// export function* deleteMerchandiseStart(action: {
//   type: string;
//   payload: DeleteMerchandisePayload;
// }): Generator<any> {
//   const response: any = yield call(deleteMerchandise, action.payload);
//   if (response && response.status === 200) {
//     const getResponse: any = yield call(getMerchandise, action.payload.storeId);
//     yield put(getMerchandiseCompleteAction(getResponse.data));
//     yield put(deleteMerchandiseCompleteAction());
//     yield put(clearStatusAction());
//   } else {
//     yield put(deleteMerchandiseFailedAction(response?.data?.message));
//   }
// }

export function* refundTransactionStart(action: {
  type: string;
  payload: TransactionRefundPayload;
}): Generator<any> {
  const response: any = yield call(refundTransaction, action.payload);
  if (response && response.status === 200) {
    const getResponse: any = yield call(getStoreTransactions, {
      storeId: action.payload.storeId,
    });
    if (getResponse && getResponse.status === 200) {
      yield put(getStoreTransactionsCompletedAction(getResponse));
    }
    yield put(refundTransactionCompletedAction(response));
    yield put(clearStatusAction());
  } else {
    yield put(refundTransactionFailedAction(response.data.message));
    yield put(clearStatusAction());
  }
}

export function* updateToppingStart(action: {
  type: string;
  payload: UpdateToppingPayload;
}): Generator<any> {
  const response: any = yield call(updateTopping, action.payload.toppingUpc);
  if (response && response.status === 200) {
    const getResponse: any = yield call(getMerchandise, action.payload.storeId);
    yield put(getMerchandiseCompleteAction(getResponse.data));
    yield put(
      updateToppingCompleteAction(action.payload.toppingUpc.toppingUpc.upcNumber)
    );
    yield put(clearStatusAction());
  } else {
    yield put(updateToppingFailedAction(response?.data?.message));
  }
}

export function* creatingLeadFromStarterKit(action: any): Generator<any> {
  const response: any = yield call(createLead, action.payload.leadForMarketing);
  if (response && response.status === 200) {
    yield put(creatingLeadFromStarterKitComplete(response));
    yield put(creatingLeadFromStarterKitStartAction(action.payload));
  } else {
    yield put(creatingLeadFromStarterKitFailed(response.data.message));
  }
}

export function* watchChangePasswordStart() {
  yield takeLeading(actionIds.CHANGE_PASSWORD_START, startChangePassword);
}

export function* watchGetStoreListStart() {
  yield takeLeading(actionIds.GET_STORE_LIST_START, requestStoreList);
}

export function* watchResetPasswordStart() {
  yield takeEvery(
    actionIds.RESET_PASSWORD_START,
    sendRequestToResetPasswordStart
  );
}

export function* watchLoginStart() {
  yield takeEvery(actionIds.LOGIN_START, requestLogin);
}

export function* watchToggleStoreStatusStart() {
  yield takeLeading(
    actionIds.TOGGLE_STORE_STATUS_START,
    toggleStoreStatusStart
  );
}

export function* watchGetStoreStart() {
  yield takeEvery(actionIds.GET_STORE_BY_UPC_START, getStoreByUpc);
}

export function* watchLogoutStart() {
  yield takeEvery(actionIds.LOGOUT_START, requestLogout);
}

export function* watchGetMerchantStart() {
  yield takeLeading(actionIds.GET_MERCHANT_START, getMerchantStart);
}

export function* watchGetStoreTransactionsStart() {
  yield takeLeading(
    actionIds.GET_STORE_TRANSACTIONS_START,
    getStoreTransactionsStart
  );
}

export function* watchCheckSessionStart() {
  yield takeLeading(actionIds.CHECK_SESSION_START, checkSessionStart);
}

export function* watchGetCountriesStart() {
  yield takeLeading(actionIds.GET_COUNTRIES_START, getCountriesStart);
}

export function* watchGetStatesStart() {
  yield takeLeading(actionIds.GET_STATES_START, getStatesStart);
}

export function* watchSignUpMerchantStart() {
  yield takeLeading(actionIds.SIGNUP_START, signUpMerchantStart);
}

export function* watchUpdateMerchantStart() {
  yield takeLeading(
    actionIds.UPDATE_PERSONAL_DETAILS_START,
    updateMerchantStart
  );
}

export function* watchUpdateStoreStart() {
  yield takeLeading(actionIds.UPDATE_STORE_DETAILS_START, updateStoreStart);
}

export function* watchAdminGetAllMerchants() {
  yield takeLeading(
    actionIds.ADMIN_GET_ALL_MERCHANTS_START,
    startAdminGetAllMerchants
  );
}

export function* watchAdminGetAllStores() {
  yield takeLeading(
    actionIds.ADMIN_GET_ALL_STORES_START,
    startAdminGetAllStores
  );
}

export function* watchLeadForMarketingStartAction() {
  yield takeLeading(
    actionIds.LEAD_FOR_MARKETING_START,
    creatingLeadForMarketing
  );
}

export function* watchGoogleLoginStart() {
  yield takeLeading(actionIds.GOOGLE_LOGIN_START, startGoogleLogin);
}

export function* watchAppleLoginStart() {
  yield takeLeading(actionIds.APPLE_LOGIN_START, startAppleLogin);
}

export function* watchConfirmMerchantStartAction() {
  yield takeLeading(actionIds.CONFIRM_MERCHANT_START, confirmMerchantStart);
}

export function* watchResendConfirmMerchantStartAction() {
  yield takeLeading(
    actionIds.RESEND_CONFIRM_MERCHANT_START,
    resendConfirmMerchantStart
  );
}

export function* watchUpdateTransactionStatusStartAction() {
  yield takeEvery(
    actionIds.UPDATE_TRANSACTION_STATUS_START,
    updateTransactionStatusStart
  );
}

export function* watchGetMerchandiseStartAction() {
  yield takeLeading(actionIds.GET_MERCH_START, getMerchandiseStart);
}

export function* watchUpdateMerchandiseStartAction() {
  yield takeEvery(actionIds.UPDATE_MERCH_START, updateMerchandiseStart);
}

export function* watchCreateMerchandiseStartAction() {
  yield takeLeading(actionIds.ADD_MERCH_START, createMerchandiseStart);
}

// export function* watchDeleteMerchandiseStartAction() {
//   yield takeLeading(actionIds.DELETE_MERCH_START, updateToppingStart);
// }

export function* watchGetMerchandiseCategoriesStartAction() {
  yield takeEvery(
    actionIds.GET_MERCH_CATEGORIES_START,
    getMerchandiseCategoriesStart
  );
}
export function* watchRefundTransactionStartAction() {
  yield takeEvery(actionIds.REFUND_TRANSACTION_START, refundTransactionStart);
}

export function* watchRefreshTransactionsStart() {
  yield takeLeading(
    actionIds.REFRESH_TRANSACTION_START,
    getStoreTransactionsStart
  );
}

export function* watchGetAllTransactionsStart() {
  yield takeLeading(
    actionIds.GET_ALL_TRANSACTIONS_START,
    getStoreTransactionsStart
  );
}

export function* watchUpdateToppingStart() {
  yield takeLeading(actionIds.UPDATE_TOPPING_START, updateToppingStart);
}

export function* watchCreatingLeadFromStarterKitStart() {
  yield takeLeading(
    actionIds.LEAD_FROM_STARTER_KIT_START,
    creatingLeadFromStarterKit
  );
}

export default function* mySaga() {
  yield all([
    fork(watchResetPasswordStart),
    fork(watchChangePasswordStart),
    fork(watchAddStoreStart),
    fork(watchAdminGetAllStores),
    fork(watchAdminGetAllMerchants),
    fork(watchUpdateStoreStart),
    fork(watchSignUpMerchantStart),
    fork(watchUpdateMerchantStart),
    fork(watchGetCountriesStart),
    fork(watchGetStatesStart),
    fork(watchCheckSessionStart),
    fork(watchGetMerchantStart),
    fork(watchGetStoreTransactionsStart),
    fork(watchGetStoreStart),
    fork(watchGetStoreListStart),
    fork(watchToggleStoreStatusStart),
    fork(watchLoginStart),
    fork(watchLogoutStart),
    fork(watchLeadForMarketingStartAction),
    fork(watchGoogleLoginStart),
    fork(watchAppleLoginStart),
    fork(watchConfirmMerchantStartAction),
    fork(watchResendConfirmMerchantStartAction),
    fork(watchUpdateMerchantStart),
    fork(watchUpdateTransactionStatusStartAction),
    fork(watchGetMerchandiseStartAction),
    fork(watchUpdateMerchandiseStartAction),
    fork(watchCreateMerchandiseStartAction),
    // fork(watchDeleteMerchandiseStartAction),
    fork(watchGetMerchandiseCategoriesStartAction),
    fork(watchRefundTransactionStartAction),
    fork(watchRefreshTransactionsStart),
    fork(watchGetAllTransactionsStart),
    fork(watchUpdateToppingStart),
    fork(watchCreatingLeadFromStarterKitStart),
  ]);
}

import { createQueryString } from "actions/api";
import {
  AxiosError,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { Options, RefetchOptions, UseAxiosResult } from "axios-hooks";
import { useAxiosContext } from "components/Shared/AxiosProvider/AxiosProvider";
import { cloneDeep, merge } from "lodash";
import { useState } from "react";
import {
  ErrorType1,
  PaginatedResponse,
  Transaction,
} from "types/GrabbiTypes";

export interface GetTransactionParams {
  pageNumber: number;
  pageSize: number;
  isFulfilled: boolean;
  isRefunded: boolean;
}

interface AxiosSettings {
  config?: AxiosRequestConfig;
  options?: Options;
}

interface FakeUseAxiosData<T1, T2> {
  data?: T1;
  loading: boolean;
  error?: AxiosError<T2>;
  response?: AxiosResponse<T1>;
}

type FakeUseAxiosResult<T1 = any, T2 = any> = [
  FakeUseAxiosData<T1, T2>,
  (
    config?: AxiosRequestConfig,
    options?: RefetchOptions
  ) => AxiosPromise<T1>
];

export interface TransactionContextProps {
  useSetFulfillTransaction: (
    settings?: AxiosSettings
  ) => UseAxiosResult<Transaction, ErrorType1>;
  useSetTransactionReady: (
    settings?: AxiosSettings
  ) => UseAxiosResult<Transaction, ErrorType1>;
  useGetTransactions: (
    transactionQueryString?: string,
    settings?: AxiosSettings
  ) => UseAxiosResult<PaginatedResponse<Transaction>, ErrorType1>;
  useGetReportTransactions: (
    id?: number
  ) => FakeUseAxiosResult<Transaction[], ErrorType1>;
  useCancelTransactionItem: (
    settings?: AxiosSettings
  ) => UseAxiosResult<Transaction, ErrorType1>;
  useAddTransaction: (
    settings?: AxiosSettings
  ) => UseAxiosResult<Transaction, ErrorType1>;
  useCheckoutTransaction: (
    settings?: AxiosSettings
  ) => UseAxiosResult<Transaction, ErrorType1>;
  useUpdateTransaction: (
    settings?: AxiosSettings
  ) => UseAxiosResult<Transaction, ErrorType1>;
  useUpdateTransactionItemStatus: (
    settings?: AxiosSettings
  ) => UseAxiosResult<Transaction, ErrorType1>;
}

export const useTransactionHooks = (): TransactionContextProps => {
  const { useAxios: useAxiosWithContext } = useAxiosContext();

  const useSetFulfillTransaction = (settings?: AxiosSettings) =>
    useAxiosWithContext<Transaction, ErrorType1>(
      {
        url: `restful-services/transaction/fulfilled`,
        method: "POST",
        ...settings?.config,
      },
      { manual: true, ...settings?.options }
    );

  const useSetTransactionReady = (settings?: AxiosSettings) =>
    useAxiosWithContext<Transaction, ErrorType1>(
      {
        url: `restful-services/transaction/readyForPickup`,
        method: "POST",
        ...settings?.config,
      },
      { manual: true, ...settings?.options }
    );

  const useCancelTransactionItem = (settings?: AxiosSettings) =>
    useAxiosWithContext<Transaction, ErrorType1>(
      {
        url: `restful-services/transaction/cancelOrderItem`,
        method: "POST",
        ...settings?.config,
      },
      { manual: true, ...settings?.options }
    );
  
  const useAddTransaction = (settings?: AxiosSettings) =>
    useAxiosWithContext<Transaction, ErrorType1>(
      {
        url: `restful-services/transaction/placeOrder`,
        method: "POST",
        ...settings?.config,
      },
      { manual: true, ...settings?.options }
    );
  
  const useCheckoutTransaction = (settings?: AxiosSettings) =>
    useAxiosWithContext<Transaction, ErrorType1>(
      {
        url: `restful-services/transaction/cashierCheckout`,
        method: "POST",
        ...settings?.config,
      },
      { manual: true, ...settings?.options }
    );

  const useUpdateTransaction = (settings?: AxiosSettings) =>
    useAxiosWithContext<Transaction, ErrorType1>(
      {
        url: `restful-services/transaction`,
        method: "PUT",
        ...settings?.config,
      },
      { manual: true, ...settings?.options }
    );
  
  const useUpdateTransactionItemStatus = (settings?: AxiosSettings) =>
    useAxiosWithContext<Transaction, ErrorType1>(
      {
        url: `restful-services/transaction/updateOrderItem`,
        method: "POST",
        ...settings?.config,
      },
      { manual: true, ...settings?.options }
    );

  const useGetTransactions = (
    transactionQueryString?: string,
    settings?: AxiosSettings
  ) =>
    useAxiosWithContext<PaginatedResponse<Transaction>, ErrorType1>(
      {
        url: `restful-services/transaction/filter${transactionQueryString}`,
        method: "GET",
        ...settings?.config,
      },
      { manual: true, ...settings?.options }
    );

  const queryStringComplete = {
    pageNumber: 1,
    pageSize: 10000,
    isFulfilled: true,
    isRefunded: false,
  };

  const useGetCompletedTransactions = (
    id?: number,
    transactionParams?: GetTransactionParams,
    settings?: AxiosSettings
  ) => {
    const transactionQueryString = createQueryString({
      ...queryStringComplete,
      ...transactionParams,
    });
    return useAxiosWithContext<Transaction[], ErrorType1>(
      {
        url: `restful-services/transaction/store/${id}${transactionQueryString}`,
        method: "GET",
        ...settings?.config,
      },
      { manual: true, ...settings?.options }
    );
  };

  const queryStringRefunded = {
    pageNumber: 1,
    pageSize: 10000,
    isFulfilled: false,
    isRefunded: true,
  };

  const [dataComplete, setDataComplete] = useState<Transaction[]>();

  const useGetRefundedTransactions = (
    id?: number,
    transactionParams?: GetTransactionParams,
    settings?: AxiosSettings
  ) => {
    const transactionQueryString = createQueryString({
      ...queryStringRefunded,
      ...transactionParams,
    });
    return useAxiosWithContext<Transaction[], ErrorType1>(
      {
        url: `restful-services/transaction/store/${id}${transactionQueryString}`,
        method: "GET",
        ...settings?.config,
      },
      { manual: true, ...settings?.options }
    );
  };

  const useGetReportTransactions = (id?: number) => {
    const [
      {
        loading: loadingComplete,
        error: errorComplete,
        response: responseComplete,
      },
      getComplete,
    ] = useGetCompletedTransactions(id, undefined);

    const [
      {
        data: dataRefunded,
        loading: loadingRefunded,
        error: errorRefunded,
        response: responseRefunded,
      },
      getRefunded,
    ] = useGetRefundedTransactions(id, undefined);

    const getReportTransactions = async () => {
      const complete = await getComplete();
      const completeCloned = cloneDeep(complete);
      setDataComplete(completeCloned.data);
      const refunded = await getRefunded();

      return merge(complete, refunded);
    };

    const result: FakeUseAxiosResult<Transaction[], any> = [
      {
        data:
          dataComplete && dataRefunded
            ? [...dataComplete, ...dataRefunded]
            : undefined,
        loading: loadingComplete || loadingRefunded,
        error: errorComplete ?? errorRefunded ?? undefined,
        response: merge(responseComplete, responseRefunded),
      },
      getReportTransactions,
    ];

    return result;
  };

  return {
    useSetFulfillTransaction,
    useSetTransactionReady,
    useGetTransactions,
    useGetReportTransactions,
    useCancelTransactionItem,
    useAddTransaction,
    useCheckoutTransaction,
    useUpdateTransactionItemStatus,
    useUpdateTransaction,
  };
};

export default useTransactionHooks;

import React, { useCallback, useEffect, useState } from "react";
import "./GetStartedComponent.scss";
import { Box, Button } from "@material-ui/core";
import GrabbiTextField from "components/Shared/GrabbiTextField/GrabbiTextField";
import { GrabbiLeadForMarketing } from "types/GrabbiTypes";
import { CreatedLeadForMarketingState } from "store/merchant/createdLeadForMarketingReducer";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import {
  NAME_REGEX,
  RFC_5322_EMAIL_REGEX,
  PHONE_REGEX
} from "shared/scripts/Constants";
import GrabbiCarousel from "components/Shared/GrabbiCarousel/GrabbiCarouselComponent";


interface Props {
  leadForMarketing: GrabbiLeadForMarketing;
  createdLeadForMarketingState: CreatedLeadForMarketingState;
  createdLeadForMarketingError?: string;
  dispatchGetStarted: (leadForMarketing: GrabbiLeadForMarketing) => void;
}

const GetStartedComponent: React.FC<Props> = ({
  createdLeadForMarketingState,
  createdLeadForMarketingError,
  dispatchGetStarted,
}) => {
  const link = (text: string, href: string) => (
    <a className="link_to_email" rel="noopener noreferrer" href={href} target="_blank">
      {text}
    </a>
  );

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    control,
  } = useForm({
    reValidateMode: "onBlur",
    shouldFocusError: true,
  });
  const history = useHistory();
  const [fieldsInitialized, setFieldsInitialized] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const initializeStoreFields = useCallback(() => {
    setValue("full_name", "");
    setValue("email_address", "");
    setValue("phone_number", "");
    setValue("business_name", "");
    setFieldsInitialized(true);
  }, [setFieldsInitialized, setValue]);

  const handleSubmitValid = () => {
    const newLeadForMarketing: GrabbiLeadForMarketing = {
      firstName: getValues("full_name"),
      lastName: getValues("full_name"),
      email: getValues("email_address"),
      phone: getValues("phone_number"),
      storeName: getValues("business_name"),
      noOfLocations: "1",
      country: "USAorCANADA",
    };
    dispatchGetStarted(newLeadForMarketing);
  };

  const handleSubmitError = () => {
    enqueueSnackbar("Please resolve errors and try again.", {
      variant: "error",
    });
  };

  useEffect(() => {
    if (!fieldsInitialized) {
      initializeStoreFields();
    }
  }, [fieldsInitialized, initializeStoreFields, enqueueSnackbar]);

  useEffect(() => {
    if (createdLeadForMarketingState.createdLeadForMarketing) {
      enqueueSnackbar("Success! ONE OF OUR RETAIL EXPERTS WILL CONTACT YOU.", {
        variant: "success",
        autoHideDuration: 3500,
      });

      initializeStoreFields();
    } else if (createdLeadForMarketingError) {
      enqueueSnackbar(createdLeadForMarketingError, { variant: "error" });
    }
  }, [history, createdLeadForMarketingState, createdLeadForMarketingError, enqueueSnackbar, initializeStoreFields]);

  return (
    <div className="getstarted_wrapper">
      <Box className="getstarted_root">

      <div className='grabbi_getstarted_section'>
          <div className='grabbi_two_column_grid_in_getstarted'>
            <div className='grabbi_two_column_grid_in_getstarted_header_description'>
              <div className='grabbi_getstarted_main_header_container'>
                <span className='grabbi_getstarted_main_header_text'>
                  Increase Profits, cut costs.
                </span>
              </div>
              <div className="grabbi_getstarted_main_body_container">
                <span className='grabbi_getstarted_sub_header_text'>
                  Grabbi increases your profit by as much as 30% while reducing your cost.
                </span>
              </div>
              <Box m={3} />
              <div className="grabbi_getstarted_main_body_container">
                <span className='grabbi_getstarted_sub_header_text'>
                  Contact us at&nbsp;
                  {link("sales@grabbi.shop", "mailto:sales@grabbi.shop")}
                  &nbsp;or share your email with us to get you started.
                </span>
              </div>
            </div>
            <div className="getstarted_grid_input_container">
              <form
                className="getstarted_form"
                onSubmit={handleSubmit(handleSubmitValid, handleSubmitError)}
              >
                <div className='grabbi_getstarted_form'>
                  <div className='grabbi_getstarted_form_texboxes'>

                    <GrabbiTextField
                      ref={register({
                        minLength: {
                          value: 2,
                          message: "2 or more characters needed",
                        },
                        maxLength: {
                          value: 40,
                          message: "40 or less characters needed",
                        },
                        required: {
                          value: true,
                          message: "Enter full name",
                        },
                        pattern: {
                          value: NAME_REGEX,
                          message: "Name may only contain letters",
                        },
                      })}
                      placeholder="Full Name"
                      name="full_name"
                      autoComplete="full_name"
                      label="Full Name"
                      error={errors.full_name}
                      errorMessage={errors?.full_name?.message}
                      control={control}
                      classes={{
                        root:
                          "demo_text_field_root_large",
                      }}
                      InputLabelProps={{
                        classes: {
                          root: `grabbi_texbox_label_with_light_background`,
                          focused: `grabbi_texbox_label_with_light_background_focused`,
                          disabled: "grabbi_texbox_label_with_light_background_disabled",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root:
                            "demo_text_field_root_large",
                          focused: `grabbi_label_with_light_background_focused`,
                        },
                        className: "grabbi_texboxes_with_light_background",
                      }}
                      className="demo_text_field_root_large"
                    />

                    <GrabbiTextField
                      ref={register({
                        minLength: {
                          value: 6,
                          message: "6 or more characters needed",
                        },
                        maxLength: {
                          value: 50,
                          message: "50 or less characters needed",
                        },
                        required: {
                          value: true,
                          message: "Enter email address",
                        },
                        pattern: {
                          value: RFC_5322_EMAIL_REGEX,
                          message: "Invalid email address",
                        },
                        //? [REVISIT] Add validation of email address once email server is implemented
                      })}
                      placeholder="Email Address"
                      name="email_address"
                      label="Email Address"
                      error={errors.email_address}
                      errorMessage={errors?.email_address?.message}
                      control={control}
                      classes={{
                        root:
                          "demo_text_field_root_large",
                      }}
                      InputLabelProps={{
                        classes: {
                          root: `grabbi_texbox_label_with_light_background`,
                          focused: `grabbi_texbox_label_with_light_background_focused`,
                          disabled: "grabbi_texbox_label_with_light_background_disabled",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root:
                            "demo_text_field_root_large",
                          focused: `grabbi_label_with_light_background_focused`,
                        },
                        className: "grabbi_texboxes_with_light_background",
                      }}
                      className="demo_text_field_root_large"
                    />


                  <GrabbiTextField
                    ref={register({
                      minLength: {
                        value: 10,
                        message: "10 or more digits needed",
                      },
                      maxLength: {
                        value: 15,
                        message: "15 or less digits needed",
                      },
                      required: {
                        value: true,
                        message: "Enter phone number",
                      },
                      pattern: {
                        value: PHONE_REGEX,
                        message: "Invalid phone number",
                      },
                    })}
                    placeholder="Phone Number"
                    name="phone_number"
                    autoComplete="phone_number"
                    label="Phone Number"
                    error={errors.phone_number}
                    errorMessage={errors?.phone_number?.message}
                    control={control}
                    classes={{
                      root:
                        "demo_text_field_root_large",
                    }}
                    InputLabelProps={{
                      classes: {
                        root: `grabbi_texbox_label_with_light_background`,
                        focused: `grabbi_texbox_label_with_light_background_focused`,
                        disabled: "grabbi_texbox_label_with_light_background_disabled",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root:
                          "demo_text_field_root_large",
                        focused: `grabbi_label_with_light_background_focused`,
                      },
                      className: "grabbi_texboxes_with_light_background",
                    }}
                    className="demo_text_field_root_large"
                  />

                  <GrabbiTextField
                    ref={register({
                      minLength: {
                        value: 2,
                        message: "Must have 2 or more characters",
                      },
                      maxLength: {
                        value: 50,
                        message: "Must have 50 or less characters",
                      },
                      required: {
                        value: true,
                        message: "Enter business name",
                      },
                    })}
                    placeholder="Business Name"
                    name="business_name"
                    autoComplete="business_name"
                    label="Business Name"
                    error={errors.business_name}
                    errorMessage={errors?.business_name?.message}
                    control={control}
                    classes={{
                      root:
                        "demo_text_field_root_large",
                    }}
                    InputLabelProps={{
                      classes: {
                        root: `grabbi_texbox_label_with_light_background`,
                        focused: `grabbi_texbox_label_with_light_background_focused`,
                        disabled: "grabbi_texbox_label_with_light_background_disabled",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root:
                          "demo_text_field_root_large",
                        focused: `grabbi_label_with_light_background_focused`,
                      },
                      className: "grabbi_texboxes_with_light_background",
                    }}
                    className="demo_text_field_root_large"
                  />

                  </div>
                  <Button
                    type="submit"
                    classes={{ root: "grabbi_getstarted_button" }}
                  >
                    Get Started
                  </Button>
                  </div>
              </form>
            </div>
          </div>
        </div>

        <div className='section_divider divider'>.</div>

        <GrabbiCarousel />

        <div className='section_divider divider'>.</div>

        <div className='grabbi_three_column_grid_in_starter_kit'>
          <div className='grabbi_getstarter_partners_testimonials_description'>
            <div className='grabbi_grabbi_getstarted_partners_testimonials_header_container'>
              <span className='grabbi_getstarted_partners_testimonials_header_text'>
                10 Sec
              </span>
            </div>
            <div className='grabbi_getstarted_partners_testimonials_body_text_container'>
              <span className='grabbi_getstarted_partners_testimonials_body_text'>
                receive instant orders in 10 seconds or less
              </span>
            </div>
          </div>
          <div className='grabbi_getstarter_partners_testimonials_link_one'>
            <div className='grabbi_grabbi_getstarted_partners_testimonials_header_container'>
              <span className='grabbi_getstarted_partners_testimonials_header_text'>
                60%
              </span>
            </div>
            <div className='grabbi_getstarted_partners_testimonials_body_text_container'>
              <span className='grabbi_getstarted_partners_testimonials_body_text'>
                more in-person tips
              </span>
            </div>
          </div>
          <div className='grabbi_getstarter_partners_testimonials_link_two'>
            <div className='grabbi_grabbi_getstarted_partners_testimonials_header_container'>
              <span className='grabbi_getstarted_partners_testimonials_header_text'>
                5%
              </span>
            </div>
            <div className='grabbi_getstarted_partners_testimonials_body_text_container'>
              <span className='grabbi_getstarted_partners_testimonials_body_text'>
                more money back in your pocket
              </span>
            </div>
          </div>
        </div>

        <div className='section_divider divider'>.</div>

        <div className='grabbi_partners_logo_section'>
          <div className='grabbi_partners_logo_header_text_container'>
            <span className='grabbi_partners_logo_header_text'>
              Food businesses using Grabbi
            </span>
          </div>
          <div className='grabbi_partners_section_grid'>
            <div className='grabbi_partners_logo' style={{
                backgroundImage: `url('assets/partners/grabbi-partners-logo-elpollopicante.png')`}}>
            </div>
            <div className='grabbi_partners_logo' style={{
                backgroundImage: `url('assets/partners/grabbi-partners-logo-swahili.png')`}}>
            </div>
            <div className='grabbi_partners_logo' style={{
                backgroundImage: `url('assets/partners/grabbi-partners-logo-alreca.png')`}}>
            </div>
            <div className='grabbi_partners_logo' style={{
                backgroundImage: `url('assets/partners/grabbi-partners-logo-shiloh.png')`}}>
            </div>
            <div className='grabbi_partners_logo' style={{
                backgroundImage: `url('assets/partners/grabbi-partners-logo-hungrymoon.png')`}}>
            </div>
            <div className='grabbi_partners_logo' style={{
                backgroundImage: `url('assets/partners/grabbi-partners-logo-mustacheburger.png')`}}>
            </div>
            <div className='grabbi_partners_logo' style={{
                backgroundImage: `url('assets/partners/grabbi-partners-logo-mashallah.png')`}}>
            </div>
            <div className='grabbi_partners_logo' style={{
                backgroundImage: `url('assets/partners/grabbi-partners-logo-thesmokestop.png')`}}>
            </div>
            <div className='grabbi_partners_logo' style={{
                backgroundImage: `url('assets/partners/grabbi-partners-logo-freshcoconut.png')`}}>
            </div>
            <div className='grabbi_partners_logo' style={{
                backgroundImage: `url('assets/partners/grabbi-partners-logo-freddies.png')`}}>
            </div>
            <div className='grabbi_partners_logo' style={{
                backgroundImage: `url('assets/partners/grabbi-partners-logo-vibesoftehran.png')`}}>
            </div>
            <div className='grabbi_partners_logo' style={{
                backgroundImage: `url('assets/partners/grabbi-partners-logo-born2eat.png')`}}>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default GetStartedComponent;

import { Typography } from "@material-ui/core";
import { AxiosResponse } from "axios";
import { useAxiosContext } from "components/Shared/AxiosProvider/AxiosProvider";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import GrabbiDialog from "components/Shared/GrabbiDialog/GrabbiDialogComponent";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { State } from "store";
import {
  CreateGrabbiMerchandiseErrorPayload,
  CreateGrabbiMerchandisePayload,
  CreateGrabbiToppingPayload,
  GrabbiMerchandise,
  GrabbiTopping,
  MerchandiseCategory,
} from "types/GrabbiTypes";
import MerchandiseForm from "../MerchandiseForm/MerchandiseFormComponent";
import "./CreateDeleteMerchandiseDialog.scss";

interface Props {
  className?: string;
  open: boolean;
  merchandise?: GrabbiMerchandise;
  topping?: GrabbiTopping;
  mode?: "ADD" | "UPDATE" | "DELETE";
  isTopping?: boolean;
  callback: (payload?: GrabbiMerchandise) => void;
  onClose: () => void;
}

const CreateDeleteMerchandiseDialog: React.FC<Props> = ({
  className,
  open,
  mode,
  merchandise,
  topping,
  isTopping,
  onClose,
  callback,
}) => {
  const { useAxios } = useAxiosContext();
  const selectedStore = useSelector(
    (state: State) => state.storeList.selectedStore
  );

  const [
    {
      data: dataCategories,
      loading: loadingCategories,
      error: errorCategories,
    },
    getMerchandiseCategories,
  ] = useAxios<MerchandiseCategory[]>({
    url: `restful-services/public/merchandiseCategories`,
    method: "GET",
  });

  const [
    {
      data: dataCreateMerch,
      loading: loadingCreateMerch,
      error: errorCreateMerch,
    },
    createMerchandise,
  ] = useAxios<GrabbiMerchandise, CreateGrabbiMerchandiseErrorPayload>({
    url: `restful-services/merchandise/menu`,
    method: "POST",
  });

  const [
    {
      data: dataDeleteMerch,
      loading: loadingDeleteMerch,
      error: errorDeleteMerch,
    },
    deleteMerchandise,
  ] = useAxios<GrabbiMerchandise>({
    url: `restful-services/merchandise/${selectedStore?.id}/${merchandise?.id}`,
    method: "DELETE",
  });

  const merchId = merchandise?.id;
  const deleteToppingId = topping?.toppingUpc.upcNumber;

  const [
    {
      data: dataDeleteTopping,
      loading: loadingDeleteTopping,
      error: errorDeleteTopping,
    },
    deleteToppingPost,
  ] = useAxios<GrabbiTopping>(
    {
      url: `restful-services/merchandise/${merchId}/topping/${deleteToppingId}`,
      method: "DELETE",
    },
    { manual: true }
  );

  const addMode = mode === "ADD";
  const deleteMode = mode === "DELETE";

  const { enqueueSnackbar } = useSnackbar();

  const loading = loadingCategories;
  const actionLoading =
    loadingCreateMerch || loadingDeleteMerch || loadingDeleteTopping;

  useEffect(() => {
    getMerchandiseCategories();
  }, [getMerchandiseCategories]);

  // const createToppingPayload = (): CreateGrabbiToppingPayload | undefined => {
  //   if (dataCategories && selectedStore && selectedStore.id && topping) {
  //     const label = getValues("label");
  //     const price = getValues("price");
  //     const upcNumber = getValues("upc");
  //     const description = getValues("description");
  //     const merchandiseCategory = dataCategories[getValues("category")];

  //     return {
  //       ...topping,
  //       price,
  //       storeId: topping.store.id,
  //       toppingUpc: {
  //         upcNumber,
  //         label,
  //         description,
  //         merchandiseCategory,
  //       },
  //       available: addMode ? false : topping?.available,
  //     };
  //   } else {
  //     return;
  //   }
  // };

  const handleAddMerchandise = (
    payload: CreateGrabbiMerchandisePayload | CreateGrabbiToppingPayload
  ) => {
    createMerchandise({
      data: payload,
    }).then((response: AxiosResponse<GrabbiMerchandise>) => {
      enqueueSnackbar("Merchandise created", { variant: "success" });
      handleClose(response.data);
    });
  };

  const handleDeleteMerchandise = () => {
    if (topping && merchId && deleteToppingId) {
      deleteToppingPost();
    } else if (merchandise && selectedStore && selectedStore.id) {
      deleteMerchandise();
    }
  };

  const handleClose = useCallback(
    (payload?: GrabbiMerchandise) => {
      callback(payload);
      onClose();
    },
    [onClose, callback]
  );

  const errors = {
    add: "",
  };

  useEffect(() => {
    // ? [REVISIT] Delete this if case after backend sends correct status code for error
    if (dataCreateMerch && (dataCreateMerch as any).reason) {
      enqueueSnackbar((dataCreateMerch as any).reason.response?.data.reason, {
        variant: "error",
      });
      errors.add = "UPC already exists, please use another";
    } else if (errorCreateMerch) {
      enqueueSnackbar(errorCreateMerch.response?.data.reason, {
        variant: "error",
      });
      if (
        errorCreateMerch?.response?.data?.reason?.includes(
          "Record already exists for upc"
        )
      ) {
        errors.add = "UPC already exists, please use another";
      }
    }
  }, [dataCreateMerch, errors, errorCreateMerch, enqueueSnackbar]);

  useEffect(() => {
    if (errorDeleteMerch) {
      enqueueSnackbar(errorDeleteMerch.message, { variant: "error" });
    } else if (dataDeleteMerch) {
      enqueueSnackbar("Merchandise deleted", { variant: "success" });
      handleClose();
    }
  }, [errorDeleteMerch, dataDeleteMerch, enqueueSnackbar, handleClose]);

  useEffect(() => {
    if (dataDeleteTopping) {
      enqueueSnackbar("Topping deleted", { variant: "success" });
      handleClose();
    } else if (errorDeleteTopping) {
      enqueueSnackbar(errorDeleteTopping.message, { variant: "error" });
    }
  }, [dataDeleteTopping, errorDeleteTopping, enqueueSnackbar, handleClose]);

  useEffect(() => {
    if (errorCategories) {
      enqueueSnackbar(errorCategories, { variant: "error" });
      handleClose();
    }
  }, [errorCategories, enqueueSnackbar, handleClose]);

  const subject = isTopping ? "Add-On" : "Merchandise";

  return addMode ? (
    <GrabbiDialog
      dialogProps={{
        open,
        onClose: () => handleClose(),
        className: `edit_merchandise_dialog_root ${className ?? ""}`,
      }}
    >
      <Typography className="title">
        {addMode ? `Create ${subject}` : `Update ${subject}`}
      </Typography>
      {dataCategories && (
        <MerchandiseForm
          addMode={addMode}
          merchandise={merchandise}
          dataCategories={dataCategories}
          handlePayload={handleAddMerchandise}
          actionLoading={actionLoading}
          isTopping={isTopping}
        />
      )}
      {loading && <LoadingIcon />}
    </GrabbiDialog>
  ) : deleteMode ? (
    <GrabbiDialog
      dialogProps={{
        open,
        onClose: () => handleClose(),
        className: `edit_merchandise_dialog_root ${className ?? ""}`,
      }}
    >
      <Typography className="title">{`Delete ${subject}`}</Typography>
      <Typography className="delete_merch_name">
        {merchandise?.upc.label}
      </Typography>
      <Typography className="warning">
        Please confirm, this operation is permenant and cannot be undone.
      </Typography>
      {!actionLoading && (
        <div className="action_area">
          <GrabbiButton onClick={handleDeleteMerchandise}>DELETE</GrabbiButton>
          <GrabbiButton onClick={() => handleClose()}>Cancel</GrabbiButton>
        </div>
      )}
      {actionLoading && <LoadingIcon />}
    </GrabbiDialog>
  ) : (
    <React.Fragment></React.Fragment>
  );
};

export default CreateDeleteMerchandiseDialog;

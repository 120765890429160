import { ExcelSheet } from "components/Shared/ExcelExport/ExcelExportComponent";
import moment from "moment";
import { CURRENCY_NUMFMT } from "shared/scripts/Constants";
import { GrabbiStore, Transaction, TransactionItem } from "types/GrabbiTypes";

export const createExcelConfig = (data: Transaction[], selectedStore: GrabbiStore): ExcelSheet[] => {
  return [
    {
      title: `${selectedStore.name}`,
      columns: [
        {
          title: "Date",
          width: 26,
          valueFn: (transaction: Transaction) => {
            const orderDate =
              `${moment(transaction.createdDate).utc().local().format("ddd, MMM Do YY, h:mma")}`;
            return orderDate;
          },
        },
        {
          title: "Proof Of Payment",
          width: 20,
          valueFn: (transaction: Transaction) => {
            return transaction.orderCode;
          },
          propsFn: (transaction: Transaction) => {
            const colorCode = `FF${transaction.orderCodeColor
              .toUpperCase()
              .replace("#", "")}`;
            return {
              fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: {
                  argb: colorCode,
                },
              },
            };
          },
          bodyProps: {
            font: {
              color: {
                argb: "FFFFFF",
              },
            },
          },
        },
        {
          title: "Label",
          width: 50,
          valueFn: (transaction: Transaction) => {
            let descriptionValue = "";
            transaction.transactionItems.forEach(
              (item: TransactionItem, index: number) => {
                descriptionValue += `${item?.upc.label}${index < transaction.transactionItems.length - 1 ? ",\r\n" : ""
                  }`;
              }
            );
            return descriptionValue;
          },
        },
        {
          title: "Quantity",
          width: 10,
          valueFn: (transaction: Transaction) => {
            let quantityValue = "";
            transaction.transactionItems.forEach(
              (item: TransactionItem, index: number) => {
                quantityValue += `${item.quantity}${index < transaction.transactionItems.length - 1 ? ",\r\n" : ""
                  }`;
              }
            );
            return quantityValue;
          },
        },
        {
          title: "Merchant Price",
          width: 14,
          bodyProps: {
            numFmt: CURRENCY_NUMFMT,
          },
          valueFn: (transaction: Transaction) => {
            return transaction.transactionTotal;
          },
        },
        {
          title: "Merchandise Price",
          width: 20,
          bodyProps: {
            numFmt: CURRENCY_NUMFMT,
          },
          valueFn: (transaction: Transaction) => {
            return transaction.merchandiseTotal;
          },
        },
        {
          title: "Tax",
          width: 8,
          bodyProps: {
            numFmt: CURRENCY_NUMFMT,
          },
          valueFn: (transaction: Transaction) => {
            return transaction.totalTax;
          },
        },
        {
          title: "Customer Service Fee",
          width: 20,
          bodyProps: {
            numFmt: CURRENCY_NUMFMT,
          },
          valueFn: (transaction: Transaction) => {
            return transaction.serviceFee;
          },
        },
        {
          title: "Tip",
          width: 8,
          bodyProps: {
            numFmt: CURRENCY_NUMFMT,
          },
          valueFn: (transaction: Transaction) => {
            return transaction.tip ? transaction.tip : 0;
          },
        },
        {
          title: "Items",
          width: 8,
          valueFn: (transaction: Transaction) => {
            let itemTotal = 0;
            transaction.transactionItems.forEach((item: TransactionItem) => {
              itemTotal += item.quantity;
            });
            return itemTotal;
          },
        },
        {
          title: "Status",
          width: 15,
          valueFn: (transaction: Transaction) => {
            return transaction.refunded
              ? "Refunded"
              : transaction.fulfilled
                ? "Completed"
                : "";
          },
        },
      ],
      data,
    },
  ]
};
import { connect } from "react-redux";
import { State } from "store";
import { getStoreListStartAction, updateStoreDetailsStart } from "actions";
import { GrabbiStore } from "types/GrabbiTypes";
import ManageAttendantComponent from "./ManageAttendantComponent";

const mapStateToProps = (state: State) => ({
  stores: state.storeList.stores,
  loadingStores: state.storeList.loadingStores,
  loginState: state.loginState,
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatchGetStores: (id: number) => dispatch(getStoreListStartAction(id)),
  dispatchUpdateStoreDetails: (store: GrabbiStore) =>
    dispatch(updateStoreDetailsStart(store)),
});

export const ManageAttendant = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageAttendantComponent);

import React from "react";
import "./Privacy.scss";
import { Box } from "@material-ui/core";
import { NavLink } from "react-router-dom";

const Privacy: React.FC = () => {
  const link = (text: string, href: string, useATag = false) =>
    useATag ? (
      <a className="privacy_link" href={href}>
        {text}
      </a>
    ) : (
      <NavLink className="privacy_link" to={`/${href}`}>
        {text}
      </NavLink>
    );

  return (
    <Box className="privacy_root">
      <Box className="privacy_header">
        Privacy Policy
      </Box>
      <Box component="p" className="privacy_paragraph">
        Effective date: 11/28/2019
      </Box>
      <Box component="p" className="privacy_paragraph">
        We at Grabbi, LLC (“Grabbi”, “the Company”, “we”, and “us”) know you
        care about how your personal information is used and shared, and we take
        your privacy seriously. Please read the following to learn more about
        our Privacy Policy.{" "}
        <Box component="span" className="privacy_bold">
          By using or accessing the Grabbi Platform in any manner, you
          acknowledge that you accept the practices and policies outlined in
          this Privacy Policy and you hereby consent that we will collect, use,
          and share your information in the following ways.
        </Box>
      </Box>
      <Box component="p" className="privacy_paragraph">
        Remember that your use of the Grabbi Platform is at all times subject to
        the {link("Terms of Service", "terms-of-service")}, which incorporates
        this Privacy Policy.Any terms we use in this Policy without defining
        them have the definitions given to them in the{" "}
        {link("Terms of Service", "terms-of-service")}.
      </Box>
      <Box className="privacy_subheader">
        What does this Privacy Policy cover?
      </Box>
      <Box component="p" className="privacy_paragraph">
        This Privacy Policy covers our treatment of personally identifiable
        information ("Personal Information") that we gather when you are
        accessing or using our the Grabbi Platform, but this Privacy Policy does
        not cover the practices of companies we don’t own or control or people
        that we don’t manage.We gather various types of Personal Information
        from our users, as explained in more detail below, and we use this
        Personal Information internally in connection with the Grabbi Platform,
        including to personalize, provide, and improve the Grabbi Platform, to
        allow you to set up a user account and profile, to contact you and allow
        other users to contact you, to fulfill your requests for certain
        products and services, and to analyze how you use the Grabbi Platform.
        In certain cases, we may also share some Personal Information with third
        parties, but only as described below.
      </Box>
      <Box component="p" className="privacy_paragraph">
        As noted in the {link("Terms of Service", "terms-of-service")}, we do
        not knowingly collect or solicit personal information from anyone under
        the age of 18. If you are under 18, please do not attempt to register
        for the Grabbi Platform or send any personal information about yourself
        to us. If we learn that we have collected personal information from a
        child under the age of 18, we will delete that information as quickly as
        possible. If you believe that a child under 18 years old may have
        provided us personal information, please contact us at{" "}
        {link("support@grabbi.shop", "mailto:support@grabbi.shop", true)}
      </Box>
      <Box className="privacy_subheader">
        Will Grabbi ever change this Privacy Policy?
      </Box>
      <Box component="p" className="privacy_paragraph">
        We’re constantly trying to improve the Grabbi Platform, so we may need
        to change this Privacy Policy from time to time as well, but we will
        alert you to any changes by placing a notice on our <u>website</u>, by
        sending you an email, and/or by some other means.Please note that if
        you’ve opted not to receive legal notice emails from us (or you haven’t
        provided us with your email address), those legal notices will still
        govern your use of the Grabbi Platform, and you are still responsible
        for reading and understanding them. If you use the Grabbi Platform after
        any changes to the Privacy Policy have been posted, that means you agree
        to all of the changes made.
      </Box>
      <Box className="privacy_subheader">
        What Information does Grabbi Collect?
      </Box>
      <Box component="p" className="privacy_paragraph">
        <i>Information You Provide to Us:</i>
      </Box>
      <Box component="p" className="privacy_paragraph">
        We receive and store any information you knowingly provide to us. For
        example, we may collect Personal Information such as your name, email
        address, phone number, pictures or videos of you and third-party account
        credentials (for example, your log-in credentials for Facebook, Google,
        or other third-party sites). If you provide your third-party account
        credentials to us or otherwise sign in to the Grabbi Platform through a
        third party site or service, you understand some content and/or
        information in those accounts (“Third Party Account Information”) may be
        transmitted into your Account with us and that Third Party Account
        Information transmitted to the Grabbi Platform is covered by this
        Privacy Policy; for example, Google Sign In. In using this Third Party
        sign in, we may ask for your First Name, Last Name, Email Address and
        Google User ID in order to register an account on the Grabbi Platform.
      </Box>
      <Box component="p" className="privacy_paragraph">
        We may communicate with you via email, push notifications or SMS, MMS,
        or other text messages or through the Grabbi Platform. For example, we
        may send you promotional email offers on behalf of other businesses or
        email you about your use of the Grabbi Platform.Also, we may collect
        information regarding such communications, such as when you receive a
        confirmation and when you open an email from us. This confirmation helps
        us improve our communications with you and improve the Grabbi Platform.
        If you do not want to receive email communications from us, please
        indicate your preference by emailing us at{" "}
        {link("support@grabbi.shop", "mailto:support@grabbi.shop", true)}
      </Box>
      <Box component="p" className="privacy_paragraph">
        <i>Information Collected Automatically</i>
      </Box>
      <Box component="p" className="privacy_paragraph">
        Whenever you interact with the Grabbi Platform, we automatically receive
        and record information on our server logs from your browser or device,
        which may include your IP address, geolocation data, device
        identification, “cookie” information, the type of browser and/or device
        you’re using to access the Grabbi Platform, and the page or feature you
        requested. “Cookies” are identifiers we transfer to your browser or
        device that allow us to recognize your browser or device and tell us how
        and when pages and features in the Grabbi Platform are visited and by
        how many people (“Aggregate Information”). You may be able to change the
        preferences on your browser or device to prevent or limit your device’s
        acceptance of cookies, but this may prevent you from taking advantage of
        some ofthe Grabbi Platform’s features.We may use cookies to perform
        taskssuch as: monitoring aggregate site usage metrics, storing and
        remembering your passwords (if you allow us to do so), storing account
        and advertising preferences that you have set, and personalizing the
        Grabbi Platform.
      </Box>
      <Box component="p" className="privacy_paragraph">
        Our advertising partners may also transmit cookies to your browser or
        device when you click on ads that appear on the Grabbi Platform. Also,
        if you click on a link to a third-party website or service, a third
        party may also transmit cookies to you. Again, this Privacy Policy does
        not cover the use of cookies by any third parties and we aren’t
        responsible for their privacy policies and practices.Please be aware
        that cookies placed by third parties may continue to track your
        activities online even after you have left the Grabbi Platform and those
        third parties may not honor “Do Not Track” requests you have set using
        your browser or device. Please see below for our policy on “Do Not
        Track” requests.
      </Box>
      <Box component="p" className="privacy_paragraph">
        We may use this data to customize content for you that we think you
        might like based on your usage patterns. We may also use it to improve
        the Grabbi Platform.For example, the collected data can tell us how
        often users use a particular feature of the Grabbi Platform and we can
        use that knowledge to customize and improve the Grabbi Platform.
      </Box>
      <Box component="p" className="privacy_paragraph">
        Our Merchants may have devices that take pictures or videos of you while
        you are on their premises (including of your likeness) and we may use
        any such images or videos in connection with the Grabbi Platform, such
        as for the purpose of preventing theft, improving the speed of
        checkouts, and for other security related purposes.
      </Box>
      <Box component="p" className="privacy_paragraph">
        We use Google Analytics, Facebook Analytics, Apple App Analytics and
        other analytic services to help analyze how Members use the Grabbi
        Platform.
      </Box>
      <Box component="p" className="privacy_paragraph">
        We use Google Analytics to collect and process some personal information
        about you. For more information about Google Analytics,please review the
        site “How Google uses data when you use our partners’ sites or apps,”
        (located at{" "}
        {link(
          "www.google.com/policies/privacy/partners/",
          "https://www.google.com/policies/privacy/partners/",
          true
        )}
        ).
      </Box>
      <Box component="p" className="privacy_paragraph">
        <i>Information Collected From Other Websites and Do Not Track Policy</i>
      </Box>
      <Box component="p" className="privacy_paragraph">
        Through cookies we place on your browser or device, we may collect
        information about your online activity after you leave the Grabbi
        Platform. Just like any other usage information we collect, this
        information allows us to improve the Grabbi Platform and customize your
        online experience. Your browser may offer you a “Do Not Track”option,
        which allows you to signal to operators of websites and web applications
        and services (including behavioral advertising services) that you do not
        wish such operators to track certain of your online activities over time
        and across different websites. Our Services do not support Do Not Track
        requests at this time, which means that wecollect information about your
        online activity both while you are using the Grabbi Platform and after
        you leave the Grabbi Platform.
      </Box>
      <Box className="privacy_subheader">
        Will Grabbi Share Any of the Personal Information it Receives?
      </Box>
      <Box component="p" className="privacy_paragraph">
        We do not rent or sell your Personal Information to anyone except
        expressly set forth below; however, we may rent or sell the Aggregate
        Information that we collect from users’ use of the Grabbi Platform. We
        may share your Personal Information with third parties as described
        below.
      </Box>
      <Box component="p" className="privacy_paragraph">
        <Box component="span" className="privacy_bold">
          Information that’s been de-identified.
        </Box>{" "}
        We may de-identify your Personal Information so that you are not
        identified as an individual and provide that information to our
        partners. We may also provide Aggregate Information to our partners (or
        allow partners to collect that information from you), who may use such
        information to understand how often and in what ways people use the
        Grabbi Platform, so that they, too, can provide you with an optimal
        experience. However, we will never disclose Aggregate informatoin or
        de-identified information to a partner (or allow a partner to collect
        such information) in a manner that would identify you as an individual
        person.
      </Box>
      <Box component="p" className="privacy_paragraph">
        <Box component="span" className="privacy_bold">
          Affiliated Businesses:
        </Box>{" "}
        In certain situations, businesses, merchant store fronts, or third party
        websites we’re affiliated with may sell or provide products or services
        to you through, or in connection with, the Grabbi Platform (either alone
        or jointly with us). You can recognize when an affiliated business is
        associated with such a transaction or service and we will share your
        Personal Information with that affiliated business only to the extent
        that it is related to such transaction or service. One such service may
        include the ability for you to automatically transmit Third Party
        Account Information to your Account profile or to automatically transmit
        information in your Account profile to your third party account; for
        example, Google signin. We have no control over the policies and
        practices of third-party websites or businesses as to privacy or
        anything else, so if you choose to take part in any transaction or
        service relating to an affiliated website or business, please review all
        such business’ or websites’ policies.
      </Box>
      <Box component="p" className="privacy_paragraph">
        <Box component="span" className="privacy_bold">
          Agents:
        </Box>{" "}
        We employ other companies and people to perform tasks on our behalf and
        need to share your information with them to provide products or services
        to you. For example, we may use a payment processing company to receive
        and process your credit card transactions for us. Unless otherwise
        stated, our agents do not have any right to use the Personal Information
        we share with them beyond what is necessary to assist us. Note that an
        “agent” may also be considered a “partner” in certain circumstances and
        would be subject to the terms of the{" "}
        <Box component="span" className="privacy_bold">
          “Information that’s been de-identified”
        </Box>{" "}
        section.
      </Box>
      <Box component="p" className="privacy_paragraph">
        <Box component="span" className="privacy_bold">
          User Profiles and Submissions:
        </Box>{" "}
        Certain user profile information, including your name, location, and any
        video or image content that such user has uploaded to the Grabbi
        Platform , may be displayed to other users to facilitate user
        interaction within the Grabbi Platform or address your request for our
        Services. Your Account privacy settings may allow you to limit the other
        users who can see the Personal Information in your user profile and/or
        what information in your user profile is visible to others. Please
        remember that any content you upload to your public user profile, along
        with any Personal Information or content that you voluntarily disclose
        online in a manner other users can view (on discussion boards, in
        messages and chat areas, etc.) becomes publicly available and can be
        collected and used by anyone. Your username may also be displayed to
        other users if and when you send messages or comments or upload images
        or videos through the Grabbi Platform and other users can contact you
        through messages and comments. Additionally, if you sign into the Grabbi
        Platform through a third party social networking site or service, your
        list of “friends” from that site or service may be automatically
        imported to the Grabbi Platform and such “friends” if they are also
        registered users of the Grabbi Platform , may be able to access certain
        non-public information you have entered in your Account. Again, we do
        not control the policies and practices of any other third-party site or
        service.
      </Box>
      <Box component="p" className="privacy_paragraph">
        <Box component="span" className="privacy_bold">
          Business Transfers:
        </Box>{" "}
        We may choose to buy or sell assets and may share and/or transfer
        customer information in connection with the evaluation of and entry into
        such transactions. Also, if we (or our assets) are acquired or if we go
        out of business, enter bankruptcy, orgo through some other change of
        control, Personal Information could be one of the assets transferred to
        or acquired by a third party.
      </Box>
      <Box component="p" className="privacy_paragraph">
        <Box component="span" className="privacy_bold">
          Protection of Grabbi and Others:
        </Box>{" "}
        We reserve the right to access,read, preserve, and disclose any
        information that we believe is necessary to comply with the law or a
        court order; enforce or apply our{" "}
        {link("Terms of Service", "terms-of-service")} and other agreements; or
        protect the rights, property, or safety of Grabbi, our employees, our
        users, or others.
      </Box>
      <Box className="privacy_subheader">
        Is Personal Information about me secure?
      </Box>
      <Box component="p" className="privacy_paragraph">
        Your account is protected by a password for your privacy and security.
        If you access your account via a third-party site or service, you may
        have additional or different sign-on protections via that third party
        site or service. You must prevent unauthorized access to your Account
        and Personal Information by selecting and protecting your password
        and/or other sign-on mechanism appropriately and limiting access to your
        computer or device and browser by signing off after you have finished
        accessing your Account.
      </Box>
      <Box component="p" className="privacy_paragraph">
        We endeavor to protect the privacy of your Account and other Personal
        Information we hold in our records, but unfortunately, we cannot
        guarantee complete security. Unauthorized entry or use, hardware or
        software failure, and other factors, may compromise the security of user
        information at any time.
      </Box>
      <Box className="privacy_subheader">
        What Personal Information can I access?
      </Box>
      <Box component="p" className="privacy_paragraph">
        Through your Account settings, you may access and, in some cases, edit
        or delete the following information you’ve provided to us:
      </Box>
      <Box component="ul" className="privacy_paragraph">
        <li>name and password</li>
        <li>email address</li>
        <li>location</li>
        <li>
          user profile information, including images and videos you have
          uploaded to the Grabbi Platform
        </li>
      </Box>
      <Box component="p" className="privacy_paragraph">
        The information you can view, update, and delete may change as the
        Grabbi Platform changes. If you have any questions about viewing or
        updating information we have on file about you, please contact us at{" "}
        {link("support@grabbi.shop", "mailto:support@grabbi.shop", true)}.
      </Box>
      <Box component="p" className="privacy_paragraph">
        Under California Civil Code Sections 1798.83-1798.84, California
        residents are entitled to contact us to prevent disclosure of Personal
        Information to third parties for such third parties’ direct marketing
        purposes. In order to submit such a request, please contact us at{" "}
        {link("support@grabbi.shop", "mailto:support@grabbi.shop", true)}.
      </Box>
      <Box className="privacy_subheader">
        What choices do I have?
      </Box>
      <Box component="p" className="privacy_paragraph">
        You can always opt not to disclose information to us, but keep in mind
        some information may be needed to register with us or to take advantage
        of some of the Grabbi Platform features. You can opt-out by emailing us
        at {link("support@grabbi.shop", "mailto:support@grabbi.shop", true)}.
      </Box>
      <Box component="p" className="privacy_paragraph">
        You may be able to add, update, or delete information as explained
        above. When you update information; however, we may maintain a copy of
        the unrevised information in our records. You may request the
        deactivation of your Account by emailing{" "}
        {link("support@grabbi.shop", "mailto:support@grabbi.shop", true)}. Even
        after you deactivate your Account, your Personal Information may remain
        in our records. We may use any AggregateInformation derived from or
        incorporating your Personal Information after you update or deactivate
        it, but not in a manner that would identify you personally.
      </Box>
      <Box className="privacy_subheader">
        What if I have questions about this policy?
      </Box>
      <Box component="p" className="privacy_paragraph">
        If you have any questions or concerns regarding our privacy
        policies,please send us a detailed message to{" "}
        {link("support@grabbi.shop", "mailto:support@grabbi.shop", true)} and we
        will address your concerns.
      </Box>
    </Box>
  );
};

export default Privacy;

let urlContext = "/"

const hostname = window && window.location && window.location.hostname;

if(hostname === 'localhost') {
  urlContext = "/PayGexServer/";
} 

export const CONTEXT = `${urlContext}`;

//Adding comment to force deployment
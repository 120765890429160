import {
  confirmMerchantStartAction,
  resendConfirmMerchantStartAction,
} from "actions";
import { connect } from "react-redux";
import { State } from "store";
import ConfirmMerchantComponent from "./ConfirmMerchantComponent";

const mapStateToProps = (state: State) => ({
  merchantConfirmed: state.confrimMerchantState.merchantConfirmed,
  merchantConfirmedError: state.confrimMerchantState.merchantConfirmedError,
  merchantConfirmedLoading: state.confrimMerchantState.merchantConfirmedLoading,
  merchantConfirmResendSuccess:
    state.confrimMerchantState.merchantConfirmResendSuccess,
  merchantConfirmResendLoading:
    state.confrimMerchantState.merchantConfirmResendLoading,
  merchantConfirmResendError:
    state.confrimMerchantState.merchantConfirmResendError,
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatchConfirmMerchant: (token: string) =>
    dispatch(confirmMerchantStartAction(token)),
  dispatchResendConfirmMerchant: (token: string) =>
    dispatch(resendConfirmMerchantStartAction(token)),
});

export const ConfirmMerchant = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmMerchantComponent);

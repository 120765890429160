import { useAxiosContext } from "components/Shared/AxiosProvider/AxiosProvider";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import GrabbiDialog from "components/Shared/GrabbiDialog/GrabbiDialogComponent";
import GrabbiTextField from "components/Shared/GrabbiTextField/GrabbiTextField";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";
import SelectionStateChip from "components/Shared/SelectionStateChip/SelectionStateChipComponent";
import useTransactionHooks from "hooks/TransactionHooks";
import { sumBy } from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  AddTransactionPayload,
  GrabbiMerchandise,
  GrabbiStore,
} from "types/GrabbiTypes";
import MerchandiseSelector, {
  useMerchSelection,
} from "../MerchandiseSelector/MerchandiseSelectorComponent";
import RestaurantBoardDialogHeader from "../RestaurantBoardDialogHeader/RestaurantBoardDialogHeader";
import UnavailableCheckbox from "../UnavailableCheckbox/UnavailableCheckbox";
import "./OpenTabDialogComponent.scss";

const { REACT_APP_SELECTOR_TESTING_MODE } = process.env;

interface Props {
  open: boolean;
  store: GrabbiStore;
  onClose: () => void;
  refreshCallback: () => void;
}

const OpenTabDialog: React.FC<Props> = ({
  open,
  store,
  onClose,
  refreshCallback,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, errors, getValues, control } = useForm({
    shouldFocusError: true,
    reValidateMode: "onBlur",
  });
  const [selectedTableNumber, setSelectedTableNumber] = useState<
    string | undefined
  >(REACT_APP_SELECTOR_TESTING_MODE === "true" ? "1" : undefined);

  const {
    showUnavailable,
    merchandiseCartSelection,
    getCartTotal,
    getCartQuantity,
    toggleShowCart,
    isCartValid,
    toggleShowUnavailable,
  } = useMerchSelection();

  const handleOnClose = () => {
    onClose();
  };

  const { useAxios } = useAxiosContext();

  //Get Merchandise - Start
  const [{ data: dataMerchandise }] = useAxios<GrabbiMerchandise[]>(
    {
      url: `restful-services/merchandise/${store.id}`,
      method: "GET",
    },
    {
      manual: false,
    }
  );

  const handleSubmitTableValid = () => {
    const tableNumber = getValues("table_number");
    setSelectedTableNumber(tableNumber);
  };

  const tableSelected = selectedTableNumber !== undefined;

  const handleSubmitTableError = () => {
    enqueueSnackbar("Please resolve errors and try again.", {
      variant: "error",
    });
  };

  const storeId = store.id;

  const { useAddTransaction } = useTransactionHooks();

  const [{ loading: loadingAddTransaction, error }, addTransaction] =
    useAddTransaction();

  useEffect(() => {
    if (error) {
      enqueueSnackbar("Failed to create transaction", {
        variant: "error",
      });
    }
  }, [error, enqueueSnackbar]);

  const handleCreateTransaction = () => {
    const data: AddTransactionPayload | undefined =
      selectedTableNumber !== undefined && storeId !== undefined
        ? {
            payload: {
              currency: store.currency,
              payLater: true,
              storeId,
              tableNumber: selectedTableNumber,
              totalAmount: sumBy(merchandiseCartSelection, "price"),
              totalTax: sumBy(merchandiseCartSelection, "taxAmount"),
              transactionTotal:
                sumBy(merchandiseCartSelection, "price") +
                sumBy(merchandiseCartSelection, "taxAmount"),
              orderItems: merchandiseCartSelection.map((item) => ({
                itemTax: item.taxAmount,
                merchandiseId: item.merchandiseId,
                quantity: item.quantity,
                toppings: item.toppings,
                itemTotal: item.price * item.quantity,
              })),
              paymentType: "ATTENDANT_CHECKOUT",
            },
          }
        : undefined;

    if (data) {
      addTransaction({ data }).then((data) => {
        enqueueSnackbar("Items added to Transaction", { variant: "success" });
        refreshCallback();
        handleOnClose();
      });
    }
  };

  useEffect(() => {
    if (
      control?.fieldsRef?.current?.table_number?.ref?.focus &&
      getValues("table_number").length === 0
    ) {
      control.fieldsRef.current.table_number.ref.focus();
    }
  });

  const transactionTotal = getCartTotal();
  const canOpenTab = merchandiseCartSelection.length > 0;
  const cartValid = isCartValid();

  return (
    <GrabbiDialog
      dialogProps={{
        open,
        classes: { paper: "open_tab_dialog_root resturant_dashboard_dialog" },
      }}
      dialogContentProps={{ classes: { root: "open_tab_dialog_content" } }}
      dialogHeader={
        <RestaurantBoardDialogHeader
          title="Open New Tab"
          actionArea={
            tableSelected ? (
              <UnavailableCheckbox
                checked={showUnavailable}
                onChange={toggleShowUnavailable}
              />
            ) : undefined
          }
          onClose={handleOnClose}
        />
      }
      dialogFooter={
        <div className="footer_container">
          {!tableSelected ? (
            <GrabbiButton
              type="submit"
              form="table_number_form"
              shapeVariant="rounded"
              colorVariant="black"
              className="add_items_button"
            >
              Select Items
            </GrabbiButton>
          ) : !loadingAddTransaction ? (
            <React.Fragment>
              <SelectionStateChip
                quantity={getCartQuantity()}
                total={transactionTotal}
                currency={store.currency ?? "USD"}
                onClick={toggleShowCart}
              />
              {canOpenTab && (
                <GrabbiButton
                  disabled={loadingAddTransaction}
                  className="add_items_button"
                  shapeVariant="rounded"
                  colorVariant="black"
                  onClick={cartValid ? handleCreateTransaction : undefined}
                >
                  Open Tab
                </GrabbiButton>
              )}
            </React.Fragment>
          ) : (
            <LoadingIcon />
          )}
        </div>
      }
    >
      {!tableSelected ? (
        <form
          id="table_number_form"
          className="open_tab_dialog_content"
          autoComplete="off"
          onSubmit={handleSubmit(
            handleSubmitTableValid,
            handleSubmitTableError
          )}
        >
          <GrabbiTextField
            className="table_number_field"
            type="tel" //To force mobile keyboard to display numbers
            label={"Table Number (Optional)"}
            control={control}
            placeholder="Table Number"
            name="table_number"
            error={errors.table_number !== undefined}
            errorMessage={errors?.table_number?.message}
            ref={register({
              maxLength: {
                value: 16,
                message: "Cannot exceed 16 characters",
              },
            })}
          />
        </form>
      ) : dataMerchandise ? (
        <MerchandiseSelector merchandise={dataMerchandise} store={store} />
      ) : (
        <LoadingIcon />
      )}
    </GrabbiDialog>
  );
};

export default React.memo(OpenTabDialog);

import React from 'react';
import './SuccessMessageComponent.scss';
import { Box, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useLocation } from "react-router-dom";

interface Props {}

interface SuccessState {
  mainMessage?: string;
  secondMessage?: string;
  buttonText?: string;
  goTo?: string;
}

const SuccessMessage: React.FC<Props> = () => {
  const history = useHistory();
  const location = useLocation();
  const mainMessage = location.state ? (location.state as SuccessState).mainMessage : '';
  const secondMessage = location.state ? (location.state as SuccessState).secondMessage : '';
  const buttonText = location.state ? (location.state as SuccessState).buttonText : '';
  const goTo = location.state ? (location.state as SuccessState).goTo : '';

  const handleNavigation = () => {
    history.push(`${goTo}`);
  };

  return (
    <Box className="success_message_root">
			<div className="success_message_icon"></div>
      <Box className="success_message_header">
        {mainMessage}
      </Box>
      <Box className="success_message_sub_header">
        {secondMessage}
      </Box>
      <Button
        classes={{ root: "success_message_navigate_to_button" }}
        onClick={() => {
          handleNavigation();
        }}
      >
        {buttonText}
      </Button>
    </Box>
  );
}

export default SuccessMessage;
import React from "react";
import { Radio, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    border: "thick solid",
    borderColor: "#000000",
    borderWidth: 3,

    width: 18,
    height: 18,
    backgroundColor: "#FFF100",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgb(255,255,255)",
      outlineOffset: 2,
    },
  },
  checked_icon: {
    backgroundColor: "#000000",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 18,
      height: 18,
      backgroundImage: "radial-gradient(#fff,#fff 30%,transparent 20%)",
      content: '""',
    },
  },
});

const StyledRadioButton = (props: any) => {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={
        <span className={clsx(classes.icon, classes.checked_icon)} />
      }
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};

export default StyledRadioButton;

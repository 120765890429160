import axios from "axios";
import Axios from "axios";
import { makeUseAxios, UseAxios } from "axios-hooks";
import { DeviceUUID } from "device-uuid";
import { DateTime } from "luxon";
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Cookies } from "react-cookie";
interface LoginState {
  tokenUuid?: string;
  accessToken?: string;
  refreshToken?: string;
  error?: string;
  isAuthenticated: boolean;
  loading: boolean;
  loginType?: "customer" | "merchant" | "admin";
}
interface LoginContextProps extends LoginState {
  login: (username: string, password: string) => Promise<void>;
  customerLogin: () => Promise<void>;
  logout: () => void;
  customerLogout: () => void;
  clearError: () => void;
  setToken: (accessToken: string) => void;
  useAxios: UseAxios;
}

const DEFAULT_STATE: LoginState = {
  isAuthenticated: false,
  loading: false,
};

const DEFAULT_PROPS: LoginContextProps = {
  ...DEFAULT_STATE,
  login: async (username: string, password: string) => {},
  customerLogin: async () => {},
  logout: () => {},
  customerLogout: () => {},
  clearError: () => { },
  setToken: (accessToken: string) => {},
  useAxios: makeUseAxios({
    axios: axios.create({
      baseURL: process.env?.API_CONTEXT ?? "",
    }),
    defaultOptions: {
      manual: true,
    },
  }),
};

const TOKEN_UUID_KEY = "tokenUUID";
const GUEST_UUID_KEY = "guestUUID";
const ACCESS_TOKEN_KEY = "customerAccessToken";
const REFRESH_TOKEN_KEY = "customerRefreshToken";
const LOGIN_TYPE_KEY = "loginType";

const loginContext = React.createContext<LoginContextProps>(DEFAULT_PROPS);

export const useLoginContext = () => React.useContext(loginContext);

const LoginContextProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [loginState, setLoginState] = useState<LoginState>(DEFAULT_STATE);

  useEffect(() => {
    const cookies = new Cookies();
    setLoginState((state) => ({
      ...state,
      accessToken: cookies.get(ACCESS_TOKEN_KEY),
      refreshToken: cookies.get(REFRESH_TOKEN_KEY),
    }));
  }, [setLoginState]);

  const setLoading = useCallback(
    (loading: boolean) =>
      setLoginState((loginState) => ({ ...loginState, loading })),
    [setLoginState]
  );

  const login = useCallback(async (username: string, password: string) => {
    throw new Error("Not Implemented");
  }, []);

  const customerLogin = useCallback(async () => {
    setLoading(true);
    const cookies = new Cookies();
    const existingUUID = cookies.get(GUEST_UUID_KEY);
    const newUUID = new DeviceUUID().get();
    const uuid = existingUUID ?? newUUID;
    const now = DateTime.now();
    const expirationTime = now.plus({ hours: 24 }).toJSDate();
    const cookiesConfig = { path: "/", expires: expirationTime };
    if (!existingUUID) {
      cookies.set(GUEST_UUID_KEY, newUUID, cookiesConfig);
    }
    const tokenUuid = cookies.get(TOKEN_UUID_KEY);
    const accessToken = cookies.get(ACCESS_TOKEN_KEY);
    const refreshToken = cookies.get(REFRESH_TOKEN_KEY);
    const loginType = cookies.get(LOGIN_TYPE_KEY);

    if (accessToken && refreshToken && loginType && loginType === "customer") {
      setLoginState((loginState) => ({
        ...loginState,
        loading: false,
        tokenUuid,
        accessToken,
        refreshToken,
        isAuthenticated: true,
        error: undefined,
        loginType,
      }));
    } else {
       Axios.post<CustomerLoginResponse>(
         `/auth/guest`,
         `deviceUuid=${uuid}&currency=USD`,
         {
           headers: {
             "Content-Type": "application/x-www-form-urlencoded",
           },
         }
       )
         .then((loginData) => {
           const { accessToken, refreshToken, tokenUuid } = loginData.data;
           cookies.set(TOKEN_UUID_KEY, tokenUuid, cookiesConfig)
           cookies.set(ACCESS_TOKEN_KEY, accessToken, cookiesConfig);
           cookies.set(REFRESH_TOKEN_KEY, refreshToken, cookiesConfig);
           cookies.set(LOGIN_TYPE_KEY, loginType, cookiesConfig);
           setLoginState((loginState) => ({
             ...loginState,
             loading: false,
             accessToken,
             refreshToken,
             tokenUuid,
             isAuthenticated: true,
             error: undefined,
             loginType,
           }));
         })
         .catch((err) => {
           setLoginState((loginState) => ({
             ...loginState,
             loading: false,
             error: "Failed to login",
           }));
         });
    }
  }, [setLoading, setLoginState]);

  const logout = useCallback(() => {
    throw new Error("Not Implemented");
  }, []);

  const customerLogout = useCallback(
    async () =>
      setLoginState((loginState) => ({
        ...loginState,
        accessToken: undefined,
        refreshToken: undefined,
        isAuthenticated: false,
        loginType: undefined,
      })),
    [setLoginState]
  );

  const clearError = useCallback(
    () => setLoginState((loginState) => ({ ...loginState, error: undefined })),
    [setLoginState]
  );

  //? Used when login is performed outside this context to update AxiosProvider
  const setToken = useCallback((accessToken: string) => {
    setLoginState(state => ({...state, accessToken}))
  }, [setLoginState]);

  const axiosInstance = axios.create({
    baseURL: process.env?.API_CONTEXT ?? "",
    headers: {
      Authorization: loginState.accessToken
        ? `Bearer ${loginState.accessToken}`
        : undefined,
    },
  });

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    (error) => {
      const errorMessage = error?.response?.data?.message;
      if (errorMessage?.toLocaleLowerCase()?.includes("expired")) {
        setLoginState((state) => ({
          ...state,
          accessToken: undefined,
          refreshToken: undefined,
        }));
      }
    }
  );

  const useAxios = makeUseAxios({
    axios: axiosInstance,
    defaultOptions: {
      manual: true,
    },
  });

  const value: LoginContextProps = useMemo(() => {
    return {
      ...loginState,
      login,
      customerLogin,
      logout,
      customerLogout,
      clearError,
      setToken,
      useAxios,
    };
  }, [loginState, useAxios, login, customerLogin, logout, customerLogout, clearError, setToken]);

  return (
    <loginContext.Provider value={value}>{children}</loginContext.Provider>
  );
};

export default LoginContextProvider;

interface CustomerLoginResponse {
  emailVerified: boolean;
  authProvider: string;
  tokenUuid: string;
  tokenType: string;
  accessToken: string;
  userName: string;
  email: string;
  status: boolean;
  refreshToken: string;
}

import OrderCode from "components/Shared/OrderCode/OrderCodeComponent";
import React from "react";
import { Transaction } from "types/GrabbiTypes";
import "./CashierNotifiedComponent.scss";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";

interface Props {
  className?: string;
  transaction: Transaction;
  onCheckout: () => void;
  onClose: () => void;
}

const CashierNotified: React.FC<Props> = ({
  className,
  transaction,
  onCheckout,
  onClose,
}) => {
  return (
    <div className={`cashier_notified_root ${className ?? ""}`}>
      <div className="header">
        <div className="close_button" onClick={onClose}>
          CLOSE
        </div>
      </div>
      <div className="cashier_woman_icon" />
      <div className="title">Cashier Notified</div>
      <div className="order_info">
        <span>Order</span>
        <OrderCode
          transaction={transaction}
          hideOrderedOn={true}
          className="order_code"
        />
      </div>
      <span className="message">
        Your cashier has been notified, please wait for your cashier to take
        payment.
      </span>
      <GrabbiButton className="m-t-2" onClick={onCheckout}>
        Checkout now
      </GrabbiButton>
    </div>
  );
};

export default CashierNotified;

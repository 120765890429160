import React from "react";
import { GrabbiTopping } from "types/GrabbiTypes";
import "./ToppingChipComponent.scss";

interface Props {
  className?: string;
  topping: GrabbiTopping;
  isChecked: boolean;
  onClick?: () => void;
  disabled: boolean;
}

const ToppingChip: React.FC<Props> = ({
  className,
  topping,
  isChecked,
  onClick,
  disabled,
}) => {
  return (
    <div
      className={`topping_chip_root ${className ?? ""} ${
        disabled ? "disabled" : ""
      }`}
      style={{
        backgroundColor: isChecked ? "#ffffff" : undefined,
        color: isChecked ? "#000000" : undefined,
      }}
      onClick={!disabled ? onClick : undefined}
    >
      <span className={`topping_label_text`}>
        <p>{topping.toppingUpc.label}</p>
        <p>{topping.price}</p>
        {!topping.available && (
          <span className="merchandise_text_status_warning">- Unavailable</span>
        )}
      </span>
    </div>
  );
};

export default ToppingChip;

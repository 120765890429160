import React, { useEffect } from "react";
import "./LogoutComponent.scss";
import { Box } from "@material-ui/core";
import { LoginState } from "store/signin/loginReducer";
import { CONTEXT } from "shared/scripts/UrlContext";
import { useHistory } from "react-router-dom";

interface Props {
  dispatchLogout: () => void;
  loginState: LoginState;
}

const LogoutComponent: React.FC<Props> = ({ dispatchLogout, loginState }) => {
  const history = useHistory();
  if (loginState && loginState.loggedIn && !loginState.checkingSession) {
    dispatchLogout();
  }

  useEffect(() => {
    if (
      loginState &&
      !loginState.loggedIn &&
      loginState.sessionChecked &&
      !loginState.checkingSession
    ) {
      history.push(`${CONTEXT}`);
    }
  }, [loginState, history]);

  return (
    <Box className="logout_root">
      <Box className="logout_text">Logging out...</Box>
      <Box className="logout_text">You will be redirected shortly.</Box>
    </Box>
  );
};

export default LogoutComponent;

import { changePasswordStartAction } from "actions";
import { connect } from "react-redux";
import { State } from "store";
import { Merchant } from "types/GrabbiTypes";
import ChangePasswordComponent from "./ChangePasswordComponent";

const mapStateToProps = (state: State) => ({
  merchant: state.merchantStores.merchant,
  changePasswordStatus: state.loginState.changePasswordStatus,
  changePasswordMessage: state.loginState.changePasswordMessage,
  changePasswordLoading: state.loginState.changePasswordLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatchChangePassword: (
    merchant: Merchant,
    currentPassword: string,
    newPassword: string,
    confirmPassword: string
  ) =>
    dispatch(
      changePasswordStartAction({ merchant, currentPassword, newPassword, confirmPassword })
    ),
});

export const ChangePassword = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordComponent);

import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import "./ManageAttendantComponent.scss";
import {
  GrabbiStore,
  GrabbiAttendant,
  GrabbiAttendantPayload,
} from "types/GrabbiTypes";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import { useAxiosContext } from "components/Shared/AxiosProvider/AxiosProvider";
import { LoginState } from "store/signin/loginReducer";
import GrabbiTextField from "components/Shared/GrabbiTextField/GrabbiTextField";
import { AddCircleOutline } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { NAME_REGEX, RFC_5322_EMAIL_REGEX } from "shared/scripts/Constants";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Cookies } from "react-cookie";

interface Props {
  stores?: GrabbiStore[];
  loadingStores: boolean;
  loginState: LoginState;
  dispatchGetStores: (id: number) => void;
}

const ManageAttendantComponent: React.FC<Props> = ({
  stores,
  loadingStores,
  loginState,
  dispatchGetStores,
}) => {
  const { useAxios } = useAxiosContext();

  if (!stores && !loadingStores && loginState) {
    dispatchGetStores(Number(loginState.merchantId));
  }
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isDirty },
    clearErrors,
  } = useForm({
    reValidateMode: "onBlur",
    shouldFocusError: true
  });

  const [listOfStoreAttendants, setListOfStoreAttendants] =
    useState<GrabbiAttendant[]>();
  const [store, setStore] = useState<GrabbiStore>();
  const [selectedStoreId, setSelectedStoreId] = useState(0);
  const [fieldsInitialized, setFieldsInitialized] = useState(false);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [fieldError, setFieldError] = useState(false);

  useEffect(() => {
    const cookies = new Cookies();
    setToken(cookies.get("accessToken"));
  }, [setToken]);

  const { enqueueSnackbar } = useSnackbar();

  const selectedStore =
    selectedStoreId !== undefined ? stores?.[selectedStoreId] : undefined;
  const initializeStoreFields = useCallback(() => {
    if (selectedStore !== undefined) {
      setSelectedStoreId(selectedStore.id);
      setStore(selectedStore);
    }
    setFieldsInitialized(true);
  }, [selectedStore, setFieldsInitialized]);

  const [{ data: storeAttendants }, getStoreAttendants] = useAxios<
    GrabbiAttendant[]
  >({
    url: `/restful-services/store/${selectedStoreId}/attendants`,
    method: "GET",
  });

  const [
    {
      data: savedListOfStoreAttendants,
      error: errorSavingListOfStoreAttendants,
    },
    savingListOfStoreAttendants,
  ] = useAxios<GrabbiAttendantPayload>({
    url: `restful-services/store/attendant`,
    method: "POST",
  });

  useEffect(() => {
    if (savedListOfStoreAttendants) {
      enqueueSnackbar("Saving list of attendants completed", {
        variant: "success",
      });
    } else if (errorSavingListOfStoreAttendants) {
      enqueueSnackbar("Saving list of attendants failed", { variant: "error" });
    }
  }, [
    savedListOfStoreAttendants,
    errorSavingListOfStoreAttendants,
    enqueueSnackbar,
  ]);

  const setEmptyAttendant = (selectedStoreId: number) => {
    const emptyAttendatObject: GrabbiAttendant = {
      id: null,
      store: {
        id: selectedStoreId,
      },
      firstName: "",
      lastName: "",
      nickName: "",
      email: "",
      errorResponse: "",
      createdDate: 0,
      updatedDate: 0,
    };
    const emptyAttendant: GrabbiAttendant[] = [emptyAttendatObject];
    setListOfStoreAttendants(emptyAttendant);
  }

  useEffect(() => {
    if (!fieldsInitialized) {
      initializeStoreFields();
    }

    if (!storeAttendants && selectedStoreId !== 0) {
      getStoreAttendants();
    }
  }, [
    fieldsInitialized,
    getStoreAttendants,
    initializeStoreFields,
    listOfStoreAttendants,
    selectedStoreId,
    storeAttendants,
  ]);

  useEffect(() => {
    if (storeAttendants && storeAttendants.length > 0) {
      const storeObj = {
        id: selectedStoreId,
      };
      const newArray = storeAttendants.map((v) => ({ ...v, store: storeObj }));

      setListOfStoreAttendants([...newArray]);
    } else if (storeAttendants && storeAttendants.length === 0) {
      setEmptyAttendant(selectedStoreId);
    }
  }, [selectedStoreId, store, storeAttendants]);

  const handleInputChange = (e: any, index: number) => {
    if (listOfStoreAttendants !== undefined) {
      if (e.target.name === `email-${index}`) {
        const newEmailAddress = e.target.value;
        const searchedObjectIndex = listOfStoreAttendants.findIndex(
          (attendant) => attendant.email === newEmailAddress
        );
        if (searchedObjectIndex !== -1) {
          setError(`email-${index}`, {
            type: "custom",
            message: "Please choose a different email address.",
          });
          setFieldError(true);
        } else {
          clearErrors(`email-${index}`);
          setFieldError(Object.values(errors).length > 0);
        }
      }
      const updatedListOfStoreAttendants = [...listOfStoreAttendants!];
      let test: string = e.target.name.toString();
      updatedListOfStoreAttendants[index] = {
        ...updatedListOfStoreAttendants[index],
        [test.substring(0, test.indexOf("-"))]: e.target.value,
      };

      setListOfStoreAttendants([...updatedListOfStoreAttendants]);
    }
  };

  const handleAddAttendant = () => {
    const emptyAttendatObject: GrabbiAttendant = {
      id: null,
      store: {
        id: selectedStoreId,
      },
      firstName: "",
      lastName: "",
      nickName: "",
      email: "",
      errorResponse: "",
      createdDate: 0,
      updatedDate: 0,
    };
    if (listOfStoreAttendants !== undefined) {
      setListOfStoreAttendants((listOfStoreAttendants) => [
        ...listOfStoreAttendants!,
        emptyAttendatObject,
      ]);
    }
  };

  const removeAttendant = async (id: number) => {
    try {
      axios.delete(
        `restful-services/store/${selectedStoreId}/attendant/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTimeout(async () => {
        await getStoreAttendants();
      }, 20);
    } catch (error) {
      enqueueSnackbar("Error while deleting attandant", { variant: "error" });
    }
  };

  const handleRemoveAttendant = (index: number) => {
    removeAttendant(index);
  };

  const handleStoreAttendantsPayload = (
    listOfStoreAttendants: GrabbiAttendant[]
  ) => {
    const newArray = listOfStoreAttendants.map(
      ({ createdDate, errorResponse, updatedDate, ...keepAttrs }) => keepAttrs
    );
    return newArray;
  };

  const handleSavingStoreAttendants = async () => {
    if (listOfStoreAttendants) {
      const payload = handleStoreAttendantsPayload(listOfStoreAttendants);
      await savingListOfStoreAttendants({
        data: payload,
      });
      enqueueSnackbar("Updating profile details...", { variant: "info" });
    }
  };

  const handleSavingStoreAttendantsError = () => {
    enqueueSnackbar("Please resolve errors and try again.", {
      variant: "error",
    });
  };

  return (
    <React.Fragment>
      <div className="edit_profile_manage_attendant_content">
        <Box className="subtitle manage_attendant_subtitle">MANAGE STAFF</Box>
        <form
          onSubmit={handleSubmit(
            handleSavingStoreAttendants,
            handleSavingStoreAttendantsError
          )}
        >
          <div className="">
            {Array.from(
              listOfStoreAttendants !== undefined
                ? listOfStoreAttendants.entries()
                : []
            ).map(([key, item]) => (
              <div
                key={`container-${key}`}
                className="manage_attendants_group_container"
              >
                <div className="attendant_group_header">
                  <div
                    key={`member-${key}`}
                    className="attendant_group_header_text"
                  >
                    Member
                  </div>
                  {listOfStoreAttendants !== undefined && (
                    //   className="manage_attendant_button"
                    <div
                      key={`remove-${key}`}
                      onClick={() =>
                        handleRemoveAttendant(item.id ? item.id : 0)
                      }
                      className="attendant_group_header_remove_link"
                    >
                      Remove
                    </div>
                  )}
                </div>
                <div className="manage_attendants_container">
                  <div className="attendant_list_container">
                    <GrabbiTextField
                      ref={register({
                        minLength: {
                          value: 2,
                          message: "Must have 2 or more characters.",
                        },
                        maxLength: {
                          value: 40,
                          message: "Must have 40 or less characters",
                        },
                        required: {
                          value: true,
                          message: "Please enter a first name",
                        },
                        pattern: {
                          value: NAME_REGEX,
                          message: "First name may only contain letters.",
                        },
                      })}
                      classes={{
                        root: "tip_amount_field",
                      }}
                      name={`firstName-${key}`}
                      label="firstName"
                      value={item.firstName}
                      error={errors[`firstName-${key}`]}
                      errorMessage={errors?.[`firstName-${key}`]?.message}
                      onChange={(e: any) => handleInputChange(e, key)}
                      key={`firstName-${key}`}
                    />
                    <GrabbiTextField
                      ref={register({
                        minLength: {
                          value: 2,
                          message: "Must have 2 or more characters.",
                        },
                        maxLength: {
                          value: 40,
                          message: "Must have 40 or less characters",
                        },
                        required: {
                          value: true,
                          message: "Please enter a nickname",
                        },
                        pattern: {
                          value: NAME_REGEX,
                          message: "First name may only contain letters.",
                        },
                      })}
                      classes={{
                        root: "tip_amount_field",
                      }}
                      name={`nickName-${key}`}
                      label="nickName"
                      value={item.nickName}
                      error={errors[`nickName-${key}`]}
                      errorMessage={errors?.[`nickName-${key}`]?.message}
                      onChange={(e: any) => handleInputChange(e, key)}
                      key={`nickName-${key}`}
                    />
                  </div>
                  <div className="attendant_list_container">
                    <GrabbiTextField
                      ref={register({
                        minLength: {
                          value: 2,
                          message: "Must have 2 or more characters.",
                        },
                        maxLength: {
                          value: 40,
                          message: "Must have 40 or less characters",
                        },
                        required: {
                          value: true,
                          message: "Please enter a last name",
                        },
                        pattern: {
                          value: NAME_REGEX,
                          message: "Last name may only contain letters.",
                        },
                      })}
                      classes={{
                        root: "tip_amount_field",
                      }}
                      name={`lastName-${key}`}
                      label="Last Name"
                      value={item.lastName}
                      error={errors[`lastName-${key}`]}
                      errorMessage={errors?.[`lastName-${key}`]?.message}
                      onChange={(e: any) => handleInputChange(e, key)}
                      key={`lastName-${key}`}
                    />
                    <GrabbiTextField
                      ref={register({
                        minLength: {
                          value: 6,
                          message: "6 or more characters needed",
                        },
                        maxLength: {
                          value: 50,
                          message: "50 or less characters needed",
                        },
                        required: {
                          value: true,
                          message: "Enter email address",
                        },
                        pattern: {
                          value: RFC_5322_EMAIL_REGEX,
                          message: "Invalid email address",
                        },
                      })}
                      classes={{
                        root: "tip_amount_field",
                      }}
                      name={`email-${key}`}
                      label="Email"
                      value={item.email}
                      error={errors[`email-${key}`]}
                      errorMessage={errors?.[`email-${key}`]?.message}
                      onChange={(e: any) => handleInputChange(e, key)}
                      key={`email-${key}`}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="save_attendant_button_container">
            <div className="add_more_attendant_button_container">
              <AddCircleOutline
                className="add_more_attendant_button"
                onClick={handleAddAttendant}
              />
              <span className="add_more_attendant_button_text">ADD MORE</span>
            </div>
            <div>
              <GrabbiButton
                type="submit"
                classes={{
                  root: "edit_profile_manage_attendant_content save_attendant_button",
                }}
                disabled={
                  (fieldError && Object.values(errors).length !== 0) || !isDirty
                }
              >
                SAVE
              </GrabbiButton>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default ManageAttendantComponent;

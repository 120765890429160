import { combineReducers } from "redux";
import {
  StoreListState,
  storeCollectionReducer,
} from "./stores/storesReducer";
import {
  CreatedMerchantState,
  createdMerchantReducer,
} from "./merchant/createdMerchantReducer";
import {
  CreatedLeadForMarketingState,
  createdLeadForMarketingReducer,
} from "./merchant/createdLeadForMarketingReducer";

import {
  MerchantResetPasswordState,
  merchantResetPasswordReducer,
} from "./merchant/merchantResetPasswordReducer";
import { LoginState, loginReducer } from "./signin/loginReducer";
import {
  MerchantStoreState,
  merchantStoreReducer,
} from "./merchant/merchantReducer";
import { RegionsState, regionReducer } from "./regions/regions";
import { adminReducer, AdminState } from "./admin/adminReducer";
import {
  confirmMerchantReducer,
  ConfirmMerchantState,
} from "./confirmMerchant/confirmMerchantReducer";
import { merchandiseReducer, MerchandiseState } from "./merchant/merchandiseReducer";
import { transactionReducer, TransactionState } from "./merchant/transactionsReducer";
import {
  CreatedLeadFromStarterKitState,
  createdLeadFromStarterKitReducer,
} from "./merchant/createdLeadFromStarterKitReducer";
import { CustomerState, membershipReducer } from "./customer/customerReducer";

export interface State {
  merchantStores: MerchantStoreState;
  storeList: StoreListState;
  loginState: LoginState;
  regionState: RegionsState;
  adminState: AdminState;
  createdMerchantState: CreatedMerchantState;
  sendRequestToResetPasswordState: MerchantResetPasswordState;
  createdLeadForMarketingState: CreatedLeadForMarketingState;
  confrimMerchantState: ConfirmMerchantState;
  transactions: TransactionState;
  merchandiseState: MerchandiseState;
  createdLeadFromStarterKitState: CreatedLeadFromStarterKitState;
  customerState: CustomerState;
};

export const rootReducer = combineReducers<State>({
  merchantStores: merchantStoreReducer,
  storeList: storeCollectionReducer,
  loginState: loginReducer,
  regionState: regionReducer,
  adminState: adminReducer,
  createdMerchantState: createdMerchantReducer,
  sendRequestToResetPasswordState: merchantResetPasswordReducer,
  createdLeadForMarketingState: createdLeadForMarketingReducer,
  confrimMerchantState: confirmMerchantReducer,
  transactions: transactionReducer,
  merchandiseState: merchandiseReducer,
  createdLeadFromStarterKitState: createdLeadFromStarterKitReducer,
  customerState: membershipReducer
});

export default rootReducer;

import React, { PropsWithChildren } from "react";
import { useAxiosContext } from "components/Shared/AxiosProvider/AxiosProvider";
import { filter, flatten, forEach, keys, map } from "lodash";
import { useCallback, useMemo } from "react";
import { ErrorType1, GrabbiMerchandise } from "types/GrabbiTypes";
import { CreateAxiosHookDefault } from "./utils";

export interface MerchandiseContextProps {
  useGetMerchandise: (
    settings?: AxiosSettings
  ) => FakeUseAxiosResultWithParams<
    GrabbiMerchandise[],
    ErrorType1,
    { id: number }
  >;
  useGetAllToppingGroups: (
    settings?: AxiosSettings
  ) => FakeUseAxiosResultWithParams<string[], ErrorType1, { id: number }>;
}

const merchandiseContext = React.createContext<MerchandiseContextProps>({
  useGetMerchandise: (settings?: AxiosSettings) =>
    CreateAxiosHookDefault<GrabbiMerchandise[], ErrorType1, { id: number }>(),
  useGetAllToppingGroups: (settings?: AxiosSettings) =>
    CreateAxiosHookDefault<string[], ErrorType1, { id: number }>(),
});

export const useMerchandiseHooks = () => React.useContext(merchandiseContext);

export const MerchandiseProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const { useAxios: useAxiosWithContext } = useAxiosContext();
  const useGetMerchandise = (settings?: AxiosSettings) => {
    const url = `restful-services/merchandise`;
    const axiosResult = useAxiosWithContext<GrabbiMerchandise[], ErrorType1>(
      {
        method: "GET",
        ...settings?.config,
      },
      { manual: true, ...settings?.options }
    );
    const functionWithParams = useCallback(
      (params: { id: number }) => {
        const { id } = params;
        const fetchFunction = axiosResult[1];
        return fetchFunction({ url: `${url}/${id}`, method: "GET" });
      },
      [axiosResult, url]
    );
    const ResultWithParams: FakeUseAxiosResultWithParams = useMemo(() => {
      return [axiosResult[0], functionWithParams];
    }, [axiosResult, functionWithParams]);
    return ResultWithParams;
  };

  const useGetAllToppingGroups = (settings?: AxiosSettings) => {
    const url = `restful-services/merchandise`;
    const axiosResult = useAxiosWithContext<GrabbiMerchandise[], ErrorType1>(
      {
        url,
        method: "GET",
        ...settings?.config,
      },
      { manual: true, ...settings?.options }
    );
    const functionWithParams = useCallback(
      async (params: { id: number }) => {
        const { id } = params;
        const fetchFunction = axiosResult[1];
        const response = await fetchFunction({ url: `${url}/${id}` });

        const groupMap: Record<string, boolean> = {};

        forEach(
          map(
            flatten(
              filter(
                map(response.data, (item) => item.toppings),
                (item) => item !== undefined
              )
            ),
            (topping) => topping.grouping
          ),
          (group) => {
            if (typeof group === "string") {
              groupMap[group] = true;
            }
          }
        );
        return filter(keys(groupMap), (item) => item !== "");
      },
      [axiosResult, url]
    );
    const ResultWithParams: FakeUseAxiosResultWithParams = useMemo(() => {
      return [axiosResult[0], functionWithParams];
    }, [axiosResult, functionWithParams]);

    return ResultWithParams;
  };

  return (
    <merchandiseContext.Provider
      value={{
        useGetMerchandise,
        useGetAllToppingGroups,
      }}
    >
      {children}
    </merchandiseContext.Provider>
  );
};

export default useMerchandiseHooks;

import MerchantDashboard from "components/MerchantDashboard/MerchantDashboardComponent";
import ResturantBoard from "components/ResturantBoard/ResturantBoardComponent";
import StoreDashboard from "components/Shared/StoreDashboard/StoreDashboardComponent";
import React from "react";
import { useSelector } from "react-redux";
import { State } from "store";
import "./MerchantViewComponent.scss";

interface Props {
  refundMode?: boolean;
}

const MerchantView: React.FC<Props> = ({ refundMode }) => {
  const storeState = useSelector((state: State) => state.storeList);
  const { selectedStore } = storeState;

  const storeType = selectedStore?.storeType;

  return (
    <StoreDashboard
      className="merchant_dashboard_root"
      dashboardTitle={refundMode ? "Refund Dashboard" : "Transaction Dashboard"}
    >
      {storeType === 0 || !storeType ? (
        <MerchantDashboard />
      ) : (
        <ResturantBoard refundMode={refundMode} />
      )}
    </StoreDashboard>
  );
};

export default MerchantView;

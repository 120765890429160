import React, { useEffect } from "react";
import "./RoutesComponent.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Grid } from "@material-ui/core";
import AdminConsole from "components/AdminConsole/AdminConsole";
import Home from "pages/Home/Home";
import { StarterKit } from "components/StarterKit/StarterKitContainer";
import NotFound from "pages/NotFound/NotFound";
import Footer from "components/Footer/FooterComponent";
import About from "pages/About/About";
import Privacy from "pages/Privacy/Privacy";
import TermsOfService from "pages/TermsOfService/TermsOfService";
import { Login } from "components/Login/LoginContainer";
import { SignUp } from "components/SignUp/SignUpContainer";
import { AddStore } from "components/AddStore/AddStoreContainer";
import { Logout } from "components/Logout/LogoutContainer";
import { Header } from "components/Header/HeaderContainer";
import { LoginState } from "store/signin/loginReducer";
import { AuthRoute } from "components/AuthRoute/AuthRouteContainer";
import LoggedOutComponent from "components/LoggedOut/LoggedOutComponent";
import { EditProfile } from "components/Profile/EditProfile/EditProfileContainer";
import { CONTEXT } from "shared/scripts/UrlContext";
import SuccessMessage from "components/Shared/SuccessMessage/SuccessMessageComponent";
import { Forgot } from "components/ForgotPassword/ForgotPasswordContainer";
import { ChangePasswordForgot } from "components/ChangePasswordForgot/ChangePasswordForgotContainer";
import AnalyticsComponent from "components/Analytics/AnalyticsComponent";
import ScrollToTop from "shared/scripts/scrollToTop";
import { ConfirmMerchant } from "components/ConfirmMerchant/ConfirmMerchantContainer";
// import { ResetPassword } from "components/ResetPassword/ResetPasswordContainer";
import { GetStarted } from "components/GetStarted/GetStartedContainer";
import MerchantMenu from "components/MerchantMenu/MerchantMenuComponent";
import { isIOS, isAndroid } from "react-device-detect";
import { APPLE_STORE_URL, GOOGLE_STORE_URL } from "shared/scripts/Constants";
import ReportDashboard from "components/ReportDashboard/ReportDashboardComponent";
import EditMerchandise from "components/MerchantMenu/EditMerchandise/EditMerchandiseComponent";
import { CookiesProvider } from "react-cookie";
import MerchantView from "components/MerchantView/MerchantViewComponent";
import CustomerOrder from "components/CustomerOrder/CustomerOrderComponent";

interface Props {
  loginState: LoginState;
  checkSessionDispatch: () => void;
}

const RoutesComponent: React.FC<Props> = ({
  loginState,
  checkSessionDispatch,
}) => {
  const sessionChecked = loginState.sessionChecked;
  const checkingSession = loginState.checkingSession;

  useEffect(() => {
    if (!sessionChecked && !checkingSession) {
      checkSessionDispatch();
    }
  }, [loginState, checkSessionDispatch, checkingSession, sessionChecked]);

  const location = window.location.pathname;
  const hideFooter = location.includes("demo");

  return (
    <Grid
      classes={{ root: "routes_root" }}
      container
      direction="column"
      wrap="nowrap"
    >
      <Router>
        <CookiesProvider>
          <Switch>
            {/*
            //! TODO: Protect Admin Console path with assigned role
          */}
            <Route
              path={`${CONTEXT}store`}
              component={
                isIOS || isAndroid
                  ? () => {
                      window.location.href = isIOS
                        ? APPLE_STORE_URL
                        : GOOGLE_STORE_URL;
                      return null;
                    }
                  : () => <Redirect to={CONTEXT} />
              }
            ></Route>
            <Route
              exact
              path={`${CONTEXT}admin/login`}
              render={() => <Login admin={true} />}
            />
            <AuthRoute
              path={`${CONTEXT}admin/*`}
              adminRoute={true}
              render={() => {
                return <AdminConsole />;
              }}
            />
            <Route>
              <Switch>
                <Route path={`${CONTEXT}getstarted`} />
                <Route path={`${CONTEXT}starterkit`} />
                <Route path={`${CONTEXT}order/:upc`} />
                <Route>
                  <Grid item xs={12}>
                    <Header />
                  </Grid>
                </Route>
              </Switch>
              {/* <Grid item xs={12} classes={{ root: "home_routes home_padded" }}> */}
              <Grid item xs={12} classes={{ root: "home_routes" }}>
                <Switch>
                  <Route
                    path={`${CONTEXT}order/:upc`}
                    render={() => <CustomerOrder />}
                  />
                  <AuthRoute
                    path={`${CONTEXT}dashboard/activity/:upc?`}
                    render={() => <MerchantView />}
                  />
                  <AuthRoute
                    path={`${CONTEXT}dashboard/refund/:upc?`}
                    render={() => <MerchantView refundMode={true} />}
                  />
                  <Route
                    path={`${CONTEXT}analytics`}
                    render={() => <AnalyticsComponent />}
                  />
                  <AuthRoute
                    path={`${CONTEXT}menu/:storeId/merch/edit/:merchUpc`}
                    render={() => <EditMerchandise />}
                  />
                  <AuthRoute
                    exact={true}
                    path={`${CONTEXT}menu/:upc?`}
                    render={() => <MerchantMenu />}
                  />
                  <AuthRoute
                    path={`${CONTEXT}report/:upc?`}
                    render={() => <ReportDashboard />}
                  />
                  <AuthRoute
                    path={`${CONTEXT}addstore`}
                    render={() => <AddStore />}
                  />
                  <AuthRoute
                    path={`${CONTEXT}merchant/edit-profile/:tab?`}
                    render={() => <EditProfile />}
                  />
                  <Route
                    path={`${CONTEXT}successmessage`}
                    render={() => <SuccessMessage />}
                  />
                  <Route
                    exact
                    path={`${CONTEXT}index.html`}
                    render={() => {
                      return <Redirect to={`${CONTEXT}`} />;
                    }}
                  />
                  <Route
                    exact
                    path="index.html"
                    render={() => {
                      return <Redirect to={`${CONTEXT}`} />;
                    }}
                  />
                  <Route exact path={`${CONTEXT}`} component={Home} />
                  <Route
                    exact
                    path={`${CONTEXT}starterkit`}
                    component={StarterKit}
                  />
                  <Route exact path={`${CONTEXT}login`} component={Login} />
                  <Route exact path={`${CONTEXT}signup`} component={SignUp} />
                  <Route
                    exact
                    path={`${CONTEXT}getstarted`}
                    component={GetStarted}
                  />
                  <Route exact path={`${CONTEXT}forgot`} component={Forgot} />
                  <Route
                    exact
                    path={`${CONTEXT}reset`}
                    component={ChangePasswordForgot}
                  />
                  <Route
                    exact
                    path={`${CONTEXT}confirm`}
                    component={ConfirmMerchant}
                  />
                  <Route
                    exact
                    path={`${CONTEXT}resendToken`}
                    render={() => <ConfirmMerchant resend={true} />}
                  />
                  <Route exact path={`${CONTEXT}logout`} component={Logout} />
                  <Route
                    exact
                    path={`${CONTEXT}session-expired`}
                    render={() => (
                      <LoggedOutComponent message="Your session has expired, log back in to view store activity." />
                    )}
                  />
                  <Route
                    exact
                    path={`${CONTEXT}privacy-policy`}
                    component={Privacy}
                  />
                  <Route
                    exact
                    path={`${CONTEXT}terms-of-service`}
                    component={TermsOfService}
                  />
                  <Route exact path={`${CONTEXT}about`} component={About} />
                  <Route
                    exact
                    path={`/`}
                    render={() => {
                      return <Redirect to={`${CONTEXT}`} />;
                    }}
                  />
                  <Route component={NotFound} />
                </Switch>
              </Grid>
              {!hideFooter && (
                <Grid item xs={12} className="home_footer">
                  <Switch>
                    <Route path={`${CONTEXT}order/:upc`} />
                    <Route path={`${CONTEXT}dashboard/*`} />
                    <Route path={`${CONTEXT}menu/*`} />
                    <Route>
                      <Footer />
                    </Route>
                  </Switch>
                </Grid>
              )}
            </Route>
          </Switch>
        </CookiesProvider>
        <ScrollToTop />
      </Router>
    </Grid>
  );
};
export default RoutesComponent;

import React from 'react';
import { CircularProgress, CircularProgressProps } from "@material-ui/core";
import "./LoadingIconComponent.scss"

type ColorVariant = "black" | "yellow";

interface Props extends CircularProgressProps {
  size?: number;
  className?: string;
  style?: any;
  colorVariant?: ColorVariant;
}

const colorVariantClasses: Record<ColorVariant, string> = {
  black: "grabbi_loading_icon_root_black",
  yellow: "grabbi_loading_icon_root_yellow",
};

const LoadingIcon: React.FC<Props> = ({ size = 60, style, className, colorVariant, ...otherProps }) => {
  const colorVariantClass = colorVariantClasses[colorVariant ?? "black"];
  return (
    <CircularProgress
      classes={{ root: `loading_icon_root ${colorVariantClass} ${className}` }}
      size={size}
      style={style}
      {...otherProps}
    />
  );
};

export default LoadingIcon
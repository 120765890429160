import { CircularProgress, Grid } from "@material-ui/core";
import {
  generatePrintReceipt,
  generatePrintUrl,
} from "components/Shared/GrabbiPrint/printUtil";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";
import ToppingTag from "components/Shared/ToppingTag/ToppingTagComponent";
import useTransactionHooks from "hooks/TransactionHooks";
import { sumBy } from "lodash";
import { useSnackbar } from "notistack";
import React, { ReactNode, useEffect } from "react";
import { Draggable } from "react-beautiful-dnd";
import {
  ITEM_STATUS_SEQUENCE,
  printersLocationIdBasedOnNameStr,
} from "shared/scripts/Constants";
import { formatCurrency } from "shared/scripts/Utils";
import {
  StatusUpdateHistory,
  OrderItemStatus,
  Transaction,
  TransactionItem,
  UpdateTransactionItemStatusPayload,
  GrabbiPrinter,
  GrabbiPrinterUrlPayload,
} from "types/GrabbiTypes";
import "./ItemCardComponent.scss";

type CardVariant = "recieved" | "preparing" | "delivered";

interface ItemCardBaseProps {
  item: TransactionItem;
  className?: string;
  variant?: CardVariant;
}

interface SimpleCardProps extends ItemCardBaseProps {
  currency: string;
}

interface DraggableItemCardProps extends ItemCardBaseProps {
  transaction: Transaction;
  storeId: number;
  updateCallback?: (lastAction: StatusUpdateHistory) => void;
  index: number;
  date: string;
  orderCode: string;
  transactionItem: TransactionItem[];
  serviceFee: number;
  totalTax: number;
  totalTip?: number;
  transactionTotal: number;
  transactionCurrency: string;
  paymentStatus: string;
  tableNumber?: string;
  storeName?: string;
  storePrinters: GrabbiPrinter[];
  token: string;
}

interface ItemCardContentProps {
  item: TransactionItem;
  showCheckbox?: boolean;
  showPrice?: boolean;
  currency?: string;
}

export const itemCardVariant: Record<CardVariant, string> = {
  recieved: "item_card_root_white",
  preparing: "item_card_root_blue",
  delivered: "item_card_root_green",
};

const ItemCardContent: React.FC<ItemCardContentProps> = ({
  showCheckbox,
  showPrice,
  item,
  currency,
}) => {
  const { dependant } = item;
  const price =
    (dependant ? sumBy(item.toppings, "price") : item.price) * item.quantity;
  return (
    <Grid item container xs={12}>
      <div className="item_card_content_top">
        <div className="left">
          <div className="title_container">
            <div>
              <span className="quantity">{item.quantity}</span>
              <span className="separator">x</span>
              <span className="label">{item.upc.label}</span>
            </div>
          </div>
        </div>
        {(showPrice || showCheckbox) && (
          <div className="right">
            {showCheckbox && !(showPrice && currency) && (
              <div className="checkbox_icon" />
            )}
            {showPrice && currency && (
              <span className="price">{formatCurrency(price, currency)}</span>
            )}
          </div>
        )}
      </div>
      <div className="toppings_container">
        {item.toppings.map((topping) => {
          return (
            <ToppingTag key={`topping-tag-${topping.id}`} topping={topping} />
          );
        })}
      </div>
    </Grid>
  );
};

export const ItemCard: React.FC<SimpleCardProps> = ({
  item,
  className,
  variant,
  currency,
}) => {
  return (
    <Grid
      container
      item
      className={`item_card_root ${className ?? ""} ${
        variant ? itemCardVariant[variant] : itemCardVariant.recieved
      } `}
    >
      <ItemCardContent
        item={item}
        currency={currency}
        showCheckbox={variant === "delivered"}
        showPrice={true}
      />
    </Grid>
  );
};

const DraggableItemCard: React.FC<DraggableItemCardProps> = ({
  transaction,
  item,
  storeId,
  className,
  variant,
  updateCallback,
  index,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const { useUpdateTransactionItemStatus } = useTransactionHooks();
  const [{ loading, error }, updateStatus] = useUpdateTransactionItemStatus();
  useEffect(() => {
    if (error) {
      console.error(error);
      enqueueSnackbar("Error: Failed to update item status", {
        variant: "error",
      });
    }
  }, [error, enqueueSnackbar]);

  const createPayload = (
    orderItemStatus: OrderItemStatus
  ): UpdateTransactionItemStatusPayload => {
    return {
      orderItemStatus,
      storeId,
      transactionId: transaction.id,
      transactionItemId: item.id,
    };
  };

  const handleStatusUpdate = updateCallback
    ? async () => {
        const currentStatus = item.orderItemStatus;
        const currentStatusIndex = ITEM_STATUS_SEQUENCE.findIndex(
          (s) => s === currentStatus
        );
        if (currentStatusIndex === -1) {
          enqueueSnackbar(
            "Error: Current Item Status is invalid - please contact support.",
            {
              variant: "error",
            }
          );
          return;
        }
        const nextItemStatus = ITEM_STATUS_SEQUENCE[currentStatusIndex + 1];

        const payload = createPayload(nextItemStatus);
        await updateStatus({
          data: payload,
        });
        updateCallback({
          nextStatus: nextItemStatus,
          currentStatus,
          itemId: item.id,
          transactionId: transaction.id,
          storeId,
        });
      }
    : undefined;

  return (
    <Draggable
      draggableId={`${item.id}`}
      index={index}
      isDragDisabled={!updateCallback}
    >
      {(provided, snapshot) => (
        <Grid
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          container
          item
          onClick={
            item.orderItemStatus !== "DELIVERED" && handleStatusUpdate
              ? () => handleStatusUpdate()
              : undefined
          }
          className={`item_card_root ${
            item.orderItemStatus !== "DELIVERED" && handleStatusUpdate
              ? "clickable"
              : ""
          } ${className ?? ""} ${
            variant ? itemCardVariant[variant] : itemCardVariant.recieved
          } `}
        >
          {loading ? (
            <LoadingIcon size={40} />
          ) : (
            <ItemCardContent
              item={item}
              showCheckbox={variant === "delivered"}
            />
          )}
        </Grid>
      )}
    </Draggable>
  );
};

export default DraggableItemCard;

interface ActionCardProps {
  storeId?: number;
  transactionId?: number;
  items?: TransactionItem[];
  status?: OrderItemStatus;
  variant?: CardVariant;
  className?: string;
  children?: ReactNode;
  date: string;
  orderCode: string;
  transactionItem: TransactionItem[];
  serviceFee: number;
  totalTax: number;
  totalTip?: number;
  transactionTotal: number;
  transactionCurrency: string;
  paymentStatus: string;
  tableNumber?: string;
  storeName?: string;
  storePrinters: GrabbiPrinter[];
  token: string;
  callback?: () => void;
  errorCallback?: (item: TransactionItem) => void;
}

export const ActionCard: React.FC<ActionCardProps> = ({
  variant,
  className,
  children,
  items,
  status,
  storeId,
  transactionId,
  date,
  orderCode,
  transactionItem,
  serviceFee,
  totalTax,
  totalTip,
  transactionTotal,
  transactionCurrency,
  paymentStatus,
  tableNumber,
  storeName,
  storePrinters,
  token,
  callback,
  errorCallback,
}) => {
  const { useUpdateTransactionItemStatus } = useTransactionHooks();
  const [{ loading }, updateTransactionItemStatus] =
    useUpdateTransactionItemStatus();
  const clickable =
    items && status && storeId != null && transactionId != null && !loading;

  const handleUpdateStatus = async (
    items: TransactionItem[],
    newStatus: OrderItemStatus
  ) => {
    if (clickable) {
      let kitchenOrders = [];
      let barOrders = [];
      let textPayLoadReceipt: {
        port: string | null | undefined;
        fontSize: number;
        receipt: string;
        transactionId: number;
        transactionItems: any[];
      }[] = [];

      if (newStatus === "PREPARING") {
        for (let item of transactionItem) {
          if (
            item.menuStyle === printersLocationIdBasedOnNameStr.get("Kitchen")
          ) {
            kitchenOrders.push(item);
          } else {
            barOrders.push(item);
          }
        }

        if (kitchenOrders.length !== 0) {
          const kitchenOrdersPayload: GrabbiPrinterUrlPayload = {
            className: className,
            date: date,
            transactionId: transactionId,
            orderCode: orderCode,
            transactionItem: kitchenOrders,
            serviceFee: serviceFee,
            totalTax: totalTax,
            totalTip: totalTip,
            transactionTotal: transactionTotal,
            transactionCurrency: transactionCurrency,
            paymentStatus: paymentStatus,
            tableNumber: tableNumber,
            storeId: storeId,
            storeName: storeName,
            storePrinters: storePrinters,
            token: token,
            orderStep: newStatus,
            printerType: 0,
          };
          textPayLoadReceipt.push(generatePrintReceipt(kitchenOrdersPayload));
        }
        if (barOrders.length !== 0) {
          const barOrdersPayload: GrabbiPrinterUrlPayload = {
            className: className,
            date: date,
            transactionId: transactionId,
            orderCode: orderCode,
            transactionItem: barOrders,
            serviceFee: serviceFee,
            totalTax: totalTax,
            totalTip: totalTip,
            transactionTotal: transactionTotal,
            transactionCurrency: transactionCurrency,
            paymentStatus: paymentStatus,
            tableNumber: tableNumber,
            storeId: storeId,
            storeName: storeName,
            storePrinters: storePrinters,
            token: token,
            orderStep: newStatus,
            printerType: 1,
          };
          if (barOrders.length !== 0) {
            textPayLoadReceipt.push(generatePrintReceipt(barOrdersPayload));
          }
        }

        if (barOrders.length !== 0 || kitchenOrders.length) {
          if (storePrinters.length > 0) {
            setTimeout(() => {
              let passprnt_uri = generatePrintUrl(
                textPayLoadReceipt,
                storePrinters,
                token,
                storeId
              );
              if (passprnt_uri.indexOf("port") === -1) {
                passprnt_uri = "";
              } else {
                window.open(passprnt_uri, "_blank");
              }
            }, 3000);
          }
        }
      }

      let errorItem: TransactionItem | null = null;
      for (const item of items) {
        if (!errorItem) {
          const payload: UpdateTransactionItemStatusPayload = {
            orderItemStatus: newStatus,
            storeId,
            transactionId,
            transactionItemId: item.id,
          };
          // ? [REVISIT] Need a way to do this in bulk
          try {
            await updateTransactionItemStatus({
              data: payload,
            });
          } catch (err) {
            errorItem = item;
          }
        }
      }
      if (callback && errorItem === null) {
        callback();
      } else if (errorCallback && errorItem !== null) {
        errorCallback(errorItem);
      }
    }
  };

  return (
    <Grid
      item
      container
      onClick={clickable ? () => handleUpdateStatus(items, status) : () => {}}
      className={`item_card_root action_card_root ${
        clickable ? "clickable" : ""
      } ${loading ? "loading" : ""} ${className ?? ""} ${
        variant ? itemCardVariant[variant] : itemCardVariant.recieved
      } `}
    >
      <div className="loading_wrapper">
        {loading && <CircularProgress className="loading_icon" size={36} />}
      </div>
      {children && <div className="children_wrapper">{children}</div>}
    </Grid>
  );
};

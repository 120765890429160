import { Dialog, DialogContent } from "@material-ui/core";
import AndroidCheckoutButton from "components/CustomerOrder/AndroidCheckoutButton/AndroidCheckoutButtonComponent";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import React from "react";
import { calculateTipableAmount, formatCurrency } from "shared/scripts/Utils";
import { GrabbiStore, Transaction } from "types/GrabbiTypes";
import ChangeTipButton from "../ChangeTipButton/ChangeTipButtonComponent";
import "./CloseCustomerTabComponent.scss";

interface Props {
  className?: string;
  open: boolean;
  onCashierCheckout?: () => void;
  onTransactionSuccess: () => void;
  onClose: () => void;
  transaction?: Transaction;
  store: GrabbiStore;
  isFoodTruck?: boolean;
  onChange?: (transaction : Transaction) => void;
}

const CloseCustomerTab: React.FC<Props> = ({
  className,
  open,
  transaction,
  store,
  isFoodTruck,
  onTransactionSuccess,
  onCashierCheckout,
  onClose,
  onChange
}) => {
  const handleCashierCheckout = () => {
    if (onCashierCheckout) {
      onCashierCheckout();
    }
    
  };

  const handlePaymentSuccess = () => {
    onTransactionSuccess();
  };

  return transaction ? (
    <Dialog
      open={open}
      className={`${className ?? ""} close_customer_tab_root`}
      disablePortal={true}
      onClose={onClose}
      classes={{
        paper: "close_dialog_paper",
      }}
    >
      <DialogContent className="close_dialog_content">
        <div className="row_container">
          <div className="row flex-row-center">
            <span>Tips</span>
            <div>
              <ChangeTipButton
                className="change_tip_button"
                transaction={transaction}
                store={store}
                onChange={onChange}
              />
              <span>
                {formatCurrency(transaction.tip ?? 0, transaction.currency)}
              </span>
            </div>
          </div>
          <div className="row">
            <span>Sub-Total</span>
            <span>
              {formatCurrency(
                calculateTipableAmount(transaction),
                transaction.currency
              )}
            </span>
          </div>
          <div className="row">
            <span>Tax</span>
            <span>
              {formatCurrency(transaction.totalTax, transaction.currency)}
            </span>
          </div>
          <div className="row">
            <span>Service Fee</span>
            <span>
              {formatCurrency(
                transaction.serviceFee + transaction.stripeTotalFee,
                transaction.currency
              )}
            </span>
          </div>
          <div className="row">
            <span>Total</span>
            <span>
              {formatCurrency(
                transaction.transactionTotal,
                transaction.currency
              )}
            </span>
          </div>
        </div>
        <div className="divider_text">Checkout method</div>
        <AndroidCheckoutButton
          isFoodTruck={true}
          store={store}
          transaction={transaction}
          onSuccess={handlePaymentSuccess}
        />
        {!isFoodTruck && onCashierCheckout && (
          <GrabbiButton
            className="cashier_checkout_button"
            colorVariant="white"
            onClick={handleCashierCheckout}
          >
            <React.Fragment>
              <span className="cashier_checkout_icon" />
              Cashier Checkout
            </React.Fragment>
          </GrabbiButton>
        )}
        <GrabbiButton className="m-t-2 full-width" onClick={onClose}>
          Cancel
        </GrabbiButton>
      </DialogContent>
    </Dialog>
  ) : <></>;
};

export default CloseCustomerTab;

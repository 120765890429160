import React from "react";
import "./GrabbiPrintComponent.scss";
import { GrabbiPrinter, TransactionItem } from "types/GrabbiTypes";
import { generatePrintReceipt, generatePrintUrl } from "./printUtil";

interface Props {
  className?: string;
  date: string;
  orderCode: string;
  transactionId: number;
  transactionItem: TransactionItem[];
  serviceFee: number;
  totalTax: number;
  totalTip?: number;
  transactionTotal: number;
  transactionCurrency: string;
  paymentStatus: string;
  tableNumber?: string;
  storeId: number;
  storeName?: string;
  storePrinters: GrabbiPrinter[];
  token: string;
  printerType: number;
}

const GrabbiPrint: React.FC<Props> = React.forwardRef((props: Props) => {
  const { className } = props;

  // const GrabbiPrint: React.FC<Props> = ({
  //   className,
  //   date,
  //   orderCode,
  //   transactionItem,
  //   serviceFee,
  //   totalTax,
  //   totalTip,
  //   transactionTotal,
  //   transactionCurrency,
  //   paymentStatus,
  //   tableNumber,
  //   storeUpc,
  //   storeName
  // }) => {
  // const orderItemToppingsList = (toppings: TransactionTopping[]) =>
  //   toppings
  //     .sort((a, b) => a.id.toString().localeCompare(b.id.toString()))
  //     .map((topping: TransactionTopping) => {
  //       return (
  //         `<text>* ${topping.quantity} ${topping?.upc?.label.replace('&', '&amp;')}&#10;</text>
  //         <text x="50"/>`
  //       );
  //     });

  // const orderItemsList = (items: TransactionItem[]) =>
  //   items
  //     .sort((a, b) => a.id.toString().localeCompare(b.id.toString()))
  //     .map((item: TransactionItem) => {
  //       return (
  //         `<text>${item.quantity} ${item?.upc?.label.replace('&', '&amp;')}&#10;</text>
  //         ${(item.toppings.length !== 0) ? orderItemToppingsList(item.toppings) : '<text x="50"/>'}
  //         <text>------------------------------------</text>
  //         <feed line="1"/>`
  //       );
  //     });

  // const tableNum = (tableNumber !== null) ? `Table #${tableNumber}&#10;` : '';
  // const xmlPayLoad = `<epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
  //   <feed line="2"/>
  //   <text reverse="false" ul="false" em="true" color="color_1"/>
  //   <text width="1" height="1"/>
  //   <text font="font_a"/>
  //   <text align="center"/>
  //   <text linespc="60"/>
  //   <text reverse="false" ul="false" em="true" color="color_1"/>
  //   <feed line="1"/>
  //   <text reverse="false" ul="false" em="false" color="color_1"/>
  //   <text align="left"/>
  //   <text x="50"/>
  //   ${orderItemsList(transactionItem)}
  //   <feed line="1"/>
  //   <text align="center" width="1" height="1">${date}&#10;</text>
  //   <text align="center" width="2" height="2">${tableNum}</text>
  //   <text align="center" width="2" height="2">POP ${orderCode}&#10;</text>
  //   <feed line="3"/>
  //   <cut type="no_feed"/>
  //   </epos-print>`;

  //PassPrnt
  // const orderItemToppingsList = (toppings: TransactionTopping[]) =>
  //   toppings
  //     .sort((a, b) => a.id.toString().localeCompare(b.id.toString()))
  //     .map((topping: TransactionTopping) => {
  //       return (
  //         `<div style="font-size: 30px; text-align: center; display: inline-flex;">
  //           <div style="width:232px; float:left; text-align: left; font-size: 24px; padding-left: 18px;">* ${topping?.upc?.label}</div>
  //           <div style="width:50px; float: right; font-size: 24px;">${topping.quantity}</div>
  //         </div>`
  //       );
  //     }).join('');

  // const orderItemsList = (items: TransactionItem[]) =>
  //   items
  //     .sort((a, b) => a.id.toString().localeCompare(b.id.toString()))
  //     .map((item: TransactionItem) => {
  //       return (
  //         `<div style="font-size: 30px; text-align: center; display: inline-flex;">
  //           <div style="width:250px; float:left; text-align: left; font-size: 24px;">${item?.upc?.label}</div>
  //           <div style="width:50px; float: right; font-size: 24px;">${item.quantity}</div>
  //         </div>${(item.toppings.length !== 0) ? orderItemToppingsList(item.toppings) : ''}<br/>`
  //       );
  //     }).join('');

  // const tableNum = (tableNumber !== null) ? `Table #${tableNumber}` : '';

  // let htmlPayLoad = `<!DOCTYPE html>
  // <html>
  // <head>
  //   <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  // </head>
  // <body style="text-align: center;">
  //   <div style="font-size: 48px;">
  //     <p>${storeName}</p>
  //   </div>
  //   <br>
  //   <div style="text-align: center;display: grid;margin: 0 auto;max-width: 300px;">
  //     ${orderItemsList(transactionItem)}
  //   </div>
  //   <br>
  //   <hr style="width:500px; height:5px; background-color:#000000;">
  //   <br>
  //   <div style="font-size: 30px;">
  //     <div>
  //       <div>
  //         <div style="text-align:center; font-size: 28px">${date}</div>
  //       </div>
  //       <div>
  //         <div style="text-align:center; font-size: 28px">${tableNum}</div>
  //       </div>
  //       <div>
  //         <div style="text-align:center; font-size: 28px">${orderCode}</div>
  //       </div>
  //     </div>
  //   </table>
  //   <br>
  // </body>
  // </html>`;

  //PassPrnt
  // let base_passprnt_uri = "starpassprnt://v1/print/nopreview?back=" + encodeURIComponent(window.location.href);
  // const callBackUrl = encodeURIComponent(`${window.location.protocol}//${window.location.host}/dashboard/activity/${storeUpc}`);
  // const callBackUrl = `posterminal://pos.paygex.com/print?printer=star&receiptHTML=encodedreciept&printerType=star`;
  // const base_passprnt_uri = `starpassprnt://v1/print/nopreview?`;
  // const encodedPayLoad = encodeURIComponent(htmlPayLoad);
  // const passprnt_uri = `${base_passprnt_uri}&html=${encodedPayLoad}&back=${callBackUrl}`;

  // const orderItemToppingsList = (toppings: TransactionTopping[]) =>
  //   toppings
  //     .sort((a, b) => a.id.toString().localeCompare(b.id.toString()))
  //     .map((topping: TransactionTopping) => {
  //       let toppingLabelLength = 0;

  //       if (topping?.upc?.label !== undefined && topping?.upc?.label.length > 26) {
  //         toppingLabelLength = Math.abs(21 - Math.abs(21 - topping?.upc?.label.length));
  //         toppingLabelStr = `${topping?.upc?.label}${spaceChar.repeat(toppingLabelLength)}`;
  //       } else {
  //         toppingLabelLength = 21 - topping?.upc?.label.length;
  //         toppingLabelStr = `${topping?.upc?.label}${spaceChar.repeat(toppingLabelLength)}`;
  //       }

  //       return (
  //         `*%20${toppingLabelStr}${spaceChar.repeat(topping.quantity > 10 ? 2 : 1)}%0A`
  //       );
  //     }).join('');

  // const orderItemsList = (items: TransactionItem[]) =>
  //   items
  //     .sort((a, b) => a.id.toString().localeCompare(b.id.toString()))
  //     .map((item: TransactionItem) => {
  //       let labelLength = 0;
  //       let labelStr = '';

  //       if (item?.upc?.label !== undefined && item?.upc?.label.length > 26) {
  //         labelLength = Math.abs(23 - Math.abs(23 - item?.upc?.label.length));
  //         labelStr = `${item?.upc?.label}${spaceChar.repeat(labelLength)}`;
  //       } else {
  //         labelLength = 23 - item?.upc?.label.length;
  //         labelStr = `${item?.upc?.label}${spaceChar.repeat(labelLength)}`;
  //       }

  //       return (
  //         `${labelStr}${spaceChar.repeat(item.quantity > 10 ? 2 : 1)}${item.quantity}%0A${(item.toppings.length !== 0) ? orderItemToppingsList(item.toppings) : ''}%0A`
  //       );
  //     }).join('');

  // // const updatedStoreName= `${spaceChar.repeat(storeNameLength)}${storeName}${spaceChar.repeat(storeNameLength)}`;
  // let textPayLoad = `${labelStr}%0A%0A%0A${orderItemsList(transactionItem)}%0A${spaceChar.repeat(dateLength)}${date}${spaceChar.repeat(dateLength)}%0A%0A${tableNum}%0A%0A${spaceChar.repeat(orderCodeLength)}${orderCode}${spaceChar.repeat(orderCodeLength)}%0A%0A`;

  // //PassPrnt via POS Terminal""
  // let base_passprnt_uri = "posterminal://pos.paygex.com/pay?command=print&printerType=star&port=TCP:192.168.7.218&receipt=";
  // const passprnt_uri = `${base_passprnt_uri}${textPayLoad}`;
  // const updatedStoreName= `${spaceChar.repeat(storeNameLength)}${storeName}${spaceChar.repeat(storeNameLength)}`;

  // let tableNum = '';
  // let adjustedStoreName = '';
  // let toppingLabelStr = '';
  // let tableNumLength = 0;
  // let storeNameLength = 0;
  // let dateLength = Math.floor((26 - date.length) / 2);
  // let orderCodeLength = Math.floor((26 - orderCode.length) / 2);
  // let textPayLoad = '';
  // const spaceChar = '%20';
  // const currencyCode = '%24';
  // // const currencyCode = transactionCurrency === 'USD' ? '%24' : 'CA%24';

  // if (tableNumber !== null) {
  //   const tableNumStr = `Table %23${tableNumber}`
  //   tableNumLength = Math.floor((26 - tableNumStr.length) / 2);
  //   tableNum = `${spaceChar.repeat(tableNumLength)}${tableNumStr}${spaceChar.repeat(tableNumLength)}`;
  // }

  // if (storeName !== undefined && storeName.length > 26) {
  //   storeNameLength = Math.abs(26 - Math.abs(26 - storeName.length));
  //   adjustedStoreName = `${spaceChar.repeat(storeNameLength)}${storeName}${spaceChar.repeat(storeNameLength)}`;
  // } else if (storeName !== undefined){
  //   storeNameLength = Math.floor((26 - storeName.length) / 2);
  //   adjustedStoreName = `${spaceChar.repeat(storeNameLength)}${storeName}${spaceChar.repeat(storeNameLength)}`;
  // }

  // if (paymentStatus === 'FULFILLED') {
  //   const itemToppingsList = (toppings: TransactionTopping[]) =>
  //     toppings
  //       .sort((a, b) => a.id.toString().localeCompare(b.id.toString()))
  //       .map((topping: TransactionTopping) => {
  //         let toppingLabelLength = 0;

  //         if (
  //           topping?.upc?.label !== undefined &&
  //           topping?.upc?.label.length > 26
  //         ) {
  //           toppingLabelLength = Math.abs(
  //             13 - Math.abs(13 - topping?.upc?.label.length)
  //           );
  //           toppingLabelStr = `${topping?.upc?.label}${spaceChar.repeat(
  //             toppingLabelLength
  //           )}`;
  //         } else {
  //           toppingLabelLength = Math.abs(13 - topping?.upc?.label.length);
  //           toppingLabelStr = `${topping?.upc?.label}${spaceChar.repeat(
  //             toppingLabelLength
  //           )}`;
  //         }

  //         return `*%20${toppingLabelStr}${spaceChar.repeat(
  //           topping.price > 10 ? 6 : 4
  //         )}${currencyCode}${topping.price}%0A`;
  //       })
  //       .join("");

  //   const itemsList = (items: TransactionItem[]) =>
  //     items
  //       .sort((a, b) => a.id.toString().localeCompare(b.id.toString()))
  //       .map((item: TransactionItem) => {
  //         let labelLength = 0;
  //         let labelStr = "";

  //         if (item?.upc?.label !== undefined && item?.upc?.label.length > 26) {
  //           labelLength = Math.abs(15 - Math.abs(15 - item?.upc?.label.length));
  //           labelStr = `${item?.upc?.label}${spaceChar.repeat(labelLength)}`;
  //         } else {
  //           labelLength = Math.abs(15 - item?.upc?.label.length);
  //           labelStr = `${item?.upc?.label}${spaceChar.repeat(labelLength)}`;
  //         }
  //         return `${labelStr}${spaceChar.repeat(item.price > 10 ? 6 : 4)}${currencyCode}${
  //           item.price
  //         }%0A${
  //           item.toppings.length !== 0
  //             ? itemToppingsList(item.toppings)
  //             : ""
  //         }%0A`;
  //       })
  //       .join("");

  //   const transactionFees = `Service Fee: %24${serviceFee}%0A
  //   ${totalTax !== undefined && totalTax > 0 ? `Tax: %24${totalTax}%0A` : '%0A'}
  //   ${totalTip !== undefined && totalTip > 0 ? `Tip: %24${totalTip}%0A` : '%0A'}
  //   Total: %24${transactionTotal}%0A%0A`
  //   textPayLoad = `${adjustedStoreName}%0A%0A%0A${itemsList(transactionItem)}%0A${spaceChar.repeat(dateLength)}${date}${spaceChar.repeat(dateLength)}%0A%0A%0D${spaceChar.repeat(orderCodeLength)}${orderCode}${spaceChar.repeat(orderCodeLength)}%0A%0A
  //   ${transactionFees}%0A%0A`;
  // } else {
  //   const orderItemToppingsList = (toppings: TransactionTopping[]) =>
  //     toppings
  //       .sort((a, b) => a.id.toString().localeCompare(b.id.toString()))
  //       .map((topping: TransactionTopping) => {
  //         let toppingLabelLength = 0;
  //         if (
  //           topping?.upc?.label !== undefined &&
  //           topping?.upc?.label.length > 26
  //         ) {
  //           toppingLabelLength = Math.abs(
  //             21 - Math.abs(21 - topping?.upc?.label.length)
  //           );
  //           toppingLabelStr = `${topping?.upc?.label}${spaceChar.repeat(
  //             toppingLabelLength
  //           )}`;
  //         } else {
  //           toppingLabelLength = 21 - topping?.upc?.label.length;
  //           toppingLabelStr = `${topping?.upc?.label}${spaceChar.repeat(
  //             toppingLabelLength
  //           )}`;
  //         }

  //         return `*%20${toppingLabelStr}${spaceChar.repeat(
  //           topping.quantity > 10 ? 2 : 1
  //         )}%0A`;
  //       })
  //       .join("");

  //   const orderItemsList = (items: TransactionItem[]) =>
  //     items
  //       .sort((a, b) => a.id.toString().localeCompare(b.id.toString()))
  //       .map((item: TransactionItem) => {
  //         let labelLength = 0;
  //         let labelStr = "";

  //         if (item?.upc?.label !== undefined && item?.upc?.label.length > 26) {
  //           labelLength = Math.abs(23 - Math.abs(23 - item?.upc?.label.length));
  //           labelStr = `${item?.upc?.label}${spaceChar.repeat(labelLength)}`;
  //         } else {
  //           labelLength = 23 - item?.upc?.label.length;
  //           labelStr = `${item?.upc?.label}${spaceChar.repeat(labelLength)}`;
  //         }

  //         return `${labelStr}${spaceChar.repeat(item.quantity > 10 ? 2 : 1)}${
  //           item.quantity
  //         }%0A${
  //           item.toppings.length !== 0
  //             ? orderItemToppingsList(item.toppings)
  //             : ""
  //         }%0A`;
  //       })
  //       .join("");
  //   textPayLoad = `${adjustedStoreName}%0A%0A%0A${orderItemsList(transactionItem)}%0A${spaceChar.repeat(dateLength)}${date}${spaceChar.repeat(dateLength)}%0A%0A${tableNum}%0A%0A${spaceChar.repeat(orderCodeLength)}${orderCode}${spaceChar.repeat(orderCodeLength)}%0A%0A`;
  // }
  //PassPrnt via POS Terminal""
  // let base_passprnt_uri = "posterminal://pos.paygex.com/pay?command=print&printerType=star&port=TCP:192.168.7.218&receipt=";
  // const passprnt_uri = `${base_passprnt_uri}${textPayLoadReceipt}`;

  const textPayLoadReceipt = generatePrintReceipt(props);
  let passprnt_uri = generatePrintUrl(textPayLoadReceipt, props.storePrinters, props.token, props.storeId);
  if (passprnt_uri.indexOf('port') === -1){
    passprnt_uri = '';
  }
  return (
    //EPSON
    //   <a className={`link_to_instagram grabbi-print-button ${className}`} rel="noopener noreferrer"
    //     href={`tmprintassistant://tmprintassistant.epson.com/print?ver=1&data-type=eposprintxml&data=${encodeURIComponent(xmlPayLoad)}`}
    //     target="_blank">
    //   </a>

    //PassPrnt
    // <a className={`link_to_instagram grabbi-print-button ${className}`} rel="noopener noreferrer"
    // href={`${passprnt_uri}`}
    // target="_blank">
    // </a>

    //PassPrnt via POS Terminal
    <a
      className={`link_to_instagram grabbi-print-button ${className}`}
      rel="noopener noreferrer"
      href={`${passprnt_uri}`}
      target="_blank"
    >&nbsp;</a>
  );
});

export default GrabbiPrint;

import React from "react";
import "components/AdminConsole/AdminConsole.scss";
import { BrowserRouter as Switch, Route } from "react-router-dom";
import NavigationComponent from "components/AdminConsole/Navigation/NavigationComponent";
import { Box, Container } from "@material-ui/core";
import { isMobile } from "shared/scripts/Constants";
import { StoreManagerContainer } from "components/AdminConsole/StoreManager/StoreManagerContainer";
import { NavigationOption } from "types/navigationOption";
import { useWindowSize } from "shared/scripts/ResizeListener";
import { CONTEXT } from "shared/scripts/UrlContext";

export const ADMIN_BASE_URL: string = "admin";

const NotFound: React.FC = () => {
  return <h2 style={{ fontFamily: "LemonMilkBold" }}>404: Path not found</h2>;
};

const ADMIN_NAVIGATION_OPTIONS: NavigationOption[] = [
  {
    title: "Regions",
    route: "region-manager",
    component: NotFound,
  },
  {
    title: "Stores",
    route: "store-manager",
    component: StoreManagerContainer,
  },
  {
    title: "Items",
    route: "item-manager",
    component: NotFound,
  },
  {
    title: "Users",
    route: "user-manager",
    component: NotFound,
  },
  {
    title: "Help",
    route: "help",
    component: NotFound,
  },
];

const AdminConsole: React.FC = () => {
  useWindowSize();
  return (
    <Box
      className={`admin_console_main ${
        isMobile() ? "admin_console_main_mobile" : ""
      }`}
    >
      <NavigationComponent navigationOptions={ADMIN_NAVIGATION_OPTIONS} />
      <Container className="admin_console_content" maxWidth="xl">
        <Switch>
          {ADMIN_NAVIGATION_OPTIONS.map((option) => {
            return (
              <Route
                key={`route-${option.title}`}
                path={`${CONTEXT}${ADMIN_BASE_URL}/${option.route}`}
                component={option.component}
              />
            );
          })}
        </Switch>
      </Container>
    </Box>
  );
};
export default AdminConsole;

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import useAxios from "axios-hooks";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";
import { useLoginContext } from "hooks/CustomerLogin";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getExternalCustomerIdSelector,
  getMemberEmailSelector,
  getMembershipCheckedSelector,
  getMembershipMenuIdSelector,
} from "selectors/customer";
import {
  GrabbiMerchandise,
  GrabbiStore,
  MenuRequestPayload,
  Transaction,
} from "types/GrabbiTypes";
import Cookies from "universal-cookie";
import "./CustomerOrderComponent.scss";
import Membership from "./Membership/MembershipComponent";
import OpenTab from "./OpenTab/OpenTabComponent";
import OrderPlaced from "./OrderPlaced/OrderPlacedComponent";
import PaymentSuccessComponent from "./OrderPlaced/PaymentSuccess/PaymentSuccessComponent";
import CloseCustomerTab from "./OrderPlaced/CloseCustomerTab/CloseCustomerTabComponent";
import CashierNotified from "./OrderPlaced/CashierNotified/CashierNotifiedComponent";
import { useMerchSelection } from "components/ResturantBoard/MerchandiseSelector/MerchandiseSelectorComponent";

interface Props {
  className?: string;
}

const GUEST_TRANSACTION_PARAM = "LAST_GUEST_TRANSACTION";
const cookies = new Cookies();

const STRIPE_KEY = process.env.REACT_APP_STRIPE_PUB_KEY ?? "";

const stripePromise = loadStripe(STRIPE_KEY, {
  stripeAccount: "acct_1KMQmBKtMqvKNw98",
});

const handleSetLastTransactionCookie = (id?: number) => {
  const now = DateTime.now();
  const expirationTime = now.plus({ hours: 24 }).toJSDate();
  const cookiesConfig = { path: "/", expires: expirationTime };
  if (id !== undefined) {
    cookies.set(GUEST_TRANSACTION_PARAM, id, cookiesConfig);
  } else {
    cookies.remove(GUEST_TRANSACTION_PARAM, cookiesConfig);
  }
};

const CustomerOrder: React.FC<Props> = ({ className }) => {
  const lastOrderId = cookies.get(GUEST_TRANSACTION_PARAM);
  const [checkoutTab, setCheckoutTab] = useState<Transaction>();
  const [openedTab, setOpenedTab] = useState<Transaction>();
  const [closedTab, setClosedTab] = useState<Transaction>();
  const [showNotified, setShowNotified] = useState(false);
  const [addMoreTab, setAddMoreTab] = useState<Transaction>();
  const { upc } = useParams<{ upc: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const { setIsPosted } = useMerchSelection();
  const {
    customerLogin,
    isAuthenticated,
    error,
    loading: loginLoading,
    accessToken,
  } = useLoginContext();

  const memberEmail = useSelector(getMemberEmailSelector);
  const memberExternalId = useSelector(getExternalCustomerIdSelector);
  const membershipMenuId = useSelector(getMembershipMenuIdSelector);
  const membershipChecked = useSelector(getMembershipCheckedSelector);

  useEffect(() => {
    if (!isAuthenticated && !loginLoading && !error) {
      customerLogin();
    }
  }, [isAuthenticated, loginLoading, error, customerLogin]);

  useMemo(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
  }, [error, enqueueSnackbar]);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const [
    { loading: loadingStore, data: dataStore, error: storeError },
    getStore,
  ] = useAxios<GrabbiStore>(
    {
      headers,
      url: `/restful-services/public/store/byUpc/${upc}`,
    },
    { manual: true }
  );

  const isFoodTruck = dataStore?.storeType === 0;

  useEffect(() => {
    if (isAuthenticated && upc) {
      getStore();
    }
  }, [isAuthenticated, upc, getStore]);

  useMemo(() => {
    if (storeError) {
      enqueueSnackbar(storeError, { variant: "error" });
    }
  }, [storeError, enqueueSnackbar]);

  const [
    {
      loading: loadingMerchandise,
      data: dataMerchandise,
      error: merchandiseError,
    },
    getMerchandise,
  ] = useAxios<GrabbiMerchandise[]>(
    {
      headers,
      url: `/restful-services/store/menu`,
      method: "POST",
    },
    { manual: true }
  );

  useEffect(() => {
    if (isAuthenticated && dataStore && membershipChecked) {
      const payload: MenuRequestPayload = {
        storeId: dataStore.id,
        membershipPayload:
          memberEmail && memberExternalId && membershipMenuId !== undefined
            ? {
                email: memberEmail,
                externalCustomerId: memberExternalId,
                menuId: membershipMenuId,
              }
            : undefined,
      };
      getMerchandise({
        data: payload,
      });
    }
  }, [
    isAuthenticated,
    upc,
    memberEmail,
    dataStore,
    membershipChecked,
    memberExternalId,
    membershipMenuId,
    getMerchandise,
  ]);

  useMemo(() => {
    if (merchandiseError) {
      enqueueSnackbar(merchandiseError, { variant: "error" });
    }
  }, [merchandiseError, enqueueSnackbar]);

  const [
    {
      data: dataTransaction,
      loading: loadingLastTransaction,
      error: errorLastTransaction,
    },
    getLastTransaction,
  ] = useAxios<Transaction>(
    {
      headers,
      url: `/restful-services/transaction/${lastOrderId}`,
    },
    {
      manual: true,
    }
  );

  const handleFetchTransaction = useCallback(() => {
    getLastTransaction().then((res) => {
      if (res.data.transactionStatus !== "PROCESSING_ORDER" && isFoodTruck) {
        setCheckoutTab(res.data);
        setIsPosted(true);
      } else if (res.data.transactionStatus !== "PROCESSING_ORDER") {
        setOpenedTab(res.data);
        setIsPosted(true);
      } else {
        setClosedTab(res.data);
      }
    });
  }, [
    isFoodTruck,
    setIsPosted,
    getLastTransaction,
    setCheckoutTab,
    setOpenedTab,
    setClosedTab,
  ]);

  const handleCheckoutChange = useCallback(
    (transaction: Transaction) => {
      setCheckoutTab(transaction);
    },
    [setCheckoutTab]
  );

  useEffect(() => {
    if (lastOrderId) {
      handleFetchTransaction();
    }
  }, [
    lastOrderId,
    isFoodTruck,
    handleFetchTransaction,
    setOpenedTab,
    getLastTransaction,
  ]);

  useMemo(() => {
    if (errorLastTransaction) {
      enqueueSnackbar(errorLastTransaction, { variant: "error" });
    }
  }, [errorLastTransaction, enqueueSnackbar]);

  const loading =
    loadingStore ||
    loginLoading ||
    loadingMerchandise ||
    (!dataTransaction && loadingLastTransaction);

  const handleFoodTruckTab = (transaction: Transaction) => {
    handleSetLastTransactionCookie(transaction.id);
    setCheckoutTab(transaction);
  };

  const handleCheckout = (transaction: Transaction) => {
    setCheckoutTab(transaction);
  };

  const handleSuccessfulTransactionCreation = useCallback(
    (transaction: Transaction) => {
      handleSetLastTransactionCookie(transaction.id);
      setAddMoreTab(transaction);
      setIsPosted(true);
      setOpenedTab(transaction);
    },
    [setOpenedTab, setIsPosted]
  );

  const handleTabClosed = useCallback(
    (transaction: Transaction) => {
      setClosedTab(transaction);
    },
    [setClosedTab]
  );

  const handleCashierCheckout = useCallback(
    (transaction: Transaction) => {
      setShowNotified(true);
      handleTabClosed(transaction);
    },
    [setShowNotified, handleTabClosed]
  );

  const handleTransactionSuccess = useCallback(() => {
    setShowNotified(false);
    setClosedTab(checkoutTab);
  }, [checkoutTab, setClosedTab]);

  const handleCancelCloseCustomerTab = useCallback(() => {
    setCheckoutTab(undefined);
  }, [setCheckoutTab]);

  const handleOnDone = useCallback(() => {
    handleSetLastTransactionCookie(undefined);
    setAddMoreTab(undefined);
    setClosedTab(undefined);
    setOpenedTab(undefined);
    setShowNotified(false);
    setCheckoutTab(undefined);
  }, []);

  const customerView = useMemo(() => {
    if (!membershipChecked && dataStore) {
      return <Membership store={dataStore} />;
    } else if (showNotified && closedTab) {
      return (
        <CashierNotified
          transaction={closedTab}
          onCheckout={() => handleCheckout(closedTab)}
          onClose={handleOnDone}
        />
      );
    } else if (dataMerchandise && dataStore) {
      if (closedTab) {
        return (
          <PaymentSuccessComponent
            transaction={closedTab}
            store={dataStore}
            onDone={handleOnDone}
          />
        );
      } else if (!openedTab || addMoreTab) {
        return (
          <OpenTab
            onSuccess={handleSuccessfulTransactionCreation}
            onCreated={handleFoodTruckTab}
            onClose={() => setAddMoreTab(undefined)}
            merchandise={dataMerchandise}
            store={dataStore}
            addToTransaction={addMoreTab}
          />
        );
      } else if (openedTab) {
        return (
          <OrderPlaced
            transaction={openedTab}
            store={dataStore}
            onRefetch={handleFetchTransaction}
            onAddMore={setAddMoreTab}
            onClose={handleTabClosed}
            onCashierCheckout={handleCashierCheckout}
          />
        );
      }
    }
  }, [
    addMoreTab,
    openedTab,
    dataMerchandise,
    dataStore,
    handleFetchTransaction,
    handleSuccessfulTransactionCreation,
    handleTabClosed,
    handleCashierCheckout,
    handleOnDone,
    membershipChecked,
    closedTab,
    showNotified,
  ]);

  return (
    <div className={`customer_order_root ${className ?? ""}`}>
      {loading && (
        <div className="loading_state">
          {loginLoading && <span>Logging in as guest, please wait...</span>}
          <LoadingIcon />
        </div>
      )}
      <Elements stripe={stripePromise}>
        {isAuthenticated && dataStore && !loading && (
          <React.Fragment>
            {customerView}
            <CloseCustomerTab
              isFoodTruck={true}
              onClose={handleCancelCloseCustomerTab}
              onTransactionSuccess={handleTransactionSuccess}
              open={true}
              store={dataStore}
              transaction={checkoutTab}
              onChange={handleCheckoutChange}
            />
          </React.Fragment>
        )}
      </Elements>
    </div>
  );
};

export default CustomerOrder;

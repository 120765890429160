import _ from "lodash";
import { GetStoreTransactionsPayload } from "types/GrabbiTypes";
import { createQueryString, get, post } from "./api";

export const getStoreTransactions = (payload: GetStoreTransactionsPayload) => {
  const queryParams = _.omit(payload, "id");
  const qs = createQueryString(queryParams);
  const endpoint = `restful-services/transaction/store/${payload.storeId}${qs}`;

  const thenHandler = (response: any) => {
    return response.data;
  };

  const errorHandler = (error: any) => {
    console.error(error);
    return error.response;
  };

  const finallyHandler = () => {
    return {};
  };

  return get(endpoint, {}, {}, thenHandler, errorHandler, finallyHandler);
};

export interface TransactionUpdatePayload {
  id: number;
  fulfilled: boolean;
  store: {
    id: number;
  };
  transactionStatus?: string;
}

export const updateTransactionStatus = (payload: TransactionUpdatePayload) => {
  const endpoint = "restful-services/transaction";

  const body = payload;

  const thenHandler = (response: any) => {
    return response;
  };

  const errorHandler = (error: any) => {
    return error.response;
  };

  const finallyHandler = () => {
    return {};
  };

  return post(endpoint, body, {}, thenHandler, errorHandler, finallyHandler);
};

export interface TransactionRefundPayload {
  transactionId: number;
  storeId: number;
}

export const refundTransaction = (payload: TransactionRefundPayload) => {
  const endpoint = "restful-services/transaction/refund";

  const body = payload;

  const thenHandler = (response: any) => {
    return response;
  };

  const errorHandler = (error: any) => {
    return error.response;
  };

  const finallyHandler = () => {
    return {};
  };

  return post(endpoint, body, {}, thenHandler, errorHandler, finallyHandler);
};

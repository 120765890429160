import React, { ReactNode } from "react";
import { Box, Grid } from "@material-ui/core";
import GrabbiTabs from "components/Shared/GrabbiTabs/GrabbiTabsComponent";
import "components/Profile/EditProfile/EditProfileComponent.scss";
import { PersonalDetails } from "./PersonalDetails/PersonalDetailsContainer";
import { StoreDetails } from "./StoreDetails/StoreDetailsContainer";
import { ChangePassword } from "./ChangePassword/ChangePasswordContainer";
import { LoginState } from "store/signin/loginReducer";
import EditSettings from "./EditSettings/EditSettingsComponent";
import { PrinterSetup } from "./PrinterSetup/PrinterSetupContainer";
import { ManageAttendant } from "./ManageAttendant/ManageAttendantContainer";

interface Props {
  loginState: LoginState;
}

interface Tab {
  name: string;
  node: ReactNode;
}

const EditProfileComponent: React.FC<Props> = ({ loginState }) => {
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleTabChange = (tab: number) => {
    setSelectedTab(tab);
  };

  const socialAuth = loginState.authProvider !== undefined;

  const tabs: Tab[] = [
    {
      name: "PERSONAL DETAILS",
      node: <PersonalDetails />,
    },
  ];

  if (!socialAuth) {
    tabs.push({
      name: "CHANGE PASSWORD",
      node: <ChangePassword />,
    });
  }

  tabs.push({
    name: "STORE DETAILS",
    node: <StoreDetails />,
  });

  tabs.push({
    name: "SETTINGS",
    node: <EditSettings />,
  });

  tabs.push({
    name: "PRINTER SETUP",
    node: <PrinterSetup />,
  });

  tabs.push({
    name: "MANAGE ATTENDANT",
    node: <ManageAttendant />,
  });

  return (
    <Grid
      container
      item
      xs={12}
      className="edit_profile_root"
      alignContent="flex-start"
      justify="center"
    >
      <Grid item xs={12}>
        <Box className="edit_profile_header">EDIT PROFILE</Box>
      </Grid>
      <Grid item xs={12}>
        <GrabbiTabs
          tabs={tabs.map((tab) => tab.name)}
          selectedTab={selectedTab}
          mobileDialogTitle="Select Option"
          callback={handleTabChange}
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        className="edit_profile_content"
        direction="column"
        alignItems="center"
      >
        {tabs[selectedTab].node}
      </Grid>
    </Grid>
  );
};

export default EditProfileComponent;

import React from "react";
import * as _ from "lodash";
import "components/Shared/GrabbiTabs/GrabbiTabsComponent.scss";
import {
  Tabs,
  Tab,
  TabsClassKey,
  TabClassKey,
  Box,
  IconButton,
  FormControl,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import { isMobile } from "shared/scripts/Constants";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
import StyledRadioButton from "../RadioButton/RadioButton";
import { useWindowSize } from "shared/scripts/ResizeListener";
import GrabbiDialog from "../GrabbiDialog/GrabbiDialogComponent";

interface Props {
  disabled?: boolean;
  tabs: string[];
  selectedTab: number;
  mobileDialogTitle?: string;
  callback: (selectedIndex: number) => any;
  classes?: {
    tabs?: Partial<Record<TabsClassKey, string>> | undefined;
    tab?: Partial<Record<TabClassKey, string>> | undefined;
    mobile?: string;
  };
}

const GrabbiTabs: React.FC<Props> = ({
  tabs,
  selectedTab,
  mobileDialogTitle,
  callback,
  classes,
  disabled
}) => {
  useWindowSize();
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [selectedRadio, setSelectedRadio] = React.useState(selectedTab);

  const handleTabChange = (event: React.ChangeEvent<{}>, value: number) => {
    setSelectedRadio(value);
    callback(value);
  };

  const handleRadioChange = (event: React.ChangeEvent<{}>, value: string) => {
    setSelectedRadio(parseInt(value));
  };

  const handleFilterSelectOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setDialogOpen(true);
  };

  const handleFilterSelectClose = () => {
    setDialogOpen(false);
  };

  const handleSubmit = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    callback(selectedRadio);
    setDialogOpen(false);
  };

  return isMobile() ? (
    <React.Fragment>
      <button
        disabled={disabled}
        onClick={handleFilterSelectOpen}
        className={`grabbi_tabs_filter_dialog_top_mobile ${
          classes?.mobile ?? ""
        }`}
      >
        {tabs[selectedTab]}
        <ArrowDropDownIcon fontSize="large" />
      </button>
      <GrabbiDialog
        dialogProps={{
          open: dialogOpen,
          onClose: handleFilterSelectClose,
        }}
      >
        <Box className="grabbi_tabs_filter_dialog_top">
          <IconButton
            edge="end"
            classes={{ root: "grabbi_tabs_close_button_mobile" }}
            onClick={handleFilterSelectClose}
          >
            <CloseIcon
              fontSize="large"
              classes={{ root: "grabbi_tabs_close_icon_mobile" }}
            />
          </IconButton>
        </Box>
        <Box>
          <Box className="grabbi_tabs_filter_dialog_title">
            {mobileDialogTitle ? mobileDialogTitle : "FILTER BY"}
          </Box>
          <FormControl component="fieldset">
            <RadioGroup
              classes={{ root: "grabbi_tabs_filter_dialog_radio_group" }}
              aria-label="filter"
              name="filter"
              value={selectedRadio}
              onChange={handleRadioChange}
            >
              {tabs.map((tab: string, index: number) => (
                <FormControlLabel
                  key={tab}
                  classes={{
                    root: "grabbi_tabs_filter_dialog_radio_label_root",
                    label: "grabbi_tabs_filter_dialog_radio_label",
                  }}
                  value={index}
                  control={<StyledRadioButton />}
                  label={tab}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <button
            className="grabbi_tabs_filter_dialog_button"
            onClick={handleSubmit}
          >
            DONE
          </button>
        </Box>
      </GrabbiDialog>
    </React.Fragment>
  ) : (
    <Tabs
      classes={{
        root: `grabbi_tabs_root ${classes?.tabs?.root ?? ""}`,
        indicator: `grabbi_tabs_tab_indicator ${
          classes?.tabs?.indicator ?? ""
        }`,
        ..._.omit(classes?.tab, ["root", "indicator"]),
      }}
      value={selectedTab}
      onChange={handleTabChange}
    >
      {tabs.map((tab: string, index: number) => (
        <Tab
          disabled={disabled}
          classes={{
            root: `grabbi_tabs_tab ${tab.replace(/\s/g, "")}-tab ${
              classes?.tab?.root
            }`,
            selected: `grabbi_tabs_tab_selected ${
              classes?.tab?.selected ?? ""
            }`,
            ..._.omit(classes?.tab, ["root", "selected"]),
          }}
          key={tab}
          label={tab}
          value={index}
        />
      ))}
    </Tabs>
  );
};

export default GrabbiTabs;

import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  SelectProps,
} from "@material-ui/core";
import React, { useState } from "react";
import "./GrabbiMultiSelectDropdown.scss";

interface Props extends SelectProps {
  id?: string;
  className?: string;
  options: string[];
  selectedValues?: string[];
  handleChange?: (value: any) => void;
  label?: string;
  error?: boolean;
  errorMessage?: string;
}

const GrabbiMultiSelectDropdown: React.FC<Props> = React.forwardRef(
  (props: Props, ref) => {
    const {
      id,
      className,
      options,
      selectedValues,
      handleChange,
      label,
      error,
      errorMessage,
      ...otherProps
    } = props;
    const [selected, setSelected] = useState<string[]>([]);
    const isAllSelected =
      options.length > 0 && selected !== undefined && selected.length === options.length;
    const useStyles = makeStyles((theme) => ({
      formControl: {
        margin: theme.spacing(1),
        width: 300
      },
      indeterminateColor: {
        color: "#f50057"
      },
      selectAllText: {
        fontWeight: 500
      },
      selectedAll: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
      }
    }));
    const classes = useStyles();

    const handleClick = (event: any) => {
      const value = event.target.value;
      if (value[value.length - 1] === "all") {
        setSelected(selected.length === options.length ? [] : [...options]);
        if (handleChange) {
          handleChange(options);
        }
        return;
      }
      setSelected(value);
      if (handleChange) {
        handleChange(value);
      }
    };

    return (
      <React.Fragment>
        {errorMessage && (
          <Box className="grabbi_multi_select_dropdown_error">{errorMessage}</Box>
        )}
        <FormControl
          className={`grabbi_multi_select_dropdown_wrapper ${
            props.disabled ? "disabled" : ""
          }`}
        >
          {label && <InputLabel id="mutiple-select-label" className="label">{label}</InputLabel>}
          <Select
            id={id ? id : 'select'}
            labelId="mutiple-select-label"
            multiple
            value={selectedValues ? selectedValues : selected}
            onChange={handleClick}
            renderValue={(selected: any) => selected.join(", ")}

            inputRef={ref}
            inputProps={{
              className: "grabbi_multi_select_dropdown_input"
            }}
            displayEmpty
            variant="outlined"
            className={`grabbi_multi_select_dropdown_root ${
              label ? "labeled" : ""
            } ${className}`}
            MenuProps={{
              PaperProps: {
                className: "grabbi_multi_select_dropdown_menu",
              },
            }}
            {...otherProps}
          >
            <MenuItem
              value="all"
              classes={{
                root: isAllSelected ? classes.selectedAll : ""
              }}
            >
              <ListItemIcon>
                <Checkbox
                  classes={{ indeterminate: classes.indeterminateColor }}
                  checked={isAllSelected}
                  indeterminate={
                    selected.length > 0 && selected.length < options.length
                  }
                />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.selectAllText }}
                primary="Select All"
              />
            </MenuItem>
            {options.map((option: any) => (
              <MenuItem key={option} value={option}>
                <ListItemIcon>
                  <Checkbox checked={selectedValues ?  selectedValues.indexOf(option as never) > -1 : selected.indexOf(option as never) > -1} />
                </ListItemIcon>
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </React.Fragment>
    );
  }
);

export default GrabbiMultiSelectDropdown;

import { Grid, Typography } from "@material-ui/core";
import GrabbiTextField from "components/Shared/GrabbiTextField/GrabbiTextField";
import React, { useEffect } from "react";
import "./ChangePasswordComponent.scss";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { Merchant } from "types/GrabbiTypes";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";

interface Props {
  dispatchChangePassword: (
    merchant: Merchant,
    currentPassword: string,
    newPassword: string,
    confirmPassword: string
  ) => void;
  merchant?: Merchant;
  changePasswordStatus?: number;
  changePasswordMessage?: string;
  changePasswordLoading: boolean;
}

const ChangePasswordComponent: React.FC<Props> = ({
  dispatchChangePassword,
  merchant,
  changePasswordLoading,
  changePasswordMessage,
  changePasswordStatus,
}) => {
  const { register, handleSubmit, errors, getValues } = useForm({
    reValidateMode: "onChange",
    shouldFocusError: true,
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleChangePassword = () => {
    if (merchant) {
      dispatchChangePassword(
        merchant,
        getValues("password"),
        getValues("new_password"),
        getValues("confirm_password")
      );
    }
    enqueueSnackbar("Updating password...", { variant: "info" });
  };

  useEffect(() => {
    if (
      changePasswordStatus &&
      changePasswordMessage &&
      !changePasswordLoading
    ) {
      if (changePasswordStatus === 200) {
        enqueueSnackbar("Password changed.", { variant: "success" });
      } else if (changePasswordStatus === 401) {
        enqueueSnackbar(changePasswordMessage, {
          variant: "error",
        });
      } else {
        enqueueSnackbar(`Server Error: ${changePasswordMessage}`, {
          variant: "error",
        });
      }
    }
  }, [
    changePasswordStatus,
    changePasswordLoading,
    changePasswordMessage,
    enqueueSnackbar,
  ]);

  const handleSubmitError = () => {
    enqueueSnackbar("Please resolve errors and try again.", {
      variant: "error",
    });
  };

  return (
    <React.Fragment>
      <Grid
        item
        container
        className="edit_profile_change_password_content"
        direction="column"
        alignItems="center"
      >
        <form onSubmit={handleSubmit(handleChangePassword, handleSubmitError)}>
          <GrabbiTextField
            ref={register({
              minLength: {
                value: 8,
                message: "Must have 8 or more characters",
              },
              maxLength: {
                value: 128,
                message: "Must have 128 or less characters",
              },
              required: {
                value: true,
                message: "Please enter current password",
              },
            })}
            classes={{
              root: "edit_profile_field edit_profile_password_field",
            }}
            placeholder="Current Password"
            name="password"
            type="password"
            autoComplete="password"
            error={errors.password}
            errorMessage={errors?.password?.message}
            label="Current Password"
          />
          <GrabbiTextField
            ref={register({
              minLength: {
                value: 8,
                message: "Must have 8 or more characters",
              },
              maxLength: {
                value: 128,
                message: "Must have 128 or less characters",
              },
              required: {
                value: true,
                message: "Please enter new password",
              },
              validate: {
                passwordsEqual: (value) =>
                  value === getValues().confirm_password,
              },
            })}
            classes={{
              root: "edit_profile_field edit_profile_password_field",
            }}
            placeholder="New Password"
            name="new_password"
            type="password"
            autoComplete="new-password"
            error={errors.new_password}
            errorMessage={errors?.new_password?.message}
            label="New Password"
          />
          <GrabbiTextField
            ref={register({
              minLength: {
                value: 8,
                message: "Must have 8 or more characters",
              },
              maxLength: {
                value: 128,
                message: "Must have 128 or less characters",
              },
              required: {
                value: true,
                message: "Please confirm new password",
              },
              validate: {
                passwordsEqual: (value) => value === getValues().new_password,
              },
            })}
            classes={{
              root: "edit_profile_field edit_profile_password_field",
            }}
            placeholder="Confirm Password"
            name="confirm_password"
            type="password"
            autoComplete="confirm-password"
            error={errors.confirm_password}
            errorMessage={errors?.confirm_password?.message}
            label="Confirm Password"
          />
          {(errors.new_password || errors.confirm_password) &&
            ((errors.new_password &&
              errors.new_password.type === "passwordsEqual") ||
              (errors.confirm_password &&
                errors.confirm_password.type === "passwordsEqual")) && (
              <Typography className="edit_profile_form_error">
                New passwords must match
              </Typography>
            )}
          <GrabbiButton
            type="submit"
            classes={{
              root: "edit_profile_button",
            }}
          >
            {changePasswordLoading ? (
              <LoadingIcon className="loading" />
            ) : (
              "CHANGE PASSWORD"
            )}
          </GrabbiButton>
        </form>
      </Grid>
    </React.Fragment>
  );
};

export default ChangePasswordComponent;

import { connect } from "react-redux";
import { State } from "store";
import { getMerchantStartAction, updatePersonalDetailsStart } from "actions";
import { Merchant } from "types/GrabbiTypes";
import PersonalDetailsComponent from "./PersonalDetailsComponent";

const mapStateToProps = (state: State) => ({
  merchant: state.merchantStores.merchant,
  loadingMerchant: state.merchantStores.loadingMerchant,
  updateProfileLoading: state.merchantStores.updateProfileLoading,
  updateProfileError: state.merchantStores.updateProfileError
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatchGetMerchant: () => dispatch(getMerchantStartAction()),
  dispatchUpdatePersonalDetails: (merchant: Merchant) =>
    dispatch(updatePersonalDetailsStart(merchant))
});

export const PersonalDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalDetailsComponent);

import { Box, Typography } from "@material-ui/core";
import React from "react";
import {
  Bar,
  BarProps,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryLabel,
  VictoryLabelProps,
} from "victory";
import VennDiagram from "../VennDiagram/VennDiagram";
import "./HolidayActivityComponent.scss";

export interface HolidayActivity {
  holiday: string;
  Visitors: number;
  paired_item1: string;
  paired_item2: string;
}

interface Props {
  data: HolidayActivity[];
  className?: string;
}

const fontSize = 21;
const barWidth = 60;

const CustomLabel: React.FC<VictoryLabelProps> = (props) => {
  const { dx, dy, ...otherProps } = props;
  return (
    <React.Fragment>
      <VictoryLabel
        {...otherProps}
        dx={10}
        dy={-60}
        textAnchor="start"
        style={{
          fontFamily: "LemonMilk",
          fontSize,
          fill: "#ffffff",
        }}
      />
      <VictoryLabel
        {...otherProps}
        dx={30}
        dy={2}
        text="Visitors"
        textAnchor="start"
        style={{
          fontFamily: "LemonMilk",
          fontSize,
          fill: "#ffffff",
        }}
      />
    </React.Fragment>
  );
};

const CustomBar: React.FC<BarProps> = (props) => {
  const { ...otherProps } = props;
  return (
    <Bar
      {...otherProps}
      cornerRadius={{
        topLeft: 30,
        topRight: 30,
        bottomLeft: 30,
        bottomRight: 30,
      }}
    />
  );
};

const HolidaysActivity: React.FC<Props> = ({ data, className }) => {
  let visitorsMax = 0;
  for (let i = 0; i < data.length; i++) {
    const currentValue = data[i].Visitors;
    visitorsMax = currentValue > visitorsMax ? currentValue : visitorsMax;
  }

  return (
    <Box className={`holidays_activity_root ${className ?? ""}`}>
      {data.map((row) => {
        return (
          <Box className="activity_card">
            <Box className="visitor_counts_area">
              <Typography className="title">{row.holiday}</Typography>
              <VictoryChart
                domain={{ y: [0, visitorsMax] }}
                padding={{ left: 6, right: 60, top: 0, bottom: 0 }}
                containerComponent={<VictoryContainer />}
              >
                <VictoryBar
                  horizontal={true}
                  dataComponent={<CustomBar />}
                  data={[
                    { x: "Last Year", y: row.Visitors, label: row.Visitors },
                  ]}
                  style={{
                    data: { fill: "#444750" },
                    labels: {
                      fontFamily: "LemonMilk",
                      fontSize,
                      fill: "#ffffff",
                    },
                  }}
                  barWidth={barWidth}
                />
                <VictoryAxis
                  style={{
                    axis: { stroke: "transparent" },
                    ticks: { stroke: "transparent" },
                  }}
                  tickLabelComponent={<CustomLabel />}
                />
              </VictoryChart>
            </Box>
            <Box className="venn_diagram_wrapper">
              <VennDiagram
                circle1Content={row.paired_item1}
                circle2Content={row.paired_item2}
                joinText="AND"
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default HolidaysActivity;

import { BaseAction, actionIds } from "actions";
import { GrabbiStore } from "types/GrabbiTypes";

export type StoreListState = {
  stores?: GrabbiStore[];
  selectedStoreIndex?: number;
  selectedStore?: GrabbiStore;
  loadingStore: boolean;
  loadingStores: boolean;
  updateStoreLoading: boolean;
  storesChecked: boolean;
  loadingStoreError?: string;
  updateStoreErrors?: string[];
  storeCreated?: boolean;
  storeCreatedError?: string;
};

const DEFAULT_STATE: StoreListState = {
  storesChecked: false,
  loadingStore: false,
  loadingStores: false,
  updateStoreLoading: false,
  storeCreated: false,
};

export const storeCollectionReducer = (
  state: StoreListState = DEFAULT_STATE,
  action: BaseAction
): StoreListState => {
  switch (action.type) {
    case actionIds.UPDATE_STORE_DETAILS_START:
      return {
        ...state,
        updateStoreLoading: true,
        updateStoreErrors: undefined,
      };
    case actionIds.UPDATE_STORE_DETAILS_COMPLETED:
      return {
        ...state,
        updateStoreLoading: false,
      };
    case actionIds.UPDATE_STORE_DETAILS_FAILED:
      return {
        ...state,
        updateStoreLoading: false,
        updateStoreErrors: action.payload.errors,
      };
    case actionIds.GET_STORE_LIST_START:
      return {
        ...state,
        stores: state.stores,
        loadingStores: true,
      };
    case actionIds.GET_STORE_LIST_COMPLETED:
      return {
        ...state,
        stores: [...action.payload],
        loadingStores: false,
        storesChecked: true,
      };
    case actionIds.ADD_STORE_START:
      return {
        ...state,
        storeCreated: false,
        storeCreatedError: undefined,
      };
    case actionIds.ADD_STORE_SUCCESS:
      return {
        ...state,
        storeCreated: true,
      };
    case actionIds.ADD_STORE_FAILED:
      return {
        ...state,
        storeCreated: false,
        storeCreatedError: action.payload.error,
      };
    case actionIds.GET_STORE_BY_UPC_START:
      return {
        ...state,
        loadingStore: true,
      };
    case actionIds.GET_STORE_BY_UPC_COMPLETED:
      return {
        ...state,
        loadingStore: false,
        loadingStoreError: undefined,
      };
    case actionIds.GET_STORE_BY_UPC_FAILED:
      return {
        ...state,
        loadingStore: false,
        loadingStoreError: action.payload.error.response.data.message,
      };
    case actionIds.SET_SELECTED_STORE:
      return {
        ...state,
        selectedStoreIndex: action.payload.index,
        selectedStore: state.stores
          ? state.stores[action.payload.index]
          : undefined,
      };
    case actionIds.LOGOUT_START:
      return DEFAULT_STATE;
  }
  return state;
};

import { get, post } from "./api";

export const getAllMerchants = (
  options: { pageNumber: number; pageSize: number; sortBy: string }
) => {
  const endpoint = `restful-services/merchant/all?pageNumber=${options.pageNumber}&pageSize=${options.pageSize}&sortBy=${options.sortBy}`;

  const thenHandler = (response: any) => {
    return response.data;
  };

  const errorHandler = (error: any) => {
    console.error(error);
    return {};
  };

  const finallyHandler = () => {
    return {};
  };

  return get(endpoint, {}, {}, thenHandler, errorHandler, finallyHandler);
};

export const getAllStores = (options: {
  pageNumber: number;
  pageSize: number;
  sortBy: string;
}) => {
  const endpoint = `restful-services/store/all?pageNumber=${options.pageNumber}&pageSize=${options.pageSize}&sortBy=${options.sortBy}`;

  const thenHandler = (response: any) => {
    return response.data;
  };

  const errorHandler = (error: any) => {
    console.error(error);
    return {};
  };

  const finallyHandler = () => {
    return {};
  };

  return get(endpoint, {}, {}, thenHandler, errorHandler, finallyHandler);
};

export const activateStore = (id: number) => {
  const endpoint = `restful-services/store/${id}/activate`;

  const thenHandler = (response: any) => {
    return response.data;
  };

  const errorHandler = (error: any) => {
    console.error(error);
    return {};
  };

  const finallyHandler = () => {
    return {};
  };

  return post(endpoint, {}, {}, thenHandler, errorHandler, finallyHandler);
};

export const deactivateStore = (id: number) => {
  const endpoint = `restful-services/store/${id}/deactivate`;

  const thenHandler = (response: any) => {
    return response.data;
  };

  const errorHandler = (error: any) => {
    console.error(error);
    return {};
  };

  const finallyHandler = () => {
    return {};
  };

  return post(endpoint, {}, {}, thenHandler, errorHandler, finallyHandler);
};

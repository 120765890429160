import React, { useCallback, useEffect, useState } from "react";
import './AddStoreComponent.scss';
import {
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import GrabbiTextField from 'components/Shared/GrabbiTextField/GrabbiTextField';
import { CONTEXT } from 'shared/scripts/UrlContext';
import { GrabbiStore } from 'types/GrabbiTypes';
import { LoginState } from 'store/signin/loginReducer';
import { StoreListState } from 'store/stores/storesReducer';
import { useForm } from 'react-hook-form';
import { useSnackbar } from "notistack";
import { ADDRESS_REGEX, ZIP_REGEX } from "shared/scripts/Constants";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

interface Props {
  loginState: LoginState;
  storeState: StoreListState;
  storeCreatedError?: string;
  dispatchAddStore: (store: Omit<GrabbiStore, "id">) => void;
}

const AddStoreComponent: React.FC<Props> = ({
  loginState,
  storeState,
  storeCreatedError,
  dispatchAddStore,
  }) => {
  const { register, handleSubmit, errors, setValue, getValues, control } = useForm({
    reValidateMode: 'onBlur',
    shouldFocusError: true,
  });
  const history = useHistory();
  const [fieldsInitialized, setFieldsInitialized] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [selectedState, setSelectedState] = useState<string>('');

  const initializeStoreFields = useCallback(() => {
      setValue('store_name', '');
      setValue('city', '');
      setValue('store_address', '');
      setValue('store_zip', '');
      setFieldsInitialized(true);
  }, [setFieldsInitialized, setValue]);

  const handleSubmitValid = () => {
    const merchant = {
      id: Number(loginState.merchantId),
    };

    const country = {
      code: selectedCountry === 'US' ? 'USA' : selectedCountry
    };

    const state = {
      code: selectedState
    };

    const address = {
      country: country,
      state: state,
      city: getValues('city'),
      addressLine1: getValues('store_address'),
      zip: getValues('store_zip'),
    };

    const newStore: Omit<GrabbiStore, "id"> = {
      name: getValues('store_name'),
      merchant: merchant,
      address: address,
    };
    dispatchAddStore(newStore);
  };

  const handleSubmitError = () => {
    enqueueSnackbar('Please resolve errors and try again.', {
      variant: 'error',
    });
  };

  const changeCountry = (value: any) => {
    setSelectedCountry(value);
  }

  const changeState = (value: any) => {
    setSelectedState(value);
  }

  useEffect(() => {
    if (!fieldsInitialized) {
      initializeStoreFields();
    }
  }, [storeState, fieldsInitialized, initializeStoreFields]);


  useEffect(() => {
    if(storeState.storeCreated) {
      history.push({
        pathname: `${CONTEXT}successmessage`,
        state: {
          mainMessage: 'STORE ADDED',
          secondMessage: 'WE HAVE CREATED YOUR STORE',
          buttonText: 'STORE DASHBOARD',
          goTo: `${CONTEXT}dashboard/activity`,
        },
      });
      enqueueSnackbar('We have created your store', { variant: 'info' });
    }
    else if (storeCreatedError) {
      enqueueSnackbar(storeCreatedError, { variant: "error" });
    }
  }, [history, storeState, enqueueSnackbar, storeCreatedError]);

  return (
    <div className="add_store_wrapper">
      <Box className="add_store_title_container">
        <Typography classes={{ root: "add_store_title" }}>ADD STORE</Typography>
      </Box>
      <div className="add_store_detail_grid">
        <div
          className={`add_store_details`}
        >
          <Box className="add_store_root">
            <form
              className="add_store_form"
              onSubmit={handleSubmit(handleSubmitValid, handleSubmitError)}
            >
              <div>
                <GrabbiTextField
                  ref={register({
                    minLength: {
                      value: 2,
                      message: "Must have 2 or more characters",
                    },
                    maxLength: {
                      value: 50,
                      message: "Must have 50 or less characters",
                    },
                    required: {
                      value: true,
                      message: "Please enter store name",
                    },
                  })}
                  placeholder="Store Name"
                  name="store_name"
                  label="Store Name"
                  autoComplete="store_name"
                  error={errors.store_name}
                  errorMessage={errors?.store_name?.message}
                  control={control}
                />
                <CountryDropdown
                  defaultOptionLabel="Country"
                  value={selectedCountry}
                  whitelist={["CA", "US"]}
                  onChange={changeCountry}
                  valueType="short"
                  classes="grabbi_country_state_drop_down"/>
                <RegionDropdown
                  blankOptionLabel="State"
                  defaultOptionLabel="State"
                  country={selectedCountry}
                  value={selectedState}
                  onChange={changeState}
                  countryValueType="short"
                  valueType="short"
                  classes="grabbi_country_state_drop_down"/>
                <GrabbiTextField
                  ref={register({
                    minLength: {
                      value: 5,
                      message: "Must have 5 or more characters",
                    },
                    maxLength: {
                      value: 50,
                      message: "Must have 50 or less characters",
                    },
                    pattern: {
                      value: ADDRESS_REGEX,
                      message: "May only contain letters",
                    },
                    required: {
                      value: true,
                      message: "Please enter city",
                    },
                  })}
                  placeholder="City"
                  name="city"
                  autoComplete="city"
                  label="City"
                  error={errors.city}
                  errorMessage={errors?.city?.message}
                  control={control}
                />
                <GrabbiTextField
                  ref={register({
                    minLength: {
                      value: 5,
                      message: "Must have 5 or more characters",
                    },
                    maxLength: {
                      value: 50,
                      message: "Must have 50 or less characters",
                    },
                    pattern: {
                      value: ADDRESS_REGEX,
                      message: "May only contain letters, numbers, and comma",
                    },
                    required: {
                      value: true,
                      message: "Please enter store address",
                    },
                  })}
                  placeholder="Store Address"
                  name="store_address"
                  autoComplete="store_address"
                  label="Store Address"
                  error={errors.store_address}
                  errorMessage={errors?.store_address?.message}
                  control={control}
                />
                <GrabbiTextField
                  ref={register({
                    minLength: {
                      value: 1,
                      message: "Must have 1 or more digits",
                    },
                    maxLength: {
                      value: 10,
                      message: "Must have 10 or less digits",
                    },
                    required: {
                      value: true,
                      message: "Please enter zip code",
                    },
                    pattern: {
                      value: ZIP_REGEX,
                      message: "Must be valid US or Canandian Zip Code",
                    },
                  })}
                  placeholder="Zip Code"
                  name="store_zip"
                  autoComplete="store_zip"
                  label="Zip Code"
                  error={errors.store_zip}
                  errorMessage={errors?.store_zip?.message}
                  control={control}
                />
              </div>
              <div>
                <Button
                  type="submit"
                  classes={{ root: "add_store_submit_button" }}
                >
                  ADD STORE
                </Button>
              </div>
            </form>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default AddStoreComponent;
import {
  Grid,
  Box,
  RadioGroup,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import GrabbiTextField from "components/Shared/GrabbiTextField/GrabbiTextField";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";
import NoStoresRegistered from "components/Shared/NoStoresRegistered/NoStoresRegisteredComponent";
import BlackYellowRadioButton from "components/Shared/RadioButton/BlackYellowRadioButton";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { LoginState } from "store/signin/loginReducer";
import { GrabbiStore, Merchant } from "types/GrabbiTypes";
import { useForm } from "react-hook-form";
import "./StoreDetailsComponent.scss";
import {
  ADDRESS_REGEX,
  ALPHANUMERIC_REGEX,
  NUMERIC_REGEX,
  ZIP_REGEX,
} from "shared/scripts/Constants";
import GrabbiDropdown from "components/Shared/GrabbiDropdown/GrabbiDropdown";

interface Props {
  stores?: GrabbiStore[];
  loadingStores: boolean;
  loadingMerchant: boolean;
  updateStoreLoading: boolean;
  updateStoreErrors?: string[];
  merchant?: Merchant;
  loginState: LoginState;
  dispatchUpdateStoreDetails: (store: GrabbiStore) => void;
  dispatchGetStores: (id: number) => void;
  dispatchGetMerchant: () => void;
}

const StoreDetailsComponent: React.FC<Props> = ({
  stores,
  loadingStores,
  loadingMerchant,
  updateStoreLoading,
  updateStoreErrors,
  loginState,
  merchant,
  dispatchGetStores,
  dispatchUpdateStoreDetails,
  dispatchGetMerchant,
}) => {
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    control,
    setError,
    clearErrors,
  } = useForm({
    reValidateMode: "onChange",
    shouldFocusError: true,
  });

  const [selectedType, setSelectedType] = useState<number>();
  const [selectedStoreIndex, setPrimaryStore] = useState(0);
  const [fieldsInitialized, setFieldsInitialized] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [pendingUpdate, setPendingUpdate] = useState(false);

  if (!merchant && !loadingMerchant) {
    dispatchGetMerchant();
  }

  if (!stores && !loadingStores && merchant && loginState) {
    dispatchGetStores(Number(loginState.merchantId));
  }

  const selectedStore =
    selectedStoreIndex !== undefined ? stores?.[selectedStoreIndex] : undefined;

  const initializeStoreFields = useCallback(() => {
    if (selectedStore && stores && stores.length > 0) {
      const storeAddress = selectedStore.address;
      const storeAccount = selectedStore.account;
      setValue("store_name", selectedStore.name);
      setSelectedType(selectedStore.storeType);
      setValue("store_address", storeAddress?.addressLine1 ?? "");
      setValue("store_zip", storeAddress?.zip ?? "");
      setValue("bank_account", storeAccount?.accountNumber ?? "");
      setValue("routing_number", storeAccount?.routingNumber ?? "");
      setValue("account_nickname", storeAccount?.bankName ?? "");
      clearErrors();
      setFieldsInitialized(true);
    } else if (stores && stores.length === 0) {
      setFieldsInitialized(true);
    }
  }, [stores, selectedStore, setFieldsInitialized, setValue, clearErrors]);

  useEffect(() => {
    if (!fieldsInitialized) {
      initializeStoreFields();
    }
  }, [stores, fieldsInitialized, selectedStoreIndex, initializeStoreFields]);

  const changePrimaryStore = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    value: string
  ) => {
    const index: number = parseInt(value);
    setFieldsInitialized(false);
    setPrimaryStore(index);
  };

  const handleSubmitValid = () => {
    setValue(
      "store_zip",
      getValues("store_zip").toLocaleUpperCase().replace(/\s/g, "")
    );
    if (stores && merchant) {
      const currentStore: GrabbiStore = stores[selectedStoreIndex];
      if (currentStore?.address?.id) {
        const newStore: GrabbiStore = {
          ...currentStore,
          name: getValues("store_name"),
          storeType: selectedType,
          address: {
            ...currentStore.address,
            addressLine1: getValues("store_address"),
            zip: getValues("store_zip"),
          },
          merchant: {
            id: merchant.id,
          },
          account: {
            id: currentStore?.account?.id ? currentStore.account.id : undefined,
            bankName: getValues("account_nickname"),
            accountNumber: getValues("bank_account"),
            routingNumber: getValues("routing_number"),
          },
        };
        dispatchUpdateStoreDetails(newStore);
        enqueueSnackbar("Updating store details...", { variant: "info" });
      }
    }
  };

  useEffect(() => {
    if (updateStoreLoading) {
      setPendingUpdate(true);
    }
  }, [updateStoreLoading, setPendingUpdate]);

  useEffect(() => {
    if (pendingUpdate && !updateStoreLoading && !updateStoreErrors) {
      enqueueSnackbar("Store updated", { variant: "success" });
      setPendingUpdate(false);
    } else if (pendingUpdate && !updateStoreLoading && updateStoreErrors) {
      setPendingUpdate(false);
      let errorMessage = updateStoreErrors[0];
      const errorMessageLC = errorMessage.toLocaleLowerCase();
      if (errorMessageLC.includes("in the same zip code")) {
        errorMessage = `A store with this name already exists at ${getValues(
          "store_zip"
        )}`;
        setError("store_name", {
          type: "validate",
          message: errorMessage,
        });
      } else if (errorMessageLC.includes("no record found for city with zip")) {
        errorMessage = "Zip code not found, please enter a valid zip code.";
      }
      enqueueSnackbar(errorMessage, { variant: "error" });
    }
  }, [
    updateStoreLoading,
    pendingUpdate,
    setPendingUpdate,
    updateStoreErrors,
    enqueueSnackbar,
    setError,
    getValues,
  ]);

  const handleSubmitError = () => {
    enqueueSnackbar("Please resolve errors and try again.", {
      variant: "error",
    });
  };

  const storeFieldsLoading = updateStoreLoading || !stores;

  return (
    <Grid item container className="edit_profile_store_details_content">
      {((stores && stores.length > 0) || !fieldsInitialized) && (
        <React.Fragment>
          <Grid
            className="edit_profile_store_details_primary_store"
            item
            container
            direction="column"
          >
            <Box className="edit_profile_store_details_header_1">
              PRIMARY STORE
            </Box>
            {stores ? (
              <RadioGroup
                className="edit_profile_store_details_radio_group"
                name="filter"
                value={selectedStoreIndex}
                onChange={changePrimaryStore}
              >
                {stores &&
                  stores.map((store: GrabbiStore, index: number) => (
                    <React.Fragment key={`${store.name}-fragment-${index}`}>
                      <FormControlLabel
                        key={`${store.name}-form-control-label-${index}`}
                        value={index}
                        control={<BlackYellowRadioButton />}
                        label={
                          <Box className="edit_profile_store_details_radio_label edit_profile_store_details_header_2">
                            {store.name}
                          </Box>
                        }
                      />
                      <Grid
                        item
                        container
                        className="edit_profile_store_details_radio_label_subtext"
                        direction="column"
                        key={`${store.name}-grid-item-${index}`}
                      >
                        <Box className="edit_profile_store_details_subtext">
                          {store?.address?.addressLine1 ?? ""}
                        </Box>
                        <Box className="edit_profile_store_details_subtext">
                          {store?.address?.zip ?? ""}
                        </Box>
                      </Grid>
                    </React.Fragment>
                  ))}
              </RadioGroup>
            ) : (
              <LoadingIcon style={{ marginTop: 30 }} />
            )}
          </Grid>
          <Grid
            className="edit_profile_store_details_right_section"
            item
            container
          >
            <Grid
              item
              container
              direction="column"
              className="edit_profile_store_details_region_section"
            >
              <Box className="edit_profile_store_details_header_1">
                STORE REGION
              </Box>
              <Grid
                item
                container
                className="edit_profile_store_details_region_area"
                direction="row"
                wrap="wrap"
              >
                <Grid
                  item
                  className="edit_profile_store_details_region_text_box"
                >
                  <Typography className="edit_profile_store_details_region_text">
                    {stores
                      ? `${
                          stores[selectedStoreIndex]?.address?.state?.name ?? ""
                        }, ${
                          stores[selectedStoreIndex]?.address?.country?.code ??
                          ""
                        }`
                      : ""}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <form onSubmit={handleSubmit(handleSubmitValid, handleSubmitError)}>
              <Grid
                container
                item
                className="edit_profile_store_details_columns"
              >
                {storeFieldsLoading && (
                  <Box className="edit_profile_loading_icon_wrapper">
                    <LoadingIcon
                      className="edit_profile_loading_icon"
                      size={80}
                    />
                  </Box>
                )}
                <Grid
                  className="edit_profile_store_details_left_column"
                  item
                  container
                  direction="column"
                >
                  <Box className="edit_profile_store_details_header_1 edit_profile_store_details_header">
                    STORES
                  </Box>
                  <React.Fragment>
                    <GrabbiTextField
                      ref={register({
                        minLength: {
                          value: 2,
                          message: "Must have 2 or more characters",
                        },
                        maxLength: {
                          value: 50,
                          message: "Must have 50 or less characters",
                        },
                        required: {
                          value: true,
                          message: "Please enter store name",
                        },
                      })}
                      classes={{
                        root: "edit_profile_field edit_profile_field_store_details",
                      }}
                      InputProps={{
                        classes: {
                          input:
                            "edit_profile_field_input edit_profile_field_store_details",
                        },
                      }}
                      placeholder="Store Name"
                      name="store_name"
                      autoComplete="store_name"
                      disabled={storeFieldsLoading}
                      error={errors.store_name}
                      errorMessage={errors?.store_name?.message}
                      control={control}
                      label="Store Name"
                    />
                    <GrabbiDropdown
                      className="edit_store_dropdown_menu edit_profile_field"
                      label="Store Type"
                      name="store_type"
                      options={["Food Truck", "Restaurant", "Grocery Store"]}
                      selectedIndex={selectedType ?? -1}
                      handleChange={(index) => {
                        setSelectedType(index);
                        setValue("store_type", index);
                      }}
                    />

                    <GrabbiTextField
                      ref={register({
                        minLength: {
                          value: 5,
                          message: "Must have 5 or more characters",
                        },
                        maxLength: {
                          value: 50,
                          message: "Must have 50 or less characters",
                        },
                        pattern: {
                          value: ADDRESS_REGEX,
                          message: "May only contain letters and numbers",
                        },
                        required: {
                          value: true,
                          message: "Please enter street address",
                        },
                      })}
                      classes={{
                        root: "edit_profile_field edit_profile_field_store_details",
                      }}
                      InputProps={{
                        classes: {
                          input:
                            "edit_profile_field_input edit_profile_field_store_details",
                        },
                      }}
                      placeholder="Street Address"
                      name="store_address"
                      autoComplete="address"
                      disabled={storeFieldsLoading}
                      error={errors.store_address}
                      errorMessage={errors?.store_address?.message}
                      control={control}
                      label="Street Address"
                    />
                    <GrabbiTextField
                      ref={register({
                        minLength: {
                          value: 1,
                          message: "Must have 1 or more digits",
                        },
                        maxLength: {
                          value: 10,
                          message: "Must have 10 or less digits",
                        },
                        required: {
                          value: true,
                          message: "Please enter zip code",
                        },
                        pattern: {
                          value: ZIP_REGEX,
                          message: "Invalid zip code",
                        },
                      })}
                      classes={{
                        root: "edit_profile_field edit_profile_field_store_details",
                      }}
                      InputProps={{
                        classes: {
                          input:
                            "edit_profile_field_input edit_profile_field_store_details",
                        },
                        onChange: (event: any) => {
                          const value = event.target.value;
                          setValue(
                            "store_zip",
                            value.toLocaleUpperCase().trim()
                          );
                        },
                      }}
                      placeholder="Zip Code"
                      name="store_zip"
                      autoComplete="zipcode"
                      disabled={storeFieldsLoading}
                      error={errors.store_zip}
                      errorMessage={errors?.store_zip?.message}
                      control={control}
                      label="Zip Code"
                    />
                  </React.Fragment>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  className="edit_profile_store_details_right_column"
                >
                  <Box className="edit_profile_store_details_header_1 edit_profile_store_details_header">
                    BANKING INFO
                  </Box>
                  <GrabbiTextField
                    ref={register({
                      minLength: {
                        value: 6,
                        message: "Must have 6 or more digits",
                      },
                      maxLength: {
                        value: 25,
                        message: "Must have 25 or less digits",
                      },
                      required: {
                        value: true,
                        message: "Please enter bank account",
                      },
                      pattern: {
                        value: NUMERIC_REGEX,
                        message: "May only contain numbers",
                      },
                    })}
                    classes={{
                      root: "edit_profile_field edit_profile_field_store_details",
                    }}
                    InputProps={{
                      classes: {
                        input:
                          "edit_profile_field_input edit_profile_field_store_details",
                      },
                    }}
                    placeholder="Bank Account"
                    name="bank_account"
                    autoComplete="bank_account"
                    disabled={storeFieldsLoading}
                    error={errors.bank_account}
                    errorMessage={errors?.bank_account?.message}
                    control={control}
                    label="Bank Account"
                  />
                  <GrabbiTextField
                    ref={register({
                      minLength: {
                        value: 6,
                        message: "Must have 6 or more digits",
                      },
                      maxLength: {
                        value: 25,
                        message: "Must have 25 or less digits",
                      },
                      required: {
                        value: true,
                        message: "Please enter routing number",
                      },
                      pattern: {
                        value: NUMERIC_REGEX,
                        message: "May only contain numbers",
                      },
                    })}
                    classes={{
                      root: "edit_profile_field edit_profile_field_store_details",
                    }}
                    InputProps={{
                      classes: {
                        input:
                          "edit_profile_field_input edit_profile_field_store_details",
                      },
                    }}
                    placeholder="Routing Number"
                    name="routing_number"
                    autoComplete="routing_number"
                    disabled={storeFieldsLoading}
                    error={errors.routing_number}
                    errorMessage={errors?.routing_number?.message}
                    control={control}
                    label="Routing Number"
                  />

                  <GrabbiTextField
                    ref={register({
                      maxLength: {
                        value: 40,
                        message: "May contain up to 40 characters",
                      },
                      pattern: {
                        value: ALPHANUMERIC_REGEX,
                        message: "May only contain letters and numbers",
                      },
                    })}
                    classes={{
                      root: "edit_profile_field edit_profile_field_store_details",
                    }}
                    InputProps={{
                      classes: {
                        input:
                          "edit_profile_field_input edit_profile_field_store_details",
                      },
                    }}
                    placeholder="Account Nickname (optional)"
                    name="account_nickname"
                    autoComplete="account_nickname"
                    disabled={storeFieldsLoading}
                    error={errors.account_nickname}
                    errorMessage={errors?.account_nickname?.message}
                    control={control}
                    label="Account Nickname"
                  />
                </Grid>
                <GrabbiButton
                  type="submit"
                  className="edit_profile_update_profile_button"
                  disabled={storeFieldsLoading}
                >
                  UPDATE STORE
                </GrabbiButton>
              </Grid>
            </form>
          </Grid>
        </React.Fragment>
      )}
      {stores && stores.length === 0 && <NoStoresRegistered />}
    </Grid>
  );
};

export default StoreDetailsComponent;

import { Box } from "@material-ui/core";
import GrabbiDropdown from "components/Shared/GrabbiDropdown/GrabbiDropdown";
import React, { useState } from "react";
import "./TimeSeriesChart.scss";

interface Props {
  className?: string;
  data: TimeSeriesData[];
}

export interface TimeSeriesData {
  day_of_week: number;
  hour: number;
  Description: string;
}

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const timesOfDay = [
  {
    name: "Early Morning",
    min: 0,
    max: 5,
  },
  {
    name: "Morning",
    min: 6,
    max: 11,
  },
  {
    name: "Afternoon",
    min: 12,
    max: 17,
  },
  {
    name: "Evening",
    min: 18,
    max: 23,
  },
];

const convertTime = (time: number) => {
  if (time < 12) {
    if (time === 0) {
      return `12AM`;
    }
    return `${time}AM`;
  } else {
    if (time === 12) {
      return `12PM`
    }
    return `${time - 12}PM`;
  }
};

const TimeSeriesChart: React.FC<Props> = ({ className, data }) => {
  const [selectedDayIndex, setSelectedDayIndex] = useState(0);
  const [selectedTimeIndex, setSelectedTimeIndex] = useState(0);

  const selectedTime = timesOfDay[selectedTimeIndex];

  const filteredRows = data
    .filter((row) => row.day_of_week === selectedDayIndex)
    .filter(
      (row) => row.hour >= selectedTime.min && row.hour <= selectedTime.max
    );

  return (
    <Box className={`time_series_chart_root ${className}`}>
      <Box className="chart_wrapper">
        <Box className="filters">
          <GrabbiDropdown
            options={days}
            selectedIndex={selectedDayIndex}
            className="dropdown"
            handleChange={(index) => setSelectedDayIndex(index)}
          />
          <GrabbiDropdown
            options={timesOfDay.map((item) => item.name)}
            selectedIndex={selectedTimeIndex}
            className="dropdown"
            handleChange={(index) => setSelectedTimeIndex(index)}
          />
        </Box>
        <Box className="chart">
          <Box className="line_wrapper">
            <svg className="line_svg">
              <line className="line" x1="50%" y1={0} x2="50%" y2="100%" />
            </svg>
          </Box>
          <Box className="chart_rows">
            {filteredRows.map((row: TimeSeriesData, index: number) => {
              return (
                <Box className="chart_row">
                  <Box className="time_circle_wrapper">
                    <Box className="time_circle">{convertTime(row.hour)}</Box>
                  </Box>
                  <Box className="items_area">
                    {row.Description.split(",").map((item) => {
                      return <Box className="item_box">{item}</Box>;
                    })}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TimeSeriesChart;

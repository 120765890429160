import { connect } from "react-redux";
import { State } from "store";
import { getMerchantStartAction, getStoreListStartAction, updateStoreDetailsStart } from "actions";
import { GrabbiStore } from "types/GrabbiTypes";
import StoreDetailsComponent from "./StoreDetailsComponent";

const mapStateToProps = (state: State) => ({
  stores: state.storeList.stores,
  loadingStores: state.storeList.loadingStores,
  loadingMerchant: state.merchantStores.loadingMerchant,
  updateStoreLoading: state.storeList.updateStoreLoading,
  updateStoreErrors: state.storeList.updateStoreErrors,
  merchant: state.merchantStores.merchant,
  loginState: state.loginState,
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatchGetStores: (id: number) => dispatch(getStoreListStartAction(id)),
  dispatchGetMerchant: () => dispatch(getMerchantStartAction()),
  dispatchUpdateStoreDetails: (store: GrabbiStore) =>
    dispatch(updateStoreDetailsStart(store)),
});

export const StoreDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreDetailsComponent);

import { Box } from "@material-ui/core";
import React, { useState } from "react";
import { VictoryData } from "types/GrabbiTypes";
import { numberToWeekday } from "../constants";
import HorizontalBarChartComponent from "../HorizontalBarChart/HorizontalBarChartComponent";
import "./HotSellerChartComponent.scss";

export interface HotSellerData {
  day_of_week: number;
  items: string[];
  sales: number[];
}

interface Props {
  data: HotSellerData[];
  className?: string;
}

const dayChoices = [0, 1, 2, 3, 4, 5, 6].map((num) => numberToWeekday(num));

const HotSellerChart: React.FC<Props> = ({ data, className }) => {
  const [selectedDay, setSelectedDay] = useState(0);
  const selectedRow = data[selectedDay];

  const handleSelectDay = (index: number) => () => {
    if (index >= 0 && index <= 6) {
      setSelectedDay(index);
    }
  };

  const rows: VictoryData[] = [];
  const items = selectedRow.items[0].split(", ");
  for (let i = 0; i < selectedRow.sales.length; i++) {
    rows.push({
      x: items[i],
      y: selectedRow.sales[i],
      label: `${selectedRow.sales[i].toString()} UNITS`,
    });
  }
  rows.sort((a, b) => (a.y > b.y ? 1 : a.y < b.y ? -1 : 0));

  return (
    <Box className={`hot_seller_root ${className ?? ""}`}>
      <Box className="weekday_choices">
        {dayChoices.map((day, index) => {
          return (
            <Box
              className={`weekday_choice ${
                index === selectedDay ? "selected" : ""
              }`}
              onClick={handleSelectDay(index)}
            >
              {day}
            </Box>
          );
        })}
      </Box>
      <HorizontalBarChartComponent data={rows} />
    </Box>
  );
};

export default HotSellerChart;

import { Grid, useMediaQuery } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import React, { useCallback, useEffect, useState } from "react";
import "./ResturantBoardComponent.scss";
import DraggableItemCard, { ActionCard } from "./ItemCard/ItemCardComponent";
import {
  OrderItemStatus,
  GetStoreTransactionsPayload,
  StatusUpdateHistory,
  Transaction,
  TransactionItem,
  FulfillTransactionPayload,
  GrabbiPrinter,
  GrabbiPrinterUrlPayload,
} from "types/GrabbiTypes";
import { cloneDeep, groupBy, omit, orderBy, uniqBy } from "lodash";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import OrderCode from "components/Shared/OrderCode/OrderCodeComponent";
import EditTableDialog from "./EditTableDialog/EditTableDialogComponent";
import AddItemDialog from "./AddItemDialog/AddItemDialogComponent";
import useTransactionHooks from "hooks/TransactionHooks";
import { useParams } from "react-router-dom";
import { createQueryString } from "actions/api";
import { useSelector } from "react-redux";
import { State } from "store";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";
import { useSnackbar } from "notistack";
import OpenTabDialog from "./OpenTabDialog/OpenTabDialogComponent";
import CloseTabDialog from "./CloseTabDialog/CloseTabDialogComponent";
import { useInterval } from "shared/scripts/useInterval";
import {
  DragDropContext,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { DateTime } from "luxon";
import { useAxiosContext } from "components/Shared/AxiosProvider/AxiosProvider";
//import { ITEM_STATUS_SEQUENCE } from "shared/scripts/Constants";
import GrabbiDropdown from "components/Shared/GrabbiDropdown/GrabbiDropdown";
import {
  ITEM_STATUS_SEQUENCE,
  printersLocationIdBasedOnNameStr,
} from "shared/scripts/Constants";
import { capitalizeFirst, formatCurrency } from "shared/scripts/Utils";
import CheckoutTabDialog from "./CheckoutTabDialog/CheckoutTabDialogComponent";
import {
  Archive,
  ChevronRight,
  FilterList,
  Payment,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import {
  generatePrintReceipt,
  generatePrintUrl,
} from "components/Shared/GrabbiPrint/printUtil";
import useAxios from "axios-hooks";

interface Props {
  refundMode?: boolean;
}

// ? [REVISIT] Temporary until logic around showing the icon is better defined
// const ENABLE_PRINT = true;
const REFRESH_INTERVAL = 7;
const { REACT_APP_SELECTOR_TESTING_MODE } = process.env;

const ResturantBoard: React.FC<Props> = ({ refundMode }) => {
  const [collapsedPanels, setCollapsedPanels] = useState<{
    [index: number]: boolean;
  }>({});

  const { enqueueSnackbar } = useSnackbar();
  const [params, setParams] = useState<GetStoreTransactionsPayload>();
  const [addItemTransaction, setAddItemTransaction] = useState<Transaction>();
  const [editTransactions, setEditTransactions] = useState<Transaction[]>();
  const [showOpenTabTransaction, setShowOpenTabTransaction] = useState(
    REACT_APP_SELECTOR_TESTING_MODE === "true" ? true : false
  );
  const [closeTransaction, setCloseTransaction] = useState<Transaction>();
  const [checkoutTransaction, setCheckoutTransaction] = useState<Transaction>();
  const [transactionsState, setTransactionsState] = useState<Transaction[]>();
  const [refreshCd, setRefreshCd] = useState(0);
  const [hardRefresh, setHardRefresh] = useState(true); //Presentational: Show only loading icon, no data
  const [softRefresh, setSoftRefresh] = useState(false); //Presentational: Show loading icon and data
  const [paramChange, setParamChange] = useState(false);
  const [lastUpdate, setLastUpdate] = useState<StatusUpdateHistory>();
  const [showFilters, setShowFilters] = useState(false);

  const [tableOptions, setTableOptions] = useState<string[]>();
  const [selectedTable, setSelectedTable] = useState<number | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<number>();
  const [selectedType, setSelectedType] = useState<number>();
  const [selectedStoreId, setSelectedStoreId] = useState<number>();

  const handleToggleOpenTabDialog = useCallback(() => {
    setShowOpenTabTransaction((state) => !state);
  }, [setShowOpenTabTransaction]);

  const handleClearFilters = () => {
    if (selectedTable !== null) {
      setSelectedTable(null);
    }
    if (selectedStatus !== undefined) {
      setSelectedStatus(undefined);
    }
    if (selectedType !== undefined) {
      setSelectedType(undefined);
    }
    setParamChange(true);
  };

  const handleSoftRefresh = useCallback(async () => {
    setSoftRefresh(true);
    setRefreshCd(1);
  }, [setSoftRefresh, setRefreshCd]);

  const handleItemUpdate = async (action: StatusUpdateHistory) => {
    await handleSoftRefresh();
    setLastUpdate(action);
  };

  const handleCashierCheckout = async (item: Transaction) => {
    setCheckoutTransaction(item);
  };

  const handleShowHideClosed = () => {
    if (params)
      setParams({
        ...params,
        isFulfilled: !params.isFulfilled,
      });
    handleSoftRefresh();
  };

  const urlParams = useParams<{ upc: string }>();

  const upc = urlParams?.upc ? parseInt(urlParams.upc) : undefined;

  const {
    useGetTransactions,
    useUpdateTransactionItemStatus,
    useSetFulfillTransaction,
  } = useTransactionHooks();

  const [{ error: fullfillError }, fulfillTransaction] =
    useSetFulfillTransaction();

  const handleOpenTransaction = async (transaction: Transaction) => {
    if (selectedStore && selectedStore?.id !== undefined) {
      const payload: FulfillTransactionPayload = {
        id: transaction.id,
        fulfilled: !transaction.fulfilled,
        store: {
          id: selectedStore?.id,
        },
      };
      await fulfillTransaction({
        data: payload,
      });
      handleSoftRefresh();
    }
  };

  useEffect(() => {
    if (fullfillError) {
      console.error(fullfillError);
      enqueueSnackbar("Error: Failed to update item status", {
        variant: "error",
      });
    }
  }, [fullfillError, enqueueSnackbar]);

  const selectedStore = useSelector(
    (state: State) => state.storeList.selectedStore
  );

  useEffect(() => {
    if (!tableOptions && transactionsState) {
      const tableOptions = uniqBy(transactionsState, "tableNumber")
        .filter((t) => t.tableNumber !== undefined)
        .map((t) => t.tableNumber?.toString() ?? "No Table");
      setTableOptions(tableOptions);
    }
  }, [tableOptions, transactionsState, setTableOptions]);

  const statusTypes = ITEM_STATUS_SEQUENCE.filter((s) => s !== "CLOSED").map(
    capitalizeFirst
  );


  const calculateTimeFrame = () => {
    //? [REVISIT] The current logic is: current 24 hours period + 4 hours of the previous day (for shops that operate past midnight)
    const now = DateTime.now();
    const beginningTimestamp = now.minus({
      hours: now.hour + 4,
      minutes: now.minute,
      seconds: now.second,
      milliseconds: now.millisecond,
    });
    const endingTimeStamp = now.set({ hour: 23, minute: 59, second: 59 });
    return { beginningTimestamp, endingTimeStamp };
  };

  const [{ data: listOfStorePrinters }, getStorePrinters] = useAxios<GrabbiPrinter[]>(
    {
      url: `/restful-services/store/${selectedStoreId}/printers`,
      method: "GET",
    }
  );

  //Get Transactions - Start
  useEffect(() => {
    if (selectedStore?.id && !params && !transactionsState) {
      const { beginningTimestamp, endingTimeStamp } = calculateTimeFrame();
      setParams({
        storeId: selectedStore.id,
        pageSize: 10000,
        pageNumber: 1,
        sortBy: "id",
        isFulfilled: false,
        isRefunded: false,
        fromDate: `${beginningTimestamp.toMillis()}`,
        toDate: `${endingTimeStamp.toMillis()}`,
      });
      setSelectedStoreId(selectedStore.id);
    }
  }, [params, setParams, transactionsState, selectedTable, selectedStore]);

  useEffect(() => {
    if(selectedStoreId !== undefined) {
      getStorePrinters();
    }
  }, [getStorePrinters, selectedStoreId]);

  useEffect(() => {
    if (params && tableOptions && paramChange) {
      let newParams = cloneDeep(params);

      if (selectedTable != null) {
        newParams.tableNumber = tableOptions[selectedTable];
      } else {
        newParams = omit(newParams, "tableNumber");
      }

      if (selectedType != null) {
        //? [REVISIT] Fix once backend decides to use sequential numbering
        newParams.menuStyle = selectedType;
      } else {
        newParams = omit(newParams, "menuStyle");
      }

      setParamChange(false);
      setParams(newParams);
      handleSoftRefresh();
    }
  }, [
    params,
    paramChange,
    selectedType,
    transactionsState,
    selectedTable,
    tableOptions,
    setParams,
    handleSoftRefresh,
  ]);

  const transactionQueryString = createQueryString(params ?? {});

  const [{ error: errorStatusUpdate }, updateStatus] =
    useUpdateTransactionItemStatus();

  useEffect(() => {
    if (errorStatusUpdate) {
      console.error(errorStatusUpdate);
      enqueueSnackbar("Error: Failed to update item status", {
        variant: "error",
      });
    }
  }, [errorStatusUpdate, enqueueSnackbar]);

  const [
    { loading: loadingTransactions, error: errorTransactions },
    getTransactions,
  ] = useGetTransactions(transactionQueryString);

  useEffect(() => {
    if (errorTransactions) {
      enqueueSnackbar("Error: Failed to fetch transactions", {
        variant: "error",
      });
    }
  }, [errorTransactions, enqueueSnackbar]);

  useEffect(() => {
    if (upc && params && refreshCd === 0) {
      getTransactions().then((paginatedResponse) => {
        const transactionData = paginatedResponse.data.contents;
        const transactionsSorted = orderBy(
          transactionData.map((t) => {
            return {
              ...t,
              transactionItems: orderBy(
                t.transactionItems,
                (item) => item.id,
                "asc"
              ).map((item) => ({
                ...item,
                toppings: orderBy(
                  item.toppings,
                  (topping) => topping.id,
                  "asc"
                ),
              })),
            };
          }),
          "createdDate",
          "asc"
        );
        setTransactionsState(transactionsSorted);
        setSoftRefresh(false);
        setHardRefresh(false);
        setRefreshCd(REFRESH_INTERVAL);
        const { beginningTimestamp, endingTimeStamp } = calculateTimeFrame();
        setParams({
          ...params,
          fromDate: `${beginningTimestamp.toMillis()}`,
          toDate: `${endingTimeStamp.toMillis()}`,
        });
      });
    }
  }, [
    upc,
    refreshCd,
    params,
    setParams,
    setHardRefresh,
    setRefreshCd,
    setTransactionsState,
    getTransactions,
  ]);

  useInterval(() => {
    if (refreshCd > 0) {
      setRefreshCd(refreshCd - 1);
    }
  }, 1000);

  const handleUndoStatusUpdate = useCallback(async () => {
    if (lastUpdate && lastUpdate.currentStatus) {
      const payload = {
        orderItemStatus: lastUpdate.currentStatus,
        storeId: lastUpdate.storeId,
        transactionId: lastUpdate.transactionId,
        transactionItemId: lastUpdate.itemId,
      };
      await updateStatus({
        data: payload,
      });
      await handleSoftRefresh();
      setLastUpdate(undefined);
      enqueueSnackbar("Transaction Item Updated", {
        variant: "success",
      });
    }
  }, [lastUpdate, updateStatus, handleSoftRefresh, enqueueSnackbar]);

  useEffect(() => {
    if (lastUpdate) {
      enqueueSnackbar("Transaction Item Updated", {
        variant: "success",
        action: (
          <div
            className="item_status_undo_button"
            onClick={handleUndoStatusUpdate}
          >
            Undo
          </div>
        ),
      });
    }
  }, [lastUpdate, enqueueSnackbar, handleUndoStatusUpdate]);

  //Get Transactions - Finish

  const togglePanel = useCallback(
    (index: number) => {
      setCollapsedPanels({
        ...collapsedPanels,
        [index]: !collapsedPanels[index],
      });
    },
    [collapsedPanels, setCollapsedPanels]
  );

  const openEditDialog = editTransactions !== undefined;
  const handleOpenEditDialog = useCallback(
    (transactions: Transaction[]) => {
      setEditTransactions(transactions);
    },
    [setEditTransactions]
  );

  const openAddDialog = addItemTransaction !== undefined;
  const handleAddToTransaction = useCallback(
    (transaction?: Transaction) => {
      setAddItemTransaction(transaction);
    },
    [setAddItemTransaction]
  );

  const transactionsByTable = groupBy(
    transactionsState?.filter((t) => t.transactionItems.length > 0), //? [REVISIT] Should we filter these or show a null state?
    "tableNumber"
  );

  const showLoadingHard = loadingTransactions && hardRefresh;
  const showLoadingSoft = softRefresh;
  const filterActive =
    selectedTable != null ||
    selectedStatus !== undefined ||
    selectedType !== undefined;

  //Sort Table keys to put table with oldest transaction on top
  const tableKeys: string[] = Object.keys(transactionsByTable).sort(
    (a: string, b: string) => {
      const aTransactions = transactionsByTable[a];
      const aOldest = orderBy(aTransactions, "id")[0];
      const bTransactions = transactionsByTable[b];
      const bOldest = orderBy(bTransactions, "id")[0];
      return `${aOldest.id}`.localeCompare(`${bOldest.id}`);
    }
  );

  const lessThanMd = useMediaQuery("(max-width: 960px)");
  const lessThanSm = useMediaQuery("(max-width: 600px)");

  return (
    <Grid container className="restaurant_board_root">
      {addItemTransaction && selectedStore && (
        <AddItemDialog
          open={openAddDialog}
          transaction={addItemTransaction}
          store={selectedStore}
          refreshCallback={handleSoftRefresh}
          onClose={handleAddToTransaction}
        />
      )}
      {editTransactions && (
        <EditTableDialog
          open={openEditDialog}
          transactions={editTransactions.filter(
            (t) => t.transactionStatus !== "SUCCEEDED"
          )}
          refreshCallback={handleSoftRefresh}
          onClose={() => setEditTransactions(undefined)}
        />
      )}
      {showOpenTabTransaction && selectedStore && (
        <OpenTabDialog
          open={showOpenTabTransaction}
          store={selectedStore}
          refreshCallback={handleSoftRefresh}
          onClose={handleToggleOpenTabDialog}
        />
      )}
      {closeTransaction && upc && (
        <CloseTabDialog
          open={closeTransaction !== undefined}
          storeId={upc}
          refreshCallback={handleSoftRefresh}
          onClose={() => setCloseTransaction(undefined)}
          transaction={closeTransaction}
        />
      )}
      {checkoutTransaction && selectedStore && (
        <CheckoutTabDialog
          open={checkoutTransaction !== undefined}
          store={selectedStore}
          refreshCallback={handleSoftRefresh}
          onClose={() => setCheckoutTransaction(undefined)}
          transaction={checkoutTransaction}
        />
      )}
      {showLoadingSoft && (
        <div className="soft_loading_container">
          <LoadingIcon className="soft_loading_icon" />
        </div>
      )}
      {!showLoadingHard && upc && (
        <Grid
          container
          item
          xs={12}
          className={`board_container ${
            showLoadingSoft ? "opaque_loading" : ""
          }`}
        >
          {(!lessThanMd || showFilters) && (
            <Grid
              xs={12}
              md={9}
              item
              container
              className="board_filter_row_container"
            >
              {!paramChange && (
                <Grid
                  xs={12}
                  item
                  container
                  className="board_filter_row"
                  spacing={1}
                  justify="space-between"
                  alignItems="center"
                >
                  {tableOptions && tableOptions.length > 0 && (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      className="board_filter_grid_item"
                    >
                      <GrabbiDropdown
                        handleChange={(index) => {
                          setSelectedTable(index);
                          setParamChange(true);
                        }}
                        options={tableOptions}
                        selectedIndex={selectedTable ?? null}
                        placeholder="All Tables"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={4} className="board_filter_grid_item">
                    <GrabbiDropdown
                      handleChange={(index) => setSelectedStatus(index)}
                      options={statusTypes}
                      selectedIndex={selectedStatus}
                      placeholder="All Statuses"
                    />
                  </Grid>

                  <Grid item xs={12} md={4} className="board_filter_grid_item">
                    <GrabbiDropdown
                      handleChange={(index) => {
                        setSelectedType(index);
                        setParamChange(true);
                      }}
                      options={["Kitchen", "Bar"]}
                      selectedIndex={selectedType}
                      placeholder="All Types"
                    />
                  </Grid>
                </Grid>
              )}
              {filterActive && (
                <Grid xs={12} item container className="board_filter_row">
                  <GrabbiButton variant="outlined" onClick={handleClearFilters}>
                    Clear Filters
                  </GrabbiButton>
                </Grid>
              )}
            </Grid>
          )}
          <Grid
            xs={12}
            md={3}
            item
            container
            className="board_action_row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item container xs={6} spacing={lessThanMd ? 2 : 0}>
              <Grid item xs={4} sm={5}>
                <GrabbiButton
                  className="show_closed_tabs_button"
                  onClick={() => handleShowHideClosed()}
                >
                  {!lessThanSm ? (
                    `${params?.isFulfilled ? "Hide" : "Show"} Closed${
                      !lessThanMd ? " Tabs" : ""
                    }`
                  ) : params?.isFulfilled ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </GrabbiButton>
              </Grid>
              {lessThanMd && (
                <Grid item xs={4} sm={5}>
                  <GrabbiButton onClick={() => setShowFilters(!showFilters)}>
                    {!lessThanSm ? (
                      `${showFilters ? "Hide" : "Show"} Filters`
                    ) : (
                      <FilterList />
                    )}
                  </GrabbiButton>
                </Grid>
              )}
            </Grid>
            <Grid item container xs={4} justify="flex-end">
              <GrabbiButton onClick={() => setShowOpenTabTransaction(true)}>
                Open Tab
              </GrabbiButton>
            </Grid>
          </Grid>
          {!lessThanSm && (
            <Grid xs={12} item container className="board_column_row">
              {(selectedStatus === undefined || selectedStatus === 0) && (
                <Grid
                  item
                  xs={selectedStatus === 0 ? 12 : 4}
                  className="board_column_header"
                >
                  Received Orders
                </Grid>
              )}
              {(selectedStatus === undefined || selectedStatus === 1) && (
                <Grid
                  item
                  xs={selectedStatus === 1 ? 12 : 4}
                  className="board_column_header"
                >
                  Preparing
                </Grid>
              )}
              {(selectedStatus === undefined || selectedStatus === 2) && (
                <Grid
                  item
                  xs={selectedStatus === 2 ? 12 : 4}
                  className="board_column_header"
                >
                  Delivered To Table
                </Grid>
              )}
            </Grid>
          )}
          {tableKeys.length > 0 && selectedStore?.id != null ? (
            tableKeys.map((key: string, index: number) => {
              const tableTransactions = transactionsByTable[key];
              const collapsed = collapsedPanels[index];
              const tableNum = tableTransactions[0].tableNumber;
              const editableTransactions = tableTransactions.filter(
                (t) =>
                  t.transactionStatus !== "SUCCEEDED" &&
                  t.transactionStatus !== "PROCESSING_ORDER"
              );
              const canEdit = editableTransactions.length > 0;
              return (
                <React.Fragment key={`table-transactions-${tableNum}`}>
                  <Grid
                    xs={12}
                    item
                    container
                    className={`table_info_row ${
                      collapsed &&
                      index === Object.keys(transactionsByTable).length - 1
                        ? "table_info_row_collapsed"
                        : ""
                    }`}
                  >
                    <Grid xs={6} item className="table_number_container">
                      <ExpandMore
                        className={`expand_icon ${
                          collapsed ? "" : "expand_icon_flipped"
                        }`}
                        onClick={() => togglePanel(index)}
                      />
                      {tableNum !== undefined ? (
                        <span>Table {tableNum}</span>
                      ) : (
                        <span>No Table</span>
                      )}
                    </Grid>
                    {canEdit && (
                      <Grid
                        xs={6}
                        item
                        container
                        justify="flex-end"
                        className="edit_button_container"
                      >
                        <GrabbiButton
                          variant="outlined"
                          className="edit_button"
                          onClick={() =>
                            handleOpenEditDialog(editableTransactions)
                          }
                        >
                          EDIT <div className="pencil_icon"></div>
                        </GrabbiButton>
                      </Grid>
                    )}
                  </Grid>
                  {!collapsed &&
                    tableTransactions.map((transaction: Transaction) => {
                      const orderComplete =
                        (transaction.paymentType === "ATTENDANT_CHECKOUT" &&
                          transaction.transactionStatus ===
                            "ATTENDANT_CHECKOUT") ||
                        (transaction.paymentType !== "NONE" &&
                          transaction.transactionStatus === "SUCCEEDED") ||
                        transaction.transactionStatus === "PROCESSING_ORDER";
                      const showAdd = !orderComplete;
                      return (
                        <TransactionRow
                          key={`transaction-row-${transaction.id}`}
                          transaction={transaction}
                          showAdd={showAdd}
                          upc={upc}
                          selectedStatus={selectedStatus}
                          isGrabbiPOS={selectedStore?.posEnabled}
                          showClosed={params?.isFulfilled}
                          storeId={selectedStore?.id}
                          selectedStoreName={selectedStore?.name}
                          storePrinters={
                            listOfStorePrinters ? listOfStorePrinters : []
                          }
                          handleAddToTransaction={handleAddToTransaction}
                          setCloseTransaction={setCloseTransaction}
                          setOpenTransaction={handleOpenTransaction}
                          handleItemUpdate={handleItemUpdate}
                          handleCashierCheckout={handleCashierCheckout}
                          handleSoftRefresh={handleSoftRefresh}
                        />
                      );
                    })}
                </React.Fragment>
              );
            })
          ) : (
            <div className="no_results_root">
              <div className="header">{`No ${
                params?.isFulfilled ? "Closed" : "Open"
              } Tabs`}</div>
              <div className="message">
                REAL TIME TRANSACTIONS FOR {DateTime.now().toLocaleString()}{" "}
                WILL BEGIN TO APPEAR HERE
              </div>
            </div>
          )}
        </Grid>
      )}
      {showLoadingHard && (
        <Grid item xs={12} className="transactions_loading_container">
          <LoadingIcon className="transactions_loading_icon" />
        </Grid>
      )}
    </Grid>
  );
};

export default ResturantBoard;

interface TransactionRowProps {
  transaction: Transaction;
  showAdd: boolean;
  upc: number;
  selectedStatus?: number;
  isGrabbiPOS?: boolean;
  showClosed?: boolean;
  selectedStoreName?: string;
  storeId: number;
  storePrinters: GrabbiPrinter[];
  handleAddToTransaction: (item: Transaction) => void;
  setCloseTransaction: (item: Transaction) => void;
  setOpenTransaction: (item: Transaction) => void;
  handleItemUpdate: (action: StatusUpdateHistory) => void;
  handleCashierCheckout: (item: Transaction) => void;
  handleSoftRefresh: () => void;
}

const TransactionRow: React.FC<TransactionRowProps> = ({
  transaction,
  showAdd,
  upc,
  selectedStatus,
  isGrabbiPOS,
  showClosed,
  selectedStoreName,
  storeId,
  storePrinters,
  handleAddToTransaction,
  setCloseTransaction,
  setOpenTransaction,
  handleItemUpdate,
  handleCashierCheckout,
  handleSoftRefresh,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { useUpdateTransactionItemStatus } = useTransactionHooks();
  const [{ error }, updateStatus] = useUpdateTransactionItemStatus();
  const { token } = useAxiosContext();
  const [printerIsEnabled, setPrinterIsEnabled] = useState(false);

  useEffect(() => {
    if (error) {
      console.error(error);
      enqueueSnackbar("Error: Failed to update item status", {
        variant: "error",
      });

      if (storePrinters.length > 0) {
        setPrinterIsEnabled(true);
      }
    }
  }, [error, enqueueSnackbar, storePrinters]);

  const handleStatusUpdate = async (
    orderItemStatus: OrderItemStatus,
    itemId: number
  ) => {
    const payload = {
      orderItemStatus,
      storeId: upc,
      transactionId: transaction.id,
      transactionItemId: itemId,
    };
    await updateStatus({
      data: payload,
    });
    handleItemUpdate({
      itemId: itemId,
      nextStatus: orderItemStatus,
      storeId: upc,
      transactionId: itemId,
    });
  };

  const handleDragEnd = (result: DropResult, responder: ResponderProvided) => {
    const { destination } = result;
    const destinationId: OrderItemStatus =
      destination?.droppableId as OrderItemStatus;
    handleStatusUpdate(destinationId, parseInt(result.draggableId));
  };

  const lessThanSm = useMediaQuery("(max-width: 600px)");

  const receivedItems = transaction.transactionItems.filter(
    (item: TransactionItem) => item.orderItemStatus === "RECEIVED"
  );
  const preparingItems = transaction.transactionItems.filter(
    (item: TransactionItem) => item.orderItemStatus === "PREPARING"
  );
  const deliveredItems = transaction.transactionItems.filter(
    (item: TransactionItem) => item.orderItemStatus === "DELIVERED"
  );

  const printRequest = (
    transaction: Transaction,
    orderStep: string,
    className: string
  ) => {
    if (orderStep === "PREPARING") {
      let kitchenOrders = [];
      let barOrders = [];
      let printPayloadList: { port: string | null | undefined; fontSize: number; receipt: string; }[] = [];
      for (let item of transaction.transactionItems) {
        if (
          item.menuStyle === printersLocationIdBasedOnNameStr.get("Kitchen")
        ) {
          kitchenOrders.push(item);
        } else {
          barOrders.push(item);
        }
      }
      if (kitchenOrders.length !== 0) {
        const kitchenOrdersPayload: GrabbiPrinterUrlPayload = {
          className: className,
          date:
            `${DateTime.fromMillis(transaction.createdDate).toFormat(
              "MMM dd"
            )}` +
            ` ${DateTime.fromMillis(transaction.createdDate).toFormat(
              "h:mma"
            )}`,
          transactionId: transaction.id,
          orderCode: transaction.orderCode,
          transactionItem: transaction.transactionItems,
          serviceFee: transaction.serviceFee,
          totalTax: transaction.totalTax,
          totalTip: transaction.tip,
          transactionTotal: transaction.transactionTotal,
          transactionCurrency: transaction.currency,
          paymentStatus: transaction.transactionStatus,
          tableNumber: transaction.tableNumber,
          storeId: storeId,
          storeName: selectedStoreName,
          storePrinters: storePrinters,
          token: token,
          orderStep: orderStep,
          printerType: 0,
        };
        printPayloadList.push(generatePrintReceipt(kitchenOrdersPayload));
      }
      if (barOrders.length !== 0) {
        const barOrdersPayload: GrabbiPrinterUrlPayload = {
          className: className,
          date:
            `${DateTime.fromMillis(transaction.createdDate).toFormat(
              "MMM dd"
            )}` +
            ` ${DateTime.fromMillis(transaction.createdDate).toFormat(
              "h:mma"
            )}`,
          transactionId: transaction.id,
          orderCode: transaction.orderCode,
          transactionItem: transaction.transactionItems,
          serviceFee: transaction.serviceFee,
          totalTax: transaction.totalTax,
          totalTip: transaction.tip,
          transactionTotal: transaction.transactionTotal,
          transactionCurrency: transaction.currency,
          paymentStatus: transaction.transactionStatus,
          tableNumber: transaction.tableNumber,
          storeId: storeId,
          storeName: selectedStoreName,
          storePrinters: storePrinters,
          token: token,
          orderStep: orderStep,
          printerType: 1,
        };
        printPayloadList.push(generatePrintReceipt(barOrdersPayload));
      }
      if (barOrders.length !== 0 || kitchenOrders.length) {
        if (storePrinters.length > 0 ) {
          setTimeout(() => {
            let passprnt_uri = generatePrintUrl(printPayloadList, storePrinters, token, storeId);
            if (passprnt_uri.indexOf('port') === -1){
              passprnt_uri = '';
              enqueueSnackbar("Please select a loction for your printer", { variant: "error" });
            } else {
              window.open(passprnt_uri, "_blank");
            }
          }, 3000);
        }
      }
    } else {
      let printPayloadList: { port: string | null | undefined; fontSize: number; receipt: string; transactionId: number; transactionItems: any[]; }[] = [];
      const payload: GrabbiPrinterUrlPayload = {
        className: className,
        date:
          `${DateTime.fromMillis(transaction.createdDate).toFormat("MMM dd")}` +
          ` ${DateTime.fromMillis(transaction.createdDate).toFormat("h:mma")}`,
        transactionId: transaction.id,
        orderCode: transaction.orderCode,
        transactionItem: transaction.transactionItems,
        serviceFee: transaction.serviceFee,
        totalTax: transaction.totalTax,
        totalTip: transaction.tip,
        transactionTotal: transaction.transactionTotal,
        transactionCurrency: transaction.currency,
        paymentStatus: transaction.transactionStatus,
        tableNumber: transaction.tableNumber,
        storeId: storeId,
        storeName: selectedStoreName,
        storePrinters: storePrinters,
        token: token,
        orderStep: orderStep,
        printerType: 2,
      };
      printPayloadList.push(generatePrintReceipt(payload));

      if (storePrinters.length > 0 ) {
        setTimeout(() => {
          let passprnt_uri = generatePrintUrl(printPayloadList, storePrinters, token, storeId);
          if (passprnt_uri.indexOf('port') === -1){
            passprnt_uri = '';
            enqueueSnackbar("Please select a loction for your printer", { variant: "error" });
          } else {
            window.open(passprnt_uri, "_blank");
          }
        }, 3000);
      }
    }
  }

  const { paymentType, transactionStatus } = transaction;
  const transactionPaid =
    (paymentType === "TERMINAL_CHECKOUT" &&
      transactionStatus === "PROCESSING_ORDER") ||
    (paymentType === "GOOGLE_PAY" &&
      transactionStatus !== "AWAITING_PAYMENT") ||
    (paymentType === "APPLE_PAY" && transactionStatus !== "AWAITING_PAYMENT");

  return (
    <React.Fragment key={`transaction-${transaction.id}-order-container`}>
      <Grid
        xs={12}
        item
        container
        className="order_info_row"
        justify="space-between"
      >
        <Grid xs={3} sm={3} item>
          <OrderCode transaction={transaction} />
        </Grid>
        <Grid
          xs={9}
          sm={9}
          item
          container
          className="action_button_container"
          justify="flex-end"
          spacing={1}
        >
          <Grid
            item
            container
            xs={6}
            md={5}
            spacing={1}
            className="payment_status_container"
          >
            {!transactionPaid && (
              <Grid item xs={7} sm={5} md={6}>
                <div className="payment_status unpaid">
                  <span className="red">UNPAID </span>
                  <span>
                    {formatCurrency(
                      transaction.transactionTotal,
                      transaction.currency
                    )}
                  </span>
                </div>
              </Grid>
            )}

            <Grid
              item
              xs={transactionPaid ? 12 : 5}
              sm={transactionPaid ? 12 : 7}
              md={transactionPaid ? 12 : 6}
            >
              {!transactionPaid && isGrabbiPOS ? (
                <GrabbiButton
                  className="pos_button"
                  onClick={
                    isGrabbiPOS
                      ? () => handleCashierCheckout(transaction)
                      : undefined
                  }
                >
                  {lessThanSm ? <Payment /> : "POS Checkout"}
                </GrabbiButton>
              ) : (transaction.paymentType !== "NONE" &&
                  transaction.transactionStatus === "SUCCEEDED") ||
                transaction.transactionStatus === "PROCESSING_ORDER" ? (
                <div className="payment_status">
                  {transaction.paymentType === "GOOGLE_PAY" ? (
                    <React.Fragment>
                      <span className="google_icon" />{" "}
                      <span>
                        Guest Paid -{" "}
                        {transaction.transactionTotal.toLocaleString(
                          undefined,
                          {
                            style: "currency",
                            currency: transaction.currency,
                          }
                        )}
                      </span>
                    </React.Fragment>
                  ) : transaction.paymentType === "APPLE_PAY" ? (
                    <React.Fragment>
                      <span className="apple_icon" />{" "}
                      <span>
                        Guest Paid -{" "}
                        {transaction.transactionTotal.toLocaleString(
                          undefined,
                          {
                            style: "currency",
                            currency: transaction.currency,
                          }
                        )}
                      </span>
                    </React.Fragment>
                  ) : transaction.paymentType === "WALLET" ? (
                    <span>Wallet</span>
                  ) : transaction.paymentType === "TERMINAL_CHECKOUT" ? (
                    <span>Closed by Merchant</span>
                  ) : undefined}
                </div>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </Grid>
          </Grid>
          <Grid item xs={3} md={3}>
            {!showClosed ? (
              <GrabbiButton
                className="close_tab_button"
                disabled={!transactionPaid}
                onClick={() => setCloseTransaction(transaction)}
              >
                {lessThanSm ? <Archive /> : "CLOSE TAB"}
              </GrabbiButton>
            ) : (
              <GrabbiButton
                className="close_tab_button"
                onClick={() => setOpenTransaction(transaction)}
              >
                OPEN TAB
              </GrabbiButton>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} item container className="item_card_columns">
        <DragDropContext
          onDragEnd={handleDragEnd}
          key={`transaction-dragdropcontext-${transaction.id}-order-container`}
        >
          {!showClosed ? (
            <React.Fragment>
              {(selectedStatus === undefined || selectedStatus === 0) && (
                <Droppable
                  droppableId={"RECEIVED" as OrderItemStatus}
                  type="ItemCard"
                >
                  {(provided, snapshot) => {
                    const isDragging = snapshot.isDraggingOver;
                    return (
                      <React.Fragment>
                        {lessThanSm && (
                          <Grid item xs={12} className="board_column_header">
                            Received Orders
                          </Grid>
                        )}
                        <Grid
                          ref={provided.innerRef}
                          sm={selectedStatus === 0 ? 12 : 4}
                          xs={12}
                          item
                          container
                          className={`board_column ${
                            isDragging ? "dragging_column" : ""
                          }`}
                        >
                          <Grid
                            item
                            container
                            className={`board_column_padded_area ${
                              !showAdd ? "remove_bottom_padding" : ""
                            }`}
                          >
                            {receivedItems.map(
                              (item: TransactionItem, index: number) => (
                                <DraggableItemCard
                                  index={index}
                                  key={`item-card-${item.id}`}
                                  item={item}
                                  transaction={transaction}
                                  storeId={upc}
                                  date={DateTime.fromMillis(
                                    transaction.createdDate
                                  ).toFormat("MMM dd hh:mma")}
                                  orderCode={transaction.orderCode}
                                  transactionItem={transaction.transactionItems}
                                  serviceFee={transaction.serviceFee}
                                  totalTax={transaction.totalTax}
                                  totalTip={transaction.tip}
                                  transactionTotal={
                                    transaction.transactionTotal
                                  }
                                  transactionCurrency={transaction.currency}
                                  paymentStatus={transaction.transactionStatus}
                                  tableNumber={transaction.tableNumber}
                                  storeName={selectedStoreName}
                                  storePrinters={storePrinters}
                                  token={token}
                                  updateCallback={handleItemUpdate}
                                  variant="recieved"
                                />
                              )
                            )}
                            {provided.placeholder}
                            {receivedItems.length > 0 && (
                              <ActionCard
                                variant="recieved"
                                className="move_items_card"
                                items={receivedItems}
                                status={"PREPARING"}
                                storeId={storeId}
                                transactionId={transaction.id}
                                date={DateTime.fromMillis(
                                  transaction.createdDate
                                ).toFormat("MMM dd hh:mma")}
                                orderCode={transaction.orderCode}
                                transactionItem={transaction.transactionItems}
                                serviceFee={transaction.serviceFee}
                                totalTax={transaction.totalTax}
                                totalTip={transaction.tip}
                                transactionTotal={transaction.transactionTotal}
                                transactionCurrency={transaction.currency}
                                paymentStatus={transaction.transactionStatus}
                                tableNumber={transaction.tableNumber}
                                storeName={selectedStoreName}
                                storePrinters={storePrinters}
                                token={token}
                                callback={handleSoftRefresh}
                              >
                                Send All Orders to Preparing <ChevronRight />
                              </ActionCard>
                            )}
                          </Grid>
                          {showAdd && (
                            <div
                              className="add_items_button"
                              onClick={() =>
                                handleAddToTransaction(transaction)
                              }
                            >
                              <div className="add_icon" />
                              <span className="add_items_text">Add Items</span>
                            </div>
                          )}
                        </Grid>
                      </React.Fragment>
                    );
                  }}
                </Droppable>
              )}
              {(selectedStatus === undefined || selectedStatus === 1) && (
                <Droppable
                  droppableId={"PREPARING" as OrderItemStatus}
                  type="ItemCard"
                >
                  {(provided, snapshot) => {
                    const isDragging = snapshot.isDraggingOver;
                    const showPrint = printerIsEnabled && preparingItems.length > 0;
                    return (
                      <React.Fragment>
                        {lessThanSm && (
                          <Grid item xs={12} className="board_column_header">
                            Preparing
                          </Grid>
                        )}
                        <Grid
                          ref={provided.innerRef}
                          sm={selectedStatus === 1 ? 12 : 4}
                          xs={12}
                          container
                          item
                          direction="column"
                          className={`board_column ${
                            isDragging ? "dragging_column" : ""
                          }`}
                        >
                          {showPrint && (
                            <Grid
                              className={`item_card_print_button`}
                              item
                              container
                              alignItems="center"
                              justify="center"
                              onClick={() =>
                                printRequest(
                                  transaction,
                                  "PREPARING" as OrderItemStatus,
                                  `item_card_print_button`
                                )
                              }
                            >
                              <div className="icon" />
                              <span>PRINT</span>
                            </Grid>
                          )}
                          <Grid
                            item
                            container
                            className={`board_column_padded_area ${
                              !showAdd ? "remove_bottom_padding" : ""
                            }`}
                          >
                            {preparingItems.map((item, index) => {
                              return (
                                <DraggableItemCard
                                  index={index}
                                  key={`item-card-${item.id}`}
                                  item={item}
                                  transaction={transaction}
                                  storeId={upc}
                                  date={DateTime.fromMillis(
                                    transaction.createdDate
                                  ).toFormat("MMM dd hh:mma")}
                                  orderCode={transaction.orderCode}
                                  transactionItem={transaction.transactionItems}
                                  serviceFee={transaction.serviceFee}
                                  totalTax={transaction.totalTax}
                                  totalTip={transaction.tip}
                                  transactionTotal={
                                    transaction.transactionTotal
                                  }
                                  transactionCurrency={transaction.currency}
                                  paymentStatus={transaction.transactionStatus}
                                  tableNumber={transaction.tableNumber}
                                  storeName={selectedStoreName}
                                  storePrinters={storePrinters}
                                  token={token}
                                  updateCallback={handleItemUpdate}
                                  variant="preparing"
                                />
                              );
                            })}
                            {provided.placeholder}
                            <Grid item container xs={12}>
                              {preparingItems.length > 0 && (
                                <ActionCard
                                  variant="preparing"
                                  className="move_items_card"
                                  items={preparingItems}
                                  status={"DELIVERED"}
                                  storeId={storeId}
                                  transactionId={transaction.id}
                                  date={DateTime.fromMillis(
                                    transaction.createdDate
                                  ).toFormat("MMM dd hh:mma")}
                                  orderCode={transaction.orderCode}
                                  transactionItem={transaction.transactionItems}
                                  serviceFee={transaction.serviceFee}
                                  totalTax={transaction.totalTax}
                                  totalTip={transaction.tip}
                                  transactionTotal={
                                    transaction.transactionTotal
                                  }
                                  transactionCurrency={transaction.currency}
                                  paymentStatus={transaction.transactionStatus}
                                  tableNumber={transaction.tableNumber}
                                  storeName={selectedStoreName}
                                  storePrinters={storePrinters}
                                  token={token}
                                  callback={handleSoftRefresh}
                                >
                                  Send All Orders to Table <ChevronRight />
                                </ActionCard>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    );
                  }}
                </Droppable>
              )}
              {(selectedStatus === undefined || selectedStatus === 2) && (
                <Droppable
                  droppableId={"DELIVERED" as OrderItemStatus}
                  type="ItemCard"
                >
                  {(provided, snapshot) => {
                    const isDragging = snapshot.isDraggingOver;
                    const showPrint = deliveredItems.length > 0 && printerIsEnabled;
                    const printDisabled =
                      preparingItems.length > 0 || receivedItems.length > 0;
                    return (
                      <React.Fragment>
                        {lessThanSm && (
                          <Grid item xs={12} className="board_column_header">
                            Delivered to Table
                          </Grid>
                        )}
                        <Grid
                          ref={provided.innerRef}
                          sm={selectedStatus === 2 ? 12 : 4}
                          xs={12}
                          container
                          item
                          direction="column"
                          className={`board_column ${
                            isDragging ? "dragging_column" : ""
                          }`}
                        >
                          {showPrint && (
                            <Grid
                              className={`item_card_print_button ${
                                printDisabled ? "disabled" : ""
                              }`}
                              item
                              container
                              alignItems="center"
                              justify="center"
                              onClick={() =>
                                printRequest(
                                  transaction,
                                  "DELIVERED" as OrderItemStatus,
                                  `item_card_print_button ${
                                    printDisabled ? "disabled" : ""
                                  }`
                                )
                              }
                            >
                              <div className="icon" />
                              <span>PRINT</span>
                            </Grid>
                          )}
                          <Grid
                            item
                            container
                            className={`board_column_padded_area ${
                              !showAdd ? "remove_bottom_padding" : ""
                            }`}
                          >
                            {deliveredItems.map((item, index) => {
                              return (
                                <DraggableItemCard
                                  index={index}
                                  key={`item-card-${item.id}`}
                                  item={item}
                                  transaction={transaction}
                                  storeId={upc}
                                  date={DateTime.fromMillis(
                                    transaction.createdDate
                                  ).toFormat("MMM dd hh:mma")}
                                  orderCode={transaction.orderCode}
                                  transactionItem={transaction.transactionItems}
                                  serviceFee={transaction.serviceFee}
                                  totalTax={transaction.totalTax}
                                  totalTip={transaction.tip}
                                  transactionTotal={
                                    transaction.transactionTotal
                                  }
                                  transactionCurrency={transaction.currency}
                                  paymentStatus={transaction.transactionStatus}
                                  tableNumber={transaction.tableNumber}
                                  storeName={selectedStoreName}
                                  storePrinters={storePrinters}
                                  token={token}
                                  updateCallback={handleItemUpdate}
                                  variant="delivered"
                                />
                              );
                            })}
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    );
                  }}
                </Droppable>
              )}
            </React.Fragment>
          ) : (
            <Droppable
              droppableId={"CLOSED" as OrderItemStatus}
              type="ItemCard"
              isCombineEnabled={false}
            >
              {(provided, snapshot) => (
                <Grid
                  ref={provided.innerRef}
                  xs={12}
                  item
                  className="board_column"
                >
                  {transaction.transactionItems
                    .filter(
                      (item: TransactionItem) =>
                        item.orderItemStatus === "CLOSED"
                    )
                    .map((item: TransactionItem, index: number) => (
                      <DraggableItemCard
                        index={index}
                        key={`item-card-${item.id}`}
                        item={item}
                        transaction={transaction}
                        storeId={upc}
                        date={DateTime.fromMillis(
                          transaction.createdDate
                        ).toFormat("MMM dd hh:mma")}
                        orderCode={transaction.orderCode}
                        transactionItem={transaction.transactionItems}
                        serviceFee={transaction.serviceFee}
                        totalTax={transaction.totalTax}
                        totalTip={transaction.tip}
                        transactionTotal={transaction.transactionTotal}
                        transactionCurrency={transaction.currency}
                        paymentStatus={transaction.transactionStatus}
                        tableNumber={transaction.tableNumber}
                        storeName={selectedStoreName}
                        storePrinters={storePrinters}
                        token={token}
                        variant="delivered"
                      />
                    ))}
                </Grid>
              )}
            </Droppable>
          )}
        </DragDropContext>
      </Grid>
    </React.Fragment>
  );
};

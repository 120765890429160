// import { Box } from "@material-ui/core";
// import React, { useEffect, useState } from "react";
import "./ExcelExportComponent.scss";
import * as Excel from "exceljs";
import FileSaver from "file-saver";
import _, { assign } from "lodash";
import moment from "moment";
// import LoadingIcon from "../LoadingIcon/LoadingIconComponent";
// import { useSnackbar } from "notistack";
// import { isMobile } from "shared/scripts/Constants";

// interface Props {
//   excelProps: {
//     title: string;
//     sheets: ExcelSheet[];
//   };
//   dataReady: boolean;
//   dataLoading: boolean;
//   fetchData: () => void;
// }

// const ExcelExport: React.FC<Props> = ({
//   excelProps,
//   dataReady,
//   dataLoading,
//   fetchData,
// }) => {
//   const [reportGenerated, setReportGenerted] = useState(false);
//   const { enqueueSnackbar } = useSnackbar();
//   const generateExcel = generateExcel(excelProps);
//   useEffect(() => {
//     if (dataReady && !reportGenerated) {
//       enqueueSnackbar("Download complete", { variant: "success" });
//       generateExcel();
//       setReportGenerted(true);
//     }
//   }, [
//     dataReady,
//     generateExcel,
//     reportGenerated,
//     setReportGenerted,
//     enqueueSnackbar,
//   ]);

//   const handleFetchData = () => {
//     enqueueSnackbar("Downloading report...", { variant: "info" });
//     fetchData();
//   };

//   return !dataLoading ? (
//     <button className="excel_export_root" onClick={handleFetchData}>
//       <Box
//         className="excel_export_icon"
//         style={{
//           backgroundImage: `url(assets/excel-icon.svg)`,
//         }}
//       />
//       {isMobile() && <div>Export to Excel</div>}
//     </button>
//   ) : (
//     <LoadingIcon size={40} />
//   );
// };

// export default ExcelExport;

export interface ExcelProps {
  alignment?: Partial<Excel.Alignment>;
  style?: Partial<Excel.Style>;
  border?: Partial<Excel.Borders>;
  fill?: Excel.Fill;
  font?: Partial<Excel.Font>;
  numFmt?: string;
}

export interface ExcelColumn {
  title: string;
  width?: number;
  valueFn?: (data: any) => any;
  propsFn?: (data: any, header?: boolean) => ExcelProps;
  headerProps?: ExcelProps;
  bodyProps?: ExcelProps;
}

export interface ExcelSheet {
  columns: ExcelColumn[];
  data: any[];
  title: string;
}

export const generateExcel = (props: { title: string; sheets: ExcelSheet[] }) => {
  const numberToLetter = (n: number): string => {
    const a: number = Math.floor(n / 26);
    return a >= 0
      ? numberToLetter(a - 1) + String.fromCharCode(65 + (n % 26))
      : "";
  };

    const { title, sheets } = props;

    const wb = new Excel.Workbook();
    wb.creator = "Grabbi";
    wb.lastModifiedBy = "Grabbi";

    sheets.forEach((sheet: ExcelSheet) => {
      const ws = wb.addWorksheet(sheet.title);
      sheet.columns.forEach((column: ExcelColumn, index: number) => {
        if (column && column.width) {
          ws.getColumn(index + 1).width = column.width;
        }
        const columnLetter = numberToLetter(index);
        const headerCell = ws.getCell(`${columnLetter}1`);
        headerCell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: {
            argb: "FF000000",
          },
        };
        headerCell.font = {
          color: {
            argb: "FFFFFFFF",
          },
        };
        headerCell.value = column.title;
        sheet.data.forEach((data: any, index: number) => {
          const rowIndex = index + 2;
          const bodyCell = ws.getCell(`${columnLetter}${rowIndex}`);
          bodyCell.value = column.valueFn ? column.valueFn(data) : "";
          const bodyProps = column.propsFn
            ? _.merge(column.propsFn(data, false), column.bodyProps)
            : column.bodyProps
            ? column.bodyProps
            : undefined;
          assign(bodyCell, bodyProps);
        });
      });
    });

    const blobType: string =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    wb.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: blobType });
      const timestamp = moment(new Date()).format("YYMMDDHHSS");
      FileSaver.saveAs(blob, `${timestamp}-${title}.xlsx`);
    });
};

import { ArrowBackIos, RemoveShoppingCart } from "@material-ui/icons";
import React from "react";
import { useSelector } from "react-redux";
import { State } from "store";
import { useMerchSelection } from "../MerchandiseSelectorComponent";
import "./CartComponent.scss";
import CartItem from "./CartItem/CartItemComponent";

interface Props {
  className?: string;
}

const Cart: React.FC<Props> = ({ className }) => {
  const { cartState: cart, toggleShowCart } = useMerchSelection();
  const selectedStore = useSelector(
    (state: State) => state.storeList.selectedStore
  );
  const currency = selectedStore?.currency;

  return (
    <div className={`cart_root ${className ?? ""}`}>
      <div className="cart_header">
        <ArrowBackIos onClick={toggleShowCart} style={{ cursor: "pointer" }} />
        <span className="store_name">{selectedStore?.name}</span>
      </div>
      <div className="cart_item_container">
        {cart.length > 0 &&
          cart.map((item, index) => {
            return (
              <CartItem
                cartEntry={item}
                currency={currency ?? "USD"}
                itemIndex={index}
              />
            );
          })}
      </div>
      {cart.length === 0 && (
        <div className="empty_cart_message">
          Cart is empty
          <RemoveShoppingCart />
        </div>
      )}
    </div>
  );
};

export default Cart;

import { sendRequestToResetPasswordStartAction } from "actions";
import { connect } from "react-redux";
import { State } from "store";
import ForgotPasswordComponent from "./ForgotPasswordComponent";

const mapStateToProps = (state: State) => ({
  resetRequestReason:
    state.sendRequestToResetPasswordState.merchantResetPasswordReason,
  resetLoading: state.sendRequestToResetPasswordState.merchantResetPasswordLoading,
  resetRequestSuccess: state.sendRequestToResetPasswordState.merchantResetPassword,
});

const mapDispatchToProps = (dispatch: any) => ({
  sendRequestToResetPassword: (email: string) =>
    dispatch(sendRequestToResetPasswordStartAction({ email })),
});

export const Forgot = connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordComponent);

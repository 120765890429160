import { Typography } from "@material-ui/core";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import GrabbiTextField from "components/Shared/GrabbiTextField/GrabbiTextField";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RFC_5322_EMAIL_REGEX } from "shared/scripts/Constants";
import { useHistory } from 'react-router-dom';
import { CONTEXT } from 'shared/scripts/UrlContext';
import "./ForgotPasswordComponent.scss";

interface Props {
  resetRequestReason?: string;
  resetRequestSuccess: boolean;
  resetLoading: boolean;
  sendRequestToResetPassword: (email: string) => void;
}

const ForgotPasswordComponent: React.FC<Props> = ({
  resetRequestReason,
  resetRequestSuccess,
  resetLoading,
  sendRequestToResetPassword,
}) => {
  const history = useHistory();

  const { register, handleSubmit, errors, control, getValues } = useForm({
    reValidateMode: "onChange",
    shouldFocusError: true,
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmitForgot = () => {
    enqueueSnackbar("Submitting reset request...", { variant: "info" });
    sendRequestToResetPassword(getValues("email_address"));
    localStorage.setItem('email', getValues("email_address"));
  };

  const handleSubmitError = () => {
    enqueueSnackbar("Please resolve errors and try again.", {
      variant: "error",
    });
  };

  const [pendingReset, setPendingReset] = useState(false);

  useEffect(() => {
    if (resetLoading) {
      setPendingReset(true);
    }
  }, [resetLoading, setPendingReset]);

  useEffect(() => {
    if (pendingReset && !resetLoading && resetRequestSuccess) {
      history.push({
        pathname: `${CONTEXT}successmessage`,
        state: {
          mainMessage: 'SUCCESS',
          secondMessage: `${resetRequestReason?.toUpperCase()}`,
          buttonText: 'BACK TO LOGIN',
          goTo: `${CONTEXT}login`,
        },
      });
    } else if(!resetRequestSuccess && resetRequestReason){
      console.error(resetRequestReason);
      enqueueSnackbar(`Password was not reset: ${resetRequestReason?.toUpperCase()}`, {
        variant: "error",
      });
    }
  }, [resetLoading, pendingReset, resetRequestSuccess,
     resetRequestReason, setPendingReset, enqueueSnackbar, history]);

  return (
    <form
      className="forgot_password_root"
      onSubmit={handleSubmit(handleSubmitForgot, handleSubmitError)}
    >
      <Typography className="forgot_password_title">FORGOT PASSWORD</Typography>
      <GrabbiTextField
        ref={register({
          minLength: {
            value: 6,
            message: "Must have 6 or more characters.",
          },
          maxLength: {
            value: 50,
            message: "Must have 50 or less characters",
          },
          required: {
            value: true,
            message: "Please enter email address",
          },
          pattern: {
            value: RFC_5322_EMAIL_REGEX,
            message: "Invalid email address",
          },
          //? [REVISIT] Add validation of email address once email server is implemented
        })}
        placeholder="Email Address"
        name="email_address"
        autoComplete="email_address"
        disabled={resetLoading}
        error={errors.email_address}
        errorMessage={errors?.email_address?.message}
        label="Email Address"
        control={control}
      />
      <GrabbiButton className="forgot_password_button" type="submit">
        RESET PASSWORD
      </GrabbiButton>
    </form>
  );
};

export default ForgotPasswordComponent;

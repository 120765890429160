import { Grid, Typography, Box } from "@material-ui/core";
import GrabbiTextField from "components/Shared/GrabbiTextField/GrabbiTextField";
import React, { useEffect, useState } from "react";
import "./ChangePasswordForgotComponent.scss";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import { useQueryParam } from "use-query-params";
import { useHistory } from "react-router-dom";
import { CONTEXT } from "shared/scripts/UrlContext";

interface Props {
  resetRequestReason?: string;
  resetRequestSuccess: boolean;
  resetLoading: boolean;
  sendRequestToResetPassword: (
    token: string,
    email: string,
    password: string,
    confirmedPassword: string
  ) => void;
}

const ChangePasswordForgotComponent: React.FC<Props> = ({
  resetRequestReason,
  resetRequestSuccess,
  resetLoading,
  sendRequestToResetPassword,
}) => {
  const [token] = useQueryParam<string>("token");
  const { register, handleSubmit, errors, getValues } = useForm({
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [pendingReset, setPendingReset] = useState(false);

  useEffect(() => {
    if (token === undefined) {
      history.push(`${CONTEXT}`);
    }
  }, [token, history]);

  useEffect(() => {
    if (resetLoading) {
      setPendingReset(true);
    }
  }, [resetLoading, setPendingReset]);

  useEffect(() => {
    if (pendingReset && !resetLoading && resetRequestSuccess) {
      history.push({
        pathname: `${CONTEXT}successmessage`,
        state: {
          mainMessage: 'SUCCESS',
          secondMessage: `${resetRequestReason?.toUpperCase()}`,
          buttonText: 'BACK TO LOGIN',
          goTo: `${CONTEXT}login`,
        },
      });
    } else if(!resetRequestSuccess && resetRequestReason){
      console.error(resetRequestReason);
      enqueueSnackbar(`Password was not reset: ${resetRequestReason?.toUpperCase()}`, {
        variant: "error",
      });
    }
  }, [resetLoading, pendingReset, resetRequestSuccess,
     resetRequestReason, setPendingReset, enqueueSnackbar, history]);

  const handleChangePassword = () => {
    const email = localStorage.getItem('email');

    if(email) {
      sendRequestToResetPassword(
        token,
        email,
        getValues("new_password"),
        getValues("confirm_password")
      );
    }
    enqueueSnackbar("Updating password...", { variant: "info" });
  };

  const handleSubmitError = () => {
    enqueueSnackbar("Please resolve errors and try again.", {
      variant: "error",
    });
  };

  const newPass = getValues("new_password");
  const confirmPass = getValues("confirm_password");

  const passwordsNotMatching =
    (errors.new_password && errors.new_password.type === "passwordsEqual") ||
    (errors.confirm_password &&
      errors.confirm_password.type === "passwordsEqual");

  const disableButton: boolean =
    token === undefined ||
    newPass === undefined ||
    newPass.length === 0 ||
    confirmPass === undefined ||
    confirmPass.length === 0 ||
    Object.keys(errors).length > 0;

  const response =
    resetRequestSuccess
      ? "Invalid token"
      : undefined;

  return (
    <Box className="change_password_forgot_root">
      <Grid
        item
        container
        className="content"
        direction="column"
        alignItems="center"
      >
        <Typography className="title">CHANGE PASSWORD</Typography>
        <form onSubmit={handleSubmit(handleChangePassword, handleSubmitError)}>
          <GrabbiTextField
            ref={register({
              minLength: {
                value: 8,
                message: "Must have 8 or more characters",
              },
              maxLength: {
                value: 128,
                message: "Must have 128 or less characters",
              },
              required: {
                value: true,
                message: "Please enter new password",
              },
              validate: {
                passwordsEqual: (value) =>
                  !getValues().confirm_password ||
                  value === getValues().confirm_password,
              },
            })}
            classes={{
              root: "field",
            }}
            placeholder="New Password"
            name="new_password"
            type="password"
            autoComplete="new-password"
            error={errors.new_password !== undefined}
            errorMessage={errors?.new_password?.message}
            label="New Password"
          />
          <GrabbiTextField
            ref={register({
              minLength: {
                value: 8,
                message: "Must have 8 or more characters",
              },
              maxLength: {
                value: 128,
                message: "Must have 128 or less characters",
              },
              required: {
                value: true,
                message: "Please confirm new password",
              },
              validate: {
                passwordsEqual: (value) =>
                  !getValues().new_password ||
                  value === getValues().new_password,
              },
            })}
            classes={{
              root: "field",
            }}
            placeholder="Confirm Password"
            name="confirm_password"
            type="password"
            autoComplete="confirm-password"
            error={errors.confirm_password !== undefined}
            errorMessage={errors?.confirm_password?.message}
            label="Confirm Password"
          />
          {passwordsNotMatching && (
            <Typography className="form_error">
              New passwords must match
            </Typography>
          )}
          {response && (
            <Typography className="form_error">{response}</Typography>
          )}
          <GrabbiButton
            disabled={disableButton}
            type="submit"
            classes={{
              root: "button",
            }}
          >
            CHANGE PASSWORD
          </GrabbiButton>
        </form>
      </Grid>
    </Box>
  );
};

export default ChangePasswordForgotComponent;

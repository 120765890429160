import { BaseAction, actionIds } from "actions";
import { Transaction, TransactionItem } from "types/GrabbiTypes";

export type TransactionState = {
  updatingTransactionIds: number[];
  updateTransactionLoading: boolean;
  updateTransactionStatus?: string;
  updateTransactionError?: string;
  refundStatus?: string;
  refundError?: string;
  refundLoading: boolean;
  transactions?: Transaction[];
  transactionsLoading: boolean;
  transactionsError?: string;
  allTransactions?: Transaction[];
  allTransactionsLoading: boolean;
  allTransactionsError?: string;
};

const DEFAULT_STATE: TransactionState = {
  updatingTransactionIds: [],
  updateTransactionLoading: false,
  refundLoading: false,
  transactionsLoading: false,
  allTransactionsLoading: false,
};

const removeId = (array: number[], id: number) => {
  const index = array.findIndex((arrayId) => arrayId === id);
  if (index !== -1) array.splice(index, 1);
};

export const transactionReducer = (
  state: TransactionState = DEFAULT_STATE,
  action: BaseAction
): TransactionState => {
  switch (action.type) {
    case actionIds.UPDATE_TRANSACTION_STATUS_START:
      return {
        ...state,
        updatingTransactionIds: state.updatingTransactionIds
          ? [...state.updatingTransactionIds, action.payload.id]
          : [action.payload.id],
        updateTransactionLoading: true,
      };
    case actionIds.UPDATE_TRANSACTION_STATUS_SUCCESS:
      const completedId = action.payload.id;
      const currentIdsSuccess = state.updatingTransactionIds;
      removeId(currentIdsSuccess, completedId);
      return {
        ...state,
        updatingTransactionIds: currentIdsSuccess,
        updateTransactionStatus: `Transaction ${
          action.payload.fulfilled ? "completed" : "reopened"
        }`,
        updateTransactionLoading: false,
      };
    case actionIds.UPDATE_TRANSACTION_STATUS_FAILED:
      const failedId = action.payload.id;
      const currentIdsFailed = state.updatingTransactionIds;
      removeId(currentIdsFailed, failedId);
      return {
        ...state,
        updatingTransactionIds: currentIdsFailed,
        updateTransactionError: `Failed to update transaction`,
        updateTransactionLoading: false,
      };
    case actionIds.REFUND_TRANSACTION_START:
      return {
        ...state,
        refundLoading: true,
      };
    case actionIds.REFUND_TRANSACTION_COMPLETED:
      return {
        ...state,
        refundLoading: false,
        refundStatus: action.payload,
      };
    case actionIds.REFUND_TRANSACTION_FAILED:
      return {
        ...state,
        refundLoading: false,
        refundError: action.payload.error,
      };
    case actionIds.GET_STORE_TRANSACTIONS_START:
      return { ...state, transactionsLoading: true };
    case actionIds.REFRESH_TRANSACTION_START:
      return { ...state, transactionsLoading: true, transactions: [] };
    case actionIds.GET_STORE_TRANSACTIONS_COMPLETED:
      action.payload.transactions.forEach((transaction: Transaction) => {
        transaction.transactionItems = transaction.transactionItems.sort(
          (a: TransactionItem, b: TransactionItem) => {
            return a.upc?.label?.localeCompare(b.upc.label) ?? 0;
          }
        );
      });
      return {
        ...state,
        transactions: action.payload.transactions,
        transactionsLoading: false,
      };
    case actionIds.GET_STORE_TRANSACTIONS_FAILED:
      return {
        ...state,
        transactionsError: action.payload.error,
        transactionsLoading: false,
      };
    case actionIds.GET_STORE_BY_UPC_START:
      return { ...state, transactions: undefined };
    case actionIds.GET_ALL_TRANSACTIONS_START:
      return {
        ...state,
        allTransactionsLoading: true,
        allTransactions: undefined,
        allTransactionsError: undefined,
      };
    case actionIds.GET_ALL_TRANSACTIONS_COMPLETE:
      return {
        ...state,
        allTransactionsLoading: false,
        allTransactions: action.payload.transactions,
      };
    case actionIds.GET_ALL_TRANSACTIONS_FAILED:
      return {
        ...state,
        allTransactionsLoading: false,
        allTransactionsError: action.payload.error,
      };
    case actionIds.CLEAR_STATUS:
      return {
        ...state,
        updateTransactionStatus: undefined,
        updateTransactionError: undefined,
        refundStatus: undefined,
        refundError: undefined,
      };
  }
  return state;
};

import { Checkbox } from "@material-ui/core";
import React from "react";
import "./UnavailableCheckbox.scss";

interface Props {
  className?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const UnavailableCheckbox: React.FC<Props> = ({
  className,
  checked,
  onChange,
}) => {
  return (
    <div className={`rs_board_unavailable_checkbox_container ${className ?? ""}`}>
      <Checkbox
        checked={checked}
        onChange={(event, checked) => onChange(checked)}
        classes={{
          root: "checkbox",
        }}
      />
      <span className="checkbox_text">Show Unavailable</span>
    </div>
  );
};

export default UnavailableCheckbox;

import React, {useRef} from "react";
import "./GrabbiCarouselComponent.scss";

const slideWidth = 30;
const _items = [
  {
    partner: {
      title: "Shiloh Cuisine",
      desc: `"It makes it easier for people to place an order, enjoy their party and come to pick up their food. It is very easy with Grabbi. I highly recommend it."`,
      videoUrl: '/assets/partners/videos/Shiloh-Cuisine-Spanish.mp4',
      poster: '/assets/partners/videos/Shiloh-Cuisine-Poster.png',
    },
  },
  {
    partner: {
      title: 'Mashallah',
      desc: 'It so easy, so nice, we got it, and we are very happy. We want other businesses using it too. You save a lot of labor.',
      videoUrl: '/assets/partners/videos/Mashallah-Halal-Food.mp4',
      poster: '/assets/partners/videos/Mashallah-Halal-Food-Poster.png',
    },
  },
  {
    partner: {
      title: 'Street Eats Market',
      desc: `"We partner with Grabbi to do safe contactless purchases at all of our food trucks over here."`,
      videoUrl: '/assets/partners/videos/06-19-21-Street-Eats-Owner.mp4',
      poster: '/assets/partners/videos/Street-Eats-Owner-Poster.png',
    },
  },
  // {
  //   partner: {
  //     title: 'Road Grill',
  //     desc: `"It is so helpfull specifically when we have a big rush. It helps us to handle the line and people dont need to stay in the line and get their food faster."`,
  //     videoUrl: '/assets/partners/videos/06-19-21-Road-Grill-Owner.mp4',
  //     poster: '/assets/partners/videos/Road-Grill-Poster.png',
  //   },
  // },
  {
    partner: {
      title: 'Fresh Coconut',
      desc: `"Makes it easier for customers to look at what they have and scan it on their phone and this is amazing product."`,
      videoUrl: '/assets/partners/videos/06-19-21-Fresh-Coconut.mp4',
      poster: '/assets/partners/videos/Fresh-Coconuts-Poster.png',
    },
  },
  {
    partner: {
      title: 'The Smoke Stop',
      desc: `"Increase lineup efficiencies and customers are happy with it. For all the food trucks out there I will recommend Grabbi system."`,
      videoUrl: '/assets/partners/videos/05-16-21-The-Smoke-Stop.mp4',
      poster: '/assets/partners/videos/The-Smoke-Stop-Poster.png',
    },
  },
];

const length = _items.length;
_items.push(..._items);

const sleep = (ms = 0) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const createItem = (position: any, idx: any) => {
  const item = {
    styles: {
        transform: `translateX(${position * slideWidth}rem)`,
    },
    partner: _items[idx].partner,
  };

  switch (position) {
    case length - 1:
    case length + 1:
      //item.styles = {...item.styles, filter: 'grayscale(1)'};
      break;
    case length:
        break;
    default:
      //item.styles = {...item.styles, opacity: 0};
      break;
  }

  return item;
};
interface Props {
  pos: number;
  idx: number;
  activeIdx: any;
}

const CarouselSlideItem: React.FC<Props> = ({pos, idx, activeIdx}) => {
  const item = createItem(pos, idx);
  const playButtonRef = useRef<HTMLVideoElement>(null);
  const [videoZIndex, setVideoZIndex] = React.useState(0);
  const handlePlayVideo = () => {

  if(playButtonRef.current?.paused){
    playButtonRef.current?.play();
    setVideoZIndex(4);
  } else {
    playButtonRef.current?.pause();
    setVideoZIndex(0);
  }
  };

  return (
    <li className="carousel__slide-item" style={item.styles}>
      <div onClick={handlePlayVideo} className="carousel__slide-item-img-link">
        <video style={{zIndex: videoZIndex}}
          ref={playButtonRef} controls={false} preload="metadata"
          className='video_container' autoPlay={false} data-object-fit='cover'
          poster={item.partner.poster}>
          <source src={item.partner.videoUrl}
            type='video/mp4'/></video>
      </div>
      <div className="carousel-slide-item__body">
        <div className="item_sub_header_text">{item.partner.title}</div>
        <div className="item_sub_description_text">{item.partner.desc}</div>
      </div>
    </li>
  );
};

const keys = Array.from(Array(_items.length).keys());

const GrabbiCarousel = () => {
  const [items, setItems] = React.useState(keys);
  const [isTicking, setIsTicking] = React.useState(false);
  const [activeIdx, setActiveIdx] = React.useState(0);
  const bigLength = items.length;

  const prevClick = (jump = 1) => {
    if (!isTicking) {
      setIsTicking(true);
      setItems((prev) => {
        return prev.map((_, i) => prev[(i + jump) % bigLength]);
      });
    }
  };

  const nextClick = (jump = 1) => {
    if (!isTicking) {
      setIsTicking(true);
      setItems((prev) => {
        return prev.map(
            (_, i) => prev[(i - jump + bigLength) % bigLength],
        );
      });
    }
  };

  const handleDotClick = (idx: number) => {
    if (idx < activeIdx) prevClick(activeIdx - idx);
    if (idx > activeIdx) nextClick(idx - activeIdx);
  };

  React.useEffect(() => {
    if (isTicking) sleep(300).then(() => setIsTicking(false));
  }, [isTicking]);

  React.useEffect(() => {
    setActiveIdx((length - (items[0] % length)) % length) // prettier-ignore
  }, [items]);

  return (
    <div className="carousel__wrap">
      <div className="carousel__inner">
        <div className='carousel_header_text_container'>
          <span className='carousel_header_text'>
            PARTNER TESTIMONIALS
          </span>
        </div>
        <div className='carousel_body_text_container'>
          <span className='carousel_body_text'>
            Hear from our partners and what they have to say about Grabbi
          </span>
        </div>
        <button className="carousel__btn carousel__btn--prev" onClick={() => prevClick()}>
          <i className="carousel__btn-arrow carousel__btn-arrow--left" />
        </button>
        <div className="carousel__container">
          <ul className="carousel__slide-list">
            {items.map((pos, i) => (
              <CarouselSlideItem
                key={i}
                idx={i}
                pos={pos}
                activeIdx={activeIdx}
              />
            ))}
          </ul>
        </div>
        <button className="carousel__btn carousel__btn--next" onClick={() => nextClick()}>
          <i className="carousel__btn-arrow carousel__btn-arrow--right" />
        </button>
        <div className="carousel__dots">
          {items.slice(0, length).map((pos, i) => (
            <button
              key={i}
              onClick={() => handleDotClick(i)}
              className={i === activeIdx ? 'dot active' : 'dot'}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default GrabbiCarousel;

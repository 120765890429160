import {
  TablePagination,
  TablePaginationProps,
} from "@material-ui/core";
import React from "react";
import "./GrabbiPaginationComponent.scss";

const GrabbiPagination: React.FC<TablePaginationProps> = ({
  page,
  rowsPerPage,
  count,
  onChangePage,
  onChangeRowsPerPage,
  ...otherProps
}) => {
  return (
    <TablePagination
      classes={{
        root: "grabbi_pagination_root",
        caption: "grabbi_pagination_font",
        select: "grabbi_pagination_font",
        menuItem: "grabbi_pagination_font"
      }}
      page={page}
      rowsPerPage={rowsPerPage}
      count={count}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      {...otherProps}
    />
  );
};

export default GrabbiPagination;

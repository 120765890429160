import { Checkbox, Typography } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { useAxiosContext } from "components/Shared/AxiosProvider/AxiosProvider";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import GrabbiDialog from "components/Shared/GrabbiDialog/GrabbiDialogComponent";
import GrabbiTable, {
  GrabbiTableColumn,
} from "components/Shared/GrabbiTable/GrabbiTableComponent";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";
import UpdatingRow from "components/Shared/UpdatingRow/UpdatingRowComponent";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { State } from "store";
import { GrabbiMerchandise, GrabbiTopping } from "types/GrabbiTypes";
import "./ToppingsTableComponent.scss";

interface Props {
  className?: string;
  merchandiseId: number;
  storeId: number;
  open: boolean;
  onClose: () => void;
}

const ToppingsTable: React.FC<Props> = ({
  className,
  merchandiseId,
  storeId,
  open,
  onClose,
}) => {
  const { useAxios } = useAxiosContext();
  const { enqueueSnackbar } = useSnackbar();
  const [updatingRows, setUpdatingRows] = useState<number[]>([]);

  const selectedStore = useSelector(
    (state: State) => state.storeList.selectedStore
  );

  const [
    {
      data: dataMerchandise,
      loading: loadingMerchandise,
      error: errorMerchandise,
    },
    getMerchandise,
  ] = useAxios<GrabbiMerchandise>({
    url: `restful-services/merchandise/${storeId}/${merchandiseId}`,
    method: "GET",
  });

  dataMerchandise?.toppings.sort((a: GrabbiTopping, b: GrabbiTopping) =>
    a.toppingUpc.label.localeCompare(b.toppingUpc.label)
  );

  useEffect(() => {
    getMerchandise();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (errorMerchandise) {
      enqueueSnackbar("Failed to fetch merchandise", { variant: "error" });
      onClose();
    }
  }, [errorMerchandise, enqueueSnackbar, onClose]);

  const [
    { data: dataUpdateTopping, error: errorUpdatingTopping },
    updateTopping,
  ] = useAxios<GrabbiTopping>({
    url: `restful-services/merchandise/topping`,
    method: "POST",
  });

  useEffect(() => {
    if (dataUpdateTopping) {
      enqueueSnackbar("Topping update completed.", { variant: "success" });
    } else if (errorUpdatingTopping) {
      enqueueSnackbar("Topping update failed.", { variant: "error" });
    }
  }, [dataUpdateTopping, errorUpdatingTopping, enqueueSnackbar]);

  const handleStatusChange =
    (topping: GrabbiTopping, index: number) =>
    async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (selectedStore?.id) {
        setUpdatingRows([...updatingRows, index]);
        const payload: GrabbiTopping = {
          ...topping,
          available: !topping.available,
        };
        await updateTopping({
          data: payload,
        });
        await getMerchandise();
        setUpdatingRows(updatingRows.splice(-1, 1));
      }
    };

  const columns: GrabbiTableColumn[] = [
    new GrabbiTableColumn(<div className="checkmark_header" />, [], {
      mobilePlacement: "summary",
      render: (item: GrabbiTopping, index: number) => {
        return (
          <Checkbox
            checked={item.available}
            onChange={handleStatusChange(item, index)}
            classes={{
              root: "checkbox",
            }}
          />
        );
      },
    }),
    new GrabbiTableColumn("Add-On Name", [], {
      mobilePlacement: "summary",
      render: (item: GrabbiTopping) => item.toppingUpc.label,
    }),
  ];

  const toppings = dataMerchandise?.toppings;

  return (
    <GrabbiDialog
      dialogProps={{
        open,
        className: `toppings_table_root ${className ?? ""}`,
        onClose,
        PaperProps: {
          className: `dialog_paper`,
        },
      }}
      dialogContentProps={{
        className: `toppings_table_content`,
      }}
    >
      <div className="content_wrapper">
        <div className="text_icon_row">
          <Typography className="title">Merchandise Add-Ons</Typography>
          <Close className="icon_black clickable_black" onClick={onClose} />
        </div>
        {toppings && (
          <GrabbiTable
            data={toppings}
            columns={columns}
            className={`toppings_table_table ${className ?? ""}`}
            rowOverride={(key: string) => (
              <UpdatingRow text={"Updating Topping"} uniqueKey={key} />
            )}
            overrideRows={updatingRows}
          />
        )}
        {!toppings && loadingMerchandise && <LoadingIcon />}
        {toppings && <GrabbiButton onClick={onClose}>Close</GrabbiButton>}
      </div>
    </GrabbiDialog>
  );
};

export default ToppingsTable;

import React from "react";
import "./LoggedOutComponent.scss";
import {
  Box,
  Typography,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { CONTEXT } from "shared/scripts/UrlContext";

interface Props {
  message: string;
}

const LoggedOutComponent: React.FC<Props> = ({ message }) => {
  const history = useHistory();

  const sendToLogin = () => {
    history.push(`${CONTEXT}login`);
  };

  return (
    <Box className="loggedout_root">
      <Typography className="loggedout_header_text">
        {"You have been logged out".toUpperCase()}
      </Typography>
      <Typography className="loggedout_message_text">{message}</Typography>
      <Button classes={{root:"loggedout_login_button"}} onClick={sendToLogin}>{"Log In".toUpperCase()}</Button>
    </Box>
  );
};

export default LoggedOutComponent;

import { Table, TableBody, TableRow, TableCell, Box } from "@material-ui/core";
import { createQueryString } from "actions/api";
import { useAxiosContext } from "components/Shared/AxiosProvider/AxiosProvider";
import GrabbiDropdown from "components/Shared/GrabbiDropdown/GrabbiDropdown";
import GrabbiTable, {
  GrabbiTableColumn,
} from "components/Shared/GrabbiTable/GrabbiTableComponent";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";
import StoreDashboard from "components/Shared/StoreDashboard/StoreDashboardComponent";
import { DateTime } from "luxon";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { State } from "store";
import { Transaction, TransactionItem } from "types/GrabbiTypes";
import "./ReportDashboardComponent.scss";

interface Props {
  className?: string;
}

interface DateRange {
  from: number;
  to: number;
  label: string;
}

interface DateChoiceState {
  daily?: DateRange[];
  weekly?: DateRange[];
  monthly?: DateRange[];
}

enum RangeType {
  "Month",
  "Week",
  "Day",
}

const ReportDashboard: React.FC<Props> = ({ className }) => {
  const { useAxios } = useAxiosContext();
  const { enqueueSnackbar } = useSnackbar();
  const selectedStore = useSelector(
    (state: State) => state.storeList.selectedStore
  );

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  const [rangeType, setRangeType] = useState<RangeType>(RangeType.Day);
  const [rangeSelection, setRangeSelection] = useState<number>(0);
  const [choices, setChoices] = useState<DateChoiceState>({});

  const handlePageChange = (pageSize: number, pageNumber: number) => {
    setPageSize(pageSize);
    setPageNumber(pageNumber);
  };

  const formatDate = (date: DateTime) => {
    return date.toMillis();
  };

  useEffect(() => {
    if (
      choices.daily === undefined &&
      choices.weekly === undefined &&
      choices.monthly === undefined
    ) {
      //Calculate Days
      const dailyChoices: DateRange[] = [];
      for (let i = 0; i < 7; i++) {
        const currentTime = DateTime.local();
        const lastMidnight = currentTime.minus({
          hours: currentTime.get("hour"),
          minute: currentTime.get("minute"),
        });
        const currentDay = lastMidnight.minus({ days: i });
        dailyChoices.push({
          from: formatDate(currentDay),
          to: formatDate(currentDay.plus({ hours: 23, minutes: 59 })),
          label: currentDay.toFormat(`EEEE MM-dd`),
        });
      }

      //Calculate Weeks
      const weeklyChoices: DateRange[] = [];
      for (let i = 0; i < 4; i++) {
        const currentTime = DateTime.local();
        const lastMidnight = currentTime.minus({
          hours: currentTime.get("hour"),
          minute: currentTime.get("minute"),
        });
        const currentMonday = lastMidnight
          .set({ weekday: 1 })
          .minus({ weeks: i });
        const currentSunday = currentMonday.set({ weekday: 7 });
        weeklyChoices.push({
          from: formatDate(currentMonday),
          to: formatDate(currentSunday.plus({ hours: 23, minutes: 59 })),
          label: `Week of ${currentMonday.toFormat("MM-dd")}`,
        });
      }

      //Calculate Months
      const monthlyChoices: DateRange[] = [];
      for (let i = 0; i < 12; i++) {
        const currentTime = DateTime.local();
        const lastMidnight = currentTime.minus({
          hours: currentTime.get("hour"),
          minute: currentTime.get("minute"),
        });
        const firstDay = lastMidnight.set({ day: 1 }).minus({ months: i });
        const lastDay = firstDay.set({ day: firstDay.daysInMonth });
        monthlyChoices.push({
          from: formatDate(firstDay),
          to: formatDate(lastDay.plus({ hours: 23, minutes: 59 })),
          label: firstDay.toFormat("MMMM yyyy"),
        });
      }

      setChoices({
        daily: dailyChoices,
        weekly: weeklyChoices,
        monthly: monthlyChoices,
      });
    }
  }, [choices, setChoices]);

  const rangeTypes = Object.keys(RangeType).filter((item) =>
    isNaN(parseInt(item))
  );

  const activeChoices: DateRange[] | undefined =
    rangeType === RangeType.Month
      ? choices?.monthly
      : rangeType === RangeType.Week
      ? choices?.weekly
      : choices?.daily;

  const fromDate = activeChoices?.[rangeSelection]?.from;
  const toDate = activeChoices?.[rangeSelection]?.to;

  const [refresh, setRefresh] = useState({
    completed: true,
    refunded: true,
  });

  const handleRangeTypeChange = (event: any) => {
    const index = parseInt(event.target.value);
    if (index !== undefined) {
      const newRangeType =
        index === 0
          ? RangeType.Month
          : index === 1
          ? RangeType.Week
          : RangeType.Day;
      setRefresh({
        completed: true,
        refunded: true,
      });
      setRangeSelection(0);
      setRangeType(newRangeType);
    }
  };

  const handleRangeSelectionChange = (event: any) => {
    const index = parseInt(event.target.value);
    if (activeChoices && index < activeChoices.length) {
      setRefresh({
        completed: true,
        refunded: true,
      });
      setRangeSelection(index);
    }
  };

  interface TEMP {
    pageNumber: number;
    pageSize: number;
    isFulfilled: boolean;
    isRefunded: boolean;
    fromDate?: number;
    toDate?: number;
  }

  const params1: TEMP = {
    pageNumber: 1,
    pageSize: 100000,
    isFulfilled: true,
    isRefunded: false,
  };

  const params2: TEMP = {
    pageNumber: 1,
    pageSize: 100000,
    isFulfilled: false,
    isRefunded: true,
  };

  if (fromDate !== undefined) {
    params1.fromDate = fromDate;
    params2.fromDate = fromDate;
  }

  if (toDate !== undefined) {
    params1.toDate = toDate;
    params2.toDate = toDate;
  }

  const [
    { data: dataFulfilled, loading: loadingFulfilled, error: errorFulfilled },
    getFulfilled,
  ] = useAxios<Transaction[]>(
    {
      url: `restful-services/transaction/store/${
        selectedStore?.id
      }${createQueryString(params1)}`,
      timeout: 5000,
    },
    { useCache: false }
  );

  const [
    { data: dataRefunded, loading: loadingRefunded, error: errorRefunded },
    getRefunded,
  ] = useAxios<Transaction[]>(
    {
      url: `restful-services/transaction/store/${
        selectedStore?.id
      }${createQueryString(params2)}`,
      timeout: 5000,
    },
    { useCache: false }
  );

  useEffect(() => {
    if (
      selectedStore?.id &&
      !errorFulfilled &&
      !errorRefunded &&
      refresh.completed &&
      refresh.refunded
    ) {
      getFulfilled();
      getRefunded();
    }
  }, [
    refresh,
    selectedStore,
    errorFulfilled,
    errorRefunded,
    getFulfilled,
    getRefunded,
  ]);

  useEffect(() => {
    if (dataFulfilled && refresh.completed) {
      setRefresh({
        ...refresh,
        completed: false,
      });
    }

    if (dataRefunded && refresh.refunded) {
      setRefresh({
        ...refresh,
        refunded: false,
      });
    }
  }, [dataFulfilled, dataRefunded, refresh, setRefresh]);

  useEffect(() => {
    if (errorFulfilled) {
      console.error(errorFulfilled);
      enqueueSnackbar("Could not fetch transactions", { variant: "error" });
    } else if (errorRefunded) {
      console.error(errorRefunded);
      enqueueSnackbar("Could not fetch transactions", { variant: "error" });
    }
  }, [errorFulfilled, errorRefunded, enqueueSnackbar]);

  const transactions = [];

  if (dataFulfilled && dataFulfilled.length > 0) {
    transactions.push(...dataFulfilled);
  }
  if (dataRefunded && dataRefunded.length > 0) {
    transactions.push(...dataRefunded);
  }

  let totalMerchantSales = 0;

  for (const row of transactions.filter((t) => !t.refunded)) {
    totalMerchantSales += row.merchantTotal;
  }

  let totalMerchantTips = 0;

  for (const row of transactions.filter((t) => !t.refunded)) {
    totalMerchantTips += row?.tip ?? 0;
  }

  const totalSalesString = totalMerchantSales.toLocaleString(undefined, {
    style: "currency",
    currency: selectedStore?.currency,
  });

  const totalTipsString = totalMerchantTips.toLocaleString(undefined, {
    style: "currency",
    currency: selectedStore?.currency,
  });

  const TABLE_COLUMNS: GrabbiTableColumn[] = [
    new GrabbiTableColumn("Date", ["createdDate"], {
      sort: {
        accessor: "createdDate",
        path: ["createdDate"],
        sortFunction: (a: string, b: string) => {
          const aDate = moment(a).utc().unix();
          const bDate = moment(b).utc().unix();
          return aDate > bDate ? 1 : aDate < bDate ? -1 : 0;
        },
        locked: true,
      },
      normal: {
        headerProps: {
          className: "date_column",
        },
        bodyProps: {
          className: "date_column table_body_cell",
        },
      },
      mobile: {
        bodyProps: {
          className: "table_body_cell_mobile date_column_mobile",
        },
        headerProps: {
          className: "date_column_mobile",
        },
      },
      mobilePlacement: "summary",
      render: (data: string) => {
        return (
          <div>
            <div>{moment(data).utc().local().format("MMM DD")}</div>
            <div className="time">
              {moment(data).utc().local().format("h:mma")}
            </div>
          </div>
        );
      },
    }),
    new GrabbiTableColumn("Merchant Payable", [], {
      // sort: {
      //   accessor: "merchantTotal",
      //   path: ["merchantTotal"],
      // },
      normal: {
        headerProps: {
          align: "left",
        },
        bodyProps: {
          align: "left",
          className: "table_body_cell price_column",
        },
      },
      mobile: {
        bodyProps: {
          className: "table_body_cell_mobile price_column_mobile",
        },
        headerProps: {
          className: "table_header_cell_mobile price_column_mobile",
        },
      },
      mobilePlacement: "summary",
      placementBreakpoint: 493,
      render: (item: Transaction): ReactNode => {
        return (
          <span>
            {(item.merchantTotal * (item?.refunded ? -1 : 1)).toLocaleString(
              undefined,
              {
                style: "currency",
                currency: item.currency,
              }
            )}
          </span>
        );
      },
    }),
    new GrabbiTableColumn("Status", [], {
      normal: {
        headerProps: {
          className: "status_column",
        },
        bodyProps: {
          className: "status_column table_body_cell",
        },
      },
      mobile: {
        bodyProps: {
          className: "status_column_mobile",
        },
        headerProps: {
          className: "status_column_mobile table_body_cell_mobile",
        },
      },
      mobilePlacement: "summary",
      render: (data: Transaction) => {
        const status =
          data?.transactionStatus === "REFUNDED" || data?.refunded
            ? "Refunded"
            : data?.transactionStatus === "SUCCEEDED" || data?.fulfilled
            ? "Closed"
            : data?.transactionStatus;
        return status;
      },
    }),
    new GrabbiTableColumn("ORDER / TOPPINGS", [], {
      normal: {
        bodyProps: {
          className: "table_body_cell description_column",
        },
      },
      mobile: {
        bodyProps: {
          className: "table_body_cell_mobile description_column_mobile",
        },
        headerProps: {
          className: "table_body_cell_mobile description_column_mobile",
        },
      },
      hideDetailsHeader: true,
      render: (item: Transaction): ReactNode => {
        const items = item.transactionItems;
        return (
          <Table>
            <TableBody>
              {items
                ?.sort((a, b) => a.upc.label.localeCompare(b.upc.label))
                ?.map((item: TransactionItem) => {
                  return (
                    <TableRow
                      className="subtable_row"
                      key={`transaction-${item.id}`}
                    >
                      <TableCell
                        className="text subtable_cell quantity_cell"
                        padding="none"
                      >
                        {item.quantity}
                      </TableCell>
                      <TableCell className="text subtable_cell label_cell">
                        <div className="label_wrapper">{item?.upc?.label}</div>

                        {item.toppings
                          .sort((a, b) =>
                            a.upc.label.localeCompare(b.upc.label)
                          )
                          .map((topping) => {
                            return (
                              <div
                                className="topping_wrapper"
                                key={`topping-${topping.upc.upcNumber}`}
                              >
                                {topping.upc.label}
                              </div>
                            );
                          })}
                      </TableCell>
                    </TableRow>
                  );
                }) ?? undefined}
            </TableBody>
          </Table>
        );
      },
    }),
  ];

  const transactionsLoaded = dataFulfilled && dataRefunded;

  const loading =
    loadingFulfilled ||
    loadingRefunded ||
    refresh.completed ||
    refresh.refunded;

  const dateHumanFormat = (millis: number) =>
    DateTime.fromMillis(millis).toFormat("fff");

  return (
    <StoreDashboard dashboardTitle="Report Dashboard">
      <div className={`report_dashboard_root ${className ?? ""}`}>
        <div className="date_options">
          {activeChoices && (
            <React.Fragment>
              <GrabbiDropdown
                className="date_dropdown"
                options={rangeTypes}
                onChange={handleRangeTypeChange}
                selectedIndex={rangeType}
              />
              <GrabbiDropdown
                className="date_dropdown"
                options={activeChoices?.map((row) => row.label)}
                onChange={handleRangeSelectionChange}
                selectedIndex={rangeSelection}
              />
            </React.Fragment>
          )}
        </div>
        {transactionsLoaded &&
          !loading &&
          transactions &&
          transactions.length > 0 && (
            <React.Fragment>
              <p className="title">{`Total Sales + Tip & Tax: ${totalSalesString}`}</p>
              <p className="subtitle">{`Total Tips: ${totalTipsString}`}</p>
              <GrabbiTable
                columns={TABLE_COLUMNS}
                data={transactions}
                className="transaction_table"
                onPageChange={handlePageChange}
                overridePageNumber={pageNumber}
                overridePageSize={pageSize}
                enablePagination={true}
              />
            </React.Fragment>
          )}
        {transactionsLoaded &&
          transactions &&
          !loading &&
          fromDate &&
          toDate &&
          transactions.length === 0 && (
            <Box className="no_results_root">
              <Box className="header">
                No Completed or Refunded orders the following time range:
              </Box>
              <Box className="header">{`${dateHumanFormat(
                fromDate
              )} - ${dateHumanFormat(toDate)}`}</Box>
            </Box>
          )}
        {loading && <LoadingIcon />}
      </div>
    </StoreDashboard>
  );
};

export default ReportDashboard;

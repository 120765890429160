import { BaseAction, actionIds } from "actions";

export type CustomerState = {
  membershipChecked: boolean;
  membershipType?: string;
  membershipMenuId?: number;
  memberEmail?: string;
  externalCustomerId?: string;
  hasMembership: boolean;
  error?: string;
  loading?: boolean;
};

export type MembershipAction = Omit<CustomerState, "membershipChecked" | "loading">;

const DEFAULT_STATE = {
  membershipChecked: false,
  hasMembership: false,
  loading: false,
};

export const membershipReducer = (
  state: CustomerState = DEFAULT_STATE,
  action: BaseAction
) => {
  switch (action.type) {
    case actionIds.GET_MEMBERSHIP_COOKIE:
      return {
        ...state,
        loading: true
      };
    case actionIds.SET_MEMBERSHIP_CHECKED:
      return {
        ...state,
        membershipChecked: true,
      };
    case actionIds.GET_MEMBERSHIP_START:
      return {
        ...state,
        loading: true
      };
    case actionIds.GET_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        ...action.payload,
        membershipChecked: action.payload.externalCustomerId != null,
        loading: false
      };
    case actionIds.GET_MEMBERSHIP_FAILED:
      return {
        ...DEFAULT_STATE,
        ...action.payload,
        loading: false
      };
  }
  return state;
};

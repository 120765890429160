import { BaseAction, actionIds } from "actions";
import { CountryCode, StateCode } from "types/GrabbiTypes";

export type RegionsState = {
  countries?: CountryCode[];
  states?: StateCode[];
  loadingCountries: boolean;
  loadingStates: boolean;
};

export const regionReducer = (
  state: RegionsState = {
    loadingCountries: false,
    loadingStates: false
  },
  action: BaseAction
) : RegionsState => {
  switch (action.type) {
    case actionIds.GET_COUNTRIES_START:
      return {
        ...state,
        loadingCountries: true,
      };
    case actionIds.GET_COUNTRIES_COMPLETE:
      return {
        ...state,
        countries: action.payload.countries,
        loadingCountries: false,
      };
    case actionIds.GET_COUNTRIES_FAILED:
      return {
        ...state,
        loadingCountries: false,
      };
    case actionIds.GET_STATES_START:
      return {
        ...state,
        loadingStates: true,
      };
    case actionIds.GET_STATES_COMPLETE:
      return {
        ...state,
        states: action.payload.states,
        loadingStates: false,
      };
    case actionIds.GET_STATES_FAILED:
      return {
        ...state,
        loadingStates: false,
      };
  }
  return state;
};

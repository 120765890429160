import React from 'react';
import './MembershipChipComponent.scss';

interface Props {
  className?: string;
  text: string;
}

const MembershipChipComponent:React.FC<Props> = ({ text, className }) => {
  return (
    <span className={`membership_chip_root ${className ?? ""}`}>
      <div className="star_icon small_icon" />
      <span>{text}</span>
    </span>
  );
}

export default MembershipChipComponent;
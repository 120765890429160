import { BaseAction, actionIds } from "actions";
import { Merchant } from "types/GrabbiTypes";

export type MerchantStoreState = {
  merchant?: Merchant;
  username?: string;
  loadingMerchant: boolean;
  updateProfileLoading: boolean;
  updateProfileError?: string;
};

const DEFAULT_STATE: MerchantStoreState = {
  loadingMerchant: false,
  updateProfileLoading: false
};

export const merchantStoreReducer = (
  state: MerchantStoreState = DEFAULT_STATE,
  action: BaseAction
) => {
  switch (action.type) {
    case actionIds.UPDATE_PERSONAL_DETAILS_START:
      return {
        ...state,
        updateProfileLoading: true,
        updateProfileError: undefined
      };
    case actionIds.UPDATE_PERSONAL_DETAILS_COMPLETED:
      return {
        ...state,
        updateProfileLoading: false,
        updateProfileError: undefined
      };
    case actionIds.UPDATE_PERSONAL_DETAILS_FAILED:
      return {
        ...state,
        updateProfileLoading: false,
        updateProfileError: action.payload.error
      };
    case actionIds.GET_MERCHANT_START:
      return { ...state, loadingMerchant: true, merchant: undefined };
    case actionIds.GET_MERCHANT_COMPLETE:
      return {
        ...state,
        loadingMerchant: false,
        merchant: action.payload.merchant,
      };
    case actionIds.AUTHENTICATION_FAILED:
      return {
        ...state,
        loadingMerchant: false,
        merchant: undefined,
      };
    case actionIds.LOGOUT_START:
      return DEFAULT_STATE;
  }
  return state;
};

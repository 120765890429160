import { Grid, Box, Button } from "@material-ui/core";
import GrabbiTextField from "components/Shared/GrabbiTextField/GrabbiTextField";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { Merchant } from "types/GrabbiTypes";
import "./PersonalDetailsComponent.scss";
import { useForm } from "react-hook-form";
import { NAME_REGEX, PHONE_REGEX, RFC_5322_EMAIL_REGEX } from "shared/scripts/Constants";

interface Props {
  merchant?: Merchant;
  loadingMerchant: boolean;
  updateProfileLoading: boolean;
  updateProfileError?: string;
  dispatchGetMerchant: () => void;
  dispatchUpdatePersonalDetails: (merchant: Merchant) => void;
}

const PersonalDetailsComponent: React.FC<Props> = ({
  merchant,
  loadingMerchant,
  updateProfileLoading,
  updateProfileError,
  dispatchGetMerchant,
  dispatchUpdatePersonalDetails,
}) => {
  const { register, handleSubmit, errors, setValue, getValues, control } = useForm({
    reValidateMode: "onBlur",
    shouldFocusError: true,
  });

  if (!merchant && !loadingMerchant) {
    dispatchGetMerchant();
  }

  const [fieldsInitialized, setFieldsInitialized] = useState(false);

  const initializeFields = useCallback(() => {
    if (merchant) {
      setValue("first_name", merchant.firstName);
      setValue("last_name", merchant.lastName);
      setValue("email_address", merchant.email);
      setValue("phone_number", merchant.phone);
      setFieldsInitialized(true);
    }
  }, [merchant, setValue, setFieldsInitialized]);

  useEffect(() => {
    if (!fieldsInitialized) {
      initializeFields();
    }
  }, [fieldsInitialized, initializeFields]);

  const { enqueueSnackbar } = useSnackbar();

  const [pendingUpdate, setPendingUpdate] = useState(false);

  const handleSubmitValid = () => {
    const { first_name, last_name, email_address, phone_number } = getValues();
    if (merchant) {
      const newMerchant: Merchant = {
        id: merchant.id,
        identity: merchant.identity,
        firstName: first_name,
        lastName: last_name,
        email: email_address,
        phone: phone_number,
      };
      dispatchUpdatePersonalDetails(newMerchant);
      enqueueSnackbar("Updating profile details...", { variant: "info" });
    }
  };

  const handleSubmitError = () => {
    enqueueSnackbar("Please resolve errors and try again.", {
      variant: "error",
    });
  };

  useEffect(() => {
    if (updateProfileLoading) {
      setPendingUpdate(true);
    }
  }, [updateProfileLoading, setPendingUpdate]);

  useEffect(() => {
    if (pendingUpdate && !updateProfileLoading && !updateProfileError) {
      enqueueSnackbar("Profile updated", { variant: "success" });
      setPendingUpdate(false);
    } else if (pendingUpdate && updateProfileError) {
      enqueueSnackbar(`Error: ${updateProfileError}`, { variant: "error" });
      setPendingUpdate(false);
    }
  }, [updateProfileLoading, updateProfileError, pendingUpdate, setPendingUpdate, enqueueSnackbar]);

  const profileFieldsLoading = updateProfileLoading || !merchant;

  return (
    <React.Fragment>
      <Grid item container className={`edit_profile_personal_details_content`}>
        {profileFieldsLoading && (
          <Box className="edit_profile_loading_icon_wrapper">
            <LoadingIcon
              className="edit_profile_loading_icon"
              size={80}
              style={{ marginTop: 110 }}
            />
          </Box>
        )}
        <form onSubmit={handleSubmit(handleSubmitValid, handleSubmitError)}>
          <GrabbiTextField
            ref={register({
              minLength: {
                value: 2,
                message: "Must have 2 or more characters.",
              },
              maxLength: {
                value: 40,
                message: "Must have 40 or less characters",
              },
              required: {
                value: true,
                message: "Please enter a first name",
              },
              pattern: {
                value: NAME_REGEX,
                message: "First name may only contain letters.",
              },
            })}
            classes={{
              root: "edit_profile_field edit_profile_button_personal_details",
            }}
            InputProps={{
              classes: {
                input: "edit_profile_field_input",
              },
            }}
            placeholder="First Name"
            name="first_name"
            autoComplete="first_name"
            disabled={profileFieldsLoading}
            error={errors.first_name}
            errorMessage={errors?.first_name?.message}
            label="First Name"
            control={control}
          />
          <GrabbiTextField
            ref={register({
              minLength: {
                value: 2,
                message: "Must have 2 or more characters.",
              },
              maxLength: {
                value: 40,
                message: "Must have 40 or less characters",
              },
              required: {
                value: true,
                message: "Please enter a last name",
              },
              pattern: {
                value: NAME_REGEX,
                message: "Last name may only contain letters.",
              },
            })}
            classes={{
              root: "edit_profile_field",
            }}
            InputProps={{
              classes: {
                input: "edit_profile_field_input",
              },
            }}
            placeholder="Last Name"
            name="last_name"
            autoComplete="last_name"
            disabled={profileFieldsLoading}
            error={errors.last_name}
            errorMessage={errors?.last_name?.message}
            label="Last Name"
            control={control}
          />
          <GrabbiTextField
            ref={register({
              minLength: {
                value: 6,
                message: "Must have 6 or more characters.",
              },
              maxLength: {
                value: 50,
                message: "Must have 50 or less characters",
              },
              required: {
                value: true,
                message: "Please enter an email address",
              },
              pattern: {
                value: RFC_5322_EMAIL_REGEX,
                message: "Invalid email address",
              },
              //? [REVISIT] Add validation of email address once email server is implemented
            })}
            classes={{
              root: "edit_profile_field",
            }}
            InputProps={{
              classes: {
                input: "edit_profile_field_input",
              },
            }}
            placeholder="Email Address"
            name="email_address"
            autoComplete="email_address"
            disabled={profileFieldsLoading}
            error={errors.email_address}
            errorMessage={errors?.email_address?.message}
            label="Email Address"
            control={control}
          />
          <GrabbiTextField
            ref={register({
              minLength: {
                value: 1,
                message: "Must have 1 or more digits",
              },
              maxLength: {
                value: 15,
                message: "Must have 15 or less digits",
              },
              required: {
                value: true,
                message: "Please enter a phone number",
              },
              pattern: {
                value: PHONE_REGEX,
                message: "Invalid phone number",
              },
            })}
            classes={{
              root: "edit_profile_field",
            }}
            InputProps={{
              classes: {
                input: "edit_profile_field_input",
              },
            }}
            placeholder="Phone Number"
            name="phone_number"
            autoComplete="phone_number"
            disabled={profileFieldsLoading}
            error={errors.phone_number}
            errorMessage={errors?.phone_number?.message}
            label="Phone Number"
            control={control}
          />
          <Button
            type="submit"
            disabled={profileFieldsLoading}
            classes={{
              root: "edit_profile_button edit_profile_button_personal_details",
            }}
          >
            UPDATE PROFILE
          </Button>
        </form>
      </Grid>
    </React.Fragment>
  );
};

export default PersonalDetailsComponent;

import React, { useEffect, useState } from "react";
import "./AndroidCheckoutButtonComponent.scss";
import {
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";
import { PaymentRequest } from "@stripe/stripe-js/types/stripe-js/payment-request";
import {
  CheckoutNotificationPayload,
  CreatePaymentIntentRequest,
  CreatePaymentIntentResponse,
  GrabbiStore,
  Transaction,
} from "types/GrabbiTypes";
import useAxios from "axios-hooks";
import { useLoginContext } from "hooks/CustomerLogin";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";

interface Props {
  isFoodTruck?: boolean;
  store: GrabbiStore;
  transaction: Transaction;
  onSuccess: () => void;
}

const AndroidCheckoutButton: React.FC<Props> = ({
  isFoodTruck,
  store,
  transaction,
  onSuccess,
}) => {
  const { accessToken } = useLoginContext();
  const stripe = useStripe();
  const [loading, setLoading] = useState(true);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
  const [paymentComplete, setPaymentCompleted] = useState(false);

  const transactionTotal = Math.floor(transaction.transactionTotal * 100);

  const { "1": createPayment } = useAxios<CreatePaymentIntentResponse>(
    {
      url: `restful-services/store/paymentIntent`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
    { manual: true }
  );

  const { "1": postPayment } = useAxios<CheckoutNotificationPayload>(
    {
      url: `restful-services/customer/checkoutNotification`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    setLoading(true);
    setPaymentRequest(undefined);
    const countryCode = store.address?.country?.code;
    if (stripe && countryCode) {
      const pr = stripe.paymentRequest({
        country: countryCode.replace("USA", "US"),
        currency: transaction.currency.toLowerCase(),
        total: {
          label: `GRABBI Transaction ${transaction.id}`,
          amount: transactionTotal,
        },
        requestPayerName: true,
      });

      // Check the availability of the Payment Request API.

      pr.canMakePayment()
        .then(
          async (result) => {
            if (result) {
              console.log("Google Pay config found");
              const paymentIntentRequestBody: CreatePaymentIntentRequest = {
                storeId: store.id,
                transactionId: transaction.id,
                amount: transaction.transactionTotal,
                appFeeAmount: transaction.serviceFee,
                Currency: transaction.currency,
              };
              const paymentIntentPayload = await createPayment({
                data: paymentIntentRequestBody,
              });
              const clientSecret = paymentIntentPayload.data.clientSecret;
              pr.on("paymentmethod", async (ev) => {
                // Confirm the PaymentIntent without handling potential next actions (yet).
                const { paymentIntent, error: confirmError } =
                  await stripe.confirmCardPayment(
                    clientSecret,
                    { payment_method: ev.paymentMethod.id },
                    { handleActions: false }
                  );
                if (confirmError) {
                  console.error("ev.complete('fail')");
                  ev.complete("fail");
                } else if (paymentIntent) {
                  console.log("ev.complete('success')");
                  ev.complete("success");
                  if (paymentIntent?.status === "requires_action") {
                    // Let Stripe.js handle the rest of the payment flow.
                    const { error } = await stripe.confirmCardPayment(
                      clientSecret
                    );
                    if (error) {
                      console.error("requires_action - error_if");
                      // The payment failed -- ask your customer for a new payment method.
                    } else {
                      console.error("requires_action => error_else");
                      // Else requries_action
                    }
                  } else {
                    const payload: CheckoutNotificationPayload = {
                      paymentIntentId: paymentIntent.id,
                      storeId: store.id,
                      transactionId: transaction.id,
                    };
                    postPayment({
                      data: payload,
                    }).then((res) => {
                      onSuccess();
                    });
                    setPaymentCompleted(true);
                    // The payment has succeeded.
                  }
                } else {
                  console.error("Payment intent undefined.");
                }
              });
              console.log("setPaymentRequest")
              console.log(pr)
              setPaymentRequest(pr);
            } else {
              console.error("Unable to find Google Pay config");
            }
            setLoading(false);
          },
          (rejectionReason) => {
            console.error(
              "Error: Failed to access Google Pay and/or Autofill Settings"
            );
            console.error({ rejectionReason });
          }
        )
        .catch((err) => {
          console.error("Error: Failed to initialize Stripe/Google Pay");
          console.error(err);
        });
    } else {
      if (!stripe) {
        console.error("ERROR: Stripe object not defined");
      } else {
        console.error(`ERROR: Country code not defined for store ${store.id}`);
      }
    }
  }, [
    stripe,
    store,
    transaction,
    transactionTotal,
    paymentComplete,
    setPaymentCompleted,
    postPayment,
    onSuccess,
    createPayment,
  ]);

  if (paymentRequest) {
    return (
      <PaymentRequestButtonElement
        className="android_checkout_button_root"
        options={{ paymentRequest }}
      />
    );
  } else {
    return loading ? (
      <LoadingIcon className="android_checkout_button_root_loading" />
    ) : (
      <div className="android_checkout_button_root_fallback">
        <p>
          Please ensure you're logged into your Google account and that Google
          Pay is configured.{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://support.google.com/wallet/answer/12059331?hl=en"
          >
            Tap here for more information.
          </a>
        </p>

        {!isFoodTruck && (
          <p>Otherwise select Cashier Checkout and pay at the terminal.</p>
        )}
      </div>
    );
  }
};

export default AndroidCheckoutButton; 

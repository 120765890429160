import React, { useEffect, useState } from "react";
import meta from "../../meta.json";
const appVersion = meta.timestamp;

interface Props {}

interface CacheState {
  loading: boolean;
  isLatestVersion: boolean;
}

const DEFAULT_STATE: CacheState = {
  loading: true,
  isLatestVersion: false,
};

const refreshCacheAndReload = () => {
  console.log("Clearing cache and hard reloading...");
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(function (names) {
      for (let name of names) caches.delete(name);
    });
  }
  // delete browser cache and hard reload
  window.location.reload();
};

const CacheBuster: React.FC<React.PropsWithChildren<Props>> = ({
  children,
}) => {
  const [cacheState, setCacheState] = useState<CacheState>(DEFAULT_STATE);

  useEffect(() => {
    if (!cacheState.isLatestVersion) {
      fetch("/meta.json")
        .then((response) => response.json())
        .then((meta) => {
          const latestVersion = meta.timestamp;
          const currentVersion = appVersion;

          console.log(`currentVersion: ${currentVersion}`);
          console.log(`latestVersion: ${latestVersion}`);

          const shouldForceRefresh = latestVersion !== currentVersion;
          if (shouldForceRefresh) {
            console.log(
              `We have a new version - ${latestVersion}. Should force refresh`
            );
            setCacheState({ loading: false, isLatestVersion: false });
            refreshCacheAndReload();
          } else {
            console.log(
              `You already have the latest version - ${latestVersion}. No cache refresh needed.`
            );
            setCacheState({ loading: false, isLatestVersion: true });
          }
        });
    }
  }, [cacheState]);

  return <div> {children} </div>;
};

export default CacheBuster;

import { Grid } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { ReactNode } from "react";
import "./RestaurantBoardDialogHeader.scss";

interface Props {
  title: string;
  actionArea?: ReactNode;
  onClose: () => void;
}

const RestaurantBoardDialogHeader: React.FC<Props> = ({
  title,
  actionArea,
  onClose,
}) => {
  return (
    <Grid xs={12} item container className="restaurant_dialog_header_container">
      <Grid xs={10} item container>
        <Grid xs={12} item className="title">
          {title}
        </Grid>
        {actionArea && (
          <Grid item xs={12} className="action_area_container">
            {actionArea}
          </Grid>
        )}
      </Grid>
      <Grid xs={2} item container justify="flex-end" alignItems="center">
        <Close className="close_button" onClick={onClose} />
      </Grid>
    </Grid>
  );
};

export default RestaurantBoardDialogHeader;

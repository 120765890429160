import { connect } from "react-redux";
import { State } from "store";
import LogoutComponent from "./LogoutComponent";
import { setLogoutStatusStartedAction } from "actions";

const mapStateToProps = (state: State) => ({
  loginState: state.loginState,
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatchLogout: () => dispatch(setLogoutStatusStartedAction())
});

export const Logout = connect(
  mapStateToProps,
  mapDispatchToProps
)(LogoutComponent);

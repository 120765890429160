import { connect } from 'react-redux';
import { State } from 'store';
import StarterKitComponent from './StarterKitComponent';
import { creatingLeadFromStarterKitStartAction } from 'actions';
import { GrabbiLeadForMarketing } from "types/GrabbiTypes";

const mapStateToProps = (state: State) => ({
  loginState: state.loginState,
  createdLeadFromStarterKitState: state.createdLeadFromStarterKitState,
  createdLeadFromStarterKitError: state.createdLeadFromStarterKitState.createdLeadFromStarterKitError
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatchGetStarted: (leadForMarketing: GrabbiLeadForMarketing) =>
   dispatch(creatingLeadFromStarterKitStartAction(leadForMarketing))
});

export const StarterKit = connect(
  mapStateToProps,
  mapDispatchToProps
)(StarterKitComponent);

import { Box, Typography } from "@material-ui/core";
import { refundTransactionStartAction } from "actions";
import { TransactionRefundPayload } from "actions/transactions";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import GrabbiDialog from "components/Shared/GrabbiDialog/GrabbiDialogComponent";
import GrabbiTable, {
  GrabbiTableColumn,
} from "components/Shared/GrabbiTable/GrabbiTableComponent";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";
import useTransactionHooks from "hooks/TransactionHooks";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "store";
import {
  FulfillTransactionPayload,
  Transaction,
  TransactionItem,
} from "types/GrabbiTypes";
import "./RefundDialogComponent.scss";

interface Props {
  className?: string;
  transaction?: Transaction;
  storeId: number;
  onClose: (transactionId: number) => void;
  refreshCallback: () => void;
}

const RefundDialog: React.FC<Props> = ({
  className,
  transaction,
  storeId,
  refreshCallback,
  onClose,
}) => {
  const open = transaction !== undefined;
  // const [refundAmounts, setRefundAmounts] = useState<
  //   { item: TransactionItem; amount: number }[] | undefined
  // >();

  // useEffect(() => {
  //   if (!refundAmounts) {
  //     setRefundAmounts(
  //       transaction?.transactionItems.map((item) => ({
  //         item,
  //         amount: 0,
  //         max: item.quantity
  //       }))
  //     );
  //   }
  // }, [transaction, refundAmounts]);

  const { enqueueSnackbar } = useSnackbar();
  const { useSetFulfillTransaction } = useTransactionHooks();

  const dispatch = useDispatch();
  const dispatchRefundTransaction = useCallback(
    (payload: TransactionRefundPayload) =>
      dispatch(refundTransactionStartAction(payload)),
    [dispatch]
  );

  const [{ error }, setTransactionFulfilled] = useSetFulfillTransaction();

  const selectedStore = useSelector(
    (state: State) => state.storeList.selectedStore
  );

  const transactionStore = useSelector((state: State) => state.transactions);
  const { refundLoading, refundError, refundStatus } = transactionStore;

  useEffect(() => {
    if (error) {
      console.error(error);
    }
    if (refundError) {
      if (refundError.includes("You can't refund the transaction")) {
        enqueueSnackbar("This transaction cannot be refunded.", {
          variant: "error",
        });
      } else if (refundError.includes("has already been refunded")) {
        enqueueSnackbar("This transaction has already been refunded.", {
          variant: "error",
        });
      } else {
        enqueueSnackbar(refundError, { variant: "error" });
      }
    } else if (refundStatus) {
      enqueueSnackbar("Refund Successful", { variant: "success" });
      if (transaction?.id) {
        refreshCallback();
        onClose(transaction.id);
      }
    }
  }, [error, refundError, refundStatus, transaction, refreshCallback, onClose, enqueueSnackbar]);

  const handleConfirm = async () => {
    if (transaction && selectedStore && selectedStore.id) {
      if (transaction.fulfilled) {
        const payload: FulfillTransactionPayload = {
          id: transaction.id,
          fulfilled: false,
          store: {
            id: storeId,
          },
        };
        await setTransactionFulfilled({
          data: payload,
        });
      }
      dispatchRefundTransaction({
        storeId: selectedStore.id,
        transactionId: transaction.id,
      });
    }
  };

  const handleCancel = () => {
    if (transaction) {
      onClose(transaction.id);
    }
  };

  const TABLE_COLUMNS = [
    new GrabbiTableColumn("Item", [], {
      render: (item: TransactionItem) => item.upc.label,
    }),
    new GrabbiTableColumn("Quantity", ["quantity"]),
  ];

  return (
    <GrabbiDialog
      dialogProps={{
        open,
        onClose,
        className: "refund_dialog_root",
      }}
      dialogContentProps={{
        className: "refund_dialog_content",
      }}
    >
      <Typography className="title">Confrim Refund</Typography>
      {transaction && (
        <GrabbiTable
          className="item_table"
          columns={TABLE_COLUMNS}
          data={transaction?.transactionItems}
        />
      )}
      {!refundLoading && (
        <Box className="action_area">
          <GrabbiButton onClick={handleConfirm}>CONFIRM</GrabbiButton>
          <GrabbiButton onClick={handleCancel}>CANCEL</GrabbiButton>
        </Box>
      )}
      {refundLoading && <LoadingIcon />}
    </GrabbiDialog>
  );
};

export default RefundDialog;

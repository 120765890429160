import { ClickAwayListener, Menu, MenuItem } from "@material-ui/core";
import React, { ReactNode } from "react";
import "./GrabbiMoreActionsComponent.scss";

export interface MoreActionsItem {
  content: ReactNode;
  callback: () => void;
}

interface Props {
  content?: ReactNode;
  actions: MoreActionsItem[];
  className?: string;
}

const GrabbiMoreActions: React.FC<Props> = ({
  content,
  className,
  actions,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <div
        className={`grabbi_more_actions_root ${className ?? ""}`}
        onClick={handleClick}
        aria-controls={open ? "more-action-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        {content ?? THREE_DOT_SVG}
      </div>
      <Menu
        id="more-action-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={open}
        onClose={handleClose}
        classes={{
          paper: "grabbi_more_actions_menu_wrapper_root",
          list: "menu",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        disablePortal
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div className="menu">
            {actions.map((action, index) => {
              return (
                <MenuItem
                  key={`more-actions-item-${index}`}
                  className="action"
                  onClick={() => {
                    action.callback();
                    handleClose();
                  }}
                >
                  {action.content}
                </MenuItem>
              );
            })}
          </div>
        </ClickAwayListener>
      </Menu>
    </React.Fragment>
  );
};

export default GrabbiMoreActions;

const THREE_DOT_SVG = (
  <svg
    width="28"
    height="7"
    viewBox="0 0 28 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 0C1.575 0 0 1.575 0 3.5C0 5.425 1.575 7 3.5 7C5.425 7 7 5.425 7 
  3.5C7 1.575 5.425 0 3.5 0ZM24.5 0C22.575 0 21 1.575 21 3.5C21 5.425 22.575 7
  24.5 7C26.425 7 28 5.425 28 3.5C28 1.575 26.425 0 24.5 0ZM14 0C12.075 0 10.5
  1.575 10.5 3.5C10.5 5.425 12.075 7 14 7C15.925 7 17.5 5.425 17.5 3.5C17.5 1.575
  15.925 0 14 0Z"
      fill="black"
    />
  </svg>
);

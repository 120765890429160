import { sendRequestToResetPasswordStartAction } from "actions";
import { connect } from "react-redux";
import { State } from "store";
import ChangePasswordForgotComponent from "./ChangePasswordForgotComponent";

const mapStateToProps = (state: State) => ({
  resetRequestReason:
    state.sendRequestToResetPasswordState.merchantResetPasswordReason,
  resetLoading: state.sendRequestToResetPasswordState.merchantResetPasswordLoading,
  resetRequestSuccess: state.sendRequestToResetPasswordState.merchantResetPassword,
});

const mapDispatchToProps = (dispatch: any) => ({
  sendRequestToResetPassword: (token: string, email: string, password: string, confirmedPassword: string) =>
    dispatch(sendRequestToResetPasswordStartAction({ token, email, password, confirmedPassword })),
});

export const ChangePasswordForgot = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordForgotComponent);

import { createSelector } from "reselect";
import { State } from "store";

const getState = (state: State) => state;

export const getCustomerState = createSelector(
  [getState],
  (state) => state.customerState
);

export const getMembershipCheckedSelector = createSelector(
  [getCustomerState],
  (state) => state.membershipChecked
);

export const getHasMembershipSelector = createSelector(
  [getCustomerState],
  (state) => state.hasMembership
);
export const getMembershipMenuIdSelector = createSelector(
  [getCustomerState],
  (state) => state.membershipMenuId
);
export const getMembershipTypeSelector = createSelector(
  [getCustomerState],
  (state) => state.membershipType
);
export const getMemberEmailSelector = createSelector(
  [getCustomerState],
  (state) => state.memberEmail
);
export const getExternalCustomerIdSelector = createSelector(
  [getCustomerState],
  (state) => state.externalCustomerId
);
export const getCheckMembershipErrorSelector = createSelector(
  [getCustomerState],
  (state) => state.error
);
export const getMembershipLoading = createSelector(
  [getCustomerState],
  (state) => state.loading
);

import React, { useState } from "react";
import "./GrabbiTextField.scss";
import TextField, { StandardTextFieldProps } from "@material-ui/core/TextField";
import { Box } from "@material-ui/core";
import { Control, FieldValues } from "react-hook-form";

type ColorVariant = "black" | "yellow";

interface Props extends StandardTextFieldProps {
  control?: Control<FieldValues>;
  errorMessage?: string;
  colorVariant?: ColorVariant;
}

const colorVariantClasses: Record<ColorVariant, string> = {
  black: "grabbi_text_field_root_black",
  yellow: "grabbi_text_field_root_yellow",
};

const GrabbiTextField = React.forwardRef<any, Props>((props: Props, ref) => {
  const {
    classes,
    className,
    InputProps,
    control,
    errorMessage,
    disabled,
    colorVariant,
    ...otherProps
  } = props;
  const [forceRender, setForceRender] = useState(false);

  const currentValue =
    control && props.name ? control.getValues(props.name) : otherProps.value;
  const shrinkProp =
    currentValue?.length > 0
      ? {
          shrink: currentValue.length > 0,
        }
      : {};

  return (
    <Box
      className={`text_field_root ${
        colorVariantClasses[colorVariant ?? "yellow"]
      } ${className ?? ""}`}
    >
      {errorMessage && <Box className="text_field_error">{errorMessage}</Box>}
      <TextField
        {...otherProps}
        InputLabelProps={{
          ...props.InputLabelProps,
          classes: {
            ...props.InputLabelProps?.classes,
            root: `text_field_label`,
            focused: `text_field_label_focused`,
            disabled: "text_field_label_disabled",
          },
          disabled,
          ...shrinkProp,
        }}
        {...otherProps}
        variant="filled"
        InputProps={{
          ...InputProps,
          className: `text_field_input ${props?.InputProps?.className}`,
          classes: {
            root: `${props?.InputProps?.classes?.root} ${props?.InputProps?.className}`,
            focused: `text_field_focused`,
            disabled: "text_field_disabled",
          },
          disabled,
          inputRef: ref,
          onChange: (event: any) => {
            setForceRender(!forceRender); //? [REVISIT] Using this to force a rerender (for Material UI TextField label shrink). There is probably a way more elegant way to do this.
            if (props?.InputProps?.onChange) {
              props.InputProps.onChange(event);
            } else if (props?.onChange) {
              props.onChange(event);
            }
          },
        }}
      />
    </Box>
  );
});

export default GrabbiTextField;

import React, { useCallback, useEffect, useState } from "react";
import "./SignUpComponent.scss";
import { Box, Button, Typography } from "@material-ui/core";
import GrabbiTextField from "components/Shared/GrabbiTextField/GrabbiTextField";
import { GrabbiMerchant } from "types/GrabbiTypes";
import { CreatedMerchantState } from "store/merchant/createdMerchantReducer";
import { NavLink, useHistory } from "react-router-dom";
import { CONTEXT } from "shared/scripts/UrlContext";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import {
  NAME_REGEX,
  PHONE_REGEX,
  RFC_5322_EMAIL_REGEX,
  PASSWORD_REGEX,
} from "shared/scripts/Constants";
import AppleSignin from "react-apple-signin-auth";
import { AppleLoginPayload } from "types/GrabbiTypes";
import GoogleLogin from "react-google-login";
import { APPLE_CLIENT_ID, GOOGLE_CLIENT_ID } from "shared/scripts/Constants";
import { LoginState } from "store/signin/loginReducer";

interface Props {
  loginState: LoginState;
  merchant: GrabbiMerchant;
  createdMerchantState: CreatedMerchantState;
  createdMerchantError?: string;
  dispatchSignUp: (merchant: GrabbiMerchant) => void;
  dispatchGoogleLogin: (id_token: string) => void;
  dispatchAppleLogin: (payload: AppleLoginPayload) => void;
}

const SignUpComponent: React.FC<Props> = ({
  loginState,
  merchant,
  createdMerchantState,
  createdMerchantError,
  dispatchSignUp,
  dispatchGoogleLogin,
  dispatchAppleLogin,
}) => {
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    control,
  } = useForm({
    reValidateMode: "onBlur",
    shouldFocusError: true,
  });
  const history = useHistory();
  const [fieldsInitialized, setFieldsInitialized] = useState(false);
  const [hideMerchantInfoDetail, setHideMerchantInfoDetail] = useState<boolean>(true);
  const [hideSignupButton, setHideSignupButton] = useState<boolean>(true);
  const [hideContinueSignupButton, setHideContinueSignupButton] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [loginError, setLoginError] = useState<string | undefined>(undefined);

  const handleContinueSignup = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const USER_EMAIL = getValues("email_address");
    if (RFC_5322_EMAIL_REGEX.test(USER_EMAIL)){
      setHideMerchantInfoDetail(false);
      setHideSignupButton(false);
      setHideContinueSignupButton(true);
    } else {
      enqueueSnackbar("Invalid email addrees: Please resolve error and try again.", {
        variant: "error",
      });
    }
  };

  const appleRedirect = `${window.location.protocol}//${
    window.location.hostname
  }${
    window.location.port === "3000" ? window.location.port : ""
  }/auth/apple/merchant/redirect`;

  const handleGoogleResponse = (response: any) => {
    if (response.tokenObj && response.tokenObj.id_token) {
      dispatchGoogleLogin(response.tokenObj.id_token);
    }
  };

  const handleAppleError = (error: any) => {
    if ((error.error as string).toLocaleLowerCase() !== "popup_closed_by_user") {
      enqueueSnackbar("Error: Apple sign in failed.", { variant: "error" });
    }
  };

  const handleAppleResponse = (response: any) => {
    dispatchAppleLogin({
      ...response.authorization,
      ...response.user,
    });
  };

  const initializeStoreFields = useCallback(() => {
    setValue("first_name", "");
    setValue("last_name", "");
    setValue("email_address", "");
    setValue("merchant_password", "");
    setValue("confirmed_password", "");
    setValue("merchant_phone_number", "");
    setFieldsInitialized(true);
  }, [setFieldsInitialized, setValue]);

  const handleSubmitValid = () => {
    const identity = {
      id: getValues("email_address"),
      password: getValues("merchant_password"),
    };
    const newMerchant: GrabbiMerchant = {
      identity: identity,
      firstName: getValues("first_name"),
      lastName: getValues("last_name"),
      email: getValues("email_address"),
      phone: getValues("merchant_phone_number"),
    };
    dispatchSignUp(newMerchant);
  };

  const handleSubmitError = () => {
    enqueueSnackbar("Please resolve errors and try again.", {
      variant: "error",
    });
  };

  useEffect(() => {
    if (!fieldsInitialized) {
      initializeStoreFields();
    }
  }, [fieldsInitialized, initializeStoreFields]);

  useEffect(() => {
    if (createdMerchantState.createdMerchant) {
      const SUCCESS_MESSAGE = "please check your email to verify your account.";
      history.push({
        pathname: `${CONTEXT}successmessage`,
        state: {
          mainMessage: 'SUCCESS',
          secondMessage: `${SUCCESS_MESSAGE?.toUpperCase()}`,
          buttonText: 'BACK TO LOGIN',
          goTo: `${CONTEXT}login`,
        },
      });
    } else if (createdMerchantError) {
      let errorMessage = createdMerchantError;
      if (
        createdMerchantError
          .toLocaleLowerCase()
          .includes("Record already exists for") ||
        createdMerchantError
          .toLocaleLowerCase()
          .includes(
            "a different object with the same identifier value was already associated with the session"
          )
      ) {
        errorMessage = `Username is already in use, please try another.`;
      }
      enqueueSnackbar(errorMessage, { variant: "error" });
    }
  }, [history, createdMerchantState, createdMerchantError, enqueueSnackbar]);

  useEffect(() => {
    let loginError = "";
    if (loginState.loginError) {
      if (loginState.status === 401 || loginState.status === 403) {
        const serverResponse = loginState.loginError;
        if (serverResponse === "User has no roles assigned!") {
          loginError = "Account not activated. Please check your email.";
        } else {
          loginError = serverResponse;
        }
      } else if (loginState.status === 404) {
        loginError = "Could not connect to login server.".toUpperCase();
      } else if (loginState.status !== 200) {
        loginError = "An unknown error occured.";
      }
      setLoginError(loginError);
    }
  }, [loginState]);

  useEffect(() => {
    if (loginState.loggedIn && loginState.isAdmin) {
      history.push(`${CONTEXT}admin/store-manager`);
    } else if (loginState.loggedIn) {
      history.push(`${CONTEXT}dashboard/activity`);
    }
  }, [history, loginState]);

  return (
    <div className="signup_wrapper">
      <Box className="signup_root">
        <Typography classes={{ root: "signup_title" }}>
         {!hideContinueSignupButton ? 'Sign Up' : 'Finish Signing Up' }
        </Typography>

        <form
          className="signup_form"
          onSubmit={handleSubmit(handleSubmitValid, handleSubmitError)}
        >
          <div className={`${
            !hideContinueSignupButton
              ? "show_continue_signup_button"
              : "hide_continue_signup_button"
              }`}>
            <GrabbiTextField
              ref={register({
                minLength: {
                  value: 6,
                  message: "Must have 6 or more characters.",
                },
                maxLength: {
                  value: 50,
                  message: "Must have 50 or less characters",
                },
                required: {
                  value: true,
                  message: "Please enter email address",
                },
                pattern: {
                  value: RFC_5322_EMAIL_REGEX,
                  message: "Invalid email address",
                },
                //? [REVISIT] Add validation of email address once email server is implemented
              })}
              placeholder="Email Address"
              name="email_address"
              label="Email Address"
              autoComplete="email"
              error={errors.email_address}
              errorMessage={errors?.email_address?.message}
              control={control}
            />
            <div>
              <Button
                classes={{ root: "continue_signup" }}
                onClick={(event) => handleContinueSignup(event)}>
                Continue
              </Button>
            </div>
          </div>

          <div className={`${
            !hideMerchantInfoDetail
              ? "show_merchant_info_details"
              : "hide_merchant_info_details"
              }`}>
            <GrabbiTextField
              ref={register({
                minLength: {
                  value: 2,
                  message: "Must have 2 or more characters.",
                },
                maxLength: {
                  value: 40,
                  message: "Must have 40 or less characters",
                },
                required: {
                  value: true,
                  message: "Please enter first name",
                },
                pattern: {
                  value: NAME_REGEX,
                  message: "First name may only contain letters.",
                },
              })}
              placeholder="First Name"
              name="first_name"
              autoComplete="given-name"
              label="First Name"
              error={errors.first_name}
              errorMessage={errors?.first_name?.message}
              control={control}
            />
            <GrabbiTextField
              ref={register({
                minLength: {
                  value: 2,
                  message: "Must have 2 or more characters.",
                },
                maxLength: {
                  value: 40,
                  message: "Must have 40 or less characters",
                },
                required: {
                  value: true,
                  message: "Please enter last name",
                },
                pattern: {
                  value: NAME_REGEX,
                  message: "Last name may only contain letters.",
                },
              })}
              placeholder="Last Name"
              name="last_name"
              autoComplete="family-name"
              label="Last Name"
              error={errors.last_name}
              errorMessage={errors?.last_name?.message}
              control={control}
            />
            <GrabbiTextField
              ref={register({
                minLength: {
                  value: 1,
                  message: "Must have 1 or more digits",
                },
                maxLength: {
                  value: 17,
                  message: "Must have 17 or less digits",
                },
                required: {
                  value: true,
                  message: "Please enter phone number.",
                },
                pattern: {
                  value: PHONE_REGEX,
                  message: "Invalid phone number",
                },
              })}
              placeholder="Phone Number"
              name="merchant_phone_number"
              autoComplete="tel"
              label="Phone Number"
              error={errors.merchant_phone_number}
              errorMessage={errors?.merchant_phone_number?.message}
              control={control}
            />
            <GrabbiTextField
              ref={register({
                minLength: {
                  value: 8,
                  message: "Must have 8 or more digits",
                },
                maxLength: {
                  value: 128,
                  message: "Must have 128 or less digits",
                },
                required: {
                  value: true,
                  message: "Please enter password",
                },
                validate: {
                  passwordsEqual: (value) =>
                    value === getValues().confirmed_password,
                },
                pattern: {
                  value: PASSWORD_REGEX,
                  message:
                    "Password should contain atleast one number and one special character",
                },
              })}
              placeholder="Password"
              name="merchant_password"
              autoComplete="new-password"
              type="password"
              label="Password"
              error={errors.merchant_password}
              errorMessage={errors?.merchant_password?.message}
              control={control}
            />
            <GrabbiTextField
              ref={register({
                minLength: {
                  value: 8,
                  message: "Must have 8 or more digits",
                },
                maxLength: {
                  value: 128,
                  message: "Must have 128 or less digits",
                },
                required: {
                  value: true,
                  message: "Please Confirm password",
                },
                validate: {
                  passwordsEqual: (value) =>
                    value === getValues().merchant_password,
                },
                pattern: {
                  value: PASSWORD_REGEX,
                  message:
                    "Password should contain atleast one number and one special character",
                },
              })}
              placeholder="Re-enter Password"
              name="confirmed_password"
              autoComplete="new-password"
              type="password"
              label="Re-enter Password"
              error={errors.confirmed_password}
              errorMessage={errors?.confirmed_password?.message}
              control={control}
            />
            {(errors.merchant_password || errors.confirmed_password) &&
              (errors.merchant_password.type === "passwordsEqual" ||
                errors.confirmed_password.type === "passwordsEqual") && (
                <Typography className="signup_form_error">
                  Passwords must match
                </Typography>
              )}

            {loginError && (
              <React.Fragment>
                <Typography className="signup_helper_text_root">
                  {loginError}
                </Typography>
              </React.Fragment>
            )}

            <Typography classes={{ root: "signup_subtext" }}>
              By continuing, I confirm that I am 18+ and agree to Grabbi's{" "}
              <NavLink
                to={`${CONTEXT}terms-of-service`}
                className="signup_subtext_link"
              >
                Terms of Service
              </NavLink>{" "}
              and{" "}
              <NavLink
                to={`${CONTEXT}privacy-policy`}
                className="signup_subtext_link"
              >
                Privacy Policy
              </NavLink>
              .
            </Typography>
          </div>
          <div className={`${
            !hideSignupButton
              ? "show_signup_button"
              : "hide_signup_button"
              }`}>
            <Button type="submit" classes={{ root: "signup_submit_button" }}>
              SIGN UP
            </Button>
          </div>
        </form>
        <div className={`${
            !hideContinueSignupButton
              ? "show_continue_signup_button"
              : "hide_continue_signup_button"
              }`}>
          <Box className="seperator">
            <div />
            <Typography className="bold">OR</Typography>
            <div />
          </Box>

          <Box className="social_sign_in">
            <AppleSignin
              authOptions={{
                clientId: APPLE_CLIENT_ID,
                scope: "email name",
                redirectURI: appleRedirect,
                state: (Math.random() * 1000000).toString(),
                nonce: (Math.random() * 1000000).toString(),
                responseType: "form_post",
                usePopup: true,
              }}
              onSuccess={handleAppleResponse}
              onError={handleAppleError}
              render={(props: any) => (
                <Box
                  className="apple_sign_in social_button"
                  onClick={props.onClick}
                >
                  <div
                    className="apple_icon"
                    style={{
                      backgroundImage: `url(assets/apple-icon.svg)`,
                    }}
                  />
                  <Typography className="text">Sign up with Apple</Typography>
                </Box>
              )}
            />
            <GoogleLogin
              className="google_sign_in social_button"
              clientId={GOOGLE_CLIENT_ID}
              onSuccess={handleGoogleResponse}
              onFailure={handleGoogleResponse}
              cookiePolicy={"single_host_origin"}
              buttonText={"Sign Up with Google"}
            />
          </Box>
          <Box className="login_link_container">
              <div className="have_an_account_text">
                Have an account?
                <NavLink
                  className="grabbi_demo_body_text_with_link login_link"
                  to={`${CONTEXT}login`}
                >
                  &nbsp;{"LOG IN".toUpperCase()}
                </NavLink>
              </div>
            </Box>
        </div>
      </Box>
    </div>
  );
};

export default SignUpComponent;

import React from "react";
import "components/Shared/GrabbiButton/GrabbiButtonComponent.scss";
import { ButtonProps, Button } from "@material-ui/core";

type ColorVariant = "purple" | "black" | "blue" | "white" | "gray" | "yellow";
type ShapeVariant = "rectangle" | "rounded";

interface Props extends ButtonProps {
  colorVariant?: ColorVariant;
  shapeVariant?: ShapeVariant;
}

const colorVariantClasses: Record<ColorVariant, string> = {
  black: "grabbi_button_root_black",
  purple: "grabbi_button_root_purple",
  blue: "grabbi_button_root_blue",
  white: "grabbi_button_root_white",
  gray: "grabbi_button_root_gray",
  yellow: "grabbi_button_root_yellow",
};

const shapeVariantClasses: Record<ShapeVariant, string> = {
  rectangle: "grabbi_button_root_rectangle",
  rounded: "grabbi_button_root_rounded",
};

const GrabbiButton: React.FC<Props> = ({
  className,
  classes,
  children,
  colorVariant,
  shapeVariant,
  ...otherProps
}) => {
  const { disabled, ...otherClasses } = classes ?? {};
  const colorVariantClass = colorVariantClasses[colorVariant ?? "purple"];
  const shapeVariantClass = shapeVariantClasses[shapeVariant ?? "rectangle"];
  return (
    <Button
      className={`${
        otherProps.variant === "outlined"
          ? "grabbi_button_root_outlined"
          : "grabbi_button_root"
      } ${
        otherProps.variant !== "outlined" ? colorVariantClass : ""
      } ${shapeVariantClass} ${className}`}
      classes={{
        disabled: `${disabled} grabbi_button_disabled`,
        ...otherClasses,
      }}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

export default GrabbiButton;

import { connect } from "react-redux";
import { State } from "store";
import AddStoreComponent from "./AddStoreComponent";
import { GrabbiStore } from "types/GrabbiTypes";
import { addStoreStartAction } from "actions";

const mapStateToProps = (state: State) => ({
  loginState: state.loginState,
  storeState: state.storeList,
  storeCreatedError: state.storeList.storeCreatedError
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatchAddStore: (store: Omit<GrabbiStore, "id">) =>
   dispatch(addStoreStartAction(store))
});

export const AddStore = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddStoreComponent);

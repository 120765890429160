import { get } from "./api";

export const getCountries = () => {
  const endpoint = "restful-services/country";

  const thenHandler = (response: any) => {
    return response.data;
  };

  const errorHandler = (error: any) => {
    return {};
  };

  const finallyHandler = () => {
    return {};
  };

  return get(endpoint, {}, {}, thenHandler, errorHandler, finallyHandler);
};


export const getStateCodes = (countryCode: string) => {
  const endpoint = `restful-services/public/states/${countryCode}`;

  const thenHandler = (response: any) => {
    return response.data;
  };

  const errorHandler = (error: any) => {
    return undefined;
  };

  const finallyHandler = () => {
    return undefined;
  };

  return get(endpoint, {}, {}, thenHandler, errorHandler, finallyHandler);
};
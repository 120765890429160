import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import GrabbiDialog from "components/Shared/GrabbiDialog/GrabbiDialogComponent";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";
import OrderCode from "components/Shared/OrderCode/OrderCodeComponent";
import useTransactionHooks from "hooks/TransactionHooks";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { FulfillTransactionPayload, Transaction } from "types/GrabbiTypes";
import RestaurantBoardDialogHeader from "../RestaurantBoardDialogHeader/RestaurantBoardDialogHeader";
import "./CloseTabDialogComponent.scss";

interface Props {
  open: boolean;
  onClose: () => void;
  refreshCallback: () => void;
  storeId: number;
  transaction: Transaction;
}

const CloseTabDialog: React.FC<Props> = ({
  open,
  onClose,
  refreshCallback,
  storeId,
  transaction,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleOnClose = () => {
    onClose();
  };

  const { useSetFulfillTransaction } = useTransactionHooks();

  const [{ loading, error }, checkoutTransaction] = useSetFulfillTransaction();

  useEffect(() => {
    if (error) {
      enqueueSnackbar("Error: Failed to close transaction", {
        variant: "error",
      });
    }
  }, [error, enqueueSnackbar]);

  const createPayload = () => {
    const payload: FulfillTransactionPayload = {
      id: transaction.id,
      fulfilled: true,
      store: {
        id: storeId,
      },
    };
    return payload;
  };

  const handleCloseTab = async () => {
    await checkoutTransaction({
      data: createPayload(),
    });
    enqueueSnackbar(`Transaction ${transaction.orderCode} closed`, {
      variant: "success",
    });
    refreshCallback();
    handleOnClose();
  };

  return (
    <GrabbiDialog
      dialogProps={{
        open,
        classes: { paper: "close_tab_dialog_root resturant_dashboard_dialog" },
      }}
      dialogContentProps={{ classes: { root: "close_tab_dialog_content" } }}
      dialogHeader={
        <RestaurantBoardDialogHeader
          title="Close Tab"
          onClose={handleOnClose}
        />
      }
      dialogFooter={
        <div className="footer_container">
          {loading ? (
            <LoadingIcon />
          ) : (
            <React.Fragment>
              <GrabbiButton className="action_button" onClick={handleOnClose}>
                No
              </GrabbiButton>
              <GrabbiButton className="action_button" onClick={handleCloseTab}>
                Yes
              </GrabbiButton>
            </React.Fragment>
          )}
        </div>
      }
    >
      <div className="confirmation_text_header_container">
        Are you sure you want to close this tab?
      </div>
      <OrderCode className="order_code_container" transaction={transaction} />
      <div className="confirmation_text_info_container">
        All transaction items will be closed.
      </div>
    </GrabbiDialog>
  );
};

export default CloseTabDialog;

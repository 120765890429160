import React from "react";
import "./App.scss";
import { Routes } from "components/Routes/RoutesContainer";
import { SnackbarProvider } from "notistack";
import { QueryParamProvider } from "use-query-params";
import CacheBuster from "components/CacheBuster/CacheBusterComponent";
import ReactGA from "react-ga";
import AxiosProvider from "components/Shared/AxiosProvider/AxiosProvider";
import { MerchandiseSelectorProvider } from "components/ResturantBoard/MerchandiseSelector/MerchandiseSelectorComponent";
import LoginContextProvider from "hooks/CustomerLogin";
import { MerchandiseProvider } from "hooks/MerchandiseHooks";

const App: React.FC = () => {
  if (window.location.hostname === "grabbishop.com") {
    ReactGA.initialize("UA-192612080-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  return (
    <div className="App">
      <CacheBuster>
        <QueryParamProvider>
          <SnackbarProvider
            maxSnack={1}
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
            classes={{
              variantInfo: "grabbi-snackbar-info",
              variantError: "grabbi-snackbar-error",
              variantSuccess: "grabbi-snackbar-success",
              variantWarning: "grabbi-snackbar-warning",
            }}
          >
            <LoginContextProvider>
              <AxiosProvider>
                <MerchandiseProvider>
                  <MerchandiseSelectorProvider>
                    <Routes />
                  </MerchandiseSelectorProvider>
                </MerchandiseProvider>
              </AxiosProvider>
            </LoginContextProvider>
          </SnackbarProvider>
        </QueryParamProvider>
      </CacheBuster>
    </div>
  );
};

export default App;

import { connect } from "react-redux";
import { State } from "store";
import HeaderComponent from "./HeaderComponent";
import { getMerchantStartAction, getStoreListStartAction } from "actions";

const mapDispatchToProps = (dispatch: any) => ({
  getMerchantDispatch: () => dispatch(getMerchantStartAction()),
  getStoreListDispatch: (id: number) => dispatch(getStoreListStartAction(id)),
});

const mapStateToProps = (state: State) => ({
  loginState: state.loginState,
  loadingStores: state.storeList.loadingStores,
  storesChecked: state.storeList.storesChecked,
  merchant: state.merchantStores.merchant,
  loadingMerchant: state.merchantStores.loadingMerchant
});

export const Header = connect(
  mapStateToProps, mapDispatchToProps
)(HeaderComponent);

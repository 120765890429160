import { sortBy, values } from "lodash";
import React from "react";
import { formatCurrency } from "shared/scripts/Constants";
import { GrabbiMerchandise, GrabbiTopping } from "types/GrabbiTypes";
import {
  groupChoicesNeeded,
  ToppingGroupState,
  useMerchSelection,
} from "../MerchandiseSelectorComponent";
import ToppingChip from "./ToppingChip/ToppingChipComponent";
import "./ToppingSelectorComponent.scss";

interface Props {
  className?: string;
  merch: GrabbiMerchandise;
  toggleTopping: (topping: GrabbiTopping) => void;
  toppingGroupStates: ToppingGroupState[];
  readOnly?: boolean;
}

const ToppingSelector: React.FC<Props> = ({
  className,
  merch,
  toggleTopping,
  toppingGroupStates,
  readOnly,
}) => {
  const { store } = useMerchSelection();
  const sortedToppingGroupStates = sortBy(
    toppingGroupStates,
    "grouping"
  ).reverse();
  return (
    <div className={`topping_selector_root ${className ?? ""}`}>
      {sortedToppingGroupStates
        .filter(
          (toppingGroup) =>
            values(toppingGroup.toppingStates).filter(
              (topping) => topping.checked
            ).length > 0 || !readOnly
        )
        .map((toppingGroup) => {
          const toppingStateValues = values(toppingGroup.toppingStates);
          const choicesNeeded = groupChoicesNeeded(toppingGroup);
          const invalidGroupState = choicesNeeded > 0;
          return (
            <div
              className={`topping_group ${invalidGroupState ? "invalid" : ""}`}
              key={`topping-group-${merch.id}-${toppingGroup.grouping}`}
            >
              {toppingGroup.grouping === "" ? (
                <span>Additional Optional Toppings</span>
              ) : (
                <React.Fragment>
                  <span className="group_caption">
                    {toppingGroup.groupRequired ? "Required" : "Optional"}{" "}
                    (Choose {toppingGroup.groupCount})
                  </span>
                </React.Fragment>
              )}
              <div
                className={`topping_chip_container ${
                  toppingGroup.groupRequired ? "required" : "optional"
                }`}
              >
                {toppingStateValues
                  .filter((item) => item.checked || !readOnly)
                  .map((toppingState) => {
                    const { topping, checked } = toppingState;
                    return (
                      <div
                        className="topping_chip_wrapper flex-space-between-center p-x-2"
                        key={`topping-chip-wrapper-${merch.id}-${toppingState.topping.toppingUpc.upcNumber}`}
                      >
                        <ToppingChip
                          topping={topping}
                          disabled={
                            toppingGroup.canAddMore === false && !checked
                          }
                          isChecked={checked}
                          onClick={
                            !readOnly ? () => toggleTopping(topping) : undefined
                          }
                        />
                        {merch.dependant && (
                          <span className="topping_price_container">
                            {formatCurrency(
                              topping.price,
                              store?.currency ?? "USD"
                            )}
                          </span>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ToppingSelector;

import _ from "lodash";
import React from "react";
import {
  Flyout,
  FlyoutProps,
  LineSegment,
  LineSegmentProps,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import "./LineChart.scss";

interface Props {
  data: {
    x: any;
    y: number;
  }[];
  className?: string;
}

const LineChart: React.FC<Props> = ({ data, className }) => {
  const CustomLine: React.FC<LineSegmentProps> = (props: LineSegmentProps) => {
    return <LineSegment className="custom_cursor_line" {...props} />;
  };

  const CustomFlyout: React.FC<FlyoutProps> = (props: any) => {
    const { x, y, datum } = props;
    const label = datum ? datum["x"] : "";
    return (
      <React.Fragment>
        <Flyout {...props} />
        <circle
          cx={x}
          cy={y}
          r={4}
          stroke="black"
          strokeWidth={1.25}
          fill="white"
        />
        <CustomLine x1={x} y1={248} x2={x} y2={(y ?? 0) + 5} />
        <VictoryLabel
          text={label}
          textAnchor="middle"
          verticalAnchor="middle"
          x={x}
          y={261}
          style={{
            fontSize: 8,
            fontWeight: 600,
            fontFamily: "LemonMilkBold",
            fill: "white",
          }}
          backgroundStyle={{
            fill: "black",
          }}
          backgroundComponent={<rect ry="8" rx="8" />}
          backgroundPadding={{ top: 4, left: 5, right: 6, bottom: 5 }}
        />
      </React.Fragment>
    );
  };

  const maxY = Math.max(...data.map((item) => item.y));

  const maxYDomain = maxY + Math.floor(maxY / 4);

  const scatterArray = data.map((item, index) => {
    return _.omit(item, "label");
  });

  return (
    <VictoryChart
      domainPadding={{ x: 20 }}
      containerComponent={
        <VictoryVoronoiContainer
          className={`line_chart_root ${className ?? ""}`}
          voronoiDimension="x"
        />
      }
    >
      <VictoryAxis
        dependentAxis
        crossAxis={false}
        style={{
          axis: {
            strokeWidth: 5,
          },
          tickLabels: {
            fontSize: 8,
            fontWeight: 600,
            fontFamily: "LemonMilkBold",
          },
        }}
        domain={{ y: [0, maxYDomain] }}
        tickFormat={(tick: any) => {
          return `$${tick.toLocaleString()}`;
        }}
      />
      <VictoryAxis
        style={{
          axis: {
            strokeWidth: 5,
          },
        }}
        tickLabelComponent={
          <VictoryLabel
            style={{
              fontSize: 8,
              fontWeight: 600,
              fontFamily: "LemonMilkBold",
              fill: "black",
            }}
          />
        }
      />

      <VictoryLine
        style={{
          data: { stroke: "#000000" },
          labels: { color: "#ffffff" },
        }}
        labelComponent={
          <VictoryTooltip
            cornerRadius={2}
            pointerLength={1}
            pointerWidth={4}
            centerOffset={{ x: 0, y: -2 }}
            flyoutComponent={<CustomFlyout />}
            dy={-7}
            style={{
              paddingLeft: 12,
              paddingRight: 12,
              fontSize: 6,
              fontFamily: "LemonMilkBold",
              fill: "#ffffff",
            }}
            flyoutStyle={{
              fill: "#2F89FC",
              stroke: "none",
            }}
          />
        }
        data={data}
      />
      <VictoryScatter
        data={scatterArray}
        labels={[""]}
        style={{
          data: {
            fill: "#fff100",
            stroke: "#000000",
            strokeWidth: 0.75,
          },
        }}
      />
    </VictoryChart>
  );
};

export default LineChart;

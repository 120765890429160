import { useAxiosContext } from "components/Shared/AxiosProvider/AxiosProvider";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import GrabbiDialog from "components/Shared/GrabbiDialog/GrabbiDialogComponent";
import GrabbiDropdown from "components/Shared/GrabbiDropdown/GrabbiDropdown";
import GrabbiTextField from "components/Shared/GrabbiTextField/GrabbiTextField";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";
import OrderCode from "components/Shared/OrderCode/OrderCodeComponent";
import ToppingTag from "components/Shared/ToppingTag/ToppingTagComponent";
import useTransactionHooks from "hooks/TransactionHooks";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { State } from "store";
import {
  CancelTransactionItemPayload,
  GrabbiStoreAttendant,
  Transaction,
  TransactionItem,
  UpdateTransactionPayload,
  UpdateTransactionPayloadForAttendant,
} from "types/GrabbiTypes";
import RestaurantBoardDialogHeader from "../RestaurantBoardDialogHeader/RestaurantBoardDialogHeader";
import "./EditTableDialogComponent.scss";

interface Props {
  open: boolean;
  onClose: () => void;
  refreshCallback: () => void;
  transactions?: Transaction[];
}

const EditTableDialog: React.FC<Props> = ({
  open,
  transactions,
  refreshCallback,
  onClose,
}) => {
  const tableNumber = transactions?.[0]?.tableNumber;
  const [showChangeTable, setShowChangeTable] = useState(false);

  const { useAxios } = useAxiosContext();
  const [listOfStoreAttendants, setListOfStoreAttendants] =
    useState<GrabbiStoreAttendant[]>();
  const [selectedAttendant, setSelectedAttendant] = useState<number | null>();

  const { enqueueSnackbar } = useSnackbar();
  const [confirmCancel, setConfirmCancel] =
    useState<{ transaction: Transaction; transactionItem: TransactionItem }>();

  const { register, handleSubmit, errors, getValues, control } = useForm({
    shouldFocusError: true,
    reValidateMode: "onBlur",
  });

  const { useCancelTransactionItem, useUpdateTransaction } =
    useTransactionHooks();

  //Cancel Transaction Item - Start
  const selectedStore = useSelector(
    (state: State) => state.storeList.selectedStore
  );

  const storeId = selectedStore?.id;

  const [
    { loading: loadingUpdateTransaction, error: errorUpdateTransaction },
    updateTransaction,
  ] = useUpdateTransaction();

  const handleOnClose = () => {
    setConfirmCancel(undefined);
    onClose();
  };

  const handleSubmitTableValid = async () => {
    const tableNumber = getValues("table_number");
    if (transactions && storeId !== undefined) {
      for (const transaction of transactions) {
        const payload: UpdateTransactionPayload = {
          id: transaction.id,
          store: {
            id: storeId,
          },
          tableNumber,
        };
        await updateTransaction({
          data: payload,
        });
      }
      refreshCallback();
      handleOnClose();
    }
  };

  const handleSubmitTableError = () => {
    enqueueSnackbar("Please resolve errors and try again.", {
      variant: "error",
    });
  };

  const [{ loading, error }, cancelTransactionItems] =
    useCancelTransactionItem();


  const [{ data: storeAttendants }, getStoreAttendants] = useAxios<
    GrabbiStoreAttendant[]
  >({
    url: `/restful-services/store/${storeId}/attendants`,
    method: "GET",
  });


  useEffect(() => {
    if (!storeAttendants && storeId !== 0) {
      getStoreAttendants();
    }
  }, [getStoreAttendants, storeAttendants, storeId]);

  useEffect(() => {
    if (storeAttendants && storeAttendants.length > 0) {
      setListOfStoreAttendants([...storeAttendants]);
      setSelectedAttendant(transactions?.[0]?.attendant ? transactions?.[0]?.attendant.id : null);
    }
  }, [storeAttendants, transactions]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar("Error: Failed to cancel transaction item", {
        variant: "error",
      });
    }
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    if (errorUpdateTransaction) {
      enqueueSnackbar("Error: Failed to update transaction", {
        variant: "error",
      });
    }
  }, [errorUpdateTransaction, enqueueSnackbar]);

  const handleCancelTransactionItems = (
    transactionId: number,
    transactionItemId: number
  ) => {
    if (storeId) {
      const data: CancelTransactionItemPayload = {
        transactionId,
        storeId,
        cancelOrderItems: [{ transactionItemId }],
      };

      cancelTransactionItems({
        data,
      }).then((data) => {
        enqueueSnackbar("Transaction Item Cancelled", { variant: "success" });
        refreshCallback();
        handleOnClose();
      });
    }
  };

  const setTheAttendant = async (index: number) => {
    if(listOfStoreAttendants && listOfStoreAttendants.length > 0) {
      const attendantId = listOfStoreAttendants[index].id;
      setSelectedAttendant(index);
      if (transactions && storeId !== undefined) {
        for (const transaction of transactions) {
          const payload: UpdateTransactionPayloadForAttendant = {
            id: transaction.id,
            store: {
              id: storeId,
            },
            attendant: {
              id: attendantId ? attendantId : 0
            }
          };
          await updateTransaction({
            data: payload,
          });
        }
        refreshCallback();
        handleOnClose();
      }
    }
  }

  //Cancel Transaction Item - Finish

  const showLoading = loading || loadingUpdateTransaction;

  return (
    <GrabbiDialog
      dialogProps={{
        open,
        classes: { paper: "edit_table_dialog_root resturant_dashboard_dialog" },
      }}
      dialogContentProps={{ classes: { root: "edit_table_dialog_content" } }}
      dialogHeader={
        <RestaurantBoardDialogHeader
          title={
            confirmCancel
              ? "Delete Item"
              : tableNumber !== undefined
              ? `Edit Table ${tableNumber}`
              : `Edit No Table`
          }
          actionArea={
            !showChangeTable ? (
              <div className="header_buttons_container">
                <div className="change_table_button_container">
                  <GrabbiButton
                    classes={{
                      root: "change_table_button",
                    }}
                    variant="outlined"
                    onClick={() => {
                      setShowChangeTable(!showChangeTable);
                    }}
                  >
                    Change Table
                  </GrabbiButton>
                </div>
                {(listOfStoreAttendants !== undefined && listOfStoreAttendants.length > 0) && (
                  <div>
                    <GrabbiDropdown
                      label="Select Attendant"
                      className="store_attendant"
                      handleChange={(index) => setTheAttendant(index)}
                      options={listOfStoreAttendants.map((attendant) => attendant.firstName +' '+ attendant.lastName)}
                      selectedIndex={listOfStoreAttendants.findIndex((attendant) => attendant.id === selectedAttendant)}
                      placeholder="Select Attendant"
                    />
                  </div>
                )}
              </div>
            ) : undefined
          }
          onClose={handleOnClose}
        />
      }
      dialogFooter={
        showLoading ? (
          <div className="loading_container">
            <LoadingIcon />
          </div>
        ) : showChangeTable ? (
          <div className="footer_container">
            <GrabbiButton
              type="submit"
              form="table_number_form"
              variant="outlined"
              className="move_table_button"
            >
              Move Table
            </GrabbiButton>
            <GrabbiButton
              onClick={() => setShowChangeTable(false)}
              variant="outlined"
              className="move_table_button"
            >
              Cancel
            </GrabbiButton>
          </div>
        ) : (
          confirmCancel && (
            <React.Fragment>
              {
                <div className="footer_container">
                  <GrabbiButton
                    variant="outlined"
                    className="confirm_button"
                    onClick={() => setConfirmCancel(undefined)}
                  >
                    No
                  </GrabbiButton>
                  <GrabbiButton
                    variant="outlined"
                    className="confirm_button"
                    onClick={() =>
                      handleCancelTransactionItems(
                        confirmCancel.transaction.id,
                        confirmCancel.transactionItem.id
                      )
                    }
                  >
                    Yes
                  </GrabbiButton>
                </div>
              }
            </React.Fragment>
          )
        )
      }
    >
      {showChangeTable ? (
        <form
          id="table_number_form"
          className="change_table_form"
          autoComplete="off"
          onSubmit={handleSubmit(
            handleSubmitTableValid,
            handleSubmitTableError
          )}
        >
          <GrabbiTextField
            className="table_number_field"
            type="tel"
            label={"Table Number"}
            control={control}
            placeholder="Table Number"
            name="table_number"
            error={errors.table_number !== undefined}
            errorMessage={errors?.table_number?.message}
            ref={register({
              maxLength: {
                value: 16,
                message: "Cannot exceed 16 characters",
              },
              required: {
                value: true,
                message: "Please enter table number",
              },
            })}
          />
        </form>
      ) : (
        confirmCancel === undefined &&
        transactions &&
        transactions?.length > 0 && (
          <React.Fragment>
            {transactions?.map((transaction) => {
              return (
                <React.Fragment key={`edit-transaction-row-${transaction.id}`}>
                  <div className="order_header_container">
                    <OrderCode transaction={transaction} />
                  </div>
                  <div>
                    {transaction.transactionItems.map((transactionItem) => {
                      return (
                        <div
                          className="transaction_item_container"
                          key={`transaction-item-${transactionItem.id}`}
                        >
                          <div className="title_container">
                            <div>
                              <span className="quantity">
                                {transactionItem.quantity}
                              </span>
                              <span className="label">
                                {transactionItem.upc.label}
                              </span>
                            </div>
                          </div>
                          <div className="toppings_container">
                            {transactionItem.toppings.map((topping) => {
                              return (
                                <ToppingTag
                                  key={`topping-tag-${topping.id}`}
                                  topping={topping}
                                />
                              );
                            })}
                          </div>
                          <div
                            className="delete_button"
                            onClick={() =>
                              setConfirmCancel({
                                transaction,
                                transactionItem,
                              })
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </React.Fragment>
              );
            })}
          </React.Fragment>
        )
      )}
      {confirmCancel && (
        <div className="confirmation_container">
          <div className="confirmation_large_text_container">
            Are you sure you want to delete this item?
          </div>
          <div className="confirmation_small_text_container">
            Once you delete this item, it cannot be undone.
          </div>
        </div>
      )}
    </GrabbiDialog>
  );
};

export default EditTableDialog;

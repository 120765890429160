import { Box, Typography } from "@material-ui/core";
import React from "react";
import "./AnalyticsComponent.scss";
import LineChart from "./Shared/LineChart/LineChart";
import profitDataJson from "./Data/11-profit_weekday.json";
import pairingsDataJson from "./Data/12-pairings.json";
import { longWeekdayToShort } from "./Shared/constants";
import ProductPairingDisplay, {
  PairingData,
  TipData,
} from "./Shared/ProductPairingDisplay/ProductPairingDisplay";
import TimeSeriesChart from "./Shared/TimeSeriesChart/TimeSeriesChart";
import hotItemsJson from "./Data/13-hot_items.json";
import BarChart from "./Shared/BarChart/BarChart";
import salesVolumeJson from "./Data/21-sales_volume.json";
import CardSelector, {
  CardData,
} from "./Shared/CardSelector/CardSelectorComponent";
import hotSellerJson from "./Data/23-hot_sellers.json";
import { VictoryData } from "types/GrabbiTypes";
import HotSellerChart from "./Shared/HotSellerChart/HotSellerChartComponent";
import Visitors from "./Shared/Visitors/VisitorsComponent";
import visitorJson from "./Data/31-demo_data.json";
import { DemoCardProps } from "./Shared/DemoCard/DemoCardComponent";
import HolidaysActivity from "./Shared/HolidayActivity/HolidayActivityComponent";
import holidayDataJson from "./Data/32-holidays.json";

const AnalyticsComponent = () => {
  return (
    <Box className="analytics_component_root">
      <Box className="content_aligned padded">
        <Box className="title_area">
          <Typography className="title">PROFITABILITY</Typography>
        </Box>

        <LineChart data={profitData} className="chart" />
      </Box>
      <ProductPairingDisplay
        className="product_pairings"
        title="Smart Product Pairings"
        dataSet={pairingsDataJson}
      />
      <Box className="content_aligned padded improve_profits">
        <Box className="title_area">
          <Typography className="title">Ways to improve profits</Typography>
          <CardSelector data={improveData} />
        </Box>
      </Box>
      <Box className="content_aligned padded customer_favs">
        <Box className="title_area">
          <Typography className="title ">Customer Favorites</Typography>
          <Typography className="subtitle">
            Hot sellers based on time of day
          </Typography>
        </Box>
        <TimeSeriesChart data={hotItemsJson} className="chart" />
      </Box>
      <Box className="content_aligned sales padded">
        <Box className="title_area">
          <Typography className="title">Sales Volume</Typography>
          <Typography className="subtitle">Daily Total Transactions</Typography>
        </Box>
        <BarChart data={salesData} className="chart" />
      </Box>
      <ProductPairingDisplay
        tip={tipData}
        data={pairData}
        showTip={true}
        className="consider_pairing"
        vennDiagramProps={{
          circleProps: {
            circle1Props: {
              style: {
                backgroundColor: "#00a22b",
              },
            },
            circle2Props: {
              style: {
                backgroundColor: "#01a3ee",
              },
            },
          },
        }}
      />
      <Box className="content_aligned padded top_seller">
        <Box className="title_area">
          <Typography className="title">Top Sellers</Typography>
        </Box>

        <HotSellerChart
          data={hotSellerJson}
          className="chart top_seller_chart"
        />
      </Box>

      <Box className="content_aligned padded visitors">
        <Box className="title_area">
          <Typography className="title centered_text">Visitors</Typography>
        </Box>
        <Visitors className="visitors_chart" demoCards={visitorDemoData} />
      </Box>

      <Box className="content_aligned holidays">
        <Box className="title_area">
          <Typography className="title centered_text">UPCOMING HOLIDAYS</Typography>
        </Box>
        <HolidaysActivity data={holidayDataJson} className="holiday_activity padded"/>
      </Box>
    </Box>
  );
};

export default AnalyticsComponent;

interface ProfitData {
  day: string;
  profit: number;
}

interface SalesData {
  day: string;
  sales_volume: number;
}

const profitData: VictoryData[] = profitDataJson.map((row: ProfitData) => {
  const fixedNumber = row.profit.toFixed(2);
  return {
    x: longWeekdayToShort(row.day),
    y: parseFloat(fixedNumber),
    label: `$${fixedNumber}`,
  };
});

const salesData: VictoryData[] = salesVolumeJson.map((row: SalesData) => {
  const fixedNumber = row.sales_volume.toFixed(2);
  return {
    x: longWeekdayToShort(row.day),
    y: parseFloat(fixedNumber),
    label: `$${fixedNumber}`,
  };
});

// ? [REVISIT] Placeholder
const placeholderCards: CardData[] = [
  {
    text: "Make beer booze wednesdays!",
  },
  {
    text: "Chips goes best with bread wine",
  },
  {
    text: "Heinekin is a hot item!",
  },
  {
    text: "Chips goes best with bread wine",
  },
];

// ? [REVISIT] Placeholder
const placeholderCards2: CardData[] = [
  {
    text: "Make beer booze wednesdays! 2",
  },
  {
    text: "Chips goes best with bread wine 2",
  },
  {
    text: "Heinekin is a hot item! 2",
  },
  {
    text: "Chips goes best with bread wine 2",
  },
];

// ? [REVISIT] Placeholder
const improveData = {
  "What to promote": placeholderCards,
  "Boost slow days": placeholderCards2,
};

const pairData: PairingData = {
  antecedents: "American Spirit Yellowbox",
  consequents: "Starbucks 9.5oz coffee",
};

const tipData: TipData = {
  title: "Consider Pairing There two Items",
  problem: "Monday's are your lowest days.",
  solution: "Run a creative campaign to increase sales volume",
};

const visitorDemoData: DemoCardProps[] = [
  {
    textSections: ["Largest Age Group is", visitorJson["largest age group"]],
    dataPointIndex: 1,
    primaryColor: "#00A22B",
    secondaryColor: "#ffffff",
    iconUrl: "/assets/analytics/woman-icon.svg",
  },
  {
    textSections: [
      "Largest ethnic group is",
      visitorJson["largest ethnic group"],
    ],
    dataPointIndex: 1,
    primaryColor: "#2F89FC",
    secondaryColor: "#ffffff",
    iconUrl: "/assets/analytics/family-icon.svg",
  },
  {
    textSections: [
      "Average income is",
      `${
        !isNaN(visitorJson["average income"])
          ? `$${(visitorJson["average income"] / 1000)
              .toPrecision(2)
              .toString()}K`
          : "NAN"
      }`,
      "per household",
    ],
    dataPointIndex: 1,
    primaryColor: "#D32479",
    secondaryColor: "#ffffff",
    iconUrl: "/assets/analytics/income-icon.svg",
  },
];

import { BaseAction, actionIds } from "actions";

export type MerchantResetPasswordState = {
  merchantResetPassword: boolean;
  merchantResetPasswordReason?: string;
  merchantResetPasswordLoading: boolean;
};

export const merchantResetPasswordReducer = (
  state: MerchantResetPasswordState = {
    merchantResetPassword: false,
    merchantResetPasswordLoading: false,
  },
  action: BaseAction
) => {
  switch (action.type) {
    case actionIds.RESET_PASSWORD_START:
      return {
        ...state,
        merchantResetPassword: false,
        merchantResetPasswordReason: undefined,
        merchantResetPasswordLoading: true,
      };
    case actionIds.RESET_PASSWORD_COMPLETED:
      return {
        ...state,
        merchantResetPassword: true,
        merchantResetPasswordReason: action.payload.reason,
        merchantResetPasswordLoading: false,
      };
    case actionIds.RESET_PASSWORD_FAILED:
      return {
        ...state,
        merchantResetPassword: false,
        merchantResetPasswordReason: action.payload.reason,
        merchantResetPasswordLoading: false,
      };
  }
  return state;
};

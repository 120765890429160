import React from 'react';
import './NoStoresRegisteredComponent.scss';
import { Box, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { CONTEXT } from "shared/scripts/UrlContext";

interface Props {}

const NoStoresRegistered: React.FC<Props> = () => {
  const history = useHistory();

  const handleAddStore = () => {
    history.push(`${CONTEXT}addstore`); //! [REVISIT] Replace with actual add store route
  };

  return (
    <Box className="no_stores_root">
      <Box className="no_stores_header">
        No stores registered
      </Box>
      <Button
        classes={{ root: "no_store_add_store_button" }}
        onClick={() => {
          handleAddStore();
        }}
      >
        ADD STORE
      </Button>
    </Box>
  );
}

export default NoStoresRegistered;
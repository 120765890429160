import { Box, Typography } from "@material-ui/core";
import React from "react";
import "./DemoCardComponent.scss";

export interface DemoCardProps {
  textSections: string[];
  dataPointIndex: number;
  primaryColor: string;
  secondaryColor: string;
  iconUrl: string;
}

const DemoCard: React.FC<DemoCardProps> = ({
  textSections,
  dataPointIndex,
  primaryColor,
  secondaryColor,
  iconUrl,
}) => {
  return (
    <Box className="demo_card_root">
      <Box
        className="icon"
        style={{
          backgroundImage: `url(${iconUrl})`,
          backgroundColor: primaryColor,
        }}
      />
      <Typography className="text">
        {textSections.map((text, index) => {
          return (
            <Typography
              component="span"
              style={index === dataPointIndex ? { color: primaryColor } : {}}
            >
              {`${text} `}
            </Typography>
          );
        })}
      </Typography>
    </Box>
  );
};

export default DemoCard;

import { connect } from "react-redux";
import { State } from "store";
import LoginComponent from "./LoginComponent";
import { appleLoginStartAction, googleLoginStartAction, setLoginStatusStartAction } from "actions";
import { AppleLoginPayload } from "types/GrabbiTypes";

const mapStateToProps = (state: State) => ({
  loginState: state.loginState,
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatchLogin: (username: string, passwordHash: string, admin: boolean) =>
    dispatch(setLoginStatusStartAction(username, passwordHash, admin)),
  dispatchGoogleLogin: (id_token: string) =>
    dispatch(googleLoginStartAction({ id_token })),
  dispatchAppleLogin: (payload: AppleLoginPayload) =>
    dispatch(appleLoginStartAction(payload)),
});

export const Login = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginComponent);

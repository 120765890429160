import { BaseAction, actionIds } from "actions";

export type CreatedMerchantState = {
  createdMerchant: boolean;
  createdMerchantError?: string;
};

export const createdMerchantReducer = (
  state: CreatedMerchantState = {
    createdMerchant: false,
  },
  action: BaseAction
) => {
  switch (action.type) {
    case actionIds.SIGNUP_START:
      return {
        ...state,
        createdMerchant: false,
        createdMerchantError: undefined
      };
    case actionIds.SIGNUP_SUCCESS:
      return {
        ...state,
        createdMerchant: true,
      };
    case actionIds.SIGNUP_FAILED:
      return {
        ...state,
        createdMerchant: false,
        createdMerchantError: action.payload.error,
      };
  }
  return state;
};

import { Grid } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { calculateTipableAmount } from "shared/scripts/Utils";
import { Transaction } from "types/GrabbiTypes";
import GrabbiButton from "../GrabbiButton/GrabbiButtonComponent";
import GrabbiTextField from "../GrabbiTextField/GrabbiTextField";
import "./ChangeTipComponent.scss";

interface Props {
  className?: string;
  transaction: Transaction;
  onChange?: (tip: number) => void;
  onError?: (error?: string) => void;
}

const ChangeTip: React.FC<Props> = ({
  className,
  transaction,
  onChange,
  onError,
}) => {
  const [tipAmount, setTipAmount] = useState<string>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (onError) {
      onError(error);
    }
  }, [error, onError]);

  const setTipAmountString = (
    amountString: string,
    manual: boolean = false
  ) => {
    let value = amountString;
    const number = parseFloat(amountString);
    if (!manual) {
      value = number.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    }
    value = value.replace(/\$/g, "");
    const sigFigs = value.split(".")[1];
    value =
      sigFigs && sigFigs.length > 2
        ? value.substring(0, value.length - 1)
        : value;
    value = `$${value}`;
    setTipAmount(value);
  };

  const calculateTip = (percentage: number) => {
    return (
      Math.round(calculateTipableAmount(transaction) * percentage * 100) / 100
    );
  };

  const handleSelectTipAmount = (percentage: number) => {
    setTipAmountString(calculateTip(percentage).toString());
  };

  const tip = useMemo(() => {
    return tipAmount
      ? parseFloat(tipAmount.replace("$", ""))
      : transaction.tip || undefined;
  }, [tipAmount, transaction]);

  useEffect(() => {
    if (tip) {
      setTipAmountString(`${tip}`);
    }
  }, [tip, transaction, setTipAmount]);

  useMemo(() => {
    if (tip !== undefined && !isNaN(tip) && onChange) {
      onChange(tip);
    }
  }, [tip, onChange]);

  useEffect(() => {
    if (tip === undefined) {
      setError("Tip Amount Required");
    } else if (isNaN(tip)) {
      setError("Tip must be valid number");
    } else {
      setError(undefined);
    }
  }, [tip, setError]);

  return (
    <Grid container className={`change_tip_root ${className ?? ""}`}>
      <Grid
        xs={12}
        item
        container
        className="confirmation_text_header_container"
        justify="center"
      >
        Enter Tip Amount
      </Grid>
      <Grid
        xs={12}
        item
        container
        className="tip_selection_container"
        justify="center"
        spacing={2}
      >
        <Grid xs={4} sm={3} item>
          <GrabbiButton onClick={() => handleSelectTipAmount(0.15)}>
            15%
          </GrabbiButton>
        </Grid>
        <Grid xs={4} sm={3} item>
          <GrabbiButton onClick={() => handleSelectTipAmount(0.18)}>
            18%
          </GrabbiButton>
        </Grid>
        <Grid xs={4} sm={3} item>
          <GrabbiButton onClick={() => handleSelectTipAmount(0.2)}>
            20%
          </GrabbiButton>
        </Grid>
      </Grid>
      <Grid xs={12} item container justify="center">
        <GrabbiTextField
          classes={{
            root: "tip_amount_field",
          }}
          name="tip_amount"
          value={tipAmount}
          label="Tip Amount"
          onChange={(e) =>
            setTipAmountString(
              e.currentTarget.value.replace(/^(?!.*(\d|\.)).*/g, ""),
              true
            )
          }
          error={error !== undefined}
          errorMessage={error}
        />
      </Grid>
    </Grid>
  );
};

export default ChangeTip;

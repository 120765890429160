import React, { ReactNode, useCallback, useEffect } from "react";
import { Route, RouteProps, useHistory } from "react-router-dom";
import { LoginState } from "store/signin/loginReducer";
import LoadingIcon from "components/Shared/LoadingIcon/LoadingIconComponent";
// import {
//   Dialog,
//   DialogContent,
//   DialogActions,
//   Box,
// } from "@material-ui/core";
// import "./AuthRouteComponent.scss";
// import {
//   TOKEN_WARNING_THRESHOLD,
//   INITIAL_SESSION_CHECK_INTERVAL,
//   DECREASE_SESSION_CHECK_INTERVAL_THRESHOLD,
// } from "shared/scripts/Constants";
// import { useInterval } from "shared/scripts/useInterval";
import { CONTEXT } from "shared/scripts/UrlContext";

interface Props extends RouteProps {
  loginState: LoginState;
  adminRoute?: boolean;
  path?: string;
  render: () => ReactNode;
  dispatchLogout: () => void;
  checkSessionDispatch: () => void;
}

const AuthRouteComponent: React.FC<Props> = ({
  loginState,
  adminRoute,
  path,
  render,
  dispatchLogout,
  checkSessionDispatch,
  ...otherRouteProps
}) => {
  // const getTimeToExpiration = (): number => {
  //   return loginState.tokensExpire
  //     ? loginState.tokensExpire - Math.floor(new Date().getTime() / 1000)
  //     : 0;
  // };

  // const [sessionWarningOpen, setSessionWarningOpen] = useState(false);
  // const [timeLeft, setTimeLeft] = useState(getTimeToExpiration());
  // const [refreshInterval, setRefreshInterval] = useState(
  //   INITIAL_SESSION_CHECK_INTERVAL
  // );

  // const minutesLeft = Math.floor(timeLeft / 60);
  // const secondsLeft = timeLeft % 60 >= 0 ? timeLeft % 60 : 0;

  const history = useHistory();
  // const location = useLocation();

  // const handleWarningClose = () => {
  //   handleRefresh();
  //   setSessionWarningOpen(false);
  // };

  // const handleSessionExpired = () => {
  //   dispatchLogout();
  //   history.push(`${CONTEXT}session-expired`);
  // };

  const handleNoSession = useCallback(
    (adminRoute: boolean = false) => {
      if (adminRoute) {
        history.push(`${CONTEXT}admin/login`);
      } else {
        history.push(`${CONTEXT}login`);
      }
    },
    [history]
  );

  // const handleLogout = () => {
  //   dispatchLogout();
  //   history.push(`${CONTEXT}logout`);
  // };

  // const handleRefresh = useCallback(() => {
  //   checkSessionDispatch();
  // }, [checkSessionDispatch]);

  useEffect(() => {
    if (!loginState.loggedIn && loginState.sessionChecked) {
      handleNoSession(adminRoute);
    }
  });

  // useEffect(() => {
  //   if (location) {
  //     handleRefresh();
  //   }
  // }, [location, handleRefresh]);

  if (loginState.loggedIn && !loginState.isAdmin && adminRoute) {
    history.push(`${CONTEXT}not-found`);
  }

  // useInterval(() => {
  //   const newTimeLeft = getTimeToExpiration();
  //   setTimeLeft(newTimeLeft);

  //   if (timeLeft < 0) {
  //     handleSessionExpired();
  //   } else if (timeLeft !== 0) {
  //     if (
  //       !sessionWarningOpen &&
  //       newTimeLeft <= TOKEN_WARNING_THRESHOLD &&
  //       !loginState.checkingSession &&
  //       loginState.sessionChecked
  //     ) {
  //       setSessionWarningOpen(true);
  //     } else if (
  //       sessionWarningOpen &&
  //       newTimeLeft > TOKEN_WARNING_THRESHOLD
  //     ) {
  //       setSessionWarningOpen(false);
  //     } else if (newTimeLeft <= DECREASE_SESSION_CHECK_INTERVAL_THRESHOLD) {
  //       setRefreshInterval(1000);
  //     } else if (
  //       newTimeLeft > DECREASE_SESSION_CHECK_INTERVAL_THRESHOLD &&
  //       INITIAL_SESSION_CHECK_INTERVAL !== refreshInterval
  //     ) {
  //       setRefreshInterval(INITIAL_SESSION_CHECK_INTERVAL);
  //     }
  //   }
  // }, refreshInterval);

  const component = loginState.loggedIn ? render() : <LoadingIcon />;

  return (
    <React.Fragment>
      {/* <Dialog open={sessionWarningOpen} onClose={handleWarningClose}>
        <DialogContent
          classes={{ root: "auth_guard_session_expired_dialog_content" }}
        >
          <Box className="auth_guard_session_expired_dialog_text">
            Your session will expire in:{" "}
          </Box>
          <Box className="auth_guard_session_expired_dialog_text">
            {minutesLeft > 0 ? `${minutesLeft} minutes and ` : ""}
            {secondsLeft} seconds
          </Box>
        </DialogContent>
        <DialogActions
          disableSpacing={true}
          classes={{ root: "auth_guard_session_expired_dialog_actions" }}
        >
          <button
            onClick={handleLogout}
            className="auth_guard_session_expired_button"
          >
            LOG OUT
          </button>
          <button
            onClick={handleWarningClose}
            className="auth_guard_session_expired_button"
          >
            REFRESH SESSION
          </button>
        </DialogActions>
      </Dialog> */}
      <Route path={path} {...otherRouteProps}>
        {component}
      </Route>
    </React.Fragment>
  );
};

export default AuthRouteComponent;

import { get } from "actions/api";

export type FetchMembershipResponse = {
  createdDate?: number;
  updatedDate?: number;
  id: number;
  errorResponse?: string;
  status: number;
  externalCustomerId: string;
  discount: number;
  description: string;
  menu: {
    createdDate?: number;
    updatedDate?: number;
    id: number;
    errorResponse?: string;
    label: string;
    enabled: boolean;
  };
};

export const fetchMembership = (storeId: number, email: string) => {
  return get<FetchMembershipResponse>(
    `restful-services/membership/${storeId}/${email}`
  ).then(res => res.data);
};

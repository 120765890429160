import { useMerchSelection } from "components/ResturantBoard/MerchandiseSelector/MerchandiseSelectorComponent";
import GrabbiButton from "components/Shared/GrabbiButton/GrabbiButtonComponent";
import React, { useState } from "react";
import { GrabbiStore, Transaction } from "types/GrabbiTypes";
import MyTab from "../MyTab/MyTabComponent";
import CloseCustomerTab from "./CloseCustomerTab/CloseCustomerTabComponent";
import "./OrderPlacedComponent.scss";

interface Props {
  className?: string;
  transaction: Transaction;
  store: GrabbiStore;
  onRefetch: () => void;
  onAddMore: (transaction: Transaction) => void;
  onClose: (transaction: Transaction) => void;
  onCashierCheckout: (transaction: Transaction) => void;
}

const OrderPlaced: React.FC<Props> = ({
  className,
  transaction,
  store,
  onRefetch,
  onAddMore,
  onClose,
  onCashierCheckout
}) => {
  const { setIsPosted } = useMerchSelection();
  const [showCloseDialog, setShowCloseDialog] = useState(false);

  return (
    <div className="order_placed_root">
      <CloseCustomerTab
        open={showCloseDialog}
        onClose={() => {
          setShowCloseDialog(false);
        }}
        onTransactionSuccess={() => {
          setShowCloseDialog(false);
          setIsPosted(false);
          onClose(transaction);
        }}
        onCashierCheckout={() => {
          setIsPosted(false);
          setShowCloseDialog(false);
          onCashierCheckout(transaction);
        }}
        onChange={onRefetch}
        transaction={transaction}
        store={store}
      />
      <div className="header_container">
        <span className="title">Your order is in!</span>
        <span className="subtitle">
          Don't forget to close out your tab at the end of your meal.
        </span>
      </div>
      <div className="order_more_container">
        <GrabbiButton
          className=""
          colorVariant="blue"
          shapeVariant="rectangle"
          onClick={() => onAddMore(transaction)}
        >
          Order More
        </GrabbiButton>
      </div>
      <MyTab transaction={transaction} />
      <div className="footer">
        <GrabbiButton
          className="close_tab_button m-b-4"
          shapeVariant="rounded"
          colorVariant="black"
          onClick={() => setShowCloseDialog(true)}
        >
          Close Tab
        </GrabbiButton>
      </div>
    </div>
  );
};

export default OrderPlaced;

import React, { useCallback, useEffect, useState } from "react";
import "./StarterKitComponent.scss";
import { Box, Button } from "@material-ui/core";
import GrabbiTextField from "components/Shared/GrabbiTextField/GrabbiTextField";
import { GrabbiLeadForMarketing } from "types/GrabbiTypes";
import { CreatedLeadFromStarterKitState } from "store/merchant/createdLeadFromStarterKitReducer";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { RFC_5322_EMAIL_REGEX } from "shared/scripts/Constants";
interface Props {
  leadForMarketing: GrabbiLeadForMarketing;
  createdLeadFromStarterKitState: CreatedLeadFromStarterKitState;
  createdLeadFromStarterKitError?: string;
  dispatchGetStarted: (leadForMarketing: GrabbiLeadForMarketing) => void;
}

const StarterKitComponent: React.FC<Props> = ({
  createdLeadFromStarterKitState,
  createdLeadFromStarterKitError,
  dispatchGetStarted,
}) => {
  const link = (text: string, href: string) =>
    (
      <a className="link_to_instagram" rel="noopener noreferrer" href={href} target="_blank">
        {text}
      </a>
    );

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    control,
  } = useForm({
    reValidateMode: "onBlur",
    shouldFocusError: true,
  });

  const history = useHistory();
  const [fieldsInitialized, setFieldsInitialized] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const initializeStoreFields = useCallback(() => {
    setValue("work_email", "");
    setFieldsInitialized(true);
  }, [setFieldsInitialized, setValue]);

  const handleSubmitValid = () => {
    const newLeadForMarketing: GrabbiLeadForMarketing = {
      firstName: 'starter_kit',
      lastName: 'starter_kit',
      email: getValues("work_email"),
      phone: '2222222222',
      storeName: getValues("work_email"),
      noOfLocations: "1",
      country: "USAorCANADA",
    };
    dispatchGetStarted(newLeadForMarketing);
  };

  const handleSubmitError = () => {
    enqueueSnackbar("Please resolve errors and try again.", {
      variant: "error",
    });
  };

  useEffect(() => {
    if (!fieldsInitialized) {
      initializeStoreFields();
    }
  }, [fieldsInitialized, initializeStoreFields, enqueueSnackbar]);

  useEffect(() => {
    if (createdLeadFromStarterKitState.createdLeadFromStarterKit) {
      enqueueSnackbar("Success! ONE OF OUR RETAIL EXPERTS WILL CONTACT YOU.", {
        variant: "success",
        autoHideDuration: 3500,
      });

      initializeStoreFields();
    } else if (createdLeadFromStarterKitError) {
      enqueueSnackbar(createdLeadFromStarterKitError, { variant: "error" });
    }
  }, [history, createdLeadFromStarterKitState, createdLeadFromStarterKitError, enqueueSnackbar, initializeStoreFields]);

  return (
    <div className="starter_kit_wrapper">
      <Box className="starter_kit_root">
        <div className="grabbi_starter_kit_header_container">
          <div className="grabbi_starter_kit_header_text">
            SMARTMENU
          </div>
          <div className="grabbi_starter_kit_body_text">
            90% off a beautiful custom Smartmenu™ design for only $50 (reg. $500 value)
          </div>
        </div>

        <div className='grabbi_starter_kit_section_grid'>
          <div className='grabbi_starter_kit_section_left_column'>
            <div className='grabbi_starter_kit_step_body_text_container'>
              <span className='grabbi_starter_kit_step_body_text'>
                Attract more people with an eye-catching menu
              </span>
            </div>
          </div>
          <div className='grabbi_starter_kit_section_middle_column'>
            <div className='grabbi_starter_kit_step_body_text_container'>
              <span className='grabbi_starter_kit_step_body_text'>
                Technology enabled menu to take orders faster
              </span>
            </div>
          </div>
          <div className='grabbi_starter_kit_section_right_column'>
            <div className='grabbi_starter_kit_step_body_text_container'>
              <span className='grabbi_starter_kit_step_body_text'>
                Improve line up efficiencies and staffing              </span>
            </div>
          </div>
        </div>

        <div className="partner_testimonials_section_background_container"></div>
        <div className='partner_testimonials_section'>
          <div className='partner_testimonials_container'>
            <div className='partner_testimonials_header_container'>
              <span className='starter_kit_section_header_text'>
                PARTNER TESTIMONIALS
              </span>
            </div>
            <div className='partner_testimonials_body_container'>
              <span className='starter_kit_section_body_text'>
                Hear from our partners and what they have to say about Grabbi
              </span>
            </div>
            <div className='grabbi_partner_testimonials_grid'>
              <div className='grabbi_partner_testimonials_left_column'>
                <div className='grabbi_partner_testimonials_header_left_container'>
                  <span className='starter_kit_section_sub_header_text'>
                    {link(
                      "THE SMOKE STOP BBQ",
                      "https://www.instagram.com/p/CO8xERsjlph/"
                    )}
                  </span>
                </div>
                <div className='grabbi_partner_testimonials_image' style={{
                    backgroundImage: `url('assets/grabbi-the-smoke-stop-testimonials.png')`}}>
                </div>
              </div>
              <div className='grabbi_partner_testimonials_right_column'>
                <div className='grabbi_partner_testimonials_header_right_container'>
                  <span className='starter_kit_section_sub_header_text'>
                    {link(
                      "FRESH COCONUTS",
                      "https://www.instagram.com/p/CQUsynbjEkB/"
                    )}
                  </span>
                </div>
                <div className='grabbi_partner_testimonials_image' style={{
                    backgroundImage: `url('assets/grabbi-the-hungry-moon-testimonials.png')`}}></div>
                </div>
            </div>
          </div>
        </div>

        <div className='grabbi_starter_kit_section'>
          <div className='grabbi_two_column_grid_in_starter_kit'>
            <div className='grabbi_two_column_grid_in_starter_kit_left_column'>
              <div className='grabbi_two_column_grid_in_starter_kit_left_container'>
                <span className='grabbi_starter_kit_section_header_text'>
                  SMARTMENU™ TILES
                </span>
              </div>
              <div className="grabbi_starter_kit_body_container">
                <span className='grabbi_starter_kit_section_body_text'>
                  We’ve standardized the design to make it easy and simple to order.
                </span>
                <Box m={3} />
                <span className='grabbi_starter_kit_section_body_text'>
                  Each menu item tile displays the menu item name, photo (optional), description, price, and comes with NFC Tags for tap to pay, and QR Codes for scan to pay.
                </span>
              </div>
            </div>
            <div className='grabbi_two_column_grid_in_starter_kit_right_column'>
              <div className='grabbi_starter_kit_image' style={{
                backgroundImage: `url('assets/grabbi-smart-menu-sample-card-2x.png')`}}></div>
            </div>
          </div>
        </div>

        <div className='section_divider divider'>.</div>

        <div className='grabbi_starter_kit_section'>
          <div className='grabbi_two_column_grid_in_starter_kit'>
            <div className='grabbi_two_column_grid_in_starter_kit_left_column'>
              <div className='grabbi_two_column_grid_in_starter_kit_left_container'>
                <span className='grabbi_starter_kit_section_header_text'>
                  EASY TO INTERACT WITH
                </span>
              </div>
              <div className="grabbi_starter_kit_body_container">
                <span className='grabbi_starter_kit_section_body_text'>
                  The Smartmenu™ Sign is large and visible, and does a great job at attracting people with it’s eye-catching design.
                </span>
                <Box m={3} />
                <span className='grabbi_starter_kit_section_body_text'>
                  Standardized menu tiles provide the customer with an easy, simple way to order.
                </span>
              </div>
            </div>
            <div className='grabbi_two_column_grid_in_starter_kit_right_column'>
              <div className='grabbi_starter_kit_image' style={{
                backgroundImage: `url('assets/grabbi-customer-scanning-smart-menu.png')`}}></div>
            </div>
          </div>
        </div>

        <div className='section_divider divider'>.</div>

        <div className='grabbi_starter_kit_section'>
          <div className='grabbi_two_column_grid_in_starter_kit'>
            <div className='grabbi_two_column_grid_in_starter_kit_left_column'>
              <div className='grabbi_two_column_grid_in_starter_kit_left_container'>
                <span className='grabbi_starter_kit_section_header_text'>
                  EMPOWRING CUSTOMERS
                </span>
              </div>
              <div className="grabbi_starter_kit_body_container">
                <span className='grabbi_starter_kit_section_body_text'>
                  Customers feel empowered to make their own choices and order themsleves. Providing customers with the ability to tap, pay and enjoy!
                </span>
                <Box m={3} />
                <span className='grabbi_starter_kit_section_body_text'>
                  The combination of the Smartmenu™ and the digital experience has been proven to increase sales and revenue.
                </span>
              </div>
            </div>
            <div className='grabbi_two_column_grid_in_starter_kit_right_column'>
              <div className='grabbi_starter_kit_image' style={{
                backgroundImage: `url('assets/grabbi-smart-menu.png')`}}></div>
            </div>
          </div>
        </div>

        <div className='section_divider divider'>.</div>

        <div className='grabbi_three_column_grid_in_starter_kit'>
          <div className='grabbi_three_column_grid_in_starter_kit_left_column'>
            <div className='grabbi_benefits_header '>
              <span className='grabbi_starter_kit_section_header_text'>
                Benefits
              </span>
            </div>
            <div className='grabbi_three_column_grid_in_starter_kit_body_text_container'>
              <ul>
                <li className='grabbi_benefits_body_text'>No App Installation</li>
                <li className='grabbi_benefits_body_text'>Smartmenu™</li>
                <li className='grabbi_benefits_body_text'>Zero Transaction Fees</li>
              </ul>
            </div>
          </div>
          <div className='grabbi_three_column_grid_in_starter_kit_middle_column'>
            <div className='grabbi_three_column_grid_in_starter_kit_body_text_container'>
              <ul>
                <li className='grabbi_benefits_body_text'>Promotions and Loyalty</li>
                <li className='grabbi_benefits_body_text'>Gift Cards</li>
                <li className='grabbi_benefits_body_text'>Push Notifications</li>
              </ul>
            </div>
          </div>
          <div className='grabbi_three_column_grid_in_starter_kit_right_column'>
            <div className='grabbi_three_column_grid_in_starter_kit_body_text_container'>
              <ul>
                <li className='grabbi_benefits_body_text'>Add or Modify Toppings</li>
                <li className='grabbi_benefits_body_text'>Modify Availability</li>
                <li className='grabbi_benefits_body_text'>AI & Data Analytics</li>
              </ul>
            </div>
          </div>
        </div>

        <div className='section_divider divider'>.</div>

        <div className='grabbi_starter_kit_section section_with_large_menu'>
          <div className='grabbi_two_column_grid_in_starter_kit'>
            <div className='grabbi_two_column_grid_with_large_image_left_column'>
              <div className='grabbi_two_column_grid_in_starter_kit_left_container'>
                <span className='grabbi_starter_kit_section_header_text'>
                  SMARTMENU™
                </span>
              </div>
              <div className="grabbi_starter_kit_body_container">
                <ul>
                  <li className='grabbi_benefits_body_text'>90% off design and print</li>
                  <li className='grabbi_benefits_body_text'>32x48” Smartmenu™ sign</li>
                  <li className='grabbi_benefits_body_text'>Custom QR codes for scan to pay</li>
                  <li className='grabbi_benefits_body_text'>Custom rabbit NFC tags for tap to pay (optional)</li>
                  <li className='grabbi_benefits_body_text'>Your menu images and logo</li>
                </ul>
              </div>
            </div>
            <div className='grabbi_two_column_grid_in_starter_kit_right_column'>
              <div className='grabbi_starter_kit_large_menu_image' style={{
                backgroundImage: `url('assets/grabbi-smoke-stop-bbq-32x48.png')`}}></div>
            </div>
          </div>
        </div>


        <div className="ready_to_start_section_in_starter_kit_background_container"></div>
        <div className='ready_to_start_section'>
          <div className="starter_kit_section_header_text">
            Build your business smarter
          </div>
          <div className="starter_kit_section_body_text">
            Build a strong customer base with our proven Smartmenu™
          </div>
          <div className="grabbi_starter_kit_signup_section">
            <form
              className="starter_kit_form"
              onSubmit={handleSubmit(handleSubmitValid, handleSubmitError)}
            >
              <div className='grabbi_starter_kit_form'>
                <div className='grabbi_starter_kit_form_texboxes'>
                  <GrabbiTextField
                    ref={register({
                      minLength: {
                        value: 6,
                        message: "6 or more characters needed",
                      },
                      maxLength: {
                        value: 50,
                        message: "50 or less characters needed",
                      },
                      required: {
                        value: true,
                        message: "Enter email address",
                      },
                      pattern: {
                        value: RFC_5322_EMAIL_REGEX,
                        message: "Invalid email address",
                      },
                      //? [REVISIT] Add validation of email address once email server is implemented
                    })}
                    placeholder="Email Address"
                    name="work_email"
                    label="Email Address"
                    error={errors.work_email}
                    errorMessage={errors?.work_email?.message}
                    control={control}
                    classes={{
                      root:
                        "demo_text_field_root_small",
                    }}
                    InputLabelProps={{
                      classes: {
                        root: `grabbi_texbox_label_with_dark_background`,
                        focused: `grabbi_texbox_label_with_dark_background_focused`,
                        disabled: "grabbi_texbox_label_with_dark_background_disabled",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root:
                          "demo_text_field_root_small",
                        focused: `grabbi_label_with_dark_background_focused`,
                      },
                      className: "grabbi_texboxes_with_dark_background",
                    }}
                    className="demo_text_field_root_small"
                  />
                </div>
              </div>

              <div>
                <Button
                type="submit"
                classes={{ root: "starter_kit_submit_button" }}>
                  Get Started
                </Button>
              </div>
            </form>
          </div>
        </div>

      </Box>
    </div>
  );
};

export default StarterKitComponent;

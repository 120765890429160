import { OrderItemStatus, Transaction } from "types/GrabbiTypes";

//Pixels
export const MOBILE_BREAKPOINT: number = 768;
export const isMobile = () => {
  return window.innerWidth <= MOBILE_BREAKPOINT;
};

//Seconds
export const TOKEN_EXPIRATION: number = 14400;
export const TOKEN_WARNING_THRESHOLD: number = 120;

//Milliseconds
export const INITIAL_SESSION_CHECK_INTERVAL: number = 1000;
export const DECREASE_SESSION_CHECK_INTERVAL_THRESHOLD: number =
  TOKEN_WARNING_THRESHOLD * 2;

export const ADMIN_MERCHANT_FETCH_PAGE_SIZE: number = 10;
export const ADMIN_MERCH_FETCH_DEFAULT_SORT_BY: string = "id";

export const ADMIN_STORE_FETCH_PAGE_SIZE: number = 10;
export const ADMIN_STORE_FETCH_DEFAULT_SORT_BY: string = "id";

export const ONLINE_APP_STORE = "ios";
export const ONLINE_ANDROID_STORE = "android";

export const RFC_5322_EMAIL_REGEX =
  /^[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
export const PHONE_REGEX =
  /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,16}$/i; //? [REVISIT] We should consider replacing this with a validator based on locality
export const ZIP_REGEX =
  /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$|^\d{5}(?:[-\s]\d{4})?/; //Canadian or US
export const PASSWORD_REGEX = /^[a-zA-Z0-9!@#$%^&*-/"]+$/i;
export const NAME_REGEX = /^[a-zA-Z -']+$/i;
export const ALPHANUMERIC_REGEX = /^[a-zA-Z\d\-_\s]+$/i;
export const NUMERIC_REGEX = /^[0-9]+$/i;
export const ALPHABETIC_REGEX = /^[a-zA-Z\s]+$/i;
export const ADDRESS_REGEX = /^[a-zA-Z0-9\-_\s,.#]+$/i;
export const PRICE_REGEX = /^\$?[0-9]+(\.[0-9][0-9]?)?$/;

export const GOOGLE_CLIENT_ID =
  "249468565282-rl7nqqfbkboclp1t3sagmkgam424rq85.apps.googleusercontent.com";
export const APPLE_CLIENT_ID = "com.paygex.MerchantWebServiceId";

export const LAST_VIEWED_KEY = "grabbi_dashboard_last_viewed";

export const CURRENCY_NUMFMT = "$#,##0.00;[Red]$(#,##0.00)";

export const APPLE_STORE_URL =
  "https://apps.apple.com/us/app/grabbi/id1495566554";
export const GOOGLE_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.paygex.android.grabbi";

export const LS_SELECTOR_KEY = "ShowStoreSelector";

export const ITEM_STATUS_SEQUENCE: OrderItemStatus[] = [
  "RECEIVED",
  "PREPARING",
  "DELIVERED",
  "CLOSED",
];

export const MERCHANT_STYLE_OPTIONS: string[] = ["Kitchen", "Bar"];

export const muixs = 0;
export const muism = 600;
export const muimd = 900;
export const muilg = 1200;
export const muixl = 1536;

export const formatCurrency = (amount: number, currencyCode: string) =>
  amount.toLocaleString(undefined, {
    style: "currency",
    currency: currencyCode,
  });

export const getTransactionTotalString = (transaction: Transaction) =>
  formatCurrency(transaction.transactionTotal, transaction.currency);

  export const printersLocationIdBasedOnNameStr = new Map<string, number | null>([
    ["Kitchen", 0],
    ["Bar", 1],
    ["Customer Reciept", 2],
    ["", 3],
  ]);

  export const printersLocationBasedOnId = new Map<number, string>([
    [0, "Kitchen"],
    [1, "Bar"],
    [2, "Customer Reciept"],
    [3, ""],
  ]);

export const MEMBERSHIP_EMAIL_COOKIE_KEY = "MEMBERSHIP_EMAIL";